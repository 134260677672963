function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import log from '../utils/log';
import { isAsyncIterable } from '../utils/iterable-utils';
import { parsePropTypes } from './prop-types';
import { COMPONENT_SYMBOL, PROP_TYPES_SYMBOL, DEPRECATED_PROPS_SYMBOL, ASYNC_ORIGINAL_SYMBOL, ASYNC_RESOLVED_SYMBOL, ASYNC_DEFAULTS_SYMBOL } from './constants';
export function createProps(component, propObjects) {
  var extensions;

  for (var i = propObjects.length - 1; i >= 0; i--) {
    var props = propObjects[i];

    if ('extensions' in props) {
      extensions = props.extensions;
    }
  }

  var propsPrototype = getPropsPrototype(component.constructor, extensions);
  var propsInstance = Object.create(propsPrototype);
  propsInstance[COMPONENT_SYMBOL] = component;
  propsInstance[ASYNC_ORIGINAL_SYMBOL] = {};
  propsInstance[ASYNC_RESOLVED_SYMBOL] = {};

  for (var _i = 0; _i < propObjects.length; ++_i) {
    var _props = propObjects[_i];

    for (var key in _props) {
      propsInstance[key] = _props[key];
    }
  }

  Object.freeze(propsInstance);
  return propsInstance;
}
var MergedDefaultPropsCacheKey = '_mergedDefaultProps';

function getPropsPrototype(componentClass, extensions) {
  var cacheKey = MergedDefaultPropsCacheKey;

  if (extensions) {
    var _iterator = _createForOfIteratorHelper(extensions),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var extension = _step.value;
        var ExtensionClass = extension.constructor;

        if (ExtensionClass) {
          cacheKey += ":".concat(ExtensionClass.extensionName || ExtensionClass.name);
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
  }

  var defaultProps = getOwnProperty(componentClass, cacheKey);

  if (!defaultProps) {
    return componentClass[cacheKey] = createPropsPrototypeAndTypes(componentClass, extensions || []);
  }

  return defaultProps;
}

function createPropsPrototypeAndTypes(componentClass, extensions) {
  var parent = componentClass.prototype;

  if (!parent) {
    return null;
  }

  var parentClass = Object.getPrototypeOf(componentClass);
  var parentDefaultProps = getPropsPrototype(parentClass);
  var componentDefaultProps = getOwnProperty(componentClass, 'defaultProps') || {};
  var componentPropDefs = parsePropTypes(componentDefaultProps);
  var defaultProps = Object.assign(Object.create(null), parentDefaultProps, componentPropDefs.defaultProps);
  var propTypes = Object.assign(Object.create(null), parentDefaultProps === null || parentDefaultProps === void 0 ? void 0 : parentDefaultProps[PROP_TYPES_SYMBOL], componentPropDefs.propTypes);
  var deprecatedProps = Object.assign(Object.create(null), parentDefaultProps === null || parentDefaultProps === void 0 ? void 0 : parentDefaultProps[DEPRECATED_PROPS_SYMBOL], componentPropDefs.deprecatedProps);

  var _iterator2 = _createForOfIteratorHelper(extensions),
      _step2;

  try {
    for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
      var extension = _step2.value;
      var extensionDefaultProps = getPropsPrototype(extension.constructor);

      if (extensionDefaultProps) {
        Object.assign(defaultProps, extensionDefaultProps);
        Object.assign(propTypes, extensionDefaultProps[PROP_TYPES_SYMBOL]);
        Object.assign(deprecatedProps, extensionDefaultProps[DEPRECATED_PROPS_SYMBOL]);
      }
    }
  } catch (err) {
    _iterator2.e(err);
  } finally {
    _iterator2.f();
  }

  createPropsPrototype(defaultProps, componentClass);
  addAsyncPropsToPropPrototype(defaultProps, propTypes);
  addDeprecatedPropsToPropPrototype(defaultProps, deprecatedProps);
  defaultProps[PROP_TYPES_SYMBOL] = propTypes;
  defaultProps[DEPRECATED_PROPS_SYMBOL] = deprecatedProps;

  if (extensions.length === 0 && !hasOwnProperty(componentClass, '_propTypes')) {
    componentClass._propTypes = propTypes;
  }

  return defaultProps;
}

function createPropsPrototype(defaultProps, componentClass) {
  var id = getComponentName(componentClass);
  Object.defineProperties(defaultProps, {
    id: {
      writable: true,
      value: id
    }
  });
}

function addDeprecatedPropsToPropPrototype(defaultProps, deprecatedProps) {
  var _loop = function _loop(propName) {
    Object.defineProperty(defaultProps, propName, {
      enumerable: false,
      set: function set(newValue) {
        var nameStr = "".concat(this.id, ": ").concat(propName);

        var _iterator3 = _createForOfIteratorHelper(deprecatedProps[propName]),
            _step3;

        try {
          for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
            var newPropName = _step3.value;

            if (!hasOwnProperty(this, newPropName)) {
              this[newPropName] = newValue;
            }
          }
        } catch (err) {
          _iterator3.e(err);
        } finally {
          _iterator3.f();
        }

        log.deprecated(nameStr, deprecatedProps[propName].join('/'))();
      }
    });
  };

  for (var propName in deprecatedProps) {
    _loop(propName);
  }
}

function addAsyncPropsToPropPrototype(defaultProps, propTypes) {
  var defaultValues = {};
  var descriptors = {};

  for (var propName in propTypes) {
    var propType = propTypes[propName];
    var name = propType.name,
        value = propType.value;

    if (propType.async) {
      defaultValues[name] = value;
      descriptors[name] = getDescriptorForAsyncProp(name);
    }
  }

  defaultProps[ASYNC_DEFAULTS_SYMBOL] = defaultValues;
  defaultProps[ASYNC_ORIGINAL_SYMBOL] = {};
  Object.defineProperties(defaultProps, descriptors);
}

function getDescriptorForAsyncProp(name) {
  return {
    enumerable: true,
    set: function set(newValue) {
      if (typeof newValue === 'string' || newValue instanceof Promise || isAsyncIterable(newValue)) {
        this[ASYNC_ORIGINAL_SYMBOL][name] = newValue;
      } else {
        this[ASYNC_RESOLVED_SYMBOL][name] = newValue;
      }
    },
    get: function get() {
      if (this[ASYNC_RESOLVED_SYMBOL]) {
        if (name in this[ASYNC_RESOLVED_SYMBOL]) {
          var value = this[ASYNC_RESOLVED_SYMBOL][name];
          return value || this[ASYNC_DEFAULTS_SYMBOL][name];
        }

        if (name in this[ASYNC_ORIGINAL_SYMBOL]) {
          var state = this[COMPONENT_SYMBOL] && this[COMPONENT_SYMBOL].internalState;

          if (state && state.hasAsyncProp(name)) {
            return state.getAsyncProp(name) || this[ASYNC_DEFAULTS_SYMBOL][name];
          }
        }
      }

      return this[ASYNC_DEFAULTS_SYMBOL][name];
    }
  };
}

function hasOwnProperty(object, prop) {
  return Object.prototype.hasOwnProperty.call(object, prop);
}

function getOwnProperty(object, prop) {
  return hasOwnProperty(object, prop) && object[prop];
}

function getComponentName(componentClass) {
  var componentName = componentClass.componentName;

  if (!componentName) {
    log.warn("".concat(componentClass.name, ".componentName not specified"))();
  }

  return componentName || componentClass.name;
}