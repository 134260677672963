import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _DEFAULT_TEXTURE_PARA;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { Texture2D } from '@luma.gl/core';
var DEFAULT_TEXTURE_PARAMETERS = (_DEFAULT_TEXTURE_PARA = {}, _defineProperty(_DEFAULT_TEXTURE_PARA, 10241, 9987), _defineProperty(_DEFAULT_TEXTURE_PARA, 10240, 9729), _defineProperty(_DEFAULT_TEXTURE_PARA, 10242, 33071), _defineProperty(_DEFAULT_TEXTURE_PARA, 10243, 33071), _DEFAULT_TEXTURE_PARA);
var internalTextures = {};
export function createTexture(owner, gl, image, parameters) {
  if (image instanceof Texture2D) {
    return image;
  } else if (image.constructor && image.constructor.name !== 'Object') {
    image = {
      data: image
    };
  }

  var specialTextureParameters = null;

  if (image.compressed) {
    specialTextureParameters = _defineProperty({}, 10241, image.data.length > 1 ? 9985 : 9729);
  }

  var texture = new Texture2D(gl, _objectSpread(_objectSpread({}, image), {}, {
    parameters: _objectSpread(_objectSpread(_objectSpread({}, DEFAULT_TEXTURE_PARAMETERS), specialTextureParameters), parameters)
  }));
  internalTextures[texture.id] = owner;
  return texture;
}
export function destroyTexture(owner, texture) {
  if (!texture || !(texture instanceof Texture2D)) {
    return;
  }

  if (internalTextures[texture.id] === owner) {
    texture.delete();
    delete internalTextures[texture.id];
  }
}