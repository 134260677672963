import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _assertThisInitialized from "@babel/runtime/helpers/assertThisInitialized";
import _get from "@babel/runtime/helpers/get";
import _inherits from "@babel/runtime/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _parameters;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

import { getBounds, boundsContain, packVertices, scaleToAspectRatio, getTextureCoordinates, getTextureParams } from './heatmap-layer-utils';
import { Buffer, Texture2D, Transform, getParameters, withParameters, FEATURES, hasFeatures } from '@luma.gl/core';
import { AttributeManager, COORDINATE_SYSTEM, log } from '@deck.gl/core';
import TriangleLayer from './triangle-layer';
import AggregationLayer from '../aggregation-layer';
import { defaultColorRange, colorRangeToFlatArray } from '../utils/color-utils';
import weightsVs from './weights-vs.glsl';
import weightsFs from './weights-fs.glsl';
import vsMax from './max-vs.glsl';
import fsMax from './max-fs.glsl';
var RESOLUTION = 2;
var TEXTURE_OPTIONS = {
  mipmaps: false,
  parameters: (_parameters = {}, _defineProperty(_parameters, 10240, 9729), _defineProperty(_parameters, 10241, 9729), _defineProperty(_parameters, 10242, 33071), _defineProperty(_parameters, 10243, 33071), _parameters),
  dataFormat: 6408
};
var DEFAULT_COLOR_DOMAIN = [0, 0];
var AGGREGATION_MODE = {
  SUM: 0,
  MEAN: 1
};
var defaultProps = {
  getPosition: {
    type: 'accessor',
    value: function value(x) {
      return x.position;
    }
  },
  getWeight: {
    type: 'accessor',
    value: 1
  },
  intensity: {
    type: 'number',
    min: 0,
    value: 1
  },
  radiusPixels: {
    type: 'number',
    min: 1,
    max: 100,
    value: 50
  },
  colorRange: defaultColorRange,
  threshold: {
    type: 'number',
    min: 0,
    max: 1,
    value: 0.05
  },
  colorDomain: {
    type: 'array',
    value: null,
    optional: true
  },
  aggregation: 'SUM',
  weightsTextureSize: {
    type: 'number',
    min: 128,
    max: 2048,
    value: 2048
  },
  debounceTimeout: {
    type: 'number',
    min: 0,
    max: 1000,
    value: 500
  }
};
var REQUIRED_FEATURES = [FEATURES.BLEND_EQUATION_MINMAX, FEATURES.TEXTURE_FLOAT];
var FLOAT_TARGET_FEATURES = [FEATURES.COLOR_ATTACHMENT_RGBA32F, FEATURES.FLOAT_BLEND];
var DIMENSIONS = {
  data: {
    props: ['radiusPixels']
  }
};

var HeatmapLayer = function (_AggregationLayer) {
  _inherits(HeatmapLayer, _AggregationLayer);

  var _super = _createSuper(HeatmapLayer);

  function HeatmapLayer() {
    var _this;

    _classCallCheck(this, HeatmapLayer);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));

    _defineProperty(_assertThisInitialized(_this), "state", void 0);

    return _this;
  }

  _createClass(HeatmapLayer, [{
    key: "initializeState",
    value: function initializeState() {
      var gl = this.context.gl;

      if (!hasFeatures(gl, REQUIRED_FEATURES)) {
        this.setState({
          supported: false
        });
        log.error("HeatmapLayer: ".concat(this.id, " is not supported on this browser"))();
        return;
      }

      _get(_getPrototypeOf(HeatmapLayer.prototype), "initializeAggregationLayer", this).call(this, DIMENSIONS);

      this.setState({
        supported: true,
        colorDomain: DEFAULT_COLOR_DOMAIN
      });

      this._setupTextureParams();

      this._setupAttributes();

      this._setupResources();
    }
  }, {
    key: "shouldUpdateState",
    value: function shouldUpdateState(_ref) {
      var changeFlags = _ref.changeFlags;
      return changeFlags.somethingChanged;
    }
  }, {
    key: "updateState",
    value: function updateState(opts) {
      if (!this.state.supported) {
        return;
      }

      _get(_getPrototypeOf(HeatmapLayer.prototype), "updateState", this).call(this, opts);

      this._updateHeatmapState(opts);
    }
  }, {
    key: "_updateHeatmapState",
    value: function _updateHeatmapState(opts) {
      var props = opts.props,
          oldProps = opts.oldProps;

      var changeFlags = this._getChangeFlags(opts);

      if (changeFlags.dataChanged || changeFlags.viewportChanged) {
        changeFlags.boundsChanged = this._updateBounds(changeFlags.dataChanged);

        this._updateTextureRenderingBounds();
      }

      if (changeFlags.dataChanged || changeFlags.boundsChanged) {
        clearTimeout(this.state.updateTimer);
        this.setState({
          isWeightMapDirty: true
        });
      } else if (changeFlags.viewportZoomChanged) {
        this._debouncedUpdateWeightmap();
      }

      if (props.colorRange !== oldProps.colorRange) {
        this._updateColorTexture(opts);
      }

      if (this.state.isWeightMapDirty) {
        this._updateWeightmap();
      }

      this.setState({
        zoom: opts.context.viewport.zoom
      });
    }
  }, {
    key: "renderLayers",
    value: function renderLayers() {
      if (!this.state.supported) {
        return [];
      }

      var _this$state = this.state,
          weightsTexture = _this$state.weightsTexture,
          triPositionBuffer = _this$state.triPositionBuffer,
          triTexCoordBuffer = _this$state.triTexCoordBuffer,
          maxWeightsTexture = _this$state.maxWeightsTexture,
          colorTexture = _this$state.colorTexture,
          colorDomain = _this$state.colorDomain;
      var _this$props = this.props,
          updateTriggers = _this$props.updateTriggers,
          intensity = _this$props.intensity,
          threshold = _this$props.threshold,
          aggregation = _this$props.aggregation;
      var TriangleLayerClass = this.getSubLayerClass('triangle', TriangleLayer);
      return new TriangleLayerClass(this.getSubLayerProps({
        id: 'triangle-layer',
        updateTriggers: updateTriggers
      }), {
        coordinateSystem: COORDINATE_SYSTEM.DEFAULT,
        data: {
          attributes: {
            positions: triPositionBuffer,
            texCoords: triTexCoordBuffer
          }
        },
        vertexCount: 4,
        maxTexture: maxWeightsTexture,
        colorTexture: colorTexture,
        aggregationMode: AGGREGATION_MODE[aggregation] || 0,
        texture: weightsTexture,
        intensity: intensity,
        threshold: threshold,
        colorDomain: colorDomain
      });
    }
  }, {
    key: "finalizeState",
    value: function finalizeState(context) {
      _get(_getPrototypeOf(HeatmapLayer.prototype), "finalizeState", this).call(this, context);

      var _this$state2 = this.state,
          weightsTransform = _this$state2.weightsTransform,
          weightsTexture = _this$state2.weightsTexture,
          maxWeightTransform = _this$state2.maxWeightTransform,
          maxWeightsTexture = _this$state2.maxWeightsTexture,
          triPositionBuffer = _this$state2.triPositionBuffer,
          triTexCoordBuffer = _this$state2.triTexCoordBuffer,
          colorTexture = _this$state2.colorTexture,
          updateTimer = _this$state2.updateTimer;
      weightsTransform === null || weightsTransform === void 0 ? void 0 : weightsTransform.delete();
      weightsTexture === null || weightsTexture === void 0 ? void 0 : weightsTexture.delete();
      maxWeightTransform === null || maxWeightTransform === void 0 ? void 0 : maxWeightTransform.delete();
      maxWeightsTexture === null || maxWeightsTexture === void 0 ? void 0 : maxWeightsTexture.delete();
      triPositionBuffer === null || triPositionBuffer === void 0 ? void 0 : triPositionBuffer.delete();
      triTexCoordBuffer === null || triTexCoordBuffer === void 0 ? void 0 : triTexCoordBuffer.delete();
      colorTexture === null || colorTexture === void 0 ? void 0 : colorTexture.delete();

      if (updateTimer) {
        clearTimeout(updateTimer);
      }
    }
  }, {
    key: "_getAttributeManager",
    value: function _getAttributeManager() {
      return new AttributeManager(this.context.gl, {
        id: this.props.id,
        stats: this.context.stats
      });
    }
  }, {
    key: "_getChangeFlags",
    value: function _getChangeFlags(opts) {
      var changeFlags = {};
      var dimensions = this.state.dimensions;
      changeFlags.dataChanged = this.isAttributeChanged() || this.isAggregationDirty(opts, {
        compareAll: true,
        dimension: dimensions.data
      });
      changeFlags.viewportChanged = opts.changeFlags.viewportChanged;
      var zoom = this.state.zoom;

      if (!opts.context.viewport || opts.context.viewport.zoom !== zoom) {
        changeFlags.viewportZoomChanged = true;
      }

      return changeFlags;
    }
  }, {
    key: "_createTextures",
    value: function _createTextures() {
      var gl = this.context.gl;
      var _this$state3 = this.state,
          textureSize = _this$state3.textureSize,
          format = _this$state3.format,
          type = _this$state3.type;
      this.setState({
        weightsTexture: new Texture2D(gl, _objectSpread({
          width: textureSize,
          height: textureSize,
          format: format,
          type: type
        }, TEXTURE_OPTIONS)),
        maxWeightsTexture: new Texture2D(gl, _objectSpread({
          format: format,
          type: type
        }, TEXTURE_OPTIONS))
      });
    }
  }, {
    key: "_setupAttributes",
    value: function _setupAttributes() {
      var attributeManager = this.getAttributeManager();
      attributeManager.add({
        positions: {
          size: 3,
          type: 5130,
          accessor: 'getPosition'
        },
        weights: {
          size: 1,
          accessor: 'getWeight'
        }
      });
      this.setState({
        positionAttributeName: 'positions'
      });
    }
  }, {
    key: "_setupTextureParams",
    value: function _setupTextureParams() {
      var gl = this.context.gl;
      var weightsTextureSize = this.props.weightsTextureSize;
      var textureSize = Math.min(weightsTextureSize, getParameters(gl, 3379));
      var floatTargetSupport = hasFeatures(gl, FLOAT_TARGET_FEATURES);

      var _getTextureParams = getTextureParams({
        gl: gl,
        floatTargetSupport: floatTargetSupport
      }),
          format = _getTextureParams.format,
          type = _getTextureParams.type;

      var weightsScale = floatTargetSupport ? 1 : 1 / 255;
      this.setState({
        textureSize: textureSize,
        format: format,
        type: type,
        weightsScale: weightsScale
      });

      if (!floatTargetSupport) {
        log.warn("HeatmapLayer: ".concat(this.id, " rendering to float texture not supported, fallingback to low precession format"))();
      }
    }
  }, {
    key: "getShaders",
    value: function getShaders(type) {
      return _get(_getPrototypeOf(HeatmapLayer.prototype), "getShaders", this).call(this, type === 'max-weights-transform' ? {
        vs: vsMax,
        _fs: fsMax
      } : {
        vs: weightsVs,
        _fs: weightsFs
      });
    }
  }, {
    key: "_createWeightsTransform",
    value: function _createWeightsTransform() {
      var _weightsTransform;

      var shaders = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var gl = this.context.gl;
      var weightsTransform = this.state.weightsTransform;
      var weightsTexture = this.state.weightsTexture;
      (_weightsTransform = weightsTransform) === null || _weightsTransform === void 0 ? void 0 : _weightsTransform.delete();
      weightsTransform = new Transform(gl, _objectSpread({
        id: "".concat(this.id, "-weights-transform"),
        elementCount: 1,
        _targetTexture: weightsTexture,
        _targetTextureVarying: 'weightsTexture'
      }, shaders));
      this.setState({
        weightsTransform: weightsTransform
      });
    }
  }, {
    key: "_setupResources",
    value: function _setupResources() {
      var gl = this.context.gl;

      this._createTextures();

      var _this$state4 = this.state,
          textureSize = _this$state4.textureSize,
          weightsTexture = _this$state4.weightsTexture,
          maxWeightsTexture = _this$state4.maxWeightsTexture;
      var weightsTransformShaders = this.getShaders('weights-transform');

      this._createWeightsTransform(weightsTransformShaders);

      var maxWeightsTransformShaders = this.getShaders('max-weights-transform');
      var maxWeightTransform = new Transform(gl, _objectSpread(_objectSpread({
        id: "".concat(this.id, "-max-weights-transform"),
        _sourceTextures: {
          inTexture: weightsTexture
        },
        _targetTexture: maxWeightsTexture,
        _targetTextureVarying: 'outTexture'
      }, maxWeightsTransformShaders), {}, {
        elementCount: textureSize * textureSize
      }));
      this.setState({
        weightsTexture: weightsTexture,
        maxWeightsTexture: maxWeightsTexture,
        maxWeightTransform: maxWeightTransform,
        zoom: null,
        triPositionBuffer: new Buffer(gl, {
          byteLength: 48,
          accessor: {
            size: 3
          }
        }),
        triTexCoordBuffer: new Buffer(gl, {
          byteLength: 48,
          accessor: {
            size: 2
          }
        })
      });
    }
  }, {
    key: "updateShaders",
    value: function updateShaders(shaderOptions) {
      this._createWeightsTransform(shaderOptions);
    }
  }, {
    key: "_updateMaxWeightValue",
    value: function _updateMaxWeightValue() {
      var maxWeightTransform = this.state.maxWeightTransform;
      maxWeightTransform.run({
        parameters: {
          blend: true,
          depthTest: false,
          blendFunc: [1, 1],
          blendEquation: 32776
        }
      });
    }
  }, {
    key: "_updateBounds",
    value: function _updateBounds() {
      var forceUpdate = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var viewport = this.context.viewport;
      var viewportCorners = [viewport.unproject([0, 0]), viewport.unproject([viewport.width, 0]), viewport.unproject([viewport.width, viewport.height]), viewport.unproject([0, viewport.height])].map(function (p) {
        return p.map(Math.fround);
      });
      var visibleWorldBounds = getBounds(viewportCorners);
      var newState = {
        visibleWorldBounds: visibleWorldBounds,
        viewportCorners: viewportCorners
      };
      var boundsChanged = false;

      if (forceUpdate || !this.state.worldBounds || !boundsContain(this.state.worldBounds, visibleWorldBounds)) {
        var scaledCommonBounds = this._worldToCommonBounds(visibleWorldBounds);

        var worldBounds = this._commonToWorldBounds(scaledCommonBounds);

        if (this.props.coordinateSystem === COORDINATE_SYSTEM.LNGLAT) {
          worldBounds[1] = Math.max(worldBounds[1], -85.051129);
          worldBounds[3] = Math.min(worldBounds[3], 85.051129);
          worldBounds[0] = Math.max(worldBounds[0], -360);
          worldBounds[2] = Math.min(worldBounds[2], 360);
        }

        var normalizedCommonBounds = this._worldToCommonBounds(worldBounds);

        newState.worldBounds = worldBounds;
        newState.normalizedCommonBounds = normalizedCommonBounds;
        boundsChanged = true;
      }

      this.setState(newState);
      return boundsChanged;
    }
  }, {
    key: "_updateTextureRenderingBounds",
    value: function _updateTextureRenderingBounds() {
      var _this$state5 = this.state,
          triPositionBuffer = _this$state5.triPositionBuffer,
          triTexCoordBuffer = _this$state5.triTexCoordBuffer,
          normalizedCommonBounds = _this$state5.normalizedCommonBounds,
          viewportCorners = _this$state5.viewportCorners;
      var viewport = this.context.viewport;
      triPositionBuffer.subData(packVertices(viewportCorners, 3));
      var textureBounds = viewportCorners.map(function (p) {
        return getTextureCoordinates(viewport.projectPosition(p), normalizedCommonBounds);
      });
      triTexCoordBuffer.subData(packVertices(textureBounds, 2));
    }
  }, {
    key: "_updateColorTexture",
    value: function _updateColorTexture(opts) {
      var colorRange = opts.props.colorRange;
      var colorTexture = this.state.colorTexture;
      var colors = colorRangeToFlatArray(colorRange, false, Uint8Array);

      if (colorTexture) {
        colorTexture.setImageData({
          data: colors,
          width: colorRange.length
        });
      } else {
        colorTexture = new Texture2D(this.context.gl, _objectSpread({
          data: colors,
          width: colorRange.length,
          height: 1
        }, TEXTURE_OPTIONS));
      }

      this.setState({
        colorTexture: colorTexture
      });
    }
  }, {
    key: "_updateWeightmap",
    value: function _updateWeightmap() {
      var _this2 = this,
          _weightsTexture$setPa;

      var _this$props2 = this.props,
          radiusPixels = _this$props2.radiusPixels,
          colorDomain = _this$props2.colorDomain,
          aggregation = _this$props2.aggregation;
      var _this$state6 = this.state,
          weightsTransform = _this$state6.weightsTransform,
          worldBounds = _this$state6.worldBounds,
          textureSize = _this$state6.textureSize,
          weightsTexture = _this$state6.weightsTexture,
          weightsScale = _this$state6.weightsScale;
      this.state.isWeightMapDirty = false;

      var commonBounds = this._worldToCommonBounds(worldBounds, {
        useLayerCoordinateSystem: true
      });

      if (colorDomain && aggregation === 'SUM') {
        var viewport = this.context.viewport;
        var metersPerPixel = viewport.distanceScales.metersPerUnit[2] * (commonBounds[2] - commonBounds[0]) / textureSize;
        this.state.colorDomain = colorDomain.map(function (x) {
          return x * metersPerPixel * weightsScale;
        });
      } else {
        this.state.colorDomain = colorDomain || DEFAULT_COLOR_DOMAIN;
      }

      var uniforms = {
        radiusPixels: radiusPixels,
        commonBounds: commonBounds,
        textureWidth: textureSize,
        weightsScale: weightsScale
      };
      weightsTransform.update({
        elementCount: this.getNumInstances()
      });
      withParameters(this.context.gl, {
        clearColor: [0, 0, 0, 0]
      }, function () {
        weightsTransform.run({
          uniforms: uniforms,
          parameters: {
            blend: true,
            depthTest: false,
            blendFunc: [1, 1],
            blendEquation: 32774
          },
          clearRenderTarget: true,
          attributes: _this2.getAttributes(),
          moduleSettings: _this2.getModuleSettings()
        });
      });

      this._updateMaxWeightValue();

      weightsTexture.setParameters((_weightsTexture$setPa = {}, _defineProperty(_weightsTexture$setPa, 10240, 9729), _defineProperty(_weightsTexture$setPa, 10241, 9729), _weightsTexture$setPa));
    }
  }, {
    key: "_debouncedUpdateWeightmap",
    value: function _debouncedUpdateWeightmap() {
      var fromTimer = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var updateTimer = this.state.updateTimer;
      var debounceTimeout = this.props.debounceTimeout;

      if (fromTimer) {
        updateTimer = null;

        this._updateBounds(true);

        this._updateTextureRenderingBounds();

        this.setState({
          isWeightMapDirty: true
        });
      } else {
        this.setState({
          isWeightMapDirty: false
        });
        clearTimeout(updateTimer);
        updateTimer = setTimeout(this._debouncedUpdateWeightmap.bind(this, true), debounceTimeout);
      }

      this.setState({
        updateTimer: updateTimer
      });
    }
  }, {
    key: "_worldToCommonBounds",
    value: function _worldToCommonBounds(worldBounds) {
      var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var _opts$useLayerCoordin = opts.useLayerCoordinateSystem,
          useLayerCoordinateSystem = _opts$useLayerCoordin === void 0 ? false : _opts$useLayerCoordin;

      var _worldBounds = _slicedToArray(worldBounds, 4),
          minLong = _worldBounds[0],
          minLat = _worldBounds[1],
          maxLong = _worldBounds[2],
          maxLat = _worldBounds[3];

      var viewport = this.context.viewport;
      var textureSize = this.state.textureSize;
      var coordinateSystem = this.props.coordinateSystem;
      var offsetMode = useLayerCoordinateSystem && (coordinateSystem === COORDINATE_SYSTEM.LNGLAT_OFFSETS || coordinateSystem === COORDINATE_SYSTEM.METER_OFFSETS);
      var offsetOriginCommon = offsetMode ? viewport.projectPosition(this.props.coordinateOrigin) : [0, 0];
      var size = textureSize * RESOLUTION / viewport.scale;
      var bottomLeftCommon;
      var topRightCommon;

      if (useLayerCoordinateSystem && !offsetMode) {
        bottomLeftCommon = this.projectPosition([minLong, minLat, 0]);
        topRightCommon = this.projectPosition([maxLong, maxLat, 0]);
      } else {
        bottomLeftCommon = viewport.projectPosition([minLong, minLat, 0]);
        topRightCommon = viewport.projectPosition([maxLong, maxLat, 0]);
      }

      return scaleToAspectRatio([bottomLeftCommon[0] - offsetOriginCommon[0], bottomLeftCommon[1] - offsetOriginCommon[1], topRightCommon[0] - offsetOriginCommon[0], topRightCommon[1] - offsetOriginCommon[1]], size, size);
    }
  }, {
    key: "_commonToWorldBounds",
    value: function _commonToWorldBounds(commonBounds) {
      var _commonBounds = _slicedToArray(commonBounds, 4),
          xMin = _commonBounds[0],
          yMin = _commonBounds[1],
          xMax = _commonBounds[2],
          yMax = _commonBounds[3];

      var viewport = this.context.viewport;
      var bottomLeftWorld = viewport.unprojectPosition([xMin, yMin]);
      var topRightWorld = viewport.unprojectPosition([xMax, yMax]);
      return bottomLeftWorld.slice(0, 2).concat(topRightWorld.slice(0, 2));
    }
  }]);

  return HeatmapLayer;
}(AggregationLayer);

_defineProperty(HeatmapLayer, "layerName", 'HeatmapLayer');

_defineProperty(HeatmapLayer, "defaultProps", defaultProps);

export { HeatmapLayer as default };