export function executeFunction(targetFunction, props, configuration) {
  var matchedFunction = configuration.functions[targetFunction];

  if (!matchedFunction) {
    var log = configuration.log;

    if (log) {
      var stringProps = JSON.stringify(props, null, 0).slice(0, 40);
      log.warn("JSON converter: No registered function ".concat(targetFunction, "(").concat(stringProps, "...)  "));
    }

    return null;
  }

  return matchedFunction(props);
}