import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Transform } from '@luma.gl/core';
import GPUInterpolationTransition from '../../transitions/gpu-interpolation-transition';
import GPUSpringTransition from '../../transitions/gpu-spring-transition';
import log from '../../utils/log';
var TRANSITION_TYPES = {
  interpolation: GPUInterpolationTransition,
  spring: GPUSpringTransition
};

var AttributeTransitionManager = function () {
  function AttributeTransitionManager(gl, _ref) {
    var id = _ref.id,
        timeline = _ref.timeline;

    _classCallCheck(this, AttributeTransitionManager);

    _defineProperty(this, "id", void 0);

    _defineProperty(this, "isSupported", void 0);

    _defineProperty(this, "gl", void 0);

    _defineProperty(this, "timeline", void 0);

    _defineProperty(this, "transitions", void 0);

    _defineProperty(this, "needsRedraw", void 0);

    _defineProperty(this, "numInstances", void 0);

    this.id = id;
    this.gl = gl;
    this.timeline = timeline;
    this.transitions = {};
    this.needsRedraw = false;
    this.numInstances = 1;
    this.isSupported = Transform.isSupported(gl);
  }

  _createClass(AttributeTransitionManager, [{
    key: "finalize",
    value: function finalize() {
      for (var attributeName in this.transitions) {
        this._removeTransition(attributeName);
      }
    }
  }, {
    key: "update",
    value: function update(_ref2) {
      var attributes = _ref2.attributes,
          transitions = _ref2.transitions,
          numInstances = _ref2.numInstances;
      this.numInstances = numInstances || 1;

      for (var attributeName in attributes) {
        var attribute = attributes[attributeName];
        var settings = attribute.getTransitionSetting(transitions);
        if (!settings) continue;

        this._updateAttribute(attributeName, attribute, settings);
      }

      for (var _attributeName in this.transitions) {
        var _attribute = attributes[_attributeName];

        if (!_attribute || !_attribute.getTransitionSetting(transitions)) {
          this._removeTransition(_attributeName);
        }
      }
    }
  }, {
    key: "hasAttribute",
    value: function hasAttribute(attributeName) {
      var transition = this.transitions[attributeName];
      return transition && transition.inProgress;
    }
  }, {
    key: "getAttributes",
    value: function getAttributes() {
      var animatedAttributes = {};

      for (var attributeName in this.transitions) {
        var transition = this.transitions[attributeName];

        if (transition.inProgress) {
          animatedAttributes[attributeName] = transition.attributeInTransition;
        }
      }

      return animatedAttributes;
    }
  }, {
    key: "run",
    value: function run() {
      if (!this.isSupported || this.numInstances === 0) {
        return false;
      }

      for (var attributeName in this.transitions) {
        var updated = this.transitions[attributeName].update();

        if (updated) {
          this.needsRedraw = true;
        }
      }

      var needsRedraw = this.needsRedraw;
      this.needsRedraw = false;
      return needsRedraw;
    }
  }, {
    key: "_removeTransition",
    value: function _removeTransition(attributeName) {
      this.transitions[attributeName].cancel();
      delete this.transitions[attributeName];
    }
  }, {
    key: "_updateAttribute",
    value: function _updateAttribute(attributeName, attribute, settings) {
      var transition = this.transitions[attributeName];
      var isNew = !transition || transition.type !== settings.type;

      if (isNew) {
        if (!this.isSupported) {
          log.warn("WebGL2 not supported by this browser. Transition for ".concat(attributeName, " is disabled."))();
          return;
        }

        if (transition) {
          this._removeTransition(attributeName);
        }

        var TransitionType = TRANSITION_TYPES[settings.type];

        if (TransitionType) {
          this.transitions[attributeName] = new TransitionType({
            attribute: attribute,
            timeline: this.timeline,
            gl: this.gl
          });
        } else {
          log.error("unsupported transition type '".concat(settings.type, "'"))();
          isNew = false;
        }
      }

      if (isNew || attribute.needsRedraw()) {
        this.needsRedraw = true;
        this.transitions[attributeName].start(settings, this.numInstances);
      }
    }
  }]);

  return AttributeTransitionManager;
}();

export { AttributeTransitionManager as default };