import { fp32 } from '@luma.gl/core';
import geometry from '../misc/geometry';
import projectShader from './project.glsl';
import { getUniformsFromViewport } from './viewport-uniforms';
var INITIAL_MODULE_OPTIONS = {};

function getUniforms() {
  var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_MODULE_OPTIONS;

  if ('viewport' in opts) {
    return getUniformsFromViewport(opts);
  }

  return {};
}

export default {
  name: 'project',
  dependencies: [fp32, geometry],
  vs: projectShader,
  getUniforms: getUniforms
};