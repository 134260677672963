import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _get from "@babel/runtime/helpers/get";
import _inherits from "@babel/runtime/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

import { Buffer } from '@luma.gl/core';
import { log } from '@deck.gl/core';
import GPUGridAggregator from '../utils/gpu-grid-aggregation/gpu-grid-aggregator';
import { AGGREGATION_OPERATION } from '../utils/aggregation-operation-utils';
import { defaultColorRange, colorRangeToFlatArray } from '../utils/color-utils';
import GPUGridCellLayer from './gpu-grid-cell-layer';
import { pointToDensityGridDataCPU } from './../cpu-grid-layer/grid-aggregator';
import GridAggregationLayer from '../grid-aggregation-layer';
import { getBoundingBox, getGridParams } from '../utils/grid-aggregation-utils';
var defaultProps = {
  colorDomain: null,
  colorRange: defaultColorRange,
  getColorWeight: {
    type: 'accessor',
    value: 1
  },
  colorAggregation: 'SUM',
  elevationDomain: null,
  elevationRange: [0, 1000],
  getElevationWeight: {
    type: 'accessor',
    value: 1
  },
  elevationAggregation: 'SUM',
  elevationScale: {
    type: 'number',
    min: 0,
    value: 1
  },
  cellSize: {
    type: 'number',
    min: 1,
    max: 1000,
    value: 1000
  },
  coverage: {
    type: 'number',
    min: 0,
    max: 1,
    value: 1
  },
  getPosition: {
    type: 'accessor',
    value: function value(x) {
      return x.position;
    }
  },
  extruded: false,
  material: true
};
var DIMENSIONS = {
  data: {
    props: ['cellSize', 'colorAggregation', 'elevationAggregation']
  }
};
var POSITION_ATTRIBUTE_NAME = 'positions';

var GPUGridLayer = function (_GridAggregationLayer) {
  _inherits(GPUGridLayer, _GridAggregationLayer);

  var _super = _createSuper(GPUGridLayer);

  function GPUGridLayer() {
    _classCallCheck(this, GPUGridLayer);

    return _super.apply(this, arguments);
  }

  _createClass(GPUGridLayer, [{
    key: "initializeState",
    value: function initializeState(_ref) {
      var _attributeManager$add;

      var gl = _ref.gl;
      var isSupported = GPUGridAggregator.isSupported(gl);

      if (!isSupported) {
        log.error('GPUGridLayer is not supported on this browser, use GridLayer instead')();
      }

      _get(_getPrototypeOf(GPUGridLayer.prototype), "initializeAggregationLayer", this).call(this, {
        dimensions: DIMENSIONS
      });

      this.setState({
        gpuAggregation: true,
        projectPoints: false,
        isSupported: isSupported,
        weights: {
          color: {
            needMin: true,
            needMax: true,
            combineMaxMin: true,
            maxMinBuffer: new Buffer(gl, {
              byteLength: 4 * 4,
              accessor: {
                size: 4,
                type: 5126,
                divisor: 1
              }
            })
          },
          elevation: {
            needMin: true,
            needMax: true,
            combineMaxMin: true,
            maxMinBuffer: new Buffer(gl, {
              byteLength: 4 * 4,
              accessor: {
                size: 4,
                type: 5126,
                divisor: 1
              }
            })
          }
        },
        positionAttributeName: 'positions'
      });
      var attributeManager = this.getAttributeManager();
      attributeManager.add((_attributeManager$add = {}, _defineProperty(_attributeManager$add, POSITION_ATTRIBUTE_NAME, {
        size: 3,
        accessor: 'getPosition',
        type: 5130,
        fp64: this.use64bitPositions()
      }), _defineProperty(_attributeManager$add, "color", {
        size: 3,
        accessor: 'getColorWeight'
      }), _defineProperty(_attributeManager$add, "elevation", {
        size: 3,
        accessor: 'getElevationWeight'
      }), _attributeManager$add));
    }
  }, {
    key: "updateState",
    value: function updateState(opts) {
      if (this.state.isSupported === false) {
        return;
      }

      _get(_getPrototypeOf(GPUGridLayer.prototype), "updateState", this).call(this, opts);

      var aggregationDirty = this.state.aggregationDirty;

      if (aggregationDirty) {
        this.setState({
          gridHash: null
        });
      }
    }
  }, {
    key: "getHashKeyForIndex",
    value: function getHashKeyForIndex(index) {
      var _this$state = this.state,
          numRow = _this$state.numRow,
          numCol = _this$state.numCol,
          boundingBox = _this$state.boundingBox,
          gridOffset = _this$state.gridOffset;
      var gridSize = [numCol, numRow];
      var gridOrigin = [boundingBox.xMin, boundingBox.yMin];
      var cellSize = [gridOffset.xOffset, gridOffset.yOffset];
      var yIndex = Math.floor(index / gridSize[0]);
      var xIndex = index - yIndex * gridSize[0];
      var latIdx = Math.floor((yIndex * cellSize[1] + gridOrigin[1] + 90 + cellSize[1] / 2) / cellSize[1]);
      var lonIdx = Math.floor((xIndex * cellSize[0] + gridOrigin[0] + 180 + cellSize[0] / 2) / cellSize[0]);
      return "".concat(latIdx, "-").concat(lonIdx);
    }
  }, {
    key: "getPositionForIndex",
    value: function getPositionForIndex(index) {
      var _this$state2 = this.state,
          numRow = _this$state2.numRow,
          numCol = _this$state2.numCol,
          boundingBox = _this$state2.boundingBox,
          gridOffset = _this$state2.gridOffset;
      var gridSize = [numCol, numRow];
      var gridOrigin = [boundingBox.xMin, boundingBox.yMin];
      var cellSize = [gridOffset.xOffset, gridOffset.yOffset];
      var yIndex = Math.floor(index / gridSize[0]);
      var xIndex = index - yIndex * gridSize[0];
      var yPos = yIndex * cellSize[1] + gridOrigin[1];
      var xPos = xIndex * cellSize[0] + gridOrigin[0];
      return [xPos, yPos];
    }
  }, {
    key: "getPickingInfo",
    value: function getPickingInfo(_ref2) {
      var info = _ref2.info,
          mode = _ref2.mode;
      var index = info.index;
      var object = null;

      if (index >= 0) {
        var gpuGridAggregator = this.state.gpuGridAggregator;
        var position = this.getPositionForIndex(index);
        var colorInfo = GPUGridAggregator.getAggregationData(_objectSpread({
          pixelIndex: index
        }, gpuGridAggregator.getData('color')));
        var elevationInfo = GPUGridAggregator.getAggregationData(_objectSpread({
          pixelIndex: index
        }, gpuGridAggregator.getData('elevation')));
        object = {
          colorValue: colorInfo.cellWeight,
          elevationValue: elevationInfo.cellWeight,
          count: colorInfo.cellCount || elevationInfo.cellCount,
          position: position,
          totalCount: colorInfo.totalCount || elevationInfo.totalCount
        };

        if (mode !== 'hover') {
          var props = this.props;
          var gridHash = this.state.gridHash;

          if (!gridHash) {
            var _this$state3 = this.state,
                gridOffset = _this$state3.gridOffset,
                translation = _this$state3.translation,
                boundingBox = _this$state3.boundingBox;
            var viewport = this.context.viewport;
            var attributes = this.getAttributes();
            var cpuAggregation = pointToDensityGridDataCPU(props, {
              gridOffset: gridOffset,
              attributes: attributes,
              viewport: viewport,
              translation: translation,
              boundingBox: boundingBox
            });
            gridHash = cpuAggregation.gridHash;
            this.setState({
              gridHash: gridHash
            });
          }

          var key = this.getHashKeyForIndex(index);
          var cpuAggregationData = gridHash[key];
          Object.assign(object, cpuAggregationData);
        }
      }

      info.picked = Boolean(object);
      info.object = object;
      return info;
    }
  }, {
    key: "renderLayers",
    value: function renderLayers() {
      if (!this.state.isSupported) {
        return null;
      }

      var _this$props = this.props,
          elevationScale = _this$props.elevationScale,
          extruded = _this$props.extruded,
          cellSizeMeters = _this$props.cellSize,
          coverage = _this$props.coverage,
          material = _this$props.material,
          elevationRange = _this$props.elevationRange,
          colorDomain = _this$props.colorDomain,
          elevationDomain = _this$props.elevationDomain;
      var _this$state4 = this.state,
          weights = _this$state4.weights,
          numRow = _this$state4.numRow,
          numCol = _this$state4.numCol,
          gridOrigin = _this$state4.gridOrigin,
          gridOffset = _this$state4.gridOffset;
      var color = weights.color,
          elevation = weights.elevation;
      var colorRange = colorRangeToFlatArray(this.props.colorRange);
      var SubLayerClass = this.getSubLayerClass('gpu-grid-cell', GPUGridCellLayer);
      return new SubLayerClass({
        gridSize: [numCol, numRow],
        gridOrigin: gridOrigin,
        gridOffset: [gridOffset.xOffset, gridOffset.yOffset],
        colorRange: colorRange,
        elevationRange: elevationRange,
        colorDomain: colorDomain,
        elevationDomain: elevationDomain,
        cellSize: cellSizeMeters,
        coverage: coverage,
        material: material,
        elevationScale: elevationScale,
        extruded: extruded
      }, this.getSubLayerProps({
        id: 'gpu-grid-cell'
      }), {
        data: {
          attributes: {
            colors: color.aggregationBuffer,
            elevations: elevation.aggregationBuffer
          }
        },
        colorMaxMinBuffer: color.maxMinBuffer,
        elevationMaxMinBuffer: elevation.maxMinBuffer,
        numInstances: numCol * numRow
      });
    }
  }, {
    key: "finalizeState",
    value: function finalizeState(context) {
      var _this$state$weights = this.state.weights,
          color = _this$state$weights.color,
          elevation = _this$state$weights.elevation;
      [color, elevation].forEach(function (weight) {
        var aggregationBuffer = weight.aggregationBuffer,
            maxMinBuffer = weight.maxMinBuffer;
        maxMinBuffer.delete();
        aggregationBuffer === null || aggregationBuffer === void 0 ? void 0 : aggregationBuffer.delete();
      });

      _get(_getPrototypeOf(GPUGridLayer.prototype), "finalizeState", this).call(this, context);
    }
  }, {
    key: "updateAggregationState",
    value: function updateAggregationState(opts) {
      var props = opts.props,
          oldProps = opts.oldProps;
      var cellSize = props.cellSize,
          coordinateSystem = props.coordinateSystem;
      var viewport = this.context.viewport;
      var cellSizeChanged = oldProps.cellSize !== cellSize;
      var dimensions = this.state.dimensions;
      var positionsChanged = this.isAttributeChanged(POSITION_ATTRIBUTE_NAME);
      var attributesChanged = positionsChanged || this.isAttributeChanged();
      var boundingBox = this.state.boundingBox;

      if (positionsChanged) {
        boundingBox = getBoundingBox(this.getAttributes(), this.getNumInstances());
        this.setState({
          boundingBox: boundingBox
        });
      }

      if (positionsChanged || cellSizeChanged) {
        var _getGridParams = getGridParams(boundingBox, cellSize, viewport, coordinateSystem),
            gridOffset = _getGridParams.gridOffset,
            translation = _getGridParams.translation,
            width = _getGridParams.width,
            height = _getGridParams.height,
            numCol = _getGridParams.numCol,
            numRow = _getGridParams.numRow;

        this.allocateResources(numRow, numCol);
        this.setState({
          gridOffset: gridOffset,
          translation: translation,
          gridOrigin: [-1 * translation[0], -1 * translation[1]],
          width: width,
          height: height,
          numCol: numCol,
          numRow: numRow
        });
      }

      var aggregationDataDirty = attributesChanged || this.isAggregationDirty(opts, {
        dimension: dimensions.data,
        compareAll: true
      });

      if (aggregationDataDirty) {
        this._updateAccessors(opts);
      }

      this.setState({
        aggregationDataDirty: aggregationDataDirty
      });
    }
  }, {
    key: "_updateAccessors",
    value: function _updateAccessors(opts) {
      var _opts$props = opts.props,
          colorAggregation = _opts$props.colorAggregation,
          elevationAggregation = _opts$props.elevationAggregation;
      var _this$state$weights2 = this.state.weights,
          color = _this$state$weights2.color,
          elevation = _this$state$weights2.elevation;
      color.operation = AGGREGATION_OPERATION[colorAggregation];
      elevation.operation = AGGREGATION_OPERATION[elevationAggregation];
    }
  }]);

  return GPUGridLayer;
}(GridAggregationLayer);

_defineProperty(GPUGridLayer, "layerName", 'GPUGridLayer');

_defineProperty(GPUGridLayer, "defaultProps", defaultProps);

export { GPUGridLayer as default };