import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _get from "@babel/runtime/helpers/get";
import _inherits from "@babel/runtime/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

import * as widgets from '@jupyter-widgets/base';
import { MODULE_NAME, MODULE_VERSION } from '../version';
import { deserializeMatrix } from './utils/deserialize-matrix';
var JupyterTransportModel = null;
var DOMWidgetModel = widgets && widgets.DOMWidgetModel;

if (DOMWidgetModel) {
  var Model = function (_DOMWidgetModel) {
    _inherits(Model, _DOMWidgetModel);

    var _super = _createSuper(Model);

    function Model() {
      _classCallCheck(this, Model);

      return _super.apply(this, arguments);
    }

    _createClass(Model, [{
      key: "defaults",
      value: function defaults() {
        return _objectSpread(_objectSpread({}, _get(_getPrototypeOf(Model.prototype), "defaults", this).call(this)), {}, {
          _model_name: JupyterTransportModel.model_name,
          _model_module: JupyterTransportModel.model_module,
          _model_module_version: JupyterTransportModel.model_module_version,
          _view_name: JupyterTransportModel.view_name,
          _view_module: JupyterTransportModel.view_module,
          _view_module_version: JupyterTransportModel.view_module_version,
          custom_libraries: [],
          json_input: null,
          mapbox_key: null,
          selected_data: [],
          data_buffer: null,
          tooltip: null,
          width: '100%',
          height: 500,
          js_warning: false
        });
      }
    }], [{
      key: "serializers",
      get: function get() {
        return _objectSpread(_objectSpread({}, DOMWidgetModel.serializers), {}, {
          data_buffer: {
            deserialize: deserializeMatrix
          }
        });
      }
    }, {
      key: "model_name",
      get: function get() {
        return 'JupyterTransportModel';
      }
    }, {
      key: "model_module",
      get: function get() {
        return MODULE_NAME;
      }
    }, {
      key: "model_module_version",
      get: function get() {
        return MODULE_VERSION;
      }
    }, {
      key: "view_name",
      get: function get() {
        return 'JupyterTransportView';
      }
    }, {
      key: "view_module",
      get: function get() {
        return MODULE_NAME;
      }
    }, {
      key: "view_module_version",
      get: function get() {
        return MODULE_VERSION;
      }
    }]);

    return Model;
  }(DOMWidgetModel);

  JupyterTransportModel = Model;
}

export default JupyterTransportModel;