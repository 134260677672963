import parseExpressionString from './parse-expression-string';
import { FUNCTION_IDENTIFIER } from '../syntactic-sugar';

function hasFunctionIdentifier(value) {
  return typeof value === 'string' && value.startsWith(FUNCTION_IDENTIFIER);
}

function trimFunctionIdentifier(value) {
  return value.replace(FUNCTION_IDENTIFIER, '');
}

export default function convertFunctions(props, configuration) {
  var replacedProps = {};

  for (var propName in props) {
    var propValue = props[propName];
    var isFunction = hasFunctionIdentifier(propValue);

    if (isFunction) {
      propValue = trimFunctionIdentifier(propValue);
      propValue = parseExpressionString(propValue, configuration);
    }

    replacedProps[propName] = propValue;
  }

  return replacedProps;
}