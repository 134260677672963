import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _STRATEGIES;

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { RequestScheduler } from '@loaders.gl/loader-utils';
import { Matrix4, equals } from '@math.gl/core';
import { Tile2DHeader } from './tile-2d-header';
import { getTileIndices as _getTileIndices, tileToBoundingBox, getCullBounds } from './utils';
import { memoize } from './memoize';
var TILE_STATE_VISITED = 1;
var TILE_STATE_VISIBLE = 2;
export var STRATEGY_NEVER = 'never';
export var STRATEGY_REPLACE = 'no-overlap';
export var STRATEGY_DEFAULT = 'best-available';
var DEFAULT_CACHE_SCALE = 5;
var STRATEGIES = (_STRATEGIES = {}, _defineProperty(_STRATEGIES, STRATEGY_DEFAULT, updateTileStateDefault), _defineProperty(_STRATEGIES, STRATEGY_REPLACE, updateTileStateReplace), _defineProperty(_STRATEGIES, STRATEGY_NEVER, function () {}), _STRATEGIES);
export var DEFAULT_TILESET2D_PROPS = {
  extent: null,
  tileSize: 512,
  maxZoom: null,
  minZoom: null,
  maxCacheSize: null,
  maxCacheByteSize: null,
  refinementStrategy: 'best-available',
  zRange: null,
  maxRequests: 6,
  zoomOffset: 0,
  onTileLoad: function onTileLoad() {},
  onTileUnload: function onTileUnload() {},
  onTileError: function onTileError() {}
};
export var Tileset2D = function () {
  function Tileset2D(opts) {
    var _this = this;

    _classCallCheck(this, Tileset2D);

    _defineProperty(this, "opts", void 0);

    _defineProperty(this, "_requestScheduler", void 0);

    _defineProperty(this, "_cache", void 0);

    _defineProperty(this, "_dirty", void 0);

    _defineProperty(this, "_tiles", void 0);

    _defineProperty(this, "_cacheByteSize", void 0);

    _defineProperty(this, "_viewport", void 0);

    _defineProperty(this, "_zRange", void 0);

    _defineProperty(this, "_selectedTiles", void 0);

    _defineProperty(this, "_frameNumber", void 0);

    _defineProperty(this, "_modelMatrix", void 0);

    _defineProperty(this, "_modelMatrixInverse", void 0);

    _defineProperty(this, "_maxZoom", void 0);

    _defineProperty(this, "_minZoom", void 0);

    _defineProperty(this, "onTileLoad", void 0);

    _defineProperty(this, "_getCullBounds", memoize(getCullBounds));

    this.opts = _objectSpread(_objectSpread({}, DEFAULT_TILESET2D_PROPS), opts);

    this.onTileLoad = function (tile) {
      var _this$opts$onTileLoad, _this$opts;

      (_this$opts$onTileLoad = (_this$opts = _this.opts).onTileLoad) === null || _this$opts$onTileLoad === void 0 ? void 0 : _this$opts$onTileLoad.call(_this$opts, tile);

      if (_this.opts.maxCacheByteSize) {
        _this._cacheByteSize += tile.byteLength;

        _this._resizeCache();
      }
    };

    this._requestScheduler = new RequestScheduler({
      maxRequests: opts.maxRequests,
      throttleRequests: Boolean(opts.maxRequests && opts.maxRequests > 0)
    });
    this._cache = new Map();
    this._tiles = [];
    this._dirty = false;
    this._cacheByteSize = 0;
    this._viewport = null;
    this._zRange = null;
    this._selectedTiles = null;
    this._frameNumber = 0;
    this._modelMatrix = new Matrix4();
    this._modelMatrixInverse = new Matrix4();
    this.setOptions(opts);
  }

  _createClass(Tileset2D, [{
    key: "tiles",
    get: function get() {
      return this._tiles;
    }
  }, {
    key: "selectedTiles",
    get: function get() {
      return this._selectedTiles;
    }
  }, {
    key: "isLoaded",
    get: function get() {
      return this._selectedTiles !== null && this._selectedTiles.every(function (tile) {
        return tile.isLoaded;
      });
    }
  }, {
    key: "needsReload",
    get: function get() {
      return this._selectedTiles !== null && this._selectedTiles.some(function (tile) {
        return tile.needsReload;
      });
    }
  }, {
    key: "setOptions",
    value: function setOptions(opts) {
      Object.assign(this.opts, opts);

      if (Number.isFinite(opts.maxZoom)) {
        this._maxZoom = Math.floor(opts.maxZoom);
      }

      if (Number.isFinite(opts.minZoom)) {
        this._minZoom = Math.ceil(opts.minZoom);
      }
    }
  }, {
    key: "finalize",
    value: function finalize() {
      var _iterator = _createForOfIteratorHelper(this._cache.values()),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var _tile = _step.value;

          if (_tile.isLoading) {
            _tile.abort();
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      this._cache.clear();

      this._tiles = [];
      this._selectedTiles = null;
    }
  }, {
    key: "reloadAll",
    value: function reloadAll() {
      var _iterator2 = _createForOfIteratorHelper(this._cache.keys()),
          _step2;

      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var id = _step2.value;

          var _tile2 = this._cache.get(id);

          if (!this._selectedTiles || !this._selectedTiles.includes(_tile2)) {
            this._cache.delete(id);
          } else {
            _tile2.setNeedsReload();
          }
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }
    }
  }, {
    key: "update",
    value: function update(viewport) {
      var _this2 = this;

      var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
        zRange: null,
        modelMatrix: null
      },
          zRange = _ref.zRange,
          modelMatrix = _ref.modelMatrix;

      var modelMatrixAsMatrix4 = modelMatrix ? new Matrix4(modelMatrix) : new Matrix4();
      var isModelMatrixNew = !modelMatrixAsMatrix4.equals(this._modelMatrix);

      if (!this._viewport || !viewport.equals(this._viewport) || !equals(this._zRange, zRange) || isModelMatrixNew) {
        if (isModelMatrixNew) {
          this._modelMatrixInverse = modelMatrixAsMatrix4.clone().invert();
          this._modelMatrix = modelMatrixAsMatrix4;
        }

        this._viewport = viewport;
        this._zRange = zRange;
        var tileIndices = this.getTileIndices({
          viewport: viewport,
          maxZoom: this._maxZoom,
          minZoom: this._minZoom,
          zRange: zRange,
          modelMatrix: this._modelMatrix,
          modelMatrixInverse: this._modelMatrixInverse
        });
        this._selectedTiles = tileIndices.map(function (index) {
          return _this2._getTile(index, true);
        });

        if (this._dirty) {
          this._rebuildTree();
        }
      } else if (this.needsReload) {
        this._selectedTiles = this._selectedTiles.map(function (tile) {
          return _this2._getTile(tile.index, true);
        });
      }

      var changed = this.updateTileStates();

      this._pruneRequests();

      if (this._dirty) {
        this._resizeCache();
      }

      if (changed) {
        this._frameNumber++;
      }

      return this._frameNumber;
    }
  }, {
    key: "isTileVisible",
    value: function isTileVisible(tile, cullRect) {
      if (!tile.isVisible) {
        return false;
      }

      if (cullRect && this._viewport) {
        var boundsArr = this._getCullBounds({
          viewport: this._viewport,
          z: this._zRange,
          cullRect: cullRect
        });

        var bbox = tile.bbox;

        var _iterator3 = _createForOfIteratorHelper(boundsArr),
            _step3;

        try {
          for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
            var _step3$value = _slicedToArray(_step3.value, 4),
                minX = _step3$value[0],
                minY = _step3$value[1],
                maxX = _step3$value[2],
                maxY = _step3$value[3];

            var overlaps = void 0;

            if ('west' in bbox) {
              overlaps = bbox.west < maxX && bbox.east > minX && bbox.south < maxY && bbox.north > minY;
            } else {
              var y0 = Math.min(bbox.top, bbox.bottom);
              var y1 = Math.max(bbox.top, bbox.bottom);
              overlaps = bbox.left < maxX && bbox.right > minX && y0 < maxY && y1 > minY;
            }

            if (overlaps) {
              return true;
            }
          }
        } catch (err) {
          _iterator3.e(err);
        } finally {
          _iterator3.f();
        }

        return false;
      }

      return true;
    }
  }, {
    key: "getTileIndices",
    value: function getTileIndices(_ref2) {
      var viewport = _ref2.viewport,
          maxZoom = _ref2.maxZoom,
          minZoom = _ref2.minZoom,
          zRange = _ref2.zRange,
          modelMatrix = _ref2.modelMatrix,
          modelMatrixInverse = _ref2.modelMatrixInverse;
      var _this$opts2 = this.opts,
          tileSize = _this$opts2.tileSize,
          extent = _this$opts2.extent,
          zoomOffset = _this$opts2.zoomOffset;
      return _getTileIndices({
        viewport: viewport,
        maxZoom: maxZoom,
        minZoom: minZoom,
        zRange: zRange,
        tileSize: tileSize,
        extent: extent,
        modelMatrix: modelMatrix,
        modelMatrixInverse: modelMatrixInverse,
        zoomOffset: zoomOffset
      });
    }
  }, {
    key: "getTileId",
    value: function getTileId(index) {
      return "".concat(index.x, "-").concat(index.y, "-").concat(index.z);
    }
  }, {
    key: "getTileZoom",
    value: function getTileZoom(index) {
      return index.z;
    }
  }, {
    key: "getTileMetadata",
    value: function getTileMetadata(index) {
      var tileSize = this.opts.tileSize;
      return {
        bbox: tileToBoundingBox(this._viewport, index.x, index.y, index.z, tileSize)
      };
    }
  }, {
    key: "getParentIndex",
    value: function getParentIndex(index) {
      var x = Math.floor(index.x / 2);
      var y = Math.floor(index.y / 2);
      var z = index.z - 1;
      return {
        x: x,
        y: y,
        z: z
      };
    }
  }, {
    key: "updateTileStates",
    value: function updateTileStates() {
      var refinementStrategy = this.opts.refinementStrategy || STRATEGY_DEFAULT;
      var visibilities = new Array(this._cache.size);
      var i = 0;

      var _iterator4 = _createForOfIteratorHelper(this._cache.values()),
          _step4;

      try {
        for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
          var _tile3 = _step4.value;
          visibilities[i++] = _tile3.isVisible;
          _tile3.isSelected = false;
          _tile3.isVisible = false;
        }
      } catch (err) {
        _iterator4.e(err);
      } finally {
        _iterator4.f();
      }

      var _iterator5 = _createForOfIteratorHelper(this._selectedTiles),
          _step5;

      try {
        for (_iterator5.s(); !(_step5 = _iterator5.n()).done;) {
          var _tile4 = _step5.value;
          _tile4.isSelected = true;
          _tile4.isVisible = true;
        }
      } catch (err) {
        _iterator5.e(err);
      } finally {
        _iterator5.f();
      }

      (typeof refinementStrategy === 'function' ? refinementStrategy : STRATEGIES[refinementStrategy])(Array.from(this._cache.values()));
      i = 0;

      var _iterator6 = _createForOfIteratorHelper(this._cache.values()),
          _step6;

      try {
        for (_iterator6.s(); !(_step6 = _iterator6.n()).done;) {
          var _tile5 = _step6.value;

          if (visibilities[i++] !== _tile5.isVisible) {
            return true;
          }
        }
      } catch (err) {
        _iterator6.e(err);
      } finally {
        _iterator6.f();
      }

      return false;
    }
  }, {
    key: "_pruneRequests",
    value: function _pruneRequests() {
      var _this$opts$maxRequest = this.opts.maxRequests,
          maxRequests = _this$opts$maxRequest === void 0 ? 0 : _this$opts$maxRequest;
      var abortCandidates = [];
      var ongoingRequestCount = 0;

      var _iterator7 = _createForOfIteratorHelper(this._cache.values()),
          _step7;

      try {
        for (_iterator7.s(); !(_step7 = _iterator7.n()).done;) {
          var _tile7 = _step7.value;

          if (_tile7.isLoading) {
            ongoingRequestCount++;

            if (!_tile7.isSelected && !_tile7.isVisible) {
              abortCandidates.push(_tile7);
            }
          }
        }
      } catch (err) {
        _iterator7.e(err);
      } finally {
        _iterator7.f();
      }

      while (maxRequests > 0 && ongoingRequestCount > maxRequests && abortCandidates.length > 0) {
        var _tile6 = abortCandidates.shift();

        _tile6.abort();

        ongoingRequestCount--;
      }
    }
  }, {
    key: "_rebuildTree",
    value: function _rebuildTree() {
      var _cache = this._cache;

      var _iterator8 = _createForOfIteratorHelper(_cache.values()),
          _step8;

      try {
        for (_iterator8.s(); !(_step8 = _iterator8.n()).done;) {
          var _tile8 = _step8.value;
          _tile8.parent = null;

          if (_tile8.children) {
            _tile8.children.length = 0;
          }
        }
      } catch (err) {
        _iterator8.e(err);
      } finally {
        _iterator8.f();
      }

      var _iterator9 = _createForOfIteratorHelper(_cache.values()),
          _step9;

      try {
        for (_iterator9.s(); !(_step9 = _iterator9.n()).done;) {
          var _tile9 = _step9.value;

          var parent = this._getNearestAncestor(_tile9);

          _tile9.parent = parent;

          if (parent !== null && parent !== void 0 && parent.children) {
            parent.children.push(_tile9);
          }
        }
      } catch (err) {
        _iterator9.e(err);
      } finally {
        _iterator9.f();
      }
    }
  }, {
    key: "_resizeCache",
    value: function _resizeCache() {
      var _cache = this._cache,
          opts = this.opts;
      var maxCacheSize = opts.maxCacheSize || (opts.maxCacheByteSize ? Infinity : DEFAULT_CACHE_SCALE * this.selectedTiles.length);
      var maxCacheByteSize = opts.maxCacheByteSize || Infinity;
      var overflown = _cache.size > maxCacheSize || this._cacheByteSize > maxCacheByteSize;

      if (overflown) {
        var _iterator10 = _createForOfIteratorHelper(_cache),
            _step10;

        try {
          for (_iterator10.s(); !(_step10 = _iterator10.n()).done;) {
            var _step10$value = _slicedToArray(_step10.value, 2),
                id = _step10$value[0],
                _tile10 = _step10$value[1];

            if (!_tile10.isVisible && !_tile10.isSelected) {
              var _this$opts$onTileUnlo, _this$opts3;

              this._cacheByteSize -= opts.maxCacheByteSize ? _tile10.byteLength : 0;

              _cache.delete(id);

              (_this$opts$onTileUnlo = (_this$opts3 = this.opts).onTileUnload) === null || _this$opts$onTileUnlo === void 0 ? void 0 : _this$opts$onTileUnlo.call(_this$opts3, _tile10);
            }

            if (_cache.size <= maxCacheSize && this._cacheByteSize <= maxCacheByteSize) {
              break;
            }
          }
        } catch (err) {
          _iterator10.e(err);
        } finally {
          _iterator10.f();
        }

        this._rebuildTree();

        this._dirty = true;
      }

      if (this._dirty) {
        this._tiles = Array.from(this._cache.values()).sort(function (t1, t2) {
          return t1.zoom - t2.zoom;
        });
        this._dirty = false;
      }
    }
  }, {
    key: "_getTile",
    value: function _getTile(index, create) {
      var id = this.getTileId(index);

      var tile = this._cache.get(id);

      var needsReload = false;

      if (!tile && create) {
        tile = new Tile2DHeader(index);
        Object.assign(tile, this.getTileMetadata(tile.index));
        Object.assign(tile, {
          id: id,
          zoom: this.getTileZoom(tile.index)
        });
        needsReload = true;

        this._cache.set(id, tile);

        this._dirty = true;
      } else if (tile && tile.needsReload) {
        needsReload = true;
      }

      if (tile && needsReload) {
        tile.loadData({
          getData: this.opts.getTileData,
          requestScheduler: this._requestScheduler,
          onLoad: this.onTileLoad,
          onError: this.opts.onTileError
        });
      }

      return tile;
    }
  }, {
    key: "_getNearestAncestor",
    value: function _getNearestAncestor(tile) {
      var _this$_minZoom = this._minZoom,
          _minZoom = _this$_minZoom === void 0 ? 0 : _this$_minZoom;

      var index = tile.index;

      while (this.getTileZoom(index) > _minZoom) {
        index = this.getParentIndex(index);

        var parent = this._getTile(index);

        if (parent) {
          return parent;
        }
      }

      return null;
    }
  }]);

  return Tileset2D;
}();

function updateTileStateDefault(allTiles) {
  var _iterator11 = _createForOfIteratorHelper(allTiles),
      _step11;

  try {
    for (_iterator11.s(); !(_step11 = _iterator11.n()).done;) {
      var _tile11 = _step11.value;
      _tile11.state = 0;
    }
  } catch (err) {
    _iterator11.e(err);
  } finally {
    _iterator11.f();
  }

  var _iterator12 = _createForOfIteratorHelper(allTiles),
      _step12;

  try {
    for (_iterator12.s(); !(_step12 = _iterator12.n()).done;) {
      var _tile12 = _step12.value;

      if (_tile12.isSelected && !getPlaceholderInAncestors(_tile12)) {
        getPlaceholderInChildren(_tile12);
      }
    }
  } catch (err) {
    _iterator12.e(err);
  } finally {
    _iterator12.f();
  }

  var _iterator13 = _createForOfIteratorHelper(allTiles),
      _step13;

  try {
    for (_iterator13.s(); !(_step13 = _iterator13.n()).done;) {
      var _tile13 = _step13.value;
      _tile13.isVisible = Boolean(_tile13.state & TILE_STATE_VISIBLE);
    }
  } catch (err) {
    _iterator13.e(err);
  } finally {
    _iterator13.f();
  }
}

function updateTileStateReplace(allTiles) {
  var _iterator14 = _createForOfIteratorHelper(allTiles),
      _step14;

  try {
    for (_iterator14.s(); !(_step14 = _iterator14.n()).done;) {
      var _tile14 = _step14.value;
      _tile14.state = 0;
    }
  } catch (err) {
    _iterator14.e(err);
  } finally {
    _iterator14.f();
  }

  var _iterator15 = _createForOfIteratorHelper(allTiles),
      _step15;

  try {
    for (_iterator15.s(); !(_step15 = _iterator15.n()).done;) {
      var _tile15 = _step15.value;

      if (_tile15.isSelected) {
        getPlaceholderInAncestors(_tile15);
      }
    }
  } catch (err) {
    _iterator15.e(err);
  } finally {
    _iterator15.f();
  }

  var sortedTiles = Array.from(allTiles).sort(function (t1, t2) {
    return t1.zoom - t2.zoom;
  });

  var _iterator16 = _createForOfIteratorHelper(sortedTiles),
      _step16;

  try {
    for (_iterator16.s(); !(_step16 = _iterator16.n()).done;) {
      var _tile16 = _step16.value;
      _tile16.isVisible = Boolean(_tile16.state & TILE_STATE_VISIBLE);

      if (_tile16.children && (_tile16.isVisible || _tile16.state & TILE_STATE_VISITED)) {
        var _iterator17 = _createForOfIteratorHelper(_tile16.children),
            _step17;

        try {
          for (_iterator17.s(); !(_step17 = _iterator17.n()).done;) {
            var child = _step17.value;
            child.state = TILE_STATE_VISITED;
          }
        } catch (err) {
          _iterator17.e(err);
        } finally {
          _iterator17.f();
        }
      } else if (_tile16.isSelected) {
        getPlaceholderInChildren(_tile16);
      }
    }
  } catch (err) {
    _iterator16.e(err);
  } finally {
    _iterator16.f();
  }
}

function getPlaceholderInAncestors(startTile) {
  var tile = startTile;

  while (tile) {
    if (tile.isLoaded || tile.content) {
      tile.state |= TILE_STATE_VISIBLE;
      return true;
    }

    tile = tile.parent;
  }

  return false;
}

function getPlaceholderInChildren(tile) {
  var _iterator18 = _createForOfIteratorHelper(tile.children),
      _step18;

  try {
    for (_iterator18.s(); !(_step18 = _iterator18.n()).done;) {
      var child = _step18.value;

      if (child.isLoaded || child.content) {
        child.state |= TILE_STATE_VISIBLE;
      } else {
        getPlaceholderInChildren(child);
      }
    }
  } catch (err) {
    _iterator18.e(err);
  } finally {
    _iterator18.f();
  }
}