function isJSON(text) {
  var firstChar = text[0];
  var lastChar = text[text.length - 1];
  return firstChar === '{' && lastChar === '}' || firstChar === '[' && lastChar === ']';
}

export default {
  id: 'JSON',
  name: 'JSON',
  module: '',
  version: '',
  options: {},
  extensions: ['json', 'geojson'],
  mimeTypes: ['application/json', 'application/geo+json'],
  testText: isJSON,
  parseTextSync: JSON.parse
};