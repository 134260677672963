import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { Buffer, Transform, Framebuffer, Texture2D, readPixelsToArray } from '@luma.gl/core';
import { padBuffer, getAttributeTypeFromSize, getSourceBufferAttribute, getAttributeBufferLength, cycleBuffers } from '../lib/attribute/attribute-transition-utils';
import Attribute from '../lib/attribute/attribute';
import Transition from './transition';

var GPUSpringTransition = function () {
  function GPUSpringTransition(_ref) {
    var gl = _ref.gl,
        attribute = _ref.attribute,
        timeline = _ref.timeline;

    _classCallCheck(this, GPUSpringTransition);

    _defineProperty(this, "gl", void 0);

    _defineProperty(this, "type", 'spring');

    _defineProperty(this, "attributeInTransition", void 0);

    _defineProperty(this, "settings", void 0);

    _defineProperty(this, "attribute", void 0);

    _defineProperty(this, "transition", void 0);

    _defineProperty(this, "currentStartIndices", void 0);

    _defineProperty(this, "currentLength", void 0);

    _defineProperty(this, "texture", void 0);

    _defineProperty(this, "framebuffer", void 0);

    _defineProperty(this, "transform", void 0);

    _defineProperty(this, "buffers", void 0);

    this.gl = gl;
    this.type = 'spring';
    this.transition = new Transition(timeline);
    this.attribute = attribute;
    this.attributeInTransition = new Attribute(gl, _objectSpread(_objectSpread({}, attribute.settings), {}, {
      normalized: false
    }));
    this.currentStartIndices = attribute.startIndices;
    this.currentLength = 0;
    this.texture = getTexture(gl);
    this.framebuffer = getFramebuffer(gl, this.texture);
    this.transform = getTransform(gl, attribute, this.framebuffer);
    var bufferOpts = {
      byteLength: 0,
      usage: 35050
    };
    this.buffers = [new Buffer(gl, bufferOpts), new Buffer(gl, bufferOpts), new Buffer(gl, bufferOpts)];
  }

  _createClass(GPUSpringTransition, [{
    key: "inProgress",
    get: function get() {
      return this.transition.inProgress;
    }
  }, {
    key: "start",
    value: function start(transitionSettings, numInstances) {
      var gl = this.gl,
          buffers = this.buffers,
          attribute = this.attribute;
      var padBufferOpts = {
        numInstances: numInstances,
        attribute: attribute,
        fromLength: this.currentLength,
        fromStartIndices: this.currentStartIndices,
        getData: transitionSettings.enter
      };

      var _iterator = _createForOfIteratorHelper(buffers),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var buffer = _step.value;
          padBuffer(_objectSpread({
            buffer: buffer
          }, padBufferOpts));
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      this.settings = transitionSettings;
      this.currentStartIndices = attribute.startIndices;
      this.currentLength = getAttributeBufferLength(attribute, numInstances);
      this.attributeInTransition.setData({
        buffer: buffers[1],
        value: attribute.value
      });
      this.transition.start(_objectSpread(_objectSpread({}, transitionSettings), {}, {
        duration: Infinity
      }));
      this.transform.update({
        elementCount: Math.floor(this.currentLength / attribute.size),
        sourceBuffers: {
          aTo: getSourceBufferAttribute(gl, attribute)
        }
      });
    }
  }, {
    key: "update",
    value: function update() {
      var buffers = this.buffers,
          transform = this.transform,
          framebuffer = this.framebuffer,
          transition = this.transition;
      var updated = transition.update();

      if (!updated) {
        return false;
      }

      var settings = this.settings;
      transform.update({
        sourceBuffers: {
          aPrev: buffers[0],
          aCur: buffers[1]
        },
        feedbackBuffers: {
          vNext: buffers[2]
        }
      });
      transform.run({
        framebuffer: framebuffer,
        discard: false,
        clearRenderTarget: true,
        uniforms: {
          stiffness: settings.stiffness,
          damping: settings.damping
        },
        parameters: {
          depthTest: false,
          blend: true,
          viewport: [0, 0, 1, 1],
          blendFunc: [1, 1],
          blendEquation: [32776, 32776]
        }
      });
      cycleBuffers(buffers);
      this.attributeInTransition.setData({
        buffer: buffers[1],
        value: this.attribute.value
      });
      var isTransitioning = readPixelsToArray(framebuffer)[0] > 0;

      if (!isTransitioning) {
        transition.end();
      }

      return true;
    }
  }, {
    key: "cancel",
    value: function cancel() {
      this.transition.cancel();
      this.transform.delete();

      var _iterator2 = _createForOfIteratorHelper(this.buffers),
          _step2;

      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var buffer = _step2.value;
          buffer.delete();
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }

      this.buffers.length = 0;
      this.texture.delete();
      this.framebuffer.delete();
    }
  }]);

  return GPUSpringTransition;
}();

export { GPUSpringTransition as default };

function getTransform(gl, attribute, framebuffer) {
  var attributeType = getAttributeTypeFromSize(attribute.size);
  return new Transform(gl, {
    framebuffer: framebuffer,
    vs: "\n#define SHADER_NAME spring-transition-vertex-shader\n\n#define EPSILON 0.00001\n\nuniform float stiffness;\nuniform float damping;\nattribute ATTRIBUTE_TYPE aPrev;\nattribute ATTRIBUTE_TYPE aCur;\nattribute ATTRIBUTE_TYPE aTo;\nvarying ATTRIBUTE_TYPE vNext;\nvarying float vIsTransitioningFlag;\n\nATTRIBUTE_TYPE getNextValue(ATTRIBUTE_TYPE cur, ATTRIBUTE_TYPE prev, ATTRIBUTE_TYPE dest) {\n  ATTRIBUTE_TYPE velocity = cur - prev;\n  ATTRIBUTE_TYPE delta = dest - cur;\n  ATTRIBUTE_TYPE spring = delta * stiffness;\n  ATTRIBUTE_TYPE damper = velocity * -1.0 * damping;\n  return spring + damper + velocity + cur;\n}\n\nvoid main(void) {\n  bool isTransitioning = length(aCur - aPrev) > EPSILON || length(aTo - aCur) > EPSILON;\n  vIsTransitioningFlag = isTransitioning ? 1.0 : 0.0;\n\n  vNext = getNextValue(aCur, aPrev, aTo);\n  gl_Position = vec4(0, 0, 0, 1);\n  gl_PointSize = 100.0;\n}\n",
    fs: "\n#define SHADER_NAME spring-transition-is-transitioning-fragment-shader\n\nvarying float vIsTransitioningFlag;\n\nvoid main(void) {\n  if (vIsTransitioningFlag == 0.0) {\n    discard;\n  }\n  gl_FragColor = vec4(1.0);\n}",
    defines: {
      ATTRIBUTE_TYPE: attributeType
    },
    varyings: ['vNext']
  });
}

function getTexture(gl) {
  return new Texture2D(gl, {
    data: new Uint8Array(4),
    format: 6408,
    type: 5121,
    border: 0,
    mipmaps: false,
    dataFormat: 6408,
    width: 1,
    height: 1
  });
}

function getFramebuffer(gl, texture) {
  return new Framebuffer(gl, {
    id: 'spring-transition-is-transitioning-framebuffer',
    width: 1,
    height: 1,
    attachments: _defineProperty({}, 36064, texture)
  });
}