import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import { load } from '@loaders.gl/core';

var Resource = function () {
  function Resource(id, data, context) {
    _classCallCheck(this, Resource);

    _defineProperty(this, "id", void 0);

    _defineProperty(this, "context", void 0);

    _defineProperty(this, "isLoaded", void 0);

    _defineProperty(this, "persistent", void 0);

    _defineProperty(this, "_loadCount", 0);

    _defineProperty(this, "_subscribers", new Set());

    _defineProperty(this, "_data", void 0);

    _defineProperty(this, "_loader", void 0);

    _defineProperty(this, "_error", void 0);

    _defineProperty(this, "_content", void 0);

    this.id = id;
    this.context = context;
    this.setData(data);
  }

  _createClass(Resource, [{
    key: "subscribe",
    value: function subscribe(consumer) {
      this._subscribers.add(consumer);
    }
  }, {
    key: "unsubscribe",
    value: function unsubscribe(consumer) {
      this._subscribers.delete(consumer);
    }
  }, {
    key: "inUse",
    value: function inUse() {
      return this._subscribers.size > 0;
    }
  }, {
    key: "delete",
    value: function _delete() {}
  }, {
    key: "getData",
    value: function getData() {
      var _this = this;

      return this.isLoaded ? this._error ? Promise.reject(this._error) : this._content : this._loader.then(function () {
        return _this.getData();
      });
    }
  }, {
    key: "setData",
    value: function setData(data, forceUpdate) {
      var _this2 = this;

      if (data === this._data && !forceUpdate) {
        return;
      }

      this._data = data;
      var loadCount = ++this._loadCount;
      var loader = data;

      if (typeof data === 'string') {
        loader = load(data);
      }

      if (loader instanceof Promise) {
        this.isLoaded = false;
        this._loader = loader.then(function (result) {
          if (_this2._loadCount === loadCount) {
            _this2.isLoaded = true;
            _this2._error = undefined;
            _this2._content = result;
          }
        }).catch(function (error) {
          if (_this2._loadCount === loadCount) {
            _this2.isLoaded = true;
            _this2._error = error || true;
          }
        });
      } else {
        this.isLoaded = true;
        this._error = undefined;
        this._content = data;
      }

      var _iterator = _createForOfIteratorHelper(this._subscribers),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var subscriber = _step.value;
          subscriber.onChange(this.getData());
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    }
  }]);

  return Resource;
}();

export { Resource as default };