import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import { equals } from '@math.gl/core';
import assert from '../utils/assert';

var TransitionInterpolator = function () {
  function TransitionInterpolator(opts) {
    _classCallCheck(this, TransitionInterpolator);

    _defineProperty(this, "_propsToCompare", void 0);

    _defineProperty(this, "_propsToExtract", void 0);

    _defineProperty(this, "_requiredProps", void 0);

    var compare = opts.compare,
        extract = opts.extract,
        required = opts.required;
    this._propsToCompare = compare;
    this._propsToExtract = extract || compare;
    this._requiredProps = required;
  }

  _createClass(TransitionInterpolator, [{
    key: "arePropsEqual",
    value: function arePropsEqual(currentProps, nextProps) {
      var _iterator = _createForOfIteratorHelper(this._propsToCompare),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var key = _step.value;

          if (!(key in currentProps) || !(key in nextProps) || !equals(currentProps[key], nextProps[key])) {
            return false;
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      return true;
    }
  }, {
    key: "initializeProps",
    value: function initializeProps(startProps, endProps) {
      var startViewStateProps = {};
      var endViewStateProps = {};

      var _iterator2 = _createForOfIteratorHelper(this._propsToExtract),
          _step2;

      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var key = _step2.value;

          if (key in startProps || key in endProps) {
            startViewStateProps[key] = startProps[key];
            endViewStateProps[key] = endProps[key];
          }
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }

      this._checkRequiredProps(startViewStateProps);

      this._checkRequiredProps(endViewStateProps);

      return {
        start: startViewStateProps,
        end: endViewStateProps
      };
    }
  }, {
    key: "getDuration",
    value: function getDuration(startProps, endProps) {
      return endProps.transitionDuration;
    }
  }, {
    key: "_checkRequiredProps",
    value: function _checkRequiredProps(props) {
      if (!this._requiredProps) {
        return;
      }

      this._requiredProps.forEach(function (propName) {
        var value = props[propName];
        assert(Number.isFinite(value) || Array.isArray(value), "".concat(propName, " is required for transition"));
      });
    }
  }]);

  return TransitionInterpolator;
}();

export { TransitionInterpolator as default };