function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import { hexbin } from 'd3-hexbin';
import { createIterable, log } from '@deck.gl/core';
export function pointToHexbin(props, aggregationParams) {
  var data = props.data,
      radius = props.radius;
  var viewport = aggregationParams.viewport,
      attributes = aggregationParams.attributes;
  var centerLngLat = data.length ? getPointsCenter(data, aggregationParams) : null;
  var radiusCommon = getRadiusInCommon(radius, viewport, centerLngLat);
  var screenPoints = [];

  var _createIterable = createIterable(data),
      iterable = _createIterable.iterable,
      objectInfo = _createIterable.objectInfo;

  var positions = attributes.positions.value;

  var _attributes$positions = attributes.positions.getAccessor(),
      size = _attributes$positions.size;

  var _iterator = _createForOfIteratorHelper(iterable),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var object = _step.value;
      objectInfo.index++;
      var posIndex = objectInfo.index * size;
      var position = [positions[posIndex], positions[posIndex + 1]];
      var arrayIsFinite = Number.isFinite(position[0]) && Number.isFinite(position[1]);

      if (arrayIsFinite) {
        screenPoints.push({
          screenCoord: viewport.projectFlat(position),
          source: object,
          index: objectInfo.index
        });
      } else {
        log.warn('HexagonLayer: invalid position')();
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  var newHexbin = hexbin().radius(radiusCommon).x(function (d) {
    return d.screenCoord[0];
  }).y(function (d) {
    return d.screenCoord[1];
  });
  var hexagonBins = newHexbin(screenPoints);
  return {
    hexagons: hexagonBins.map(function (hex, index) {
      return {
        position: viewport.unprojectFlat([hex.x, hex.y]),
        points: hex,
        index: index
      };
    }),
    radiusCommon: radiusCommon
  };
}
export function getPointsCenter(data, aggregationParams) {
  var attributes = aggregationParams.attributes;
  var positions = attributes.positions.value;

  var _attributes$positions2 = attributes.positions.getAccessor(),
      size = _attributes$positions2.size;

  var minX = Infinity;
  var minY = Infinity;
  var maxX = -Infinity;
  var maxY = -Infinity;
  var i;

  for (i = 0; i < size * data.length; i += size) {
    var x = positions[i];
    var y = positions[i + 1];
    var arrayIsFinite = Number.isFinite(x) && Number.isFinite(y);

    if (arrayIsFinite) {
      minX = Math.min(x, minX);
      maxX = Math.max(x, maxX);
      minY = Math.min(y, minY);
      maxY = Math.max(y, maxY);
    }
  }

  return [minX, minY, maxX, maxY].every(Number.isFinite) ? [(minX + maxX) / 2, (minY + maxY) / 2] : null;
}
export function getRadiusInCommon(radius, viewport, center) {
  var _viewport$getDistance = viewport.getDistanceScales(center),
      unitsPerMeter = _viewport$getDistance.unitsPerMeter;

  return radius * unitsPerMeter[0];
}