import _typeof from "@babel/runtime/helpers/typeof";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import BinSorter from './bin-sorter';
import { getScaleFunctionByScaleType } from './scale-utils';
import { getValueFunc, wrapGetValueFunc } from './aggregation-operation-utils';

function nop() {}

var dimensionSteps = ['getBins', 'getDomain', 'getScaleFunc'];
var _defaultDimensions = [{
  key: 'fillColor',
  accessor: 'getFillColor',
  pickingInfo: 'colorValue',
  getBins: {
    triggers: {
      value: {
        prop: 'getColorValue',
        updateTrigger: 'getColorValue'
      },
      weight: {
        prop: 'getColorWeight',
        updateTrigger: 'getColorWeight'
      },
      aggregation: {
        prop: 'colorAggregation'
      },
      filterData: {
        prop: '_filterData',
        updateTrigger: '_filterData'
      }
    }
  },
  getDomain: {
    triggers: {
      lowerPercentile: {
        prop: 'lowerPercentile'
      },
      upperPercentile: {
        prop: 'upperPercentile'
      },
      scaleType: {
        prop: 'colorScaleType'
      }
    }
  },
  getScaleFunc: {
    triggers: {
      domain: {
        prop: 'colorDomain'
      },
      range: {
        prop: 'colorRange'
      }
    },
    onSet: {
      props: 'onSetColorDomain'
    }
  },
  nullValue: [0, 0, 0, 0]
}, {
  key: 'elevation',
  accessor: 'getElevation',
  pickingInfo: 'elevationValue',
  getBins: {
    triggers: {
      value: {
        prop: 'getElevationValue',
        updateTrigger: 'getElevationValue'
      },
      weight: {
        prop: 'getElevationWeight',
        updateTrigger: 'getElevationWeight'
      },
      aggregation: {
        prop: 'elevationAggregation'
      },
      filterData: {
        prop: '_filterData',
        updateTrigger: '_filterData'
      }
    }
  },
  getDomain: {
    triggers: {
      lowerPercentile: {
        prop: 'elevationLowerPercentile'
      },
      upperPercentile: {
        prop: 'elevationUpperPercentile'
      },
      scaleType: {
        prop: 'elevationScaleType'
      }
    }
  },
  getScaleFunc: {
    triggers: {
      domain: {
        prop: 'elevationDomain'
      },
      range: {
        prop: 'elevationRange'
      }
    },
    onSet: {
      props: 'onSetElevationDomain'
    }
  },
  nullValue: -1
}];

var defaultGetCellSize = function defaultGetCellSize(props) {
  return props.cellSize;
};

var CPUAggregator = function () {
  function CPUAggregator(opts) {
    _classCallCheck(this, CPUAggregator);

    this.state = {
      layerData: {},
      dimensions: {}
    };
    this.changeFlags = {};
    this.dimensionUpdaters = {};
    this._getCellSize = opts.getCellSize || defaultGetCellSize;
    this._getAggregator = opts.getAggregator;

    this._addDimension(opts.dimensions || _defaultDimensions);
  }

  _createClass(CPUAggregator, [{
    key: "updateState",
    value: function updateState(opts, aggregationParams) {
      var oldProps = opts.oldProps,
          props = opts.props,
          changeFlags = opts.changeFlags;
      this.updateGetValueFuncs(oldProps, props, changeFlags);
      var reprojectNeeded = this.needsReProjectPoints(oldProps, props, changeFlags);
      var aggregationDirty = false;

      if (changeFlags.dataChanged || reprojectNeeded) {
        this.getAggregatedData(props, aggregationParams);
        aggregationDirty = true;
      } else {
        var dimensionChanges = this.getDimensionChanges(oldProps, props, changeFlags) || [];
        dimensionChanges.forEach(function (f) {
          return typeof f === 'function' && f();
        });
        aggregationDirty = true;
      }

      this.setState({
        aggregationDirty: aggregationDirty
      });
      return this.state;
    }
  }, {
    key: "setState",
    value: function setState(updateObject) {
      this.state = _objectSpread(_objectSpread({}, this.state), updateObject);
    }
  }, {
    key: "setDimensionState",
    value: function setDimensionState(key, updateObject) {
      this.setState({
        dimensions: _objectSpread(_objectSpread({}, this.state.dimensions), {}, _defineProperty({}, key, _objectSpread(_objectSpread({}, this.state.dimensions[key]), updateObject)))
      });
    }
  }, {
    key: "normalizeResult",
    value: function normalizeResult() {
      var result = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      if (result.hexagons) {
        return _objectSpread({
          data: result.hexagons
        }, result);
      } else if (result.layerData) {
        return _objectSpread({
          data: result.layerData
        }, result);
      }

      return result;
    }
  }, {
    key: "getAggregatedData",
    value: function getAggregatedData(props, aggregationParams) {
      var aggregator = this._getAggregator(props);

      var result = aggregator(props, aggregationParams);
      this.setState({
        layerData: this.normalizeResult(result)
      });
      this.changeFlags = {
        layerData: true
      };
      this.getSortedBins(props);
    }
  }, {
    key: "updateGetValueFuncs",
    value: function updateGetValueFuncs(oldProps, props, changeFlags) {
      for (var key in this.dimensionUpdaters) {
        var _this$dimensionUpdate = this.dimensionUpdaters[key].getBins.triggers,
            value = _this$dimensionUpdate.value,
            weight = _this$dimensionUpdate.weight,
            aggregation = _this$dimensionUpdate.aggregation;
        var getValue = props[value.prop];
        var getValueChanged = this.needUpdateDimensionStep(this.dimensionUpdaters[key].getBins, oldProps, props, changeFlags);

        if (getValueChanged) {
          if (getValue) {
            getValue = wrapGetValueFunc(getValue, {
              data: props.data
            });
          } else {
            getValue = getValueFunc(props[aggregation.prop], props[weight.prop], {
              data: props.data
            });
          }
        }

        if (getValue) {
          this.setDimensionState(key, {
            getValue: getValue
          });
        }
      }
    }
  }, {
    key: "needsReProjectPoints",
    value: function needsReProjectPoints(oldProps, props, changeFlags) {
      return this._getCellSize(oldProps) !== this._getCellSize(props) || this._getAggregator(oldProps) !== this._getAggregator(props) || changeFlags.updateTriggersChanged && (changeFlags.updateTriggersChanged.all || changeFlags.updateTriggersChanged.getPosition);
    }
  }, {
    key: "addDimension",
    value: function addDimension(dimensions) {
      this._addDimension(dimensions);
    }
  }, {
    key: "_addDimension",
    value: function _addDimension() {
      var _this = this;

      var dimensions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      dimensions.forEach(function (dimension) {
        var key = dimension.key;
        _this.dimensionUpdaters[key] = _this.getDimensionUpdaters(dimension);
        _this.state.dimensions[key] = {
          getValue: null,
          domain: null,
          sortedBins: null,
          scaleFunc: nop
        };
      });
    }
  }, {
    key: "getDimensionUpdaters",
    value: function getDimensionUpdaters(_ref) {
      var key = _ref.key,
          accessor = _ref.accessor,
          pickingInfo = _ref.pickingInfo,
          getBins = _ref.getBins,
          getDomain = _ref.getDomain,
          getScaleFunc = _ref.getScaleFunc,
          nullValue = _ref.nullValue;
      return {
        key: key,
        accessor: accessor,
        pickingInfo: pickingInfo,
        getBins: _objectSpread({
          updater: this.getDimensionSortedBins
        }, getBins),
        getDomain: _objectSpread({
          updater: this.getDimensionValueDomain
        }, getDomain),
        getScaleFunc: _objectSpread({
          updater: this.getDimensionScale
        }, getScaleFunc),
        attributeAccessor: this.getSubLayerDimensionAttribute(key, nullValue)
      };
    }
  }, {
    key: "needUpdateDimensionStep",
    value: function needUpdateDimensionStep(dimensionStep, oldProps, props, changeFlags) {
      return Object.values(dimensionStep.triggers).some(function (item) {
        if (item.updateTrigger) {
          return changeFlags.dataChanged || changeFlags.updateTriggersChanged && (changeFlags.updateTriggersChanged.all || changeFlags.updateTriggersChanged[item.updateTrigger]);
        }

        return oldProps[item.prop] !== props[item.prop];
      });
    }
  }, {
    key: "getDimensionChanges",
    value: function getDimensionChanges(oldProps, props, changeFlags) {
      var _this2 = this;

      var updaters = [];

      var _loop = function _loop(key) {
        var needUpdate = dimensionSteps.find(function (step) {
          return _this2.needUpdateDimensionStep(_this2.dimensionUpdaters[key][step], oldProps, props, changeFlags);
        });

        if (needUpdate) {
          updaters.push(_this2.dimensionUpdaters[key][needUpdate].updater.bind(_this2, props, _this2.dimensionUpdaters[key]));
        }
      };

      for (var key in this.dimensionUpdaters) {
        _loop(key);
      }

      return updaters.length ? updaters : null;
    }
  }, {
    key: "getUpdateTriggers",
    value: function getUpdateTriggers(props) {
      var _this3 = this;

      var _updateTriggers = props.updateTriggers || {};

      var updateTriggers = {};

      var _loop2 = function _loop2(key) {
        var accessor = _this3.dimensionUpdaters[key].accessor;
        updateTriggers[accessor] = {};
        dimensionSteps.forEach(function (step) {
          Object.values(_this3.dimensionUpdaters[key][step].triggers).forEach(function (_ref2) {
            var prop = _ref2.prop,
                updateTrigger = _ref2.updateTrigger;

            if (updateTrigger) {
              var fromProp = _updateTriggers[updateTrigger];

              if (_typeof(fromProp) === 'object' && !Array.isArray(fromProp)) {
                Object.assign(updateTriggers[accessor], fromProp);
              } else if (fromProp !== undefined) {
                updateTriggers[accessor][prop] = fromProp;
              }
            } else {
              updateTriggers[accessor][prop] = props[prop];
            }
          });
        });
      };

      for (var key in this.dimensionUpdaters) {
        _loop2(key);
      }

      return updateTriggers;
    }
  }, {
    key: "getSortedBins",
    value: function getSortedBins(props) {
      for (var key in this.dimensionUpdaters) {
        this.getDimensionSortedBins(props, this.dimensionUpdaters[key]);
      }
    }
  }, {
    key: "getDimensionSortedBins",
    value: function getDimensionSortedBins(props, dimensionUpdater) {
      var key = dimensionUpdater.key;
      var getValue = this.state.dimensions[key].getValue;
      var sortedBins = new BinSorter(this.state.layerData.data || [], {
        getValue: getValue,
        filterData: props._filterData
      });
      this.setDimensionState(key, {
        sortedBins: sortedBins
      });
      this.getDimensionValueDomain(props, dimensionUpdater);
    }
  }, {
    key: "getDimensionValueDomain",
    value: function getDimensionValueDomain(props, dimensionUpdater) {
      var getDomain = dimensionUpdater.getDomain,
          key = dimensionUpdater.key;
      var _getDomain$triggers = getDomain.triggers,
          lowerPercentile = _getDomain$triggers.lowerPercentile,
          upperPercentile = _getDomain$triggers.upperPercentile,
          scaleType = _getDomain$triggers.scaleType;
      var valueDomain = this.state.dimensions[key].sortedBins.getValueDomainByScale(props[scaleType.prop], [props[lowerPercentile.prop], props[upperPercentile.prop]]);
      this.setDimensionState(key, {
        valueDomain: valueDomain
      });
      this.getDimensionScale(props, dimensionUpdater);
    }
  }, {
    key: "getDimensionScale",
    value: function getDimensionScale(props, dimensionUpdater) {
      var key = dimensionUpdater.key,
          getScaleFunc = dimensionUpdater.getScaleFunc,
          getDomain = dimensionUpdater.getDomain;
      var _getScaleFunc$trigger = getScaleFunc.triggers,
          domain = _getScaleFunc$trigger.domain,
          range = _getScaleFunc$trigger.range;
      var scaleType = getDomain.triggers.scaleType;
      var onSet = getScaleFunc.onSet;
      var dimensionRange = props[range.prop];
      var dimensionDomain = props[domain.prop] || this.state.dimensions[key].valueDomain;
      var getScaleFunction = getScaleFunctionByScaleType(scaleType && props[scaleType.prop]);
      var scaleFunc = getScaleFunction(dimensionDomain, dimensionRange);

      if (_typeof(onSet) === 'object' && typeof props[onSet.props] === 'function') {
        props[onSet.props](scaleFunc.domain());
      }

      this.setDimensionState(key, {
        scaleFunc: scaleFunc
      });
    }
  }, {
    key: "getSubLayerDimensionAttribute",
    value: function getSubLayerDimensionAttribute(key, nullValue) {
      var _this4 = this;

      return function (cell) {
        var _this4$state$dimensio = _this4.state.dimensions[key],
            sortedBins = _this4$state$dimensio.sortedBins,
            scaleFunc = _this4$state$dimensio.scaleFunc;
        var bin = sortedBins.binMap[cell.index];

        if (bin && bin.counts === 0) {
          return nullValue;
        }

        var cv = bin && bin.value;
        var domain = scaleFunc.domain();
        var isValueInDomain = cv >= domain[0] && cv <= domain[domain.length - 1];
        return isValueInDomain ? scaleFunc(cv) : nullValue;
      };
    }
  }, {
    key: "getSubLayerAccessors",
    value: function getSubLayerAccessors(props) {
      var accessors = {};

      for (var key in this.dimensionUpdaters) {
        var accessor = this.dimensionUpdaters[key].accessor;
        accessors[accessor] = this.getSubLayerDimensionAttribute(props, key);
      }

      return accessors;
    }
  }, {
    key: "getPickingInfo",
    value: function getPickingInfo(_ref3) {
      var info = _ref3.info;
      var isPicked = info.picked && info.index > -1;
      var object = null;

      if (isPicked) {
        var cell = this.state.layerData.data[info.index];
        var binInfo = {};

        for (var key in this.dimensionUpdaters) {
          var pickingInfo = this.dimensionUpdaters[key].pickingInfo;
          var sortedBins = this.state.dimensions[key].sortedBins;
          var value = sortedBins.binMap[cell.index] && sortedBins.binMap[cell.index].value;
          binInfo[pickingInfo] = value;
        }

        object = Object.assign(binInfo, cell, {
          points: cell.filteredPoints || cell.points
        });
      }

      info.picked = Boolean(object);
      info.object = object;
      return info;
    }
  }, {
    key: "getAccessor",
    value: function getAccessor(dimensionKey) {
      if (!this.dimensionUpdaters.hasOwnProperty(dimensionKey)) {
        return nop;
      }

      return this.dimensionUpdaters[dimensionKey].attributeAccessor;
    }
  }], [{
    key: "defaultDimensions",
    value: function defaultDimensions() {
      return _defaultDimensions;
    }
  }]);

  return CPUAggregator;
}();

export { CPUAggregator as default };