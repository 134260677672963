import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _inherits from "@babel/runtime/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

import GeoCellLayer from '../geo-cell-layer/GeoCellLayer';
import { getGeohashPolygon } from './geohash-utils';
var defaultProps = {
  getGeohash: {
    type: 'accessor',
    value: function value(d) {
      return d.geohash;
    }
  }
};

var GeohashLayer = function (_GeoCellLayer) {
  _inherits(GeohashLayer, _GeoCellLayer);

  var _super = _createSuper(GeohashLayer);

  function GeohashLayer() {
    _classCallCheck(this, GeohashLayer);

    return _super.apply(this, arguments);
  }

  _createClass(GeohashLayer, [{
    key: "indexToBounds",
    value: function indexToBounds() {
      var _this$props = this.props,
          data = _this$props.data,
          getGeohash = _this$props.getGeohash;
      return {
        data: data,
        _normalize: false,
        positionFormat: 'XY',
        getPolygon: function getPolygon(x, objectInfo) {
          return getGeohashPolygon(getGeohash(x, objectInfo));
        }
      };
    }
  }]);

  return GeohashLayer;
}(GeoCellLayer);

_defineProperty(GeohashLayer, "layerName", 'GeohashLayer');

_defineProperty(GeohashLayer, "defaultProps", defaultProps);

export { GeohashLayer as default };