import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var Pass = function () {
  function Pass(gl) {
    var props = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
      id: 'pass'
    };

    _classCallCheck(this, Pass);

    _defineProperty(this, "id", void 0);

    _defineProperty(this, "gl", void 0);

    _defineProperty(this, "props", void 0);

    var id = props.id;
    this.id = id;
    this.gl = gl;
    this.props = _objectSpread({}, props);
  }

  _createClass(Pass, [{
    key: "setProps",
    value: function setProps(props) {
      Object.assign(this.props, props);
    }
  }, {
    key: "render",
    value: function render(params) {}
  }, {
    key: "cleanup",
    value: function cleanup() {}
  }]);

  return Pass;
}();

export { Pass as default };