import _typeof from "@babel/runtime/helpers/typeof";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { createTexture, destroyTexture } from '../utils/texture';
import { deepEqual } from '../utils/deep-equal';
var TYPE_DEFINITIONS = {
  boolean: {
    validate: function validate(value, propType) {
      return true;
    },
    equal: function equal(value1, value2, propType) {
      return Boolean(value1) === Boolean(value2);
    }
  },
  number: {
    validate: function validate(value, propType) {
      return Number.isFinite(value) && (!('max' in propType) || value <= propType.max) && (!('min' in propType) || value >= propType.min);
    }
  },
  color: {
    validate: function validate(value, propType) {
      return propType.optional && !value || isArray(value) && (value.length === 3 || value.length === 4);
    },
    equal: function equal(value1, value2, propType) {
      return deepEqual(value1, value2, 1);
    }
  },
  accessor: {
    validate: function validate(value, propType) {
      var valueType = getTypeOf(value);
      return valueType === 'function' || valueType === getTypeOf(propType.value);
    },
    equal: function equal(value1, value2, propType) {
      if (typeof value2 === 'function') {
        return true;
      }

      return deepEqual(value1, value2, 1);
    }
  },
  array: {
    validate: function validate(value, propType) {
      return propType.optional && !value || isArray(value);
    },
    equal: function equal(value1, value2, propType) {
      var compare = propType.compare;
      var depth = Number.isInteger(compare) ? compare : compare ? 1 : 0;
      return compare ? deepEqual(value1, value2, depth) : value1 === value2;
    }
  },
  object: {
    equal: function equal(value1, value2, propType) {
      if (propType.ignore) {
        return true;
      }

      var compare = propType.compare;
      var depth = Number.isInteger(compare) ? compare : compare ? 1 : 0;
      return compare ? deepEqual(value1, value2, depth) : value1 === value2;
    }
  },
  function: {
    validate: function validate(value, propType) {
      return propType.optional && !value || typeof value === 'function';
    },
    equal: function equal(value1, value2, propType) {
      var shouldIgnore = !propType.compare && propType.ignore !== false;
      return shouldIgnore || value1 === value2;
    }
  },
  data: {
    transform: function transform(value, propType, component) {
      var dataTransform = component.props.dataTransform;
      return dataTransform && value ? dataTransform(value) : value;
    }
  },
  image: {
    transform: function transform(value, propType, component) {
      var context = component.context;

      if (!context || !context.gl) {
        return null;
      }

      return createTexture(component.id, context.gl, value, _objectSpread(_objectSpread({}, propType.parameters), component.props.textureParameters));
    },
    release: function release(value, propType, component) {
      destroyTexture(component.id, value);
    }
  }
};
export function parsePropTypes(propDefs) {
  var propTypes = {};
  var defaultProps = {};
  var deprecatedProps = {};

  for (var _i = 0, _Object$entries = Object.entries(propDefs); _i < _Object$entries.length; _i++) {
    var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
        _propName = _Object$entries$_i[0],
        propDef = _Object$entries$_i[1];

    var deprecated = propDef === null || propDef === void 0 ? void 0 : propDef.deprecatedFor;

    if (deprecated) {
      deprecatedProps[_propName] = Array.isArray(deprecated) ? deprecated : [deprecated];
    } else {
      var _propType = parsePropType(_propName, propDef);

      propTypes[_propName] = _propType;
      defaultProps[_propName] = _propType.value;
    }
  }

  return {
    propTypes: propTypes,
    defaultProps: defaultProps,
    deprecatedProps: deprecatedProps
  };
}

function parsePropType(name, propDef) {
  switch (getTypeOf(propDef)) {
    case 'object':
      return normalizePropDefinition(name, propDef);

    case 'array':
      return normalizePropDefinition(name, {
        type: 'array',
        value: propDef,
        compare: false
      });

    case 'boolean':
      return normalizePropDefinition(name, {
        type: 'boolean',
        value: propDef
      });

    case 'number':
      return normalizePropDefinition(name, {
        type: 'number',
        value: propDef
      });

    case 'function':
      return normalizePropDefinition(name, {
        type: 'function',
        value: propDef,
        compare: true
      });

    default:
      return {
        name: name,
        type: 'unknown',
        value: propDef
      };
  }
}

function normalizePropDefinition(name, propDef) {
  if (!('type' in propDef)) {
    if (!('value' in propDef)) {
      return {
        name: name,
        type: 'object',
        value: propDef
      };
    }

    return _objectSpread({
      name: name,
      type: getTypeOf(propDef.value)
    }, propDef);
  }

  return _objectSpread(_objectSpread({
    name: name
  }, TYPE_DEFINITIONS[propDef.type]), propDef);
}

function isArray(value) {
  return Array.isArray(value) || ArrayBuffer.isView(value);
}

function getTypeOf(value) {
  if (isArray(value)) {
    return 'array';
  }

  if (value === null) {
    return 'null';
  }

  return _typeof(value);
}