import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _assertThisInitialized from "@babel/runtime/helpers/assertThisInitialized";
import _get from "@babel/runtime/helpers/get";
import _inherits from "@babel/runtime/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

import AggregationLayer from './aggregation-layer';
import GPUGridAggregator from './utils/gpu-grid-aggregation/gpu-grid-aggregator';
import { Buffer } from '@luma.gl/core';
import { log } from '@deck.gl/core';
import BinSorter from './utils/bin-sorter';
import { pointToDensityGridDataCPU } from './cpu-grid-layer/grid-aggregator';

var GridAggregationLayer = function (_AggregationLayer) {
  _inherits(GridAggregationLayer, _AggregationLayer);

  var _super = _createSuper(GridAggregationLayer);

  function GridAggregationLayer() {
    var _this;

    _classCallCheck(this, GridAggregationLayer);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));

    _defineProperty(_assertThisInitialized(_this), "state", void 0);

    return _this;
  }

  _createClass(GridAggregationLayer, [{
    key: "initializeAggregationLayer",
    value: function initializeAggregationLayer(_ref) {
      var dimensions = _ref.dimensions;
      var gl = this.context.gl;

      _get(_getPrototypeOf(GridAggregationLayer.prototype), "initializeAggregationLayer", this).call(this, dimensions);

      this.setState({
        layerData: {},
        gpuGridAggregator: new GPUGridAggregator(gl, {
          id: "".concat(this.id, "-gpu-aggregator")
        }),
        cpuGridAggregator: pointToDensityGridDataCPU
      });
    }
  }, {
    key: "updateState",
    value: function updateState(opts) {
      _get(_getPrototypeOf(GridAggregationLayer.prototype), "updateState", this).call(this, opts);

      this.updateAggregationState(opts);
      var _this$state = this.state,
          aggregationDataDirty = _this$state.aggregationDataDirty,
          aggregationWeightsDirty = _this$state.aggregationWeightsDirty,
          gpuAggregation = _this$state.gpuAggregation;

      if (this.getNumInstances() <= 0) {
        return;
      }

      var aggregationDirty = false;

      if (aggregationDataDirty || gpuAggregation && aggregationWeightsDirty) {
        this._updateAggregation(opts);

        aggregationDirty = true;
      }

      if (!gpuAggregation && (aggregationDataDirty || aggregationWeightsDirty)) {
        this._updateWeightBins();

        this._uploadAggregationResults();

        aggregationDirty = true;
      }

      this.setState({
        aggregationDirty: aggregationDirty
      });
    }
  }, {
    key: "finalizeState",
    value: function finalizeState(context) {
      var _this$state$gpuGridAg;

      var count = this.state.weights.count;

      if (count && count.aggregationBuffer) {
        count.aggregationBuffer.delete();
      }

      (_this$state$gpuGridAg = this.state.gpuGridAggregator) === null || _this$state$gpuGridAg === void 0 ? void 0 : _this$state$gpuGridAg.delete();

      _get(_getPrototypeOf(GridAggregationLayer.prototype), "finalizeState", this).call(this, context);
    }
  }, {
    key: "updateShaders",
    value: function updateShaders(shaders) {
      if (this.state.gpuAggregation) {
        this.state.gpuGridAggregator.updateShaders(shaders);
      }
    }
  }, {
    key: "updateAggregationState",
    value: function updateAggregationState(opts) {
      log.assert(false);
    }
  }, {
    key: "allocateResources",
    value: function allocateResources(numRow, numCol) {
      if (this.state.numRow !== numRow || this.state.numCol !== numCol) {
        var dataBytes = numCol * numRow * 4 * 4;
        var gl = this.context.gl;
        var weights = this.state.weights;

        for (var name in weights) {
          var weight = weights[name];

          if (weight.aggregationBuffer) {
            weight.aggregationBuffer.delete();
          }

          weight.aggregationBuffer = new Buffer(gl, {
            byteLength: dataBytes,
            accessor: {
              size: 4,
              type: 5126,
              divisor: 1
            }
          });
        }
      }
    }
  }, {
    key: "updateResults",
    value: function updateResults(_ref2) {
      var aggregationData = _ref2.aggregationData,
          maxMinData = _ref2.maxMinData,
          maxData = _ref2.maxData,
          minData = _ref2.minData;
      var count = this.state.weights.count;

      if (count) {
        count.aggregationData = aggregationData;
        count.maxMinData = maxMinData;
        count.maxData = maxData;
        count.minData = minData;
      }
    }
  }, {
    key: "_updateAggregation",
    value: function _updateAggregation(opts) {
      var _this$state2 = this.state,
          cpuGridAggregator = _this$state2.cpuGridAggregator,
          gpuGridAggregator = _this$state2.gpuGridAggregator,
          gridOffset = _this$state2.gridOffset,
          posOffset = _this$state2.posOffset,
          _this$state2$translat = _this$state2.translation,
          translation = _this$state2$translat === void 0 ? [0, 0] : _this$state2$translat,
          _this$state2$scaling = _this$state2.scaling,
          scaling = _this$state2$scaling === void 0 ? [0, 0, 0] : _this$state2$scaling,
          boundingBox = _this$state2.boundingBox,
          projectPoints = _this$state2.projectPoints,
          gpuAggregation = _this$state2.gpuAggregation,
          numCol = _this$state2.numCol,
          numRow = _this$state2.numRow;
      var props = opts.props;
      var viewport = this.context.viewport;
      var attributes = this.getAttributes();
      var vertexCount = this.getNumInstances();

      if (!gpuAggregation) {
        var result = cpuGridAggregator(props, {
          gridOffset: gridOffset,
          projectPoints: projectPoints,
          attributes: attributes,
          viewport: viewport,
          posOffset: posOffset,
          boundingBox: boundingBox
        });
        this.setState({
          layerData: result
        });
      } else {
        var weights = this.state.weights;
        gpuGridAggregator.run({
          weights: weights,
          cellSize: [gridOffset.xOffset, gridOffset.yOffset],
          numCol: numCol,
          numRow: numRow,
          translation: translation,
          scaling: scaling,
          vertexCount: vertexCount,
          projectPoints: projectPoints,
          attributes: attributes,
          moduleSettings: this.getModuleSettings()
        });
      }
    }
  }, {
    key: "_updateWeightBins",
    value: function _updateWeightBins() {
      var getValue = this.state.getValue;
      var sortedBins = new BinSorter(this.state.layerData.data || [], {
        getValue: getValue
      });
      this.setState({
        sortedBins: sortedBins
      });
    }
  }, {
    key: "_uploadAggregationResults",
    value: function _uploadAggregationResults() {
      var _this$state3 = this.state,
          numCol = _this$state3.numCol,
          numRow = _this$state3.numRow;
      var data = this.state.layerData.data;
      var _ref3 = this.state.sortedBins,
          aggregatedBins = _ref3.aggregatedBins,
          minValue = _ref3.minValue,
          maxValue = _ref3.maxValue,
          totalCount = _ref3.totalCount;
      var ELEMENTCOUNT = 4;
      var aggregationSize = numCol * numRow * ELEMENTCOUNT;
      var aggregationData = new Float32Array(aggregationSize).fill(0);

      var _iterator = _createForOfIteratorHelper(aggregatedBins),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var bin = _step.value;
          var _data$bin$i = data[bin.i],
              lonIdx = _data$bin$i.lonIdx,
              latIdx = _data$bin$i.latIdx;
          var value = bin.value,
              counts = bin.counts;
          var cellIndex = (lonIdx + latIdx * numCol) * ELEMENTCOUNT;
          aggregationData[cellIndex] = value;
          aggregationData[cellIndex + ELEMENTCOUNT - 1] = counts;
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      var maxMinData = new Float32Array([maxValue, 0, 0, minValue]);
      var maxData = new Float32Array([maxValue, 0, 0, totalCount]);
      var minData = new Float32Array([minValue, 0, 0, totalCount]);
      this.updateResults({
        aggregationData: aggregationData,
        maxMinData: maxMinData,
        maxData: maxData,
        minData: minData
      });
    }
  }]);

  return GridAggregationLayer;
}(AggregationLayer);

_defineProperty(GridAggregationLayer, "layerName", 'GridAggregationLayer');

export { GridAggregationLayer as default };