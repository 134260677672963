import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { projectPosition } from '../../shaderlib/project/project-functions';
import { COORDINATE_SYSTEM } from '../../lib/constants';
var DEFAULT_LIGHT_COLOR = [255, 255, 255];
var DEFAULT_LIGHT_INTENSITY = 1.0;
var DEFAULT_ATTENUATION = [0, 0, 1];
var DEFAULT_LIGHT_POSITION = [0.0, 0.0, 1.0];
var idCount = 0;
export var PointLight = function () {
  function PointLight() {
    var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    _classCallCheck(this, PointLight);

    _defineProperty(this, "id", void 0);

    _defineProperty(this, "color", void 0);

    _defineProperty(this, "intensity", void 0);

    _defineProperty(this, "type", 'point');

    _defineProperty(this, "position", void 0);

    _defineProperty(this, "attenuation", void 0);

    _defineProperty(this, "projectedLight", void 0);

    var _props$color = props.color,
        color = _props$color === void 0 ? DEFAULT_LIGHT_COLOR : _props$color;
    var _props$intensity = props.intensity,
        intensity = _props$intensity === void 0 ? DEFAULT_LIGHT_INTENSITY : _props$intensity;
    var _props$position = props.position,
        position = _props$position === void 0 ? DEFAULT_LIGHT_POSITION : _props$position;
    this.id = props.id || "point-".concat(idCount++);
    this.color = color;
    this.intensity = intensity;
    this.type = 'point';
    this.position = position;
    this.attenuation = getAttenuation(props);
    this.projectedLight = _objectSpread({}, this);
  }

  _createClass(PointLight, [{
    key: "getProjectedLight",
    value: function getProjectedLight(_ref) {
      var layer = _ref.layer;
      var projectedLight = this.projectedLight;
      var viewport = layer.context.viewport;
      var _layer$props = layer.props,
          coordinateSystem = _layer$props.coordinateSystem,
          coordinateOrigin = _layer$props.coordinateOrigin;
      var position = projectPosition(this.position, {
        viewport: viewport,
        coordinateSystem: coordinateSystem,
        coordinateOrigin: coordinateOrigin,
        fromCoordinateSystem: viewport.isGeospatial ? COORDINATE_SYSTEM.LNGLAT : COORDINATE_SYSTEM.CARTESIAN,
        fromCoordinateOrigin: [0, 0, 0]
      });
      projectedLight.color = this.color;
      projectedLight.intensity = this.intensity;
      projectedLight.position = position;
      return projectedLight;
    }
  }]);

  return PointLight;
}();

function getAttenuation(props) {
  if (props.attenuation) {
    return props.attenuation;
  }

  if ('intensity' in props) {
    return [0, 0, props.intensity || 0];
  }

  return DEFAULT_ATTENUATION;
}