import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import { deepEqual } from '../utils/deep-equal';
import LightingEffect from '../effects/lighting/lighting-effect';
var DEFAULT_LIGHTING_EFFECT = new LightingEffect();

function compareEffects(e1, e2) {
  var _e1$order, _e2$order;

  var o1 = (_e1$order = e1.order) !== null && _e1$order !== void 0 ? _e1$order : Infinity;
  var o2 = (_e2$order = e2.order) !== null && _e2$order !== void 0 ? _e2$order : Infinity;
  return o1 - o2;
}

var EffectManager = function () {
  function EffectManager() {
    _classCallCheck(this, EffectManager);

    _defineProperty(this, "effects", void 0);

    _defineProperty(this, "_resolvedEffects", []);

    _defineProperty(this, "_defaultEffects", []);

    _defineProperty(this, "_needsRedraw", void 0);

    this.effects = [];
    this._needsRedraw = 'Initial render';

    this._setEffects([]);
  }

  _createClass(EffectManager, [{
    key: "addDefaultEffect",
    value: function addDefaultEffect(effect) {
      var defaultEffects = this._defaultEffects;

      if (!defaultEffects.find(function (e) {
        return e.id === effect.id;
      })) {
        var index = defaultEffects.findIndex(function (e) {
          return compareEffects(e, effect) > 0;
        });

        if (index < 0) {
          defaultEffects.push(effect);
        } else {
          defaultEffects.splice(index, 0, effect);
        }

        this._setEffects(this.effects);
      }
    }
  }, {
    key: "setProps",
    value: function setProps(props) {
      if ('effects' in props) {
        if (!deepEqual(props.effects, this.effects, 1)) {
          this._setEffects(props.effects);
        }
      }
    }
  }, {
    key: "needsRedraw",
    value: function needsRedraw() {
      var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
        clearRedrawFlags: false
      };
      var redraw = this._needsRedraw;

      if (opts.clearRedrawFlags) {
        this._needsRedraw = false;
      }

      return redraw;
    }
  }, {
    key: "getEffects",
    value: function getEffects() {
      return this._resolvedEffects;
    }
  }, {
    key: "_setEffects",
    value: function _setEffects(effects) {
      var oldEffectsMap = {};

      var _iterator = _createForOfIteratorHelper(this.effects),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var effect = _step.value;
          oldEffectsMap[effect.id] = effect;
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      var nextEffects = [];

      var _iterator2 = _createForOfIteratorHelper(effects),
          _step2;

      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var _effect = _step2.value;
          var oldEffect = oldEffectsMap[_effect.id];

          if (oldEffect && oldEffect !== _effect) {
            if (oldEffect.setProps) {
              oldEffect.setProps(_effect.props);
              nextEffects.push(oldEffect);
            } else {
              oldEffect.cleanup();
              nextEffects.push(_effect);
            }
          } else {
            nextEffects.push(_effect);
          }

          delete oldEffectsMap[_effect.id];
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }

      for (var removedEffectId in oldEffectsMap) {
        oldEffectsMap[removedEffectId].cleanup();
      }

      this.effects = nextEffects;
      this._resolvedEffects = nextEffects.concat(this._defaultEffects);

      if (!effects.some(function (effect) {
        return effect instanceof LightingEffect;
      })) {
        this._resolvedEffects.push(DEFAULT_LIGHTING_EFFECT);
      }

      this._needsRedraw = 'effects changed';
    }
  }, {
    key: "finalize",
    value: function finalize() {
      var _iterator3 = _createForOfIteratorHelper(this._resolvedEffects),
          _step3;

      try {
        for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
          var effect = _step3.value;
          effect.cleanup();
        }
      } catch (err) {
        _iterator3.e(err);
      } finally {
        _iterator3.f();
      }

      this.effects.length = 0;
      this._resolvedEffects.length = 0;
      this._defaultEffects.length = 0;
    }
  }]);

  return EffectManager;
}();

export { EffectManager as default };