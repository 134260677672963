import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _assertThisInitialized from "@babel/runtime/helpers/assertThisInitialized";
import _get from "@babel/runtime/helpers/get";
import _inherits from "@babel/runtime/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

import { Layer, project32, picking, UNIT } from '@deck.gl/core';
import { Model, Geometry } from '@luma.gl/core';
import vs from './text-background-layer-vertex.glsl';
import fs from './text-background-layer-fragment.glsl';
var defaultProps = {
  billboard: true,
  sizeScale: 1,
  sizeUnits: 'pixels',
  sizeMinPixels: 0,
  sizeMaxPixels: Number.MAX_SAFE_INTEGER,
  padding: {
    type: 'array',
    value: [0, 0, 0, 0]
  },
  getPosition: {
    type: 'accessor',
    value: function value(x) {
      return x.position;
    }
  },
  getSize: {
    type: 'accessor',
    value: 1
  },
  getAngle: {
    type: 'accessor',
    value: 0
  },
  getPixelOffset: {
    type: 'accessor',
    value: [0, 0]
  },
  getBoundingRect: {
    type: 'accessor',
    value: [0, 0, 0, 0]
  },
  getFillColor: {
    type: 'accessor',
    value: [0, 0, 0, 255]
  },
  getLineColor: {
    type: 'accessor',
    value: [0, 0, 0, 255]
  },
  getLineWidth: {
    type: 'accessor',
    value: 1
  }
};

var TextBackgroundLayer = function (_Layer) {
  _inherits(TextBackgroundLayer, _Layer);

  var _super = _createSuper(TextBackgroundLayer);

  function TextBackgroundLayer() {
    var _this;

    _classCallCheck(this, TextBackgroundLayer);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));

    _defineProperty(_assertThisInitialized(_this), "state", void 0);

    return _this;
  }

  _createClass(TextBackgroundLayer, [{
    key: "getShaders",
    value: function getShaders() {
      return _get(_getPrototypeOf(TextBackgroundLayer.prototype), "getShaders", this).call(this, {
        vs: vs,
        fs: fs,
        modules: [project32, picking]
      });
    }
  }, {
    key: "initializeState",
    value: function initializeState() {
      this.getAttributeManager().addInstanced({
        instancePositions: {
          size: 3,
          type: 5130,
          fp64: this.use64bitPositions(),
          transition: true,
          accessor: 'getPosition'
        },
        instanceSizes: {
          size: 1,
          transition: true,
          accessor: 'getSize',
          defaultValue: 1
        },
        instanceAngles: {
          size: 1,
          transition: true,
          accessor: 'getAngle'
        },
        instanceRects: {
          size: 4,
          accessor: 'getBoundingRect'
        },
        instancePixelOffsets: {
          size: 2,
          transition: true,
          accessor: 'getPixelOffset'
        },
        instanceFillColors: {
          size: 4,
          transition: true,
          normalized: true,
          type: 5121,
          accessor: 'getFillColor',
          defaultValue: [0, 0, 0, 255]
        },
        instanceLineColors: {
          size: 4,
          transition: true,
          normalized: true,
          type: 5121,
          accessor: 'getLineColor',
          defaultValue: [0, 0, 0, 255]
        },
        instanceLineWidths: {
          size: 1,
          transition: true,
          accessor: 'getLineWidth',
          defaultValue: 1
        }
      });
    }
  }, {
    key: "updateState",
    value: function updateState(params) {
      _get(_getPrototypeOf(TextBackgroundLayer.prototype), "updateState", this).call(this, params);

      var changeFlags = params.changeFlags;

      if (changeFlags.extensionsChanged) {
        var _this$state$model;

        var gl = this.context.gl;
        (_this$state$model = this.state.model) === null || _this$state$model === void 0 ? void 0 : _this$state$model.delete();
        this.state.model = this._getModel(gl);
        this.getAttributeManager().invalidateAll();
      }
    }
  }, {
    key: "draw",
    value: function draw(_ref) {
      var uniforms = _ref.uniforms;
      var _this$props = this.props,
          billboard = _this$props.billboard,
          sizeScale = _this$props.sizeScale,
          sizeUnits = _this$props.sizeUnits,
          sizeMinPixels = _this$props.sizeMinPixels,
          sizeMaxPixels = _this$props.sizeMaxPixels,
          getLineWidth = _this$props.getLineWidth;
      var padding = this.props.padding;

      if (padding.length < 4) {
        padding = [padding[0], padding[1], padding[0], padding[1]];
      }

      this.state.model.setUniforms(uniforms).setUniforms({
        billboard: billboard,
        stroked: Boolean(getLineWidth),
        padding: padding,
        sizeUnits: UNIT[sizeUnits],
        sizeScale: sizeScale,
        sizeMinPixels: sizeMinPixels,
        sizeMaxPixels: sizeMaxPixels
      }).draw();
    }
  }, {
    key: "_getModel",
    value: function _getModel(gl) {
      var positions = [0, 0, 1, 0, 1, 1, 0, 1];
      return new Model(gl, _objectSpread(_objectSpread({}, this.getShaders()), {}, {
        id: this.props.id,
        geometry: new Geometry({
          drawMode: 6,
          vertexCount: 4,
          attributes: {
            positions: {
              size: 2,
              value: new Float32Array(positions)
            }
          }
        }),
        isInstanced: true
      }));
    }
  }]);

  return TextBackgroundLayer;
}(Layer);

_defineProperty(TextBackgroundLayer, "defaultProps", defaultProps);

_defineProperty(TextBackgroundLayer, "layerName", 'TextBackgroundLayer');

export { TextBackgroundLayer as default };