import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _asyncToGenerator from "@babel/runtime/helpers/asyncToGenerator";
import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _assertThisInitialized from "@babel/runtime/helpers/assertThisInitialized";
import _get from "@babel/runtime/helpers/get";
import _inherits from "@babel/runtime/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _regeneratorRuntime from "@babel/runtime/regenerator";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

import { CompositeLayer, COORDINATE_SYSTEM, _deepEqual as deepEqual } from '@deck.gl/core';
import { BitmapLayer } from '@deck.gl/layers';
import { ImageSource, createImageSource } from '@loaders.gl/wms';
import { WGS84ToPseudoMercator } from './utils';
var defaultProps = {
  id: 'imagery-layer',
  data: '',
  serviceType: 'auto',
  srs: 'auto',
  layers: {
    type: 'array',
    compare: true,
    value: []
  },
  onMetadataLoad: {
    type: 'function',
    value: function value() {}
  },
  onMetadataLoadError: {
    type: 'function',
    value: console.error
  },
  onImageLoadStart: {
    type: 'function',
    value: function value() {}
  },
  onImageLoad: {
    type: 'function',
    value: function value() {}
  },
  onImageLoadError: {
    type: 'function',
    compare: false,
    value: function value(requestId, error) {
      return console.error(error, requestId);
    }
  }
};
export var WMSLayer = function (_CompositeLayer) {
  _inherits(WMSLayer, _CompositeLayer);

  var _super = _createSuper(WMSLayer);

  function WMSLayer() {
    var _this;

    _classCallCheck(this, WMSLayer);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));

    _defineProperty(_assertThisInitialized(_this), "state", void 0);

    return _this;
  }

  _createClass(WMSLayer, [{
    key: "isLoaded",
    get: function get() {
      var _this$state;

      return ((_this$state = this.state) === null || _this$state === void 0 ? void 0 : _this$state.loadCounter) === 0 && _get(_getPrototypeOf(WMSLayer.prototype), "isLoaded", this);
    }
  }, {
    key: "shouldUpdateState",
    value: function shouldUpdateState() {
      return true;
    }
  }, {
    key: "initializeState",
    value: function initializeState() {
      this.state._nextRequestId = 0;
      this.state.lastRequestId = -1;
      this.state.loadCounter = 0;
    }
  }, {
    key: "updateState",
    value: function updateState(_ref) {
      var _this2 = this;

      var changeFlags = _ref.changeFlags,
          props = _ref.props,
          oldProps = _ref.oldProps;
      var viewport = this.context.viewport;

      if (changeFlags.dataChanged || props.serviceType !== oldProps.serviceType) {
        this.state.imageSource = this._createImageSource(props);

        this._loadMetadata();

        this.debounce(function () {
          return _this2.loadImage(viewport, 'image source changed');
        }, 0);
      } else if (!deepEqual(props.layers, oldProps.layers, 1)) {
        this.debounce(function () {
          return _this2.loadImage(viewport, 'layers changed');
        }, 0);
      } else if (changeFlags.viewportChanged) {
        this.debounce(function () {
          return _this2.loadImage(viewport, 'viewport changed');
        });
      }
    }
  }, {
    key: "finalizeState",
    value: function finalizeState() {}
  }, {
    key: "renderLayers",
    value: function renderLayers() {
      var _this$state2 = this.state,
          bounds = _this$state2.bounds,
          image = _this$state2.image,
          lastRequestParameters = _this$state2.lastRequestParameters;
      return image && new BitmapLayer(_objectSpread(_objectSpread({}, this.getSubLayerProps({
        id: 'bitmap'
      })), {}, {
        _imageCoordinateSystem: lastRequestParameters.srs === 'EPSG:4326' ? COORDINATE_SYSTEM.LNGLAT : COORDINATE_SYSTEM.CARTESIAN,
        bounds: bounds,
        image: image
      }));
    }
  }, {
    key: "getFeatureInfoText",
    value: function () {
      var _getFeatureInfoText = _asyncToGenerator(_regeneratorRuntime.mark(function _callee(x, y) {
        var lastRequestParameters, _this$state$imageSour, _this$state$imageSour2, featureInfo;

        return _regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                lastRequestParameters = this.state.lastRequestParameters;

                if (!lastRequestParameters) {
                  _context.next = 6;
                  break;
                }

                _context.next = 4;
                return (_this$state$imageSour = (_this$state$imageSour2 = this.state.imageSource).getFeatureInfoText) === null || _this$state$imageSour === void 0 ? void 0 : _this$state$imageSour.call(_this$state$imageSour2, _objectSpread(_objectSpread({}, lastRequestParameters), {}, {
                  query_layers: lastRequestParameters.layers,
                  x: x,
                  y: y,
                  info_format: 'application/vnd.ogc.gml'
                }));

              case 4:
                featureInfo = _context.sent;
                return _context.abrupt("return", featureInfo);

              case 6:
                return _context.abrupt("return", '');

              case 7:
              case "end":
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function getFeatureInfoText(_x, _x2) {
        return _getFeatureInfoText.apply(this, arguments);
      }

      return getFeatureInfoText;
    }()
  }, {
    key: "_createImageSource",
    value: function _createImageSource(props) {
      if (props.data instanceof ImageSource) {
        return props.data;
      }

      if (typeof props.data === 'string') {
        return createImageSource({
          url: props.data,
          loadOptions: props.loadOptions,
          type: props.serviceType
        });
      }

      throw new Error('invalid image source in props.data');
    }
  }, {
    key: "_loadMetadata",
    value: function () {
      var _loadMetadata2 = _asyncToGenerator(_regeneratorRuntime.mark(function _callee2() {
        var imageSource, _metadata, _this$getCurrentLayer, _this$getCurrentLayer2;

        return _regeneratorRuntime.wrap(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                imageSource = this.state.imageSource;
                _context2.prev = 1;
                this.state.loadCounter++;
                _context2.next = 5;
                return imageSource.getMetadata();

              case 5:
                _metadata = _context2.sent;

                if (this.state.imageSource === imageSource) {
                  (_this$getCurrentLayer = this.getCurrentLayer()) === null || _this$getCurrentLayer === void 0 ? void 0 : _this$getCurrentLayer.props.onMetadataLoad(_metadata);
                }

                _context2.next = 12;
                break;

              case 9:
                _context2.prev = 9;
                _context2.t0 = _context2["catch"](1);
                (_this$getCurrentLayer2 = this.getCurrentLayer()) === null || _this$getCurrentLayer2 === void 0 ? void 0 : _this$getCurrentLayer2.props.onMetadataLoadError(_context2.t0);

              case 12:
                _context2.prev = 12;
                this.state.loadCounter--;
                return _context2.finish(12);

              case 15:
              case "end":
                return _context2.stop();
            }
          }
        }, _callee2, this, [[1, 9, 12, 15]]);
      }));

      function _loadMetadata() {
        return _loadMetadata2.apply(this, arguments);
      }

      return _loadMetadata;
    }()
  }, {
    key: "loadImage",
    value: function () {
      var _loadImage = _asyncToGenerator(_regeneratorRuntime.mark(function _callee3(viewport, reason) {
        var _this$props, layers, serviceType, bounds, width, height, requestId, srs, requestParams, _WGS84ToPseudoMercato, _WGS84ToPseudoMercato2, minX, minY, _WGS84ToPseudoMercato3, _WGS84ToPseudoMercato4, maxX, maxY, image, _this$getCurrentLayer3, _this$getCurrentLayer4;

        return _regeneratorRuntime.wrap(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                _this$props = this.props, layers = _this$props.layers, serviceType = _this$props.serviceType;

                if (!(serviceType === 'wms' && layers.length === 0)) {
                  _context3.next = 3;
                  break;
                }

                return _context3.abrupt("return");

              case 3:
                bounds = viewport.getBounds();
                width = viewport.width, height = viewport.height;
                requestId = this.getRequestId();
                srs = this.props.srs;

                if (srs === 'auto') {
                  srs = viewport.resolution ? 'EPSG:4326' : 'EPSG:3857';
                }

                requestParams = {
                  width: width,
                  height: height,
                  bbox: bounds,
                  layers: layers,
                  srs: srs
                };

                if (srs === 'EPSG:3857') {
                  _WGS84ToPseudoMercato = WGS84ToPseudoMercator([bounds[0], bounds[1]]), _WGS84ToPseudoMercato2 = _slicedToArray(_WGS84ToPseudoMercato, 2), minX = _WGS84ToPseudoMercato2[0], minY = _WGS84ToPseudoMercato2[1];
                  _WGS84ToPseudoMercato3 = WGS84ToPseudoMercator([bounds[2], bounds[3]]), _WGS84ToPseudoMercato4 = _slicedToArray(_WGS84ToPseudoMercato3, 2), maxX = _WGS84ToPseudoMercato4[0], maxY = _WGS84ToPseudoMercato4[1];
                  requestParams.bbox = [minX, minY, maxX, maxY];
                }

                _context3.prev = 10;
                this.state.loadCounter++;
                this.props.onImageLoadStart(requestId);
                _context3.next = 15;
                return this.state.imageSource.getImage(requestParams);

              case 15:
                image = _context3.sent;

                if (this.state.lastRequestId < requestId) {
                  (_this$getCurrentLayer3 = this.getCurrentLayer()) === null || _this$getCurrentLayer3 === void 0 ? void 0 : _this$getCurrentLayer3.props.onImageLoad(requestId);
                  this.setState({
                    image: image,
                    bounds: bounds,
                    lastRequestParameters: requestParams,
                    lastRequestId: requestId
                  });
                }

                _context3.next = 23;
                break;

              case 19:
                _context3.prev = 19;
                _context3.t0 = _context3["catch"](10);
                this.raiseError(_context3.t0, 'Load image');
                (_this$getCurrentLayer4 = this.getCurrentLayer()) === null || _this$getCurrentLayer4 === void 0 ? void 0 : _this$getCurrentLayer4.props.onImageLoadError(requestId, _context3.t0);

              case 23:
                _context3.prev = 23;
                this.state.loadCounter--;
                return _context3.finish(23);

              case 26:
              case "end":
                return _context3.stop();
            }
          }
        }, _callee3, this, [[10, 19, 23, 26]]);
      }));

      function loadImage(_x3, _x4) {
        return _loadImage.apply(this, arguments);
      }

      return loadImage;
    }()
  }, {
    key: "getRequestId",
    value: function getRequestId() {
      return this.state._nextRequestId++;
    }
  }, {
    key: "debounce",
    value: function debounce(fn) {
      var ms = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 500;
      clearTimeout(this.state._timeoutId);
      this.state._timeoutId = setTimeout(function () {
        return fn();
      }, ms);
    }
  }]);

  return WMSLayer;
}(CompositeLayer);

_defineProperty(WMSLayer, "layerName", 'WMSLayer');

_defineProperty(WMSLayer, "defaultProps", defaultProps);