import { log, COORDINATE_SYSTEM } from '@deck.gl/core';
var R_EARTH = 6378000;

function toFinite(n) {
  return Number.isFinite(n) ? n : 0;
}

export function getBoundingBox(attributes, vertexCount) {
  var positions = attributes.positions.value;
  var yMin = Infinity;
  var yMax = -Infinity;
  var xMin = Infinity;
  var xMax = -Infinity;
  var y;
  var x;

  for (var i = 0; i < vertexCount; i++) {
    x = positions[i * 3];
    y = positions[i * 3 + 1];
    yMin = y < yMin ? y : yMin;
    yMax = y > yMax ? y : yMax;
    xMin = x < xMin ? x : xMin;
    xMax = x > xMax ? x : xMax;
  }

  var boundingBox = {
    xMin: toFinite(xMin),
    xMax: toFinite(xMax),
    yMin: toFinite(yMin),
    yMax: toFinite(yMax)
  };
  return boundingBox;
}

function getTranslation(boundingBox, gridOffset, coordinateSystem, viewport) {
  var width = viewport.width,
      height = viewport.height;
  var worldOrigin = coordinateSystem === COORDINATE_SYSTEM.CARTESIAN ? [-width / 2, -height / 2] : [-180, -90];
  log.assert(coordinateSystem === COORDINATE_SYSTEM.CARTESIAN || coordinateSystem === COORDINATE_SYSTEM.LNGLAT || coordinateSystem === COORDINATE_SYSTEM.DEFAULT);
  var xMin = boundingBox.xMin,
      yMin = boundingBox.yMin;
  return [-1 * (alignToCell(xMin - worldOrigin[0], gridOffset.xOffset) + worldOrigin[0]), -1 * (alignToCell(yMin - worldOrigin[1], gridOffset.yOffset) + worldOrigin[1])];
}

export function alignToCell(inValue, cellSize) {
  var sign = inValue < 0 ? -1 : 1;
  var value = sign < 0 ? Math.abs(inValue) + cellSize : Math.abs(inValue);
  value = Math.floor(value / cellSize) * cellSize;
  return value * sign;
}
export function getGridOffset(boundingBox, cellSize) {
  var convertToMeters = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

  if (!convertToMeters) {
    return {
      xOffset: cellSize,
      yOffset: cellSize
    };
  }

  var yMin = boundingBox.yMin,
      yMax = boundingBox.yMax;
  var centerLat = (yMin + yMax) / 2;
  return calculateGridLatLonOffset(cellSize, centerLat);
}
export function getGridParams(boundingBox, cellSize, viewport, coordinateSystem) {
  var gridOffset = getGridOffset(boundingBox, cellSize, coordinateSystem !== COORDINATE_SYSTEM.CARTESIAN);
  var translation = getTranslation(boundingBox, gridOffset, coordinateSystem, viewport);
  var xMin = boundingBox.xMin,
      yMin = boundingBox.yMin,
      xMax = boundingBox.xMax,
      yMax = boundingBox.yMax;
  var width = xMax - xMin + gridOffset.xOffset;
  var height = yMax - yMin + gridOffset.yOffset;
  var numCol = Math.ceil(width / gridOffset.xOffset);
  var numRow = Math.ceil(height / gridOffset.yOffset);
  return {
    gridOffset: gridOffset,
    translation: translation,
    width: width,
    height: height,
    numCol: numCol,
    numRow: numRow
  };
}

function calculateGridLatLonOffset(cellSize, latitude) {
  var yOffset = calculateLatOffset(cellSize);
  var xOffset = calculateLonOffset(latitude, cellSize);
  return {
    yOffset: yOffset,
    xOffset: xOffset
  };
}

function calculateLatOffset(dy) {
  return dy / R_EARTH * (180 / Math.PI);
}

function calculateLonOffset(lat, dx) {
  return dx / R_EARTH * (180 / Math.PI) / Math.cos(lat * Math.PI / 180);
}