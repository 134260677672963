import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import debug from '../debug';
import DrawLayersPass from '../passes/draw-layers-pass';
import PickLayersPass from '../passes/pick-layers-pass';
import { Framebuffer } from '@luma.gl/core';
var TRACE_RENDER_LAYERS = 'deckRenderer.renderLayers';

var DeckRenderer = function () {
  function DeckRenderer(gl) {
    _classCallCheck(this, DeckRenderer);

    _defineProperty(this, "gl", void 0);

    _defineProperty(this, "layerFilter", void 0);

    _defineProperty(this, "drawPickingColors", void 0);

    _defineProperty(this, "drawLayersPass", void 0);

    _defineProperty(this, "pickLayersPass", void 0);

    _defineProperty(this, "renderCount", void 0);

    _defineProperty(this, "_needsRedraw", void 0);

    _defineProperty(this, "renderBuffers", void 0);

    _defineProperty(this, "lastPostProcessEffect", void 0);

    this.gl = gl;
    this.layerFilter = null;
    this.drawPickingColors = false;
    this.drawLayersPass = new DrawLayersPass(gl);
    this.pickLayersPass = new PickLayersPass(gl);
    this.renderCount = 0;
    this._needsRedraw = 'Initial render';
    this.renderBuffers = [];
    this.lastPostProcessEffect = null;
  }

  _createClass(DeckRenderer, [{
    key: "setProps",
    value: function setProps(props) {
      if (this.layerFilter !== props.layerFilter) {
        this.layerFilter = props.layerFilter;
        this._needsRedraw = 'layerFilter changed';
      }

      if (this.drawPickingColors !== props.drawPickingColors) {
        this.drawPickingColors = props.drawPickingColors;
        this._needsRedraw = 'drawPickingColors changed';
      }
    }
  }, {
    key: "renderLayers",
    value: function renderLayers(opts) {
      if (!opts.viewports.length) {
        return;
      }

      var layerPass = this.drawPickingColors ? this.pickLayersPass : this.drawLayersPass;

      var renderOpts = _objectSpread(_objectSpread({
        layerFilter: this.layerFilter,
        isPicking: this.drawPickingColors
      }, opts), {}, {
        target: opts.target || Framebuffer.getDefaultFramebuffer(this.gl)
      });

      if (renderOpts.effects) {
        this._preRender(renderOpts.effects, renderOpts);
      }

      var outputBuffer = this.lastPostProcessEffect ? this.renderBuffers[0] : renderOpts.target;
      var renderStats = layerPass.render(_objectSpread(_objectSpread({}, renderOpts), {}, {
        target: outputBuffer
      }));

      if (renderOpts.effects) {
        this._postRender(renderOpts.effects, renderOpts);
      }

      this.renderCount++;
      debug(TRACE_RENDER_LAYERS, this, renderStats, opts);
    }
  }, {
    key: "needsRedraw",
    value: function needsRedraw() {
      var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
        clearRedrawFlags: false
      };
      var redraw = this._needsRedraw;

      if (opts.clearRedrawFlags) {
        this._needsRedraw = false;
      }

      return redraw;
    }
  }, {
    key: "finalize",
    value: function finalize() {
      var renderBuffers = this.renderBuffers;

      var _iterator = _createForOfIteratorHelper(renderBuffers),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var buffer = _step.value;
          buffer.delete();
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      renderBuffers.length = 0;
    }
  }, {
    key: "_preRender",
    value: function _preRender(effects, opts) {
      this.lastPostProcessEffect = null;
      opts.preRenderStats = opts.preRenderStats || {};

      var _iterator2 = _createForOfIteratorHelper(effects),
          _step2;

      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var effect = _step2.value;
          opts.preRenderStats[effect.id] = effect.preRender(this.gl, opts);

          if (effect.postRender) {
            this.lastPostProcessEffect = effect.id;
          }
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }

      if (this.lastPostProcessEffect) {
        this._resizeRenderBuffers();
      }
    }
  }, {
    key: "_resizeRenderBuffers",
    value: function _resizeRenderBuffers() {
      var renderBuffers = this.renderBuffers;

      if (renderBuffers.length === 0) {
        renderBuffers.push(new Framebuffer(this.gl), new Framebuffer(this.gl));
      }

      var _iterator3 = _createForOfIteratorHelper(renderBuffers),
          _step3;

      try {
        for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
          var buffer = _step3.value;
          buffer.resize();
        }
      } catch (err) {
        _iterator3.e(err);
      } finally {
        _iterator3.f();
      }
    }
  }, {
    key: "_postRender",
    value: function _postRender(effects, opts) {
      var renderBuffers = this.renderBuffers;

      var params = _objectSpread(_objectSpread({}, opts), {}, {
        inputBuffer: renderBuffers[0],
        swapBuffer: renderBuffers[1],
        target: null
      });

      var _iterator4 = _createForOfIteratorHelper(effects),
          _step4;

      try {
        for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
          var effect = _step4.value;

          if (effect.postRender) {
            if (effect.id === this.lastPostProcessEffect) {
              params.target = opts.target;
              effect.postRender(this.gl, params);
              break;
            }

            var buffer = effect.postRender(this.gl, params);
            params.inputBuffer = buffer;
            params.swapBuffer = buffer === renderBuffers[0] ? renderBuffers[1] : renderBuffers[0];
          }
        }
      } catch (err) {
        _iterator4.e(err);
      } finally {
        _iterator4.f();
      }
    }
  }]);

  return DeckRenderer;
}();

export { DeckRenderer as default };