import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _assertThisInitialized from "@babel/runtime/helpers/assertThisInitialized";
import _get from "@babel/runtime/helpers/get";
import _inherits from "@babel/runtime/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

import Mapbox from 'react-map-gl/dist/esm/mapbox/mapbox';
import Deck from '../lib/deck';
var CANVAS_STYLE = {
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%'
};

function createCanvas(props) {
  var _props$container = props.container,
      container = _props$container === void 0 ? document.body : _props$container;

  if (typeof container === 'string') {
    container = document.getElementById(container);
  }

  if (!container) {
    throw Error('Deck: container not found');
  }

  var containerStyle = window.getComputedStyle(container);

  if (containerStyle.position === 'static') {
    container.style.position = 'relative';
  }

  var mapCanvas = document.createElement('div');
  container.appendChild(mapCanvas);
  Object.assign(mapCanvas.style, CANVAS_STYLE);
  var deckCanvas = document.createElement('canvas');
  container.appendChild(deckCanvas);
  Object.assign(deckCanvas.style, CANVAS_STYLE);
  return {
    container: container,
    mapCanvas: mapCanvas,
    deckCanvas: deckCanvas
  };
}

var DeckGL = function (_Deck) {
  _inherits(DeckGL, _Deck);

  var _super = _createSuper(DeckGL);

  function DeckGL(props) {
    var _this;

    _classCallCheck(this, DeckGL);

    if (typeof document === 'undefined') {
      throw Error('Deck can only be used in the browser');
    }

    var _createCanvas = createCanvas(props),
        mapCanvas = _createCanvas.mapCanvas,
        deckCanvas = _createCanvas.deckCanvas;

    var viewState = props.viewState || props.initialViewState;
    var isMap = Number.isFinite(viewState && viewState.latitude);
    var _props$map = props.map,
        map = _props$map === void 0 ? globalThis.mapboxgl || globalThis.maplibregl : _props$map;
    _this = _super.call(this, _objectSpread({
      canvas: deckCanvas
    }, props));

    _defineProperty(_assertThisInitialized(_this), "_map", void 0);

    if (map && map.Map) {
      _this._map = isMap && new Mapbox(_objectSpread(_objectSpread({}, props), {}, {
        viewState: viewState,
        container: mapCanvas,
        mapboxgl: map
      }));
    } else {
      _this._map = map;
    }

    return _this;
  }

  _createClass(DeckGL, [{
    key: "getMapboxMap",
    value: function getMapboxMap() {
      return this._map && this._map.getMap();
    }
  }, {
    key: "finalize",
    value: function finalize() {
      if (this._map) {
        this._map.finalize();
      }

      _get(_getPrototypeOf(DeckGL.prototype), "finalize", this).call(this);
    }
  }, {
    key: "setProps",
    value: function setProps(props) {
      if ('mapStyle' in props && this._map) {
        this._map._map.setStyle(props.mapStyle);
      }

      _get(_getPrototypeOf(DeckGL.prototype), "setProps", this).call(this, props);
    }
  }, {
    key: "_drawLayers",
    value: function _drawLayers(redrawReason, options) {
      if (this._map) {
        var viewport = this.getViewports()[0];

        if (viewport) {
          this._map.setProps({
            width: viewport.width,
            height: viewport.height,
            viewState: viewport
          });
        }
      }

      _get(_getPrototypeOf(DeckGL.prototype), "_drawLayers", this).call(this, redrawReason, options);
    }
  }]);

  return DeckGL;
}(Deck);

export { DeckGL as default };