import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { COORDINATE_SYSTEM } from '../../lib/constants';
import { getOffsetOrigin } from './viewport-uniforms';
import WebMercatorViewport from '../../viewports/web-mercator-viewport';
import * as vec4 from 'gl-matrix/vec4';
import * as vec3 from 'gl-matrix/vec3';
import { addMetersToLngLat } from '@math.gl/web-mercator';
var DEFAULT_COORDINATE_ORIGIN = [0, 0, 0];

function lngLatZToWorldPosition(lngLatZ, viewport) {
  var offsetMode = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var p = viewport.projectPosition(lngLatZ);

  if (offsetMode && viewport instanceof WebMercatorViewport) {
    var _lngLatZ = _slicedToArray(lngLatZ, 3),
        longitude = _lngLatZ[0],
        latitude = _lngLatZ[1],
        _lngLatZ$ = _lngLatZ[2],
        z = _lngLatZ$ === void 0 ? 0 : _lngLatZ$;

    var distanceScales = viewport.getDistanceScales([longitude, latitude]);
    p[2] = z * distanceScales.unitsPerMeter[2];
  }

  return p;
}

function normalizeParameters(opts) {
  var viewport = opts.viewport,
      modelMatrix = opts.modelMatrix,
      coordinateOrigin = opts.coordinateOrigin;
  var coordinateSystem = opts.coordinateSystem,
      fromCoordinateSystem = opts.fromCoordinateSystem,
      fromCoordinateOrigin = opts.fromCoordinateOrigin;

  if (coordinateSystem === COORDINATE_SYSTEM.DEFAULT) {
    coordinateSystem = viewport.isGeospatial ? COORDINATE_SYSTEM.LNGLAT : COORDINATE_SYSTEM.CARTESIAN;
  }

  if (fromCoordinateSystem === undefined) {
    fromCoordinateSystem = coordinateSystem;
  }

  if (fromCoordinateOrigin === undefined) {
    fromCoordinateOrigin = coordinateOrigin;
  }

  return {
    viewport: viewport,
    coordinateSystem: coordinateSystem,
    coordinateOrigin: coordinateOrigin,
    modelMatrix: modelMatrix,
    fromCoordinateSystem: fromCoordinateSystem,
    fromCoordinateOrigin: fromCoordinateOrigin
  };
}

export function getWorldPosition(position, _ref) {
  var viewport = _ref.viewport,
      modelMatrix = _ref.modelMatrix,
      coordinateSystem = _ref.coordinateSystem,
      coordinateOrigin = _ref.coordinateOrigin,
      offsetMode = _ref.offsetMode;

  var _position = _slicedToArray(position, 3),
      x = _position[0],
      y = _position[1],
      _position$ = _position[2],
      z = _position$ === void 0 ? 0 : _position$;

  if (modelMatrix) {
    var _vec4$transformMat = vec4.transformMat4([], [x, y, z, 1.0], modelMatrix);

    var _vec4$transformMat2 = _slicedToArray(_vec4$transformMat, 3);

    x = _vec4$transformMat2[0];
    y = _vec4$transformMat2[1];
    z = _vec4$transformMat2[2];
  }

  switch (coordinateSystem) {
    case COORDINATE_SYSTEM.LNGLAT:
      return lngLatZToWorldPosition([x, y, z], viewport, offsetMode);

    case COORDINATE_SYSTEM.LNGLAT_OFFSETS:
      return lngLatZToWorldPosition([x + coordinateOrigin[0], y + coordinateOrigin[1], z + (coordinateOrigin[2] || 0)], viewport, offsetMode);

    case COORDINATE_SYSTEM.METER_OFFSETS:
      return lngLatZToWorldPosition(addMetersToLngLat(coordinateOrigin, [x, y, z]), viewport, offsetMode);

    case COORDINATE_SYSTEM.CARTESIAN:
    default:
      return viewport.isGeospatial ? [x + coordinateOrigin[0], y + coordinateOrigin[1], z + coordinateOrigin[2]] : viewport.projectPosition([x, y, z]);
  }
}
export function projectPosition(position, params) {
  var _normalizeParameters = normalizeParameters(params),
      viewport = _normalizeParameters.viewport,
      coordinateSystem = _normalizeParameters.coordinateSystem,
      coordinateOrigin = _normalizeParameters.coordinateOrigin,
      modelMatrix = _normalizeParameters.modelMatrix,
      fromCoordinateSystem = _normalizeParameters.fromCoordinateSystem,
      fromCoordinateOrigin = _normalizeParameters.fromCoordinateOrigin;

  var _params$autoOffset = params.autoOffset,
      autoOffset = _params$autoOffset === void 0 ? true : _params$autoOffset;

  var _ref2 = autoOffset ? getOffsetOrigin(viewport, coordinateSystem, coordinateOrigin) : {},
      _ref2$geospatialOrigi = _ref2.geospatialOrigin,
      geospatialOrigin = _ref2$geospatialOrigi === void 0 ? DEFAULT_COORDINATE_ORIGIN : _ref2$geospatialOrigi,
      _ref2$shaderCoordinat = _ref2.shaderCoordinateOrigin,
      shaderCoordinateOrigin = _ref2$shaderCoordinat === void 0 ? DEFAULT_COORDINATE_ORIGIN : _ref2$shaderCoordinat,
      _ref2$offsetMode = _ref2.offsetMode,
      offsetMode = _ref2$offsetMode === void 0 ? false : _ref2$offsetMode;

  var worldPosition = getWorldPosition(position, {
    viewport: viewport,
    modelMatrix: modelMatrix,
    coordinateSystem: fromCoordinateSystem,
    coordinateOrigin: fromCoordinateOrigin,
    offsetMode: offsetMode
  });

  if (offsetMode) {
    var positionCommonSpace = viewport.projectPosition(geospatialOrigin || shaderCoordinateOrigin);
    vec3.sub(worldPosition, worldPosition, positionCommonSpace);
  }

  return worldPosition;
}