import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import { BOX_STYLE } from './component-css';

var DescriptionCard = function () {
  function DescriptionCard(_ref) {
    var container = _ref.container,
        props = _ref.props;

    _classCallCheck(this, DescriptionCard);

    var description = props.description;
    var div = document.createElement('div');
    div.className = 'deck-json-description-box';
    Object.assign(div.style, BOX_STYLE);
    this.el = div;
    var textContainer = document.createElement('div');
    textContainer.innerHTML = description;
    div.appendChild(textContainer);
    container.append(div);
  }

  _createClass(DescriptionCard, [{
    key: "remove",
    value: function remove() {
      this.el.remove();
      this.description = null;
    }
  }], [{
    key: "stringName",
    get: function get() {
      return 'description';
    }
  }]);

  return DescriptionCard;
}();

export default DescriptionCard;