import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { getOSMTileIndices } from './tile-2d-traversal';
var TILE_SIZE = 512;
var DEFAULT_EXTENT = [-Infinity, -Infinity, Infinity, Infinity];
export var urlType = {
  type: 'object',
  value: null,
  validate: function validate(value, propType) {
    return propType.optional && value === null || typeof value === 'string' || Array.isArray(value) && value.every(function (url) {
      return typeof url === 'string';
    });
  },
  equal: function equal(value1, value2) {
    if (value1 === value2) {
      return true;
    }

    if (!Array.isArray(value1) || !Array.isArray(value2)) {
      return false;
    }

    var len = value1.length;

    if (len !== value2.length) {
      return false;
    }

    for (var i = 0; i < len; i++) {
      if (value1[i] !== value2[i]) {
        return false;
      }
    }

    return true;
  }
};

function transformBox(bbox, modelMatrix) {
  var transformedCoords = [modelMatrix.transformAsPoint([bbox[0], bbox[1]]), modelMatrix.transformAsPoint([bbox[2], bbox[1]]), modelMatrix.transformAsPoint([bbox[0], bbox[3]]), modelMatrix.transformAsPoint([bbox[2], bbox[3]])];
  var transformedBox = [Math.min.apply(Math, _toConsumableArray(transformedCoords.map(function (i) {
    return i[0];
  }))), Math.min.apply(Math, _toConsumableArray(transformedCoords.map(function (i) {
    return i[1];
  }))), Math.max.apply(Math, _toConsumableArray(transformedCoords.map(function (i) {
    return i[0];
  }))), Math.max.apply(Math, _toConsumableArray(transformedCoords.map(function (i) {
    return i[1];
  })))];
  return transformedBox;
}

function stringHash(s) {
  return Math.abs(s.split('').reduce(function (a, b) {
    return (a << 5) - a + b.charCodeAt(0) | 0;
  }, 0));
}

export function getURLFromTemplate(template, tile) {
  if (!template || !template.length) {
    return null;
  }

  var index = tile.index,
      id = tile.id;

  if (Array.isArray(template)) {
    var i = stringHash(id) % template.length;
    template = template[i];
  }

  var url = template;

  for (var _i = 0, _Object$keys = Object.keys(index); _i < _Object$keys.length; _i++) {
    var key = _Object$keys[_i];
    var regex = new RegExp("{".concat(key, "}"), 'g');
    url = url.replace(regex, String(index[key]));
  }

  if (Number.isInteger(index.y) && Number.isInteger(index.z)) {
    url = url.replace(/\{-y\}/g, String(Math.pow(2, index.z) - index.y - 1));
  }

  return url;
}

function getBoundingBox(viewport, zRange, extent) {
  var bounds;

  if (zRange && zRange.length === 2) {
    var _zRange = _slicedToArray(zRange, 2),
        minZ = _zRange[0],
        maxZ = _zRange[1];

    var bounds0 = viewport.getBounds({
      z: minZ
    });
    var bounds1 = viewport.getBounds({
      z: maxZ
    });
    bounds = [Math.min(bounds0[0], bounds1[0]), Math.min(bounds0[1], bounds1[1]), Math.max(bounds0[2], bounds1[2]), Math.max(bounds0[3], bounds1[3])];
  } else {
    bounds = viewport.getBounds();
  }

  if (!viewport.isGeospatial) {
    return [Math.max(Math.min(bounds[0], extent[2]), extent[0]), Math.max(Math.min(bounds[1], extent[3]), extent[1]), Math.min(Math.max(bounds[2], extent[0]), extent[2]), Math.min(Math.max(bounds[3], extent[1]), extent[3])];
  }

  return [Math.max(bounds[0], extent[0]), Math.max(bounds[1], extent[1]), Math.min(bounds[2], extent[2]), Math.min(bounds[3], extent[3])];
}

export function getCullBounds(_ref) {
  var viewport = _ref.viewport,
      _ref$z = _ref.z,
      z = _ref$z === void 0 ? 0 : _ref$z,
      cullRect = _ref.cullRect;
  var subViewports = viewport.subViewports || [viewport];
  return subViewports.map(function (v) {
    return getCullBoundsInViewport(v, z || 0, cullRect);
  });
}

function getCullBoundsInViewport(viewport, z, cullRect) {
  if (!Array.isArray(z)) {
    var x = cullRect.x - viewport.x;
    var y = cullRect.y - viewport.y;
    var width = cullRect.width,
        height = cullRect.height;
    var unprojectOption = {
      targetZ: z
    };
    var topLeft = viewport.unproject([x, y], unprojectOption);
    var topRight = viewport.unproject([x + width, y], unprojectOption);
    var bottomLeft = viewport.unproject([x, y + height], unprojectOption);
    var bottomRight = viewport.unproject([x + width, y + height], unprojectOption);
    return [Math.min(topLeft[0], topRight[0], bottomLeft[0], bottomRight[0]), Math.min(topLeft[1], topRight[1], bottomLeft[1], bottomRight[1]), Math.max(topLeft[0], topRight[0], bottomLeft[0], bottomRight[0]), Math.max(topLeft[1], topRight[1], bottomLeft[1], bottomRight[1])];
  }

  var bounds0 = getCullBoundsInViewport(viewport, z[0], cullRect);
  var bounds1 = getCullBoundsInViewport(viewport, z[1], cullRect);
  return [Math.min(bounds0[0], bounds1[0]), Math.min(bounds0[1], bounds1[1]), Math.max(bounds0[2], bounds1[2]), Math.max(bounds0[3], bounds1[3])];
}

function getIndexingCoords(bbox, scale, modelMatrixInverse) {
  if (modelMatrixInverse) {
    var transformedTileIndex = transformBox(bbox, modelMatrixInverse).map(function (i) {
      return i * scale / TILE_SIZE;
    });
    return transformedTileIndex;
  }

  return bbox.map(function (i) {
    return i * scale / TILE_SIZE;
  });
}

function getScale(z, tileSize) {
  return Math.pow(2, z) * TILE_SIZE / tileSize;
}

export function osmTile2lngLat(x, y, z) {
  var scale = getScale(z, TILE_SIZE);
  var lng = x / scale * 360 - 180;
  var n = Math.PI - 2 * Math.PI * y / scale;
  var lat = 180 / Math.PI * Math.atan(0.5 * (Math.exp(n) - Math.exp(-n)));
  return [lng, lat];
}

function tile2XY(x, y, z, tileSize) {
  var scale = getScale(z, tileSize);
  return [x / scale * TILE_SIZE, y / scale * TILE_SIZE];
}

export function tileToBoundingBox(viewport, x, y, z) {
  var tileSize = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : TILE_SIZE;

  if (viewport.isGeospatial) {
    var _osmTile2lngLat = osmTile2lngLat(x, y, z),
        _osmTile2lngLat2 = _slicedToArray(_osmTile2lngLat, 2),
        west = _osmTile2lngLat2[0],
        north = _osmTile2lngLat2[1];

    var _osmTile2lngLat3 = osmTile2lngLat(x + 1, y + 1, z),
        _osmTile2lngLat4 = _slicedToArray(_osmTile2lngLat3, 2),
        east = _osmTile2lngLat4[0],
        south = _osmTile2lngLat4[1];

    return {
      west: west,
      north: north,
      east: east,
      south: south
    };
  }

  var _tile2XY = tile2XY(x, y, z, tileSize),
      _tile2XY2 = _slicedToArray(_tile2XY, 2),
      left = _tile2XY2[0],
      top = _tile2XY2[1];

  var _tile2XY3 = tile2XY(x + 1, y + 1, z, tileSize),
      _tile2XY4 = _slicedToArray(_tile2XY3, 2),
      right = _tile2XY4[0],
      bottom = _tile2XY4[1];

  return {
    left: left,
    top: top,
    right: right,
    bottom: bottom
  };
}

function getIdentityTileIndices(viewport, z, tileSize, extent, modelMatrixInverse) {
  var bbox = getBoundingBox(viewport, null, extent);
  var scale = getScale(z, tileSize);

  var _getIndexingCoords = getIndexingCoords(bbox, scale, modelMatrixInverse),
      _getIndexingCoords2 = _slicedToArray(_getIndexingCoords, 4),
      minX = _getIndexingCoords2[0],
      minY = _getIndexingCoords2[1],
      maxX = _getIndexingCoords2[2],
      maxY = _getIndexingCoords2[3];

  var indices = [];

  for (var x = Math.floor(minX); x < maxX; x++) {
    for (var y = Math.floor(minY); y < maxY; y++) {
      indices.push({
        x: x,
        y: y,
        z: z
      });
    }
  }

  return indices;
}

export function getTileIndices(_ref2) {
  var viewport = _ref2.viewport,
      maxZoom = _ref2.maxZoom,
      minZoom = _ref2.minZoom,
      zRange = _ref2.zRange,
      extent = _ref2.extent,
      _ref2$tileSize = _ref2.tileSize,
      tileSize = _ref2$tileSize === void 0 ? TILE_SIZE : _ref2$tileSize,
      modelMatrix = _ref2.modelMatrix,
      modelMatrixInverse = _ref2.modelMatrixInverse,
      _ref2$zoomOffset = _ref2.zoomOffset,
      zoomOffset = _ref2$zoomOffset === void 0 ? 0 : _ref2$zoomOffset;
  var z = viewport.isGeospatial ? Math.round(viewport.zoom + Math.log2(TILE_SIZE / tileSize)) + zoomOffset : Math.ceil(viewport.zoom) + zoomOffset;

  if (typeof minZoom === 'number' && Number.isFinite(minZoom) && z < minZoom) {
    if (!extent) {
      return [];
    }

    z = minZoom;
  }

  if (typeof maxZoom === 'number' && Number.isFinite(maxZoom) && z > maxZoom) {
    z = maxZoom;
  }

  var transformedExtent = extent;

  if (modelMatrix && modelMatrixInverse && extent && !viewport.isGeospatial) {
    transformedExtent = transformBox(extent, modelMatrix);
  }

  return viewport.isGeospatial ? getOSMTileIndices(viewport, z, zRange, extent) : getIdentityTileIndices(viewport, z, tileSize, transformedExtent || DEFAULT_EXTENT, modelMatrixInverse);
}
export function isURLTemplate(s) {
  return /(?=.*{z})(?=.*{x})(?=.*({y}|{-y}))/.test(s);
}
export function isGeoBoundingBox(v) {
  return Number.isFinite(v.west) && Number.isFinite(v.north) && Number.isFinite(v.east) && Number.isFinite(v.south);
}