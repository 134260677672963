import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import log from '../utils/log';
import { createMat4, getCameraPosition, getFrustumPlanes as _getFrustumPlanes } from '../utils/math-utils';
import { Matrix4, Vector3, equals as _equals, clamp } from '@math.gl/core';
import * as mat4 from 'gl-matrix/mat4';
import { getDistanceScales as _getDistanceScales, getMeterZoom, lngLatToWorld, worldToLngLat, worldToPixels, pixelsToWorld } from '@math.gl/web-mercator';
import { PROJECTION_MODE } from '../lib/constants';
var DEGREES_TO_RADIANS = Math.PI / 180;
var IDENTITY = createMat4();
var ZERO_VECTOR = [0, 0, 0];
var DEFAULT_DISTANCE_SCALES = {
  unitsPerMeter: [1, 1, 1],
  metersPerUnit: [1, 1, 1]
};

function createProjectionMatrix(_ref) {
  var width = _ref.width,
      height = _ref.height,
      orthographic = _ref.orthographic,
      fovyRadians = _ref.fovyRadians,
      focalDistance = _ref.focalDistance,
      padding = _ref.padding,
      near = _ref.near,
      far = _ref.far;
  var aspect = width / height;
  var matrix = orthographic ? new Matrix4().orthographic({
    fovy: fovyRadians,
    aspect: aspect,
    focalDistance: focalDistance,
    near: near,
    far: far
  }) : new Matrix4().perspective({
    fovy: fovyRadians,
    aspect: aspect,
    near: near,
    far: far
  });

  if (padding) {
    var _padding$left = padding.left,
        left = _padding$left === void 0 ? 0 : _padding$left,
        _padding$right = padding.right,
        right = _padding$right === void 0 ? 0 : _padding$right,
        _padding$top = padding.top,
        top = _padding$top === void 0 ? 0 : _padding$top,
        _padding$bottom = padding.bottom,
        bottom = _padding$bottom === void 0 ? 0 : _padding$bottom;
    var offsetX = clamp((left + width - right) / 2, 0, width) - width / 2;
    var offsetY = clamp((top + height - bottom) / 2, 0, height) - height / 2;
    matrix[8] -= offsetX * 2 / width;
    matrix[9] += offsetY * 2 / height;
  }

  return matrix;
}

var Viewport = function () {
  function Viewport() {
    var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    _classCallCheck(this, Viewport);

    _defineProperty(this, "id", void 0);

    _defineProperty(this, "x", void 0);

    _defineProperty(this, "y", void 0);

    _defineProperty(this, "width", void 0);

    _defineProperty(this, "height", void 0);

    _defineProperty(this, "padding", void 0);

    _defineProperty(this, "isGeospatial", void 0);

    _defineProperty(this, "zoom", void 0);

    _defineProperty(this, "focalDistance", void 0);

    _defineProperty(this, "position", void 0);

    _defineProperty(this, "modelMatrix", void 0);

    _defineProperty(this, "distanceScales", void 0);

    _defineProperty(this, "scale", void 0);

    _defineProperty(this, "center", void 0);

    _defineProperty(this, "cameraPosition", void 0);

    _defineProperty(this, "projectionMatrix", void 0);

    _defineProperty(this, "viewMatrix", void 0);

    _defineProperty(this, "viewMatrixUncentered", void 0);

    _defineProperty(this, "viewMatrixInverse", void 0);

    _defineProperty(this, "viewProjectionMatrix", void 0);

    _defineProperty(this, "pixelProjectionMatrix", void 0);

    _defineProperty(this, "pixelUnprojectionMatrix", void 0);

    _defineProperty(this, "resolution", void 0);

    _defineProperty(this, "_frustumPlanes", {});

    this.id = opts.id || this.constructor.displayName || 'viewport';
    this.x = opts.x || 0;
    this.y = opts.y || 0;
    this.width = opts.width || 1;
    this.height = opts.height || 1;
    this.zoom = opts.zoom || 0;
    this.padding = opts.padding;
    this.distanceScales = opts.distanceScales || DEFAULT_DISTANCE_SCALES;
    this.focalDistance = opts.focalDistance || 1;
    this.position = opts.position || ZERO_VECTOR;
    this.modelMatrix = opts.modelMatrix || null;
    var longitude = opts.longitude,
        latitude = opts.latitude;
    this.isGeospatial = Number.isFinite(latitude) && Number.isFinite(longitude);

    this._initProps(opts);

    this._initMatrices(opts);

    this.equals = this.equals.bind(this);
    this.project = this.project.bind(this);
    this.unproject = this.unproject.bind(this);
    this.projectPosition = this.projectPosition.bind(this);
    this.unprojectPosition = this.unprojectPosition.bind(this);
    this.projectFlat = this.projectFlat.bind(this);
    this.unprojectFlat = this.unprojectFlat.bind(this);
  }

  _createClass(Viewport, [{
    key: "subViewports",
    get: function get() {
      return null;
    }
  }, {
    key: "metersPerPixel",
    get: function get() {
      return this.distanceScales.metersPerUnit[2] / this.scale;
    }
  }, {
    key: "projectionMode",
    get: function get() {
      if (this.isGeospatial) {
        return this.zoom < 12 ? PROJECTION_MODE.WEB_MERCATOR : PROJECTION_MODE.WEB_MERCATOR_AUTO_OFFSET;
      }

      return PROJECTION_MODE.IDENTITY;
    }
  }, {
    key: "equals",
    value: function equals(viewport) {
      if (!(viewport instanceof Viewport)) {
        return false;
      }

      if (this === viewport) {
        return true;
      }

      return viewport.width === this.width && viewport.height === this.height && viewport.scale === this.scale && _equals(viewport.projectionMatrix, this.projectionMatrix) && _equals(viewport.viewMatrix, this.viewMatrix);
    }
  }, {
    key: "project",
    value: function project(xyz) {
      var _ref2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
          _ref2$topLeft = _ref2.topLeft,
          topLeft = _ref2$topLeft === void 0 ? true : _ref2$topLeft;

      var worldPosition = this.projectPosition(xyz);
      var coord = worldToPixels(worldPosition, this.pixelProjectionMatrix);

      var _coord = _slicedToArray(coord, 2),
          x = _coord[0],
          y = _coord[1];

      var y2 = topLeft ? y : this.height - y;
      return xyz.length === 2 ? [x, y2] : [x, y2, coord[2]];
    }
  }, {
    key: "unproject",
    value: function unproject(xyz) {
      var _ref3 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
          _ref3$topLeft = _ref3.topLeft,
          topLeft = _ref3$topLeft === void 0 ? true : _ref3$topLeft,
          targetZ = _ref3.targetZ;

      var _xyz = _slicedToArray(xyz, 3),
          x = _xyz[0],
          y = _xyz[1],
          z = _xyz[2];

      var y2 = topLeft ? y : this.height - y;
      var targetZWorld = targetZ && targetZ * this.distanceScales.unitsPerMeter[2];
      var coord = pixelsToWorld([x, y2, z], this.pixelUnprojectionMatrix, targetZWorld);

      var _this$unprojectPositi = this.unprojectPosition(coord),
          _this$unprojectPositi2 = _slicedToArray(_this$unprojectPositi, 3),
          X = _this$unprojectPositi2[0],
          Y = _this$unprojectPositi2[1],
          Z = _this$unprojectPositi2[2];

      if (Number.isFinite(z)) {
        return [X, Y, Z];
      }

      return Number.isFinite(targetZ) ? [X, Y, targetZ] : [X, Y];
    }
  }, {
    key: "projectPosition",
    value: function projectPosition(xyz) {
      var _this$projectFlat = this.projectFlat(xyz),
          _this$projectFlat2 = _slicedToArray(_this$projectFlat, 2),
          X = _this$projectFlat2[0],
          Y = _this$projectFlat2[1];

      var Z = (xyz[2] || 0) * this.distanceScales.unitsPerMeter[2];
      return [X, Y, Z];
    }
  }, {
    key: "unprojectPosition",
    value: function unprojectPosition(xyz) {
      var _this$unprojectFlat = this.unprojectFlat(xyz),
          _this$unprojectFlat2 = _slicedToArray(_this$unprojectFlat, 2),
          X = _this$unprojectFlat2[0],
          Y = _this$unprojectFlat2[1];

      var Z = (xyz[2] || 0) * this.distanceScales.metersPerUnit[2];
      return [X, Y, Z];
    }
  }, {
    key: "projectFlat",
    value: function projectFlat(xyz) {
      if (this.isGeospatial) {
        var result = lngLatToWorld(xyz);
        result[1] = clamp(result[1], -318, 830);
        return result;
      }

      return xyz;
    }
  }, {
    key: "unprojectFlat",
    value: function unprojectFlat(xyz) {
      if (this.isGeospatial) {
        return worldToLngLat(xyz);
      }

      return xyz;
    }
  }, {
    key: "getBounds",
    value: function getBounds() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var unprojectOption = {
        targetZ: options.z || 0
      };
      var topLeft = this.unproject([0, 0], unprojectOption);
      var topRight = this.unproject([this.width, 0], unprojectOption);
      var bottomLeft = this.unproject([0, this.height], unprojectOption);
      var bottomRight = this.unproject([this.width, this.height], unprojectOption);
      return [Math.min(topLeft[0], topRight[0], bottomLeft[0], bottomRight[0]), Math.min(topLeft[1], topRight[1], bottomLeft[1], bottomRight[1]), Math.max(topLeft[0], topRight[0], bottomLeft[0], bottomRight[0]), Math.max(topLeft[1], topRight[1], bottomLeft[1], bottomRight[1])];
    }
  }, {
    key: "getDistanceScales",
    value: function getDistanceScales(coordinateOrigin) {
      if (coordinateOrigin) {
        return _getDistanceScales({
          longitude: coordinateOrigin[0],
          latitude: coordinateOrigin[1],
          highPrecision: true
        });
      }

      return this.distanceScales;
    }
  }, {
    key: "containsPixel",
    value: function containsPixel(_ref4) {
      var x = _ref4.x,
          y = _ref4.y,
          _ref4$width = _ref4.width,
          width = _ref4$width === void 0 ? 1 : _ref4$width,
          _ref4$height = _ref4.height,
          height = _ref4$height === void 0 ? 1 : _ref4$height;
      return x < this.x + this.width && this.x < x + width && y < this.y + this.height && this.y < y + height;
    }
  }, {
    key: "getFrustumPlanes",
    value: function getFrustumPlanes() {
      if (this._frustumPlanes.near) {
        return this._frustumPlanes;
      }

      Object.assign(this._frustumPlanes, _getFrustumPlanes(this.viewProjectionMatrix));
      return this._frustumPlanes;
    }
  }, {
    key: "panByPosition",
    value: function panByPosition(coords, pixel) {
      return null;
    }
  }, {
    key: "_initProps",
    value: function _initProps(opts) {
      var longitude = opts.longitude;
      var latitude = opts.latitude;

      if (this.isGeospatial) {
        if (!Number.isFinite(opts.zoom)) {
          this.zoom = getMeterZoom({
            latitude: latitude
          }) + Math.log2(this.focalDistance);
        }

        this.distanceScales = opts.distanceScales || _getDistanceScales({
          latitude: latitude,
          longitude: longitude
        });
      }

      var scale = Math.pow(2, this.zoom);
      this.scale = scale;
      var position = opts.position,
          modelMatrix = opts.modelMatrix;
      var meterOffset = ZERO_VECTOR;

      if (position) {
        meterOffset = modelMatrix ? new Matrix4(modelMatrix).transformAsVector(position, []) : position;
      }

      if (this.isGeospatial) {
        var center = this.projectPosition([longitude, latitude, 0]);
        this.center = new Vector3(meterOffset).scale(this.distanceScales.unitsPerMeter).add(center);
      } else {
        this.center = this.projectPosition(meterOffset);
      }
    }
  }, {
    key: "_initMatrices",
    value: function _initMatrices(opts) {
      var _opts$viewMatrix = opts.viewMatrix,
          viewMatrix = _opts$viewMatrix === void 0 ? IDENTITY : _opts$viewMatrix,
          _opts$projectionMatri = opts.projectionMatrix,
          projectionMatrix = _opts$projectionMatri === void 0 ? null : _opts$projectionMatri,
          _opts$orthographic = opts.orthographic,
          orthographic = _opts$orthographic === void 0 ? false : _opts$orthographic,
          fovyRadians = opts.fovyRadians,
          _opts$fovy = opts.fovy,
          fovy = _opts$fovy === void 0 ? 75 : _opts$fovy,
          _opts$near = opts.near,
          near = _opts$near === void 0 ? 0.1 : _opts$near,
          _opts$far = opts.far,
          far = _opts$far === void 0 ? 1000 : _opts$far,
          _opts$padding = opts.padding,
          padding = _opts$padding === void 0 ? null : _opts$padding,
          _opts$focalDistance = opts.focalDistance,
          focalDistance = _opts$focalDistance === void 0 ? 1 : _opts$focalDistance;
      this.viewMatrixUncentered = viewMatrix;
      this.viewMatrix = new Matrix4().multiplyRight(viewMatrix).translate(new Vector3(this.center).negate());
      this.projectionMatrix = projectionMatrix || createProjectionMatrix({
        width: this.width,
        height: this.height,
        orthographic: orthographic,
        fovyRadians: fovyRadians || fovy * DEGREES_TO_RADIANS,
        focalDistance: focalDistance,
        padding: padding,
        near: near,
        far: far
      });
      var vpm = createMat4();
      mat4.multiply(vpm, vpm, this.projectionMatrix);
      mat4.multiply(vpm, vpm, this.viewMatrix);
      this.viewProjectionMatrix = vpm;
      this.viewMatrixInverse = mat4.invert([], this.viewMatrix) || this.viewMatrix;
      this.cameraPosition = getCameraPosition(this.viewMatrixInverse);
      var viewportMatrix = createMat4();
      var pixelProjectionMatrix = createMat4();
      mat4.scale(viewportMatrix, viewportMatrix, [this.width / 2, -this.height / 2, 1]);
      mat4.translate(viewportMatrix, viewportMatrix, [1, -1, 0]);
      mat4.multiply(pixelProjectionMatrix, viewportMatrix, this.viewProjectionMatrix);
      this.pixelProjectionMatrix = pixelProjectionMatrix;
      this.pixelUnprojectionMatrix = mat4.invert(createMat4(), this.pixelProjectionMatrix);

      if (!this.pixelUnprojectionMatrix) {
        log.warn('Pixel project matrix not invertible')();
      }
    }
  }]);

  return Viewport;
}();

_defineProperty(Viewport, "displayName", 'Viewport');

export { Viewport as default };