import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _inherits from "@babel/runtime/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

import { CubeGeometry } from '@luma.gl/core';
import { UNIT } from '@deck.gl/core';
import ColumnLayer from './column-layer';
var defaultProps = {
  cellSize: {
    type: 'number',
    min: 0,
    value: 1000
  },
  offset: {
    type: 'array',
    value: [1, 1]
  }
};

var GridCellLayer = function (_ColumnLayer) {
  _inherits(GridCellLayer, _ColumnLayer);

  var _super = _createSuper(GridCellLayer);

  function GridCellLayer() {
    _classCallCheck(this, GridCellLayer);

    return _super.apply(this, arguments);
  }

  _createClass(GridCellLayer, [{
    key: "getGeometry",
    value: function getGeometry(diskResolution) {
      return new CubeGeometry();
    }
  }, {
    key: "draw",
    value: function draw(_ref) {
      var uniforms = _ref.uniforms;
      var _this$props = this.props,
          elevationScale = _this$props.elevationScale,
          extruded = _this$props.extruded,
          offset = _this$props.offset,
          coverage = _this$props.coverage,
          cellSize = _this$props.cellSize,
          angle = _this$props.angle,
          radiusUnits = _this$props.radiusUnits;
      this.state.model.setUniforms(uniforms).setUniforms({
        radius: cellSize / 2,
        radiusUnits: UNIT[radiusUnits],
        angle: angle,
        offset: offset,
        extruded: extruded,
        coverage: coverage,
        elevationScale: elevationScale,
        edgeDistance: 1,
        isWireframe: false
      }).draw();
    }
  }]);

  return GridCellLayer;
}(ColumnLayer);

_defineProperty(GridCellLayer, "layerName", 'GridCellLayer');

_defineProperty(GridCellLayer, "defaultProps", defaultProps);

export { GridCellLayer as default };