import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _EQUATION_MAP, _parameters;

import { AGGREGATION_OPERATION } from '../aggregation-operation-utils';
export var DEFAULT_RUN_PARAMS = {
  projectPoints: false,
  viewport: null,
  createBufferObjects: true,
  moduleSettings: {}
};
export var MAX_32_BIT_FLOAT = 3.402823466e38;
export var MIN_BLEND_EQUATION = [32775, 32774];
export var MAX_BLEND_EQUATION = [32776, 32774];
export var MAX_MIN_BLEND_EQUATION = [32776, 32775];
export var EQUATION_MAP = (_EQUATION_MAP = {}, _defineProperty(_EQUATION_MAP, AGGREGATION_OPERATION.SUM, 32774), _defineProperty(_EQUATION_MAP, AGGREGATION_OPERATION.MEAN, 32774), _defineProperty(_EQUATION_MAP, AGGREGATION_OPERATION.MIN, MIN_BLEND_EQUATION), _defineProperty(_EQUATION_MAP, AGGREGATION_OPERATION.MAX, MAX_BLEND_EQUATION), _EQUATION_MAP);
export var ELEMENTCOUNT = 4;
export var DEFAULT_WEIGHT_PARAMS = {
  size: 1,
  operation: AGGREGATION_OPERATION.SUM,
  needMin: false,
  needMax: false,
  combineMaxMin: false
};
export var PIXEL_SIZE = 4;
export var WEIGHT_SIZE = 3;
export var MAX_MIN_TEXTURE_OPTS = {
  format: 34836,
  type: 5126,
  border: 0,
  mipmaps: false,
  parameters: (_parameters = {}, _defineProperty(_parameters, 10240, 9728), _defineProperty(_parameters, 10241, 9728), _parameters),
  dataFormat: 6408,
  width: 1,
  height: 1
};