import _typeof from "@babel/runtime/helpers/typeof";
import { PROP_TYPES_SYMBOL } from './constants';
export function validateProps(props) {
  var propTypes = props[PROP_TYPES_SYMBOL];

  for (var propName in propTypes) {
    var propType = propTypes[propName];
    var validate = propType.validate;

    if (validate && !validate(props[propName], propType)) {
      throw new Error("Invalid prop ".concat(propName, ": ").concat(props[propName]));
    }
  }
}
export function diffProps(props, oldProps) {
  var propsChangedReason = compareProps({
    newProps: props,
    oldProps: oldProps,
    propTypes: props[PROP_TYPES_SYMBOL],
    ignoreProps: {
      data: null,
      updateTriggers: null,
      extensions: null,
      transitions: null
    }
  });
  var dataChangedReason = diffDataProps(props, oldProps);
  var updateTriggersChangedReason = false;

  if (!dataChangedReason) {
    updateTriggersChangedReason = diffUpdateTriggers(props, oldProps);
  }

  return {
    dataChanged: dataChangedReason,
    propsChanged: propsChangedReason,
    updateTriggersChanged: updateTriggersChangedReason,
    extensionsChanged: diffExtensions(props, oldProps),
    transitionsChanged: diffTransitions(props, oldProps)
  };
}

function diffTransitions(props, oldProps) {
  if (!props.transitions) {
    return false;
  }

  var result = {};
  var propTypes = props[PROP_TYPES_SYMBOL];
  var changed = false;

  for (var key in props.transitions) {
    var propType = propTypes[key];
    var type = propType && propType.type;
    var isTransitionable = type === 'number' || type === 'color' || type === 'array';

    if (isTransitionable && comparePropValues(props[key], oldProps[key], propType)) {
      result[key] = true;
      changed = true;
    }
  }

  return changed ? result : false;
}

export function compareProps(_ref) {
  var newProps = _ref.newProps,
      oldProps = _ref.oldProps,
      _ref$ignoreProps = _ref.ignoreProps,
      ignoreProps = _ref$ignoreProps === void 0 ? {} : _ref$ignoreProps,
      _ref$propTypes = _ref.propTypes,
      propTypes = _ref$propTypes === void 0 ? {} : _ref$propTypes,
      _ref$triggerName = _ref.triggerName,
      triggerName = _ref$triggerName === void 0 ? 'props' : _ref$triggerName;

  if (oldProps === newProps) {
    return false;
  }

  if (_typeof(newProps) !== 'object' || newProps === null) {
    return "".concat(triggerName, " changed shallowly");
  }

  if (_typeof(oldProps) !== 'object' || oldProps === null) {
    return "".concat(triggerName, " changed shallowly");
  }

  for (var _i = 0, _Object$keys = Object.keys(newProps); _i < _Object$keys.length; _i++) {
    var key = _Object$keys[_i];

    if (!(key in ignoreProps)) {
      if (!(key in oldProps)) {
        return "".concat(triggerName, ".").concat(key, " added");
      }

      var changed = comparePropValues(newProps[key], oldProps[key], propTypes[key]);

      if (changed) {
        return "".concat(triggerName, ".").concat(key, " ").concat(changed);
      }
    }
  }

  for (var _i2 = 0, _Object$keys2 = Object.keys(oldProps); _i2 < _Object$keys2.length; _i2++) {
    var _key = _Object$keys2[_i2];

    if (!(_key in ignoreProps)) {
      if (!(_key in newProps)) {
        return "".concat(triggerName, ".").concat(_key, " dropped");
      }

      if (!Object.hasOwnProperty.call(newProps, _key)) {
        var _changed = comparePropValues(newProps[_key], oldProps[_key], propTypes[_key]);

        if (_changed) {
          return "".concat(triggerName, ".").concat(_key, " ").concat(_changed);
        }
      }
    }
  }

  return false;
}

function comparePropValues(newProp, oldProp, propType) {
  var equal = propType && propType.equal;

  if (equal && !equal(newProp, oldProp, propType)) {
    return 'changed deeply';
  }

  if (!equal) {
    equal = newProp && oldProp && newProp.equals;

    if (equal && !equal.call(newProp, oldProp)) {
      return 'changed deeply';
    }
  }

  if (!equal && oldProp !== newProp) {
    return 'changed shallowly';
  }

  return null;
}

function diffDataProps(props, oldProps) {
  if (oldProps === null) {
    return 'oldProps is null, initial diff';
  }

  var dataChanged = false;
  var dataComparator = props.dataComparator,
      _dataDiff = props._dataDiff;

  if (dataComparator) {
    if (!dataComparator(props.data, oldProps.data)) {
      dataChanged = 'Data comparator detected a change';
    }
  } else if (props.data !== oldProps.data) {
    dataChanged = 'A new data container was supplied';
  }

  if (dataChanged && _dataDiff) {
    dataChanged = _dataDiff(props.data, oldProps.data) || dataChanged;
  }

  return dataChanged;
}

function diffUpdateTriggers(props, oldProps) {
  if (oldProps === null) {
    return {
      all: true
    };
  }

  if ('all' in props.updateTriggers) {
    var diffReason = diffUpdateTrigger(props, oldProps, 'all');

    if (diffReason) {
      return {
        all: true
      };
    }
  }

  var reason = {};
  var changed = false;

  for (var triggerName in props.updateTriggers) {
    if (triggerName !== 'all') {
      var _diffReason = diffUpdateTrigger(props, oldProps, triggerName);

      if (_diffReason) {
        reason[triggerName] = true;
        changed = true;
      }
    }
  }

  return changed ? reason : false;
}

function diffExtensions(props, oldProps) {
  if (oldProps === null) {
    return true;
  }

  var oldExtensions = oldProps.extensions;
  var extensions = props.extensions;

  if (extensions === oldExtensions) {
    return false;
  }

  if (!oldExtensions || !extensions) {
    return true;
  }

  if (extensions.length !== oldExtensions.length) {
    return true;
  }

  for (var i = 0; i < extensions.length; i++) {
    if (!extensions[i].equals(oldExtensions[i])) {
      return true;
    }
  }

  return false;
}

function diffUpdateTrigger(props, oldProps, triggerName) {
  var newTriggers = props.updateTriggers[triggerName];
  newTriggers = newTriggers === undefined || newTriggers === null ? {} : newTriggers;
  var oldTriggers = oldProps.updateTriggers[triggerName];
  oldTriggers = oldTriggers === undefined || oldTriggers === null ? {} : oldTriggers;
  var diffReason = compareProps({
    oldProps: oldTriggers,
    newProps: newTriggers,
    triggerName: triggerName
  });
  return diffReason;
}