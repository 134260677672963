import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { lerp } from '@math.gl/core';
var availableTransformations = {
  Point: Point,
  MultiPoint: MultiPoint,
  LineString: LineString,
  MultiLineString: MultiLineString,
  Polygon: Polygon,
  MultiPolygon: MultiPolygon
};

function Point(_ref, _ref2, viewport) {
  var _ref3 = _slicedToArray(_ref, 2),
      pointX = _ref3[0],
      pointY = _ref3[1];

  var _ref4 = _slicedToArray(_ref2, 2),
      nw = _ref4[0],
      se = _ref4[1];

  var x = lerp(nw[0], se[0], pointX);
  var y = lerp(nw[1], se[1], pointY);
  return viewport.unprojectFlat([x, y]);
}

function getPoints(geometry, bbox, viewport) {
  return geometry.map(function (g) {
    return Point(g, bbox, viewport);
  });
}

function MultiPoint(multiPoint, bbox, viewport) {
  return getPoints(multiPoint, bbox, viewport);
}

function LineString(line, bbox, viewport) {
  return getPoints(line, bbox, viewport);
}

function MultiLineString(multiLineString, bbox, viewport) {
  return multiLineString.map(function (lineString) {
    return LineString(lineString, bbox, viewport);
  });
}

function Polygon(polygon, bbox, viewport) {
  return polygon.map(function (polygonRing) {
    return getPoints(polygonRing, bbox, viewport);
  });
}

function MultiPolygon(multiPolygon, bbox, viewport) {
  return multiPolygon.map(function (polygon) {
    return Polygon(polygon, bbox, viewport);
  });
}

export function transform(geometry, bbox, viewport) {
  var nw = viewport.projectFlat([bbox.west, bbox.north]);
  var se = viewport.projectFlat([bbox.east, bbox.south]);
  var projectedBbox = [nw, se];
  return _objectSpread(_objectSpread({}, geometry), {}, {
    coordinates: availableTransformations[geometry.type](geometry.coordinates, projectedBbox, viewport)
  });
}