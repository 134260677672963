import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _get from "@babel/runtime/helpers/get";
import _inherits from "@babel/runtime/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

import { GLTFMaterialParser } from '@luma.gl/experimental';
import { Model, pbr } from '@luma.gl/core';
import { SimpleMeshLayer } from '@deck.gl/mesh-layers';
import vs from './mesh-layer-vertex.glsl';
import fs from './mesh-layer-fragment.glsl';

function validateGeometryAttributes(attributes) {
  var hasColorAttribute = attributes.COLOR_0 || attributes.colors;

  if (!hasColorAttribute) {
    attributes.colors = {
      constant: true,
      value: new Float32Array([1, 1, 1])
    };
  }
}

var defaultProps = {
  pbrMaterial: {
    type: 'object',
    value: null
  },
  featureIds: {
    type: 'array',
    value: null,
    optional: true
  }
};

var MeshLayer = function (_SimpleMeshLayer) {
  _inherits(MeshLayer, _SimpleMeshLayer);

  var _super = _createSuper(MeshLayer);

  function MeshLayer() {
    _classCallCheck(this, MeshLayer);

    return _super.apply(this, arguments);
  }

  _createClass(MeshLayer, [{
    key: "getShaders",
    value: function getShaders() {
      var shaders = _get(_getPrototypeOf(MeshLayer.prototype), "getShaders", this).call(this);

      var modules = shaders.modules;
      modules.push(pbr);
      return _objectSpread(_objectSpread({}, shaders), {}, {
        vs: vs,
        fs: fs
      });
    }
  }, {
    key: "initializeState",
    value: function initializeState() {
      var featureIds = this.props.featureIds;

      _get(_getPrototypeOf(MeshLayer.prototype), "initializeState", this).call(this);

      var attributeManager = this.getAttributeManager();

      if (featureIds) {
        attributeManager.add({
          featureIdsPickingColors: {
            type: 5121,
            size: 3,
            noAlloc: true,
            update: this.calculateFeatureIdsPickingColors
          }
        });
      }
    }
  }, {
    key: "updateState",
    value: function updateState(params) {
      _get(_getPrototypeOf(MeshLayer.prototype), "updateState", this).call(this, params);

      var props = params.props,
          oldProps = params.oldProps;

      if (props.pbrMaterial !== oldProps.pbrMaterial) {
        this.updatePbrMaterialUniforms(props.pbrMaterial);
      }
    }
  }, {
    key: "draw",
    value: function draw(opts) {
      var featureIds = this.props.featureIds;

      if (!this.state.model) {
        return;
      }

      this.state.model.setUniforms({
        u_Camera: this.state.model.getUniforms().project_uCameraPosition,
        pickFeatureIds: Boolean(featureIds)
      });

      _get(_getPrototypeOf(MeshLayer.prototype), "draw", this).call(this, opts);
    }
  }, {
    key: "getModel",
    value: function getModel(mesh) {
      var _this$props = this.props,
          id = _this$props.id,
          pbrMaterial = _this$props.pbrMaterial;
      var materialParser = this.parseMaterial(pbrMaterial, mesh);
      this.setState({
        materialParser: materialParser
      });
      var shaders = this.getShaders();
      validateGeometryAttributes(mesh.attributes);
      var model = new Model(this.context.gl, _objectSpread(_objectSpread({}, this.getShaders()), {}, {
        id: id,
        geometry: mesh,
        defines: _objectSpread(_objectSpread(_objectSpread({}, shaders.defines), materialParser === null || materialParser === void 0 ? void 0 : materialParser.defines), {}, {
          HAS_UV_REGIONS: mesh.attributes.uvRegions
        }),
        parameters: materialParser === null || materialParser === void 0 ? void 0 : materialParser.parameters,
        isInstanced: true
      }));
      return model;
    }
  }, {
    key: "updatePbrMaterialUniforms",
    value: function updatePbrMaterialUniforms(pbrMaterial) {
      var model = this.state.model;

      if (model) {
        var mesh = this.props.mesh;
        var materialParser = this.parseMaterial(pbrMaterial, mesh);
        this.setState({
          materialParser: materialParser
        });
        model.setUniforms(materialParser.uniforms);
      }
    }
  }, {
    key: "parseMaterial",
    value: function parseMaterial(pbrMaterial, mesh) {
      var _this$state$materialP;

      var unlit = Boolean(pbrMaterial.pbrMetallicRoughness && pbrMaterial.pbrMetallicRoughness.baseColorTexture);
      (_this$state$materialP = this.state.materialParser) === null || _this$state$materialP === void 0 ? void 0 : _this$state$materialP.delete();
      return new GLTFMaterialParser(this.context.gl, {
        attributes: {
          NORMAL: mesh.attributes.normals,
          TEXCOORD_0: mesh.attributes.texCoords
        },
        material: _objectSpread({
          unlit: unlit
        }, pbrMaterial),
        pbrDebug: false,
        imageBasedLightingEnvironment: null,
        lights: true,
        useTangents: false
      });
    }
  }, {
    key: "calculateFeatureIdsPickingColors",
    value: function calculateFeatureIdsPickingColors(attribute) {
      var featureIds = this.props.featureIds;
      var value = new Uint8ClampedArray(featureIds.length * attribute.size);
      var pickingColor = [];

      for (var index = 0; index < featureIds.length; index++) {
        this.encodePickingColor(featureIds[index], pickingColor);
        value[index * 3] = pickingColor[0];
        value[index * 3 + 1] = pickingColor[1];
        value[index * 3 + 2] = pickingColor[2];
      }

      attribute.value = value;
    }
  }, {
    key: "finalizeState",
    value: function finalizeState(context) {
      var _this$state$materialP2;

      _get(_getPrototypeOf(MeshLayer.prototype), "finalizeState", this).call(this, context);

      (_this$state$materialP2 = this.state.materialParser) === null || _this$state$materialP2 === void 0 ? void 0 : _this$state$materialP2.delete();
      this.setState({
        materialParser: null
      });
    }
  }]);

  return MeshLayer;
}(SimpleMeshLayer);

_defineProperty(MeshLayer, "layerName", 'MeshLayer');

_defineProperty(MeshLayer, "defaultProps", defaultProps);

export { MeshLayer as default };