import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _assertThisInitialized from "@babel/runtime/helpers/assertThisInitialized";
import _inherits from "@babel/runtime/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _typeof from "@babel/runtime/helpers/typeof";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import { h3ToGeoBoundary, h3GetResolution, h3ToGeo, geoToH3, h3IsPentagon, h3Distance, edgeLength } from 'h3-js';
import { lerp } from '@math.gl/core';
import { CompositeLayer, createIterable } from '@deck.gl/core';
import { ColumnLayer, PolygonLayer } from '@deck.gl/layers';
var UPDATE_THRESHOLD_KM = 10;
export function normalizeLongitudes(vertices, refLng) {
  refLng = refLng === undefined ? vertices[0][0] : refLng;

  var _iterator = _createForOfIteratorHelper(vertices),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var pt = _step.value;
      var deltaLng = pt[0] - refLng;

      if (deltaLng > 180) {
        pt[0] -= 360;
      } else if (deltaLng < -180) {
        pt[0] += 360;
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
}
export function scalePolygon(hexId, vertices, factor) {
  var _h3ToGeo = h3ToGeo(hexId),
      _h3ToGeo2 = _slicedToArray(_h3ToGeo, 2),
      lat = _h3ToGeo2[0],
      lng = _h3ToGeo2[1];

  var actualCount = vertices.length;
  normalizeLongitudes(vertices, lng);
  var vertexCount = vertices[0] === vertices[actualCount - 1] ? actualCount - 1 : actualCount;

  for (var i = 0; i < vertexCount; i++) {
    vertices[i][0] = lerp(lng, vertices[i][0], factor);
    vertices[i][1] = lerp(lat, vertices[i][1], factor);
  }
}

function getHexagonCentroid(getHexagon, object, objectInfo) {
  var hexagonId = getHexagon(object, objectInfo);

  var _h3ToGeo3 = h3ToGeo(hexagonId),
      _h3ToGeo4 = _slicedToArray(_h3ToGeo3, 2),
      lat = _h3ToGeo4[0],
      lng = _h3ToGeo4[1];

  return [lng, lat];
}

function h3ToPolygon(hexId) {
  var coverage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  var vertices = h3ToGeoBoundary(hexId, true);

  if (coverage !== 1) {
    scalePolygon(hexId, vertices, coverage);
  } else {
    normalizeLongitudes(vertices);
  }

  return vertices;
}

function flattenPolygon(vertices) {
  var positions = new Float64Array(vertices.length * 2);
  var i = 0;

  var _iterator2 = _createForOfIteratorHelper(vertices),
      _step2;

  try {
    for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
      var pt = _step2.value;
      positions[i++] = pt[0];
      positions[i++] = pt[1];
    }
  } catch (err) {
    _iterator2.e(err);
  } finally {
    _iterator2.f();
  }

  return positions;
}

function mergeTriggers(getHexagon, coverage) {
  var trigger;

  if (getHexagon === undefined || getHexagon === null) {
    trigger = coverage;
  } else if (_typeof(getHexagon) === 'object') {
    trigger = _objectSpread(_objectSpread({}, getHexagon), {}, {
      coverage: coverage
    });
  } else {
    trigger = {
      getHexagon: getHexagon,
      coverage: coverage
    };
  }

  return trigger;
}

var defaultProps = _objectSpread(_objectSpread({}, PolygonLayer.defaultProps), {}, {
  highPrecision: 'auto',
  coverage: {
    type: 'number',
    min: 0,
    max: 1,
    value: 1
  },
  centerHexagon: null,
  getHexagon: {
    type: 'accessor',
    value: function value(x) {
      return x.hexagon;
    }
  },
  extruded: true
});

var H3HexagonLayer = function (_CompositeLayer) {
  _inherits(H3HexagonLayer, _CompositeLayer);

  var _super = _createSuper(H3HexagonLayer);

  function H3HexagonLayer() {
    var _this;

    _classCallCheck(this, H3HexagonLayer);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));

    _defineProperty(_assertThisInitialized(_this), "state", void 0);

    return _this;
  }

  _createClass(H3HexagonLayer, [{
    key: "initializeState",
    value: function initializeState() {
      H3HexagonLayer._checkH3Lib();

      this.state = {
        edgeLengthKM: 0,
        resolution: -1
      };
    }
  }, {
    key: "shouldUpdateState",
    value: function shouldUpdateState(_ref) {
      var changeFlags = _ref.changeFlags;
      return this._shouldUseHighPrecision() ? changeFlags.propsOrDataChanged : changeFlags.somethingChanged;
    }
  }, {
    key: "updateState",
    value: function updateState(_ref2) {
      var props = _ref2.props,
          changeFlags = _ref2.changeFlags;

      if (props.highPrecision !== true && (changeFlags.dataChanged || changeFlags.updateTriggersChanged && changeFlags.updateTriggersChanged.getHexagon)) {
        var dataProps = this._calculateH3DataProps();

        this.setState(dataProps);
      }

      this._updateVertices(this.context.viewport);
    }
  }, {
    key: "_calculateH3DataProps",
    value: function _calculateH3DataProps() {
      var resolution = -1;
      var hasPentagon = false;
      var hasMultipleRes = false;

      var _createIterable = createIterable(this.props.data),
          iterable = _createIterable.iterable,
          objectInfo = _createIterable.objectInfo;

      var _iterator3 = _createForOfIteratorHelper(iterable),
          _step3;

      try {
        for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
          var object = _step3.value;
          objectInfo.index++;
          var hexId = this.props.getHexagon(object, objectInfo);
          var hexResolution = h3GetResolution(hexId);

          if (resolution < 0) {
            resolution = hexResolution;
            if (!this.props.highPrecision) break;
          } else if (resolution !== hexResolution) {
            hasMultipleRes = true;
            break;
          }

          if (h3IsPentagon(hexId)) {
            hasPentagon = true;
            break;
          }
        }
      } catch (err) {
        _iterator3.e(err);
      } finally {
        _iterator3.f();
      }

      return {
        resolution: resolution,
        edgeLengthKM: resolution >= 0 ? edgeLength(resolution, 'km') : 0,
        hasMultipleRes: hasMultipleRes,
        hasPentagon: hasPentagon
      };
    }
  }, {
    key: "_shouldUseHighPrecision",
    value: function _shouldUseHighPrecision() {
      if (this.props.highPrecision === 'auto') {
        var _this$state = this.state,
            resolution = _this$state.resolution,
            hasPentagon = _this$state.hasPentagon,
            hasMultipleRes = _this$state.hasMultipleRes;
        var viewport = this.context.viewport;
        return Boolean(viewport === null || viewport === void 0 ? void 0 : viewport.resolution) || hasMultipleRes || hasPentagon || resolution >= 0 && resolution <= 5;
      }

      return this.props.highPrecision;
    }
  }, {
    key: "_updateVertices",
    value: function _updateVertices(viewport) {
      if (this._shouldUseHighPrecision()) {
        return;
      }

      var _this$state2 = this.state,
          resolution = _this$state2.resolution,
          edgeLengthKM = _this$state2.edgeLengthKM,
          centerHex = _this$state2.centerHex;

      if (resolution < 0) {
        return;
      }

      var hex = this.props.centerHexagon || geoToH3(viewport.latitude, viewport.longitude, resolution);

      if (centerHex === hex) {
        return;
      }

      if (centerHex) {
        var distance = h3Distance(centerHex, hex);

        if (distance >= 0 && distance * edgeLengthKM < UPDATE_THRESHOLD_KM) {
          return;
        }
      }

      var unitsPerMeter = viewport.distanceScales.unitsPerMeter;
      var vertices = h3ToPolygon(hex);

      var _h3ToGeo5 = h3ToGeo(hex),
          _h3ToGeo6 = _slicedToArray(_h3ToGeo5, 2),
          centerLat = _h3ToGeo6[0],
          centerLng = _h3ToGeo6[1];

      var _viewport$projectFlat = viewport.projectFlat([centerLng, centerLat]),
          _viewport$projectFlat2 = _slicedToArray(_viewport$projectFlat, 2),
          centerX = _viewport$projectFlat2[0],
          centerY = _viewport$projectFlat2[1];

      vertices = vertices.map(function (p) {
        var worldPosition = viewport.projectFlat(p);
        return [(worldPosition[0] - centerX) / unitsPerMeter[0], (worldPosition[1] - centerY) / unitsPerMeter[1]];
      });
      this.setState({
        centerHex: hex,
        vertices: vertices
      });
    }
  }, {
    key: "renderLayers",
    value: function renderLayers() {
      return this._shouldUseHighPrecision() ? this._renderPolygonLayer() : this._renderColumnLayer();
    }
  }, {
    key: "_getForwardProps",
    value: function _getForwardProps() {
      var _this$props = this.props,
          elevationScale = _this$props.elevationScale,
          material = _this$props.material,
          coverage = _this$props.coverage,
          extruded = _this$props.extruded,
          wireframe = _this$props.wireframe,
          stroked = _this$props.stroked,
          filled = _this$props.filled,
          lineWidthUnits = _this$props.lineWidthUnits,
          lineWidthScale = _this$props.lineWidthScale,
          lineWidthMinPixels = _this$props.lineWidthMinPixels,
          lineWidthMaxPixels = _this$props.lineWidthMaxPixels,
          getFillColor = _this$props.getFillColor,
          getElevation = _this$props.getElevation,
          getLineColor = _this$props.getLineColor,
          getLineWidth = _this$props.getLineWidth,
          transitions = _this$props.transitions,
          updateTriggers = _this$props.updateTriggers;
      return {
        elevationScale: elevationScale,
        extruded: extruded,
        coverage: coverage,
        wireframe: wireframe,
        stroked: stroked,
        filled: filled,
        lineWidthUnits: lineWidthUnits,
        lineWidthScale: lineWidthScale,
        lineWidthMinPixels: lineWidthMinPixels,
        lineWidthMaxPixels: lineWidthMaxPixels,
        material: material,
        getElevation: getElevation,
        getFillColor: getFillColor,
        getLineColor: getLineColor,
        getLineWidth: getLineWidth,
        transitions: transitions,
        updateTriggers: {
          getFillColor: updateTriggers.getFillColor,
          getElevation: updateTriggers.getElevation,
          getLineColor: updateTriggers.getLineColor,
          getLineWidth: updateTriggers.getLineWidth
        }
      };
    }
  }, {
    key: "_renderPolygonLayer",
    value: function _renderPolygonLayer() {
      var _this$props2 = this.props,
          data = _this$props2.data,
          getHexagon = _this$props2.getHexagon,
          updateTriggers = _this$props2.updateTriggers,
          coverage = _this$props2.coverage;
      var SubLayerClass = this.getSubLayerClass('hexagon-cell-hifi', PolygonLayer);

      var forwardProps = this._getForwardProps();

      forwardProps.updateTriggers.getPolygon = mergeTriggers(updateTriggers.getHexagon, coverage);
      return new SubLayerClass(forwardProps, this.getSubLayerProps({
        id: 'hexagon-cell-hifi',
        updateTriggers: forwardProps.updateTriggers
      }), {
        data: data,
        _normalize: false,
        _windingOrder: 'CCW',
        positionFormat: 'XY',
        getPolygon: function getPolygon(object, objectInfo) {
          var hexagonId = getHexagon(object, objectInfo);
          return flattenPolygon(h3ToPolygon(hexagonId, coverage));
        }
      });
    }
  }, {
    key: "_renderColumnLayer",
    value: function _renderColumnLayer() {
      var _this$props3 = this.props,
          data = _this$props3.data,
          getHexagon = _this$props3.getHexagon,
          updateTriggers = _this$props3.updateTriggers;
      var SubLayerClass = this.getSubLayerClass('hexagon-cell', ColumnLayer);

      var forwardProps = this._getForwardProps();

      forwardProps.updateTriggers.getPosition = updateTriggers.getHexagon;
      return new SubLayerClass(forwardProps, this.getSubLayerProps({
        id: 'hexagon-cell',
        flatShading: true,
        updateTriggers: forwardProps.updateTriggers
      }), {
        data: data,
        diskResolution: 6,
        radius: 1,
        vertices: this.state.vertices,
        getPosition: getHexagonCentroid.bind(null, getHexagon)
      });
    }
  }]);

  return H3HexagonLayer;
}(CompositeLayer);

_defineProperty(H3HexagonLayer, "defaultProps", defaultProps);

_defineProperty(H3HexagonLayer, "layerName", 'H3HexagonLayer');

_defineProperty(H3HexagonLayer, "_checkH3Lib", function () {});

export { H3HexagonLayer as default };