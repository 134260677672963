import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _assertThisInitialized from "@babel/runtime/helpers/assertThisInitialized";
import _get from "@babel/runtime/helpers/get";
import _inherits from "@babel/runtime/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _typeof from "@babel/runtime/helpers/typeof";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

import { Layer, project32, picking, log } from '@deck.gl/core';
import { isWebGL2 } from '@luma.gl/core';
import { pbr } from '@luma.gl/shadertools';
import { ScenegraphNode, createGLTFObjects } from '@luma.gl/experimental';
import { GLTFLoader } from '@loaders.gl/gltf';
import { waitForGLTFAssets } from './gltf-utils';
import { MATRIX_ATTRIBUTES, shouldComposeModelMatrix } from '../utils/matrix';
import vs from './scenegraph-layer-vertex.glsl';
import fs from './scenegraph-layer-fragment.glsl';
var DEFAULT_COLOR = [255, 255, 255, 255];
var defaultProps = {
  scenegraph: {
    type: 'object',
    value: null,
    async: true
  },
  getScene: function getScene(gltf) {
    if (gltf && gltf.scenes) {
      return _typeof(gltf.scene) === 'object' ? gltf.scene : gltf.scenes[gltf.scene || 0];
    }

    return gltf;
  },
  getAnimator: function getAnimator(scenegraph) {
    return scenegraph && scenegraph.animator;
  },
  _animations: null,
  sizeScale: {
    type: 'number',
    value: 1,
    min: 0
  },
  sizeMinPixels: {
    type: 'number',
    min: 0,
    value: 0
  },
  sizeMaxPixels: {
    type: 'number',
    min: 0,
    value: Number.MAX_SAFE_INTEGER
  },
  getPosition: {
    type: 'accessor',
    value: function value(x) {
      return x.position;
    }
  },
  getColor: {
    type: 'accessor',
    value: DEFAULT_COLOR
  },
  _lighting: 'flat',
  _imageBasedLightingEnvironment: null,
  getOrientation: {
    type: 'accessor',
    value: [0, 0, 0]
  },
  getScale: {
    type: 'accessor',
    value: [1, 1, 1]
  },
  getTranslation: {
    type: 'accessor',
    value: [0, 0, 0]
  },
  getTransformMatrix: {
    type: 'accessor',
    value: []
  },
  loaders: [GLTFLoader]
};

var ScenegraphLayer = function (_Layer) {
  _inherits(ScenegraphLayer, _Layer);

  var _super = _createSuper(ScenegraphLayer);

  function ScenegraphLayer() {
    var _this;

    _classCallCheck(this, ScenegraphLayer);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));

    _defineProperty(_assertThisInitialized(_this), "state", void 0);

    return _this;
  }

  _createClass(ScenegraphLayer, [{
    key: "getShaders",
    value: function getShaders() {
      var modules = [project32, picking];

      if (this.props._lighting === 'pbr') {
        modules.push(pbr);
      }

      return _get(_getPrototypeOf(ScenegraphLayer.prototype), "getShaders", this).call(this, {
        vs: vs,
        fs: fs,
        modules: modules
      });
    }
  }, {
    key: "initializeState",
    value: function initializeState() {
      var attributeManager = this.getAttributeManager();
      attributeManager.addInstanced({
        instancePositions: {
          size: 3,
          type: 5130,
          fp64: this.use64bitPositions(),
          accessor: 'getPosition',
          transition: true
        },
        instanceColors: {
          type: 5121,
          size: this.props.colorFormat.length,
          accessor: 'getColor',
          normalized: true,
          defaultValue: DEFAULT_COLOR,
          transition: true
        },
        instanceModelMatrix: MATRIX_ATTRIBUTES
      });
    }
  }, {
    key: "updateState",
    value: function updateState(params) {
      _get(_getPrototypeOf(ScenegraphLayer.prototype), "updateState", this).call(this, params);

      var props = params.props,
          oldProps = params.oldProps;

      if (props.scenegraph !== oldProps.scenegraph) {
        this._updateScenegraph();
      } else if (props._animations !== oldProps._animations) {
        this._applyAnimationsProp(this.state.scenegraph, this.state.animator, props._animations);
      }
    }
  }, {
    key: "finalizeState",
    value: function finalizeState(context) {
      _get(_getPrototypeOf(ScenegraphLayer.prototype), "finalizeState", this).call(this, context);

      this._deleteScenegraph();
    }
  }, {
    key: "isLoaded",
    get: function get() {
      var _this$state;

      return ((_this$state = this.state) === null || _this$state === void 0 ? void 0 : _this$state.scenegraph) && _get(_getPrototypeOf(ScenegraphLayer.prototype), "isLoaded", this);
    }
  }, {
    key: "_updateScenegraph",
    value: function _updateScenegraph() {
      var _this2 = this;

      var props = this.props;
      var gl = this.context.gl;
      var scenegraphData = null;

      if (props.scenegraph instanceof ScenegraphNode) {
        scenegraphData = {
          scenes: [props.scenegraph]
        };
      } else if (props.scenegraph && !props.scenegraph.gltf) {
        var gltf = props.scenegraph;
        var gltfObjects = createGLTFObjects(gl, gltf, this._getModelOptions());
        scenegraphData = _objectSpread({
          gltf: gltf
        }, gltfObjects);
        waitForGLTFAssets(gltfObjects).then(function () {
          return _this2.setNeedsRedraw();
        });
      } else if (props.scenegraph) {
        log.deprecated('ScenegraphLayer.props.scenegraph', 'Use GLTFLoader instead of GLTFScenegraphLoader')();
        scenegraphData = props.scenegraph;
      }

      var options = {
        layer: this,
        gl: gl
      };
      var scenegraph = props.getScene(scenegraphData, options);
      var animator = props.getAnimator(scenegraphData, options);

      if (scenegraph instanceof ScenegraphNode) {
        this._deleteScenegraph();

        this._applyAllAttributes(scenegraph);

        this._applyAnimationsProp(scenegraph, animator, props._animations);

        this.setState({
          scenegraph: scenegraph,
          animator: animator
        });
      } else if (scenegraph !== null) {
        log.warn('invalid scenegraph:', scenegraph)();
      }
    }
  }, {
    key: "_applyAllAttributes",
    value: function _applyAllAttributes(scenegraph) {
      var _this3 = this;

      if (this.state.attributesAvailable) {
        var allAttributes = this.getAttributeManager().getAttributes();
        scenegraph.traverse(function (model) {
          _this3._setModelAttributes(model.model, allAttributes);
        });
      }
    }
  }, {
    key: "_applyAnimationsProp",
    value: function _applyAnimationsProp(scenegraph, animator, animationsProp) {
      if (!scenegraph || !animator || !animationsProp) {
        return;
      }

      var animations = animator.getAnimations();
      Object.keys(animationsProp).sort().forEach(function (key) {
        var value = animationsProp[key];

        if (key === '*') {
          animations.forEach(function (animation) {
            Object.assign(animation, value);
          });
        } else if (Number.isFinite(Number(key))) {
          var number = Number(key);

          if (number >= 0 && number < animations.length) {
            Object.assign(animations[number], value);
          } else {
            log.warn("animation ".concat(key, " not found"))();
          }
        } else {
          var findResult = animations.find(function (_ref) {
            var name = _ref.name;
            return name === key;
          });

          if (findResult) {
            Object.assign(findResult, value);
          } else {
            log.warn("animation ".concat(key, " not found"))();
          }
        }
      });
    }
  }, {
    key: "_deleteScenegraph",
    value: function _deleteScenegraph() {
      var scenegraph = this.state.scenegraph;

      if (scenegraph instanceof ScenegraphNode) {
        scenegraph.delete();
      }
    }
  }, {
    key: "_getModelOptions",
    value: function _getModelOptions() {
      var _imageBasedLightingEnvironment = this.props._imageBasedLightingEnvironment;
      var env = null;

      if (_imageBasedLightingEnvironment) {
        if (typeof _imageBasedLightingEnvironment === 'function') {
          env = _imageBasedLightingEnvironment({
            gl: this.context.gl,
            layer: this
          });
        } else {
          env = _imageBasedLightingEnvironment;
        }
      }

      return {
        gl: this.context.gl,
        waitForFullLoad: true,
        imageBasedLightingEnvironment: env,
        modelOptions: _objectSpread({
          isInstanced: true,
          transpileToGLSL100: !isWebGL2(this.context.gl)
        }, this.getShaders()),
        useTangents: false
      };
    }
  }, {
    key: "updateAttributes",
    value: function updateAttributes(changedAttributes) {
      var _this4 = this;

      this.setState({
        attributesAvailable: true
      });
      if (!this.state.scenegraph) return;
      this.state.scenegraph.traverse(function (model) {
        _this4._setModelAttributes(model.model, changedAttributes);
      });
    }
  }, {
    key: "draw",
    value: function draw(_ref2) {
      var _ref2$moduleParameter = _ref2.moduleParameters,
          moduleParameters = _ref2$moduleParameter === void 0 ? null : _ref2$moduleParameter,
          _ref2$parameters = _ref2.parameters,
          parameters = _ref2$parameters === void 0 ? {} : _ref2$parameters,
          context = _ref2.context;
      if (!this.state.scenegraph) return;

      if (this.props._animations && this.state.animator) {
        this.state.animator.animate(context.timeline.getTime());
        this.setNeedsRedraw();
      }

      var viewport = this.context.viewport;
      var _this$props = this.props,
          sizeScale = _this$props.sizeScale,
          sizeMinPixels = _this$props.sizeMinPixels,
          sizeMaxPixels = _this$props.sizeMaxPixels,
          opacity = _this$props.opacity,
          coordinateSystem = _this$props.coordinateSystem;
      var numInstances = this.getNumInstances();
      this.state.scenegraph.traverse(function (model, _ref3) {
        var worldMatrix = _ref3.worldMatrix;
        model.model.setInstanceCount(numInstances);
        model.updateModuleSettings(moduleParameters);
        model.draw({
          parameters: parameters,
          uniforms: {
            sizeScale: sizeScale,
            opacity: opacity,
            sizeMinPixels: sizeMinPixels,
            sizeMaxPixels: sizeMaxPixels,
            composeModelMatrix: shouldComposeModelMatrix(viewport, coordinateSystem),
            sceneModelMatrix: worldMatrix,
            u_Camera: model.model.getUniforms().project_uCameraPosition
          }
        });
      });
    }
  }]);

  return ScenegraphLayer;
}(Layer);

_defineProperty(ScenegraphLayer, "defaultProps", defaultProps);

_defineProperty(ScenegraphLayer, "layerName", 'ScenegraphLayer');

export { ScenegraphLayer as default };