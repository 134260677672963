import { MODULE_VERSION, MODULE_NAME } from './version';
import { createDeck, updateDeck } from './playground/create-deck';
import { initPlayground } from './playground';
import jupyterTransport from './lib/jupyter-transport';
import JupyterTransportModel from './lib/jupyter-transport-model';
import JupyterTransportView from './lib/jupyter-transport-view';
import * as deckExports from './deck-bundle';
import * as lumaExports from '@deck.gl/core/scripting/lumagl';
import * as loadersExports from '@deck.gl/core/scripting/loadersgl';
var dataBaseUrl = document.body && document.body.getAttribute('data-base-url');

if (dataBaseUrl) {
  window.__webpack_public_path__ = "".concat(dataBaseUrl, "nbextensions/pydeck/nb_extension");
}

initPlayground();
globalThis.deck = globalThis.deck || {};
Object.assign(globalThis.deck, deckExports);
globalThis.luma = globalThis.luma || {};
Object.assign(globalThis.luma, lumaExports);
globalThis.loaders = globalThis.loaders || {};
Object.assign(globalThis.loaders, loadersExports);
export { jupyterTransport, MODULE_VERSION, MODULE_NAME, JupyterTransportModel, JupyterTransportView, initPlayground, createDeck, updateDeck };