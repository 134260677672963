import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _inherits from "@babel/runtime/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
var _excluded = ["updateTriggers"];

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { CompositeLayer } from '@deck.gl/core';
import { PolygonLayer } from '@deck.gl/layers';

var defaultProps = _objectSpread({}, PolygonLayer.defaultProps);

var GeoCellLayer = function (_CompositeLayer) {
  _inherits(GeoCellLayer, _CompositeLayer);

  var _super = _createSuper(GeoCellLayer);

  function GeoCellLayer() {
    _classCallCheck(this, GeoCellLayer);

    return _super.apply(this, arguments);
  }

  _createClass(GeoCellLayer, [{
    key: "indexToBounds",
    value: function indexToBounds() {
      return null;
    }
  }, {
    key: "renderLayers",
    value: function renderLayers() {
      var _this$props = this.props,
          elevationScale = _this$props.elevationScale,
          extruded = _this$props.extruded,
          wireframe = _this$props.wireframe,
          filled = _this$props.filled,
          stroked = _this$props.stroked,
          lineWidthUnits = _this$props.lineWidthUnits,
          lineWidthScale = _this$props.lineWidthScale,
          lineWidthMinPixels = _this$props.lineWidthMinPixels,
          lineWidthMaxPixels = _this$props.lineWidthMaxPixels,
          lineJointRounded = _this$props.lineJointRounded,
          lineMiterLimit = _this$props.lineMiterLimit,
          lineDashJustified = _this$props.lineDashJustified,
          getElevation = _this$props.getElevation,
          getFillColor = _this$props.getFillColor,
          getLineColor = _this$props.getLineColor,
          getLineWidth = _this$props.getLineWidth;
      var _this$props2 = this.props,
          updateTriggers = _this$props2.updateTriggers,
          material = _this$props2.material,
          transitions = _this$props2.transitions;
      var CellLayer = this.getSubLayerClass('cell', PolygonLayer);

      var _ref = this.indexToBounds() || {},
          boundsUpdateTriggers = _ref.updateTriggers,
          boundsProps = _objectWithoutProperties(_ref, _excluded);

      return new CellLayer({
        filled: filled,
        wireframe: wireframe,
        extruded: extruded,
        elevationScale: elevationScale,
        stroked: stroked,
        lineWidthUnits: lineWidthUnits,
        lineWidthScale: lineWidthScale,
        lineWidthMinPixels: lineWidthMinPixels,
        lineWidthMaxPixels: lineWidthMaxPixels,
        lineJointRounded: lineJointRounded,
        lineMiterLimit: lineMiterLimit,
        lineDashJustified: lineDashJustified,
        material: material,
        transitions: transitions,
        getElevation: getElevation,
        getFillColor: getFillColor,
        getLineColor: getLineColor,
        getLineWidth: getLineWidth
      }, this.getSubLayerProps({
        id: 'cell',
        updateTriggers: updateTriggers && _objectSpread(_objectSpread({}, boundsUpdateTriggers), {}, {
          getElevation: updateTriggers.getElevation,
          getFillColor: updateTriggers.getFillColor,
          getLineColor: updateTriggers.getLineColor,
          getLineWidth: updateTriggers.getLineWidth
        })
      }), boundsProps);
    }
  }]);

  return GeoCellLayer;
}(CompositeLayer);

_defineProperty(GeoCellLayer, "layerName", 'GeoCellLayer');

_defineProperty(GeoCellLayer, "defaultProps", defaultProps);

export { GeoCellLayer as default };