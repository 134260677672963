import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _inherits from "@babel/runtime/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

import { ArcLayer } from '@deck.gl/layers';
var defaultProps = {
  getHeight: {
    type: 'accessor',
    value: 0
  },
  greatCircle: true
};

var GreatCircleLayer = function (_ArcLayer) {
  _inherits(GreatCircleLayer, _ArcLayer);

  var _super = _createSuper(GreatCircleLayer);

  function GreatCircleLayer() {
    _classCallCheck(this, GreatCircleLayer);

    return _super.apply(this, arguments);
  }

  return GreatCircleLayer;
}(ArcLayer);

_defineProperty(GreatCircleLayer, "layerName", 'GreatCircleLayer');

_defineProperty(GreatCircleLayer, "defaultProps", defaultProps);

export { GreatCircleLayer as default };