import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _inherits from "@babel/runtime/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

import { lerp } from '@math.gl/core';
import Transition from './transition';

var CPUInterpolationTransition = function (_Transition) {
  _inherits(CPUInterpolationTransition, _Transition);

  var _super = _createSuper(CPUInterpolationTransition);

  function CPUInterpolationTransition() {
    _classCallCheck(this, CPUInterpolationTransition);

    return _super.apply(this, arguments);
  }

  _createClass(CPUInterpolationTransition, [{
    key: "value",
    get: function get() {
      return this._value;
    }
  }, {
    key: "_onUpdate",
    value: function _onUpdate() {
      var time = this.time,
          _this$settings = this.settings,
          fromValue = _this$settings.fromValue,
          toValue = _this$settings.toValue,
          duration = _this$settings.duration,
          easing = _this$settings.easing;
      var t = easing(time / duration);
      this._value = lerp(fromValue, toValue, t);
    }
  }]);

  return CPUInterpolationTransition;
}(Transition);

export { CPUInterpolationTransition as default };