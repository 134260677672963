import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import { Timeline } from '@luma.gl/core';
import { LIFECYCLE } from '../lifecycle/constants';
import log from '../utils/log';
import debug from '../debug';
import { flatten } from '../utils/flatten';
import { Stats } from '@probe.gl/stats';
import ResourceManager from './resource/resource-manager';
import Viewport from '../viewports/viewport';
import { createProgramManager } from '../shaderlib';
var TRACE_SET_LAYERS = 'layerManager.setLayers';
var TRACE_ACTIVATE_VIEWPORT = 'layerManager.activateViewport';

var LayerManager = function () {
  function LayerManager(gl) {
    var _this = this;

    var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
        deck = _ref.deck,
        stats = _ref.stats,
        _viewport = _ref.viewport,
        timeline = _ref.timeline;

    _classCallCheck(this, LayerManager);

    _defineProperty(this, "layers", void 0);

    _defineProperty(this, "context", void 0);

    _defineProperty(this, "resourceManager", void 0);

    _defineProperty(this, "_lastRenderedLayers", []);

    _defineProperty(this, "_needsRedraw", false);

    _defineProperty(this, "_needsUpdate", false);

    _defineProperty(this, "_nextLayers", null);

    _defineProperty(this, "_debug", false);

    _defineProperty(this, "activateViewport", function (viewport) {
      debug(TRACE_ACTIVATE_VIEWPORT, _this, viewport);

      if (viewport) {
        _this.context.viewport = viewport;
      }
    });

    this.layers = [];
    this.resourceManager = new ResourceManager({
      gl: gl,
      protocol: 'deck://'
    });
    this.context = {
      mousePosition: null,
      userData: {},
      layerManager: this,
      gl: gl,
      deck: deck,
      programManager: gl && createProgramManager(gl),
      stats: stats || new Stats({
        id: 'deck.gl'
      }),
      viewport: _viewport || new Viewport({
        id: 'DEFAULT-INITIAL-VIEWPORT'
      }),
      timeline: timeline || new Timeline(),
      resourceManager: this.resourceManager,
      onError: undefined
    };
    Object.seal(this);
  }

  _createClass(LayerManager, [{
    key: "finalize",
    value: function finalize() {
      this.resourceManager.finalize();

      var _iterator = _createForOfIteratorHelper(this.layers),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var layer = _step.value;

          this._finalizeLayer(layer);
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    }
  }, {
    key: "needsRedraw",
    value: function needsRedraw() {
      var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
        clearRedrawFlags: false
      };
      var redraw = this._needsRedraw;

      if (opts.clearRedrawFlags) {
        this._needsRedraw = false;
      }

      var _iterator2 = _createForOfIteratorHelper(this.layers),
          _step2;

      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var layer = _step2.value;
          var layerNeedsRedraw = layer.getNeedsRedraw(opts);
          redraw = redraw || layerNeedsRedraw;
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }

      return redraw;
    }
  }, {
    key: "needsUpdate",
    value: function needsUpdate() {
      if (this._nextLayers && this._nextLayers !== this._lastRenderedLayers) {
        return 'layers changed';
      }

      return this._needsUpdate;
    }
  }, {
    key: "setNeedsRedraw",
    value: function setNeedsRedraw(reason) {
      this._needsRedraw = this._needsRedraw || reason;
    }
  }, {
    key: "setNeedsUpdate",
    value: function setNeedsUpdate(reason) {
      this._needsUpdate = this._needsUpdate || reason;
    }
  }, {
    key: "getLayers",
    value: function getLayers() {
      var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          layerIds = _ref2.layerIds;

      return layerIds ? this.layers.filter(function (layer) {
        return layerIds.find(function (layerId) {
          return layer.id.indexOf(layerId) === 0;
        });
      }) : this.layers;
    }
  }, {
    key: "setProps",
    value: function setProps(props) {
      if ('debug' in props) {
        this._debug = props.debug;
      }

      if ('userData' in props) {
        this.context.userData = props.userData;
      }

      if ('layers' in props) {
        this._nextLayers = props.layers;
      }

      if ('onError' in props) {
        this.context.onError = props.onError;
      }
    }
  }, {
    key: "setLayers",
    value: function setLayers(newLayers, reason) {
      debug(TRACE_SET_LAYERS, this, reason, newLayers);
      this._lastRenderedLayers = newLayers;
      var flatLayers = flatten(newLayers, Boolean);

      var _iterator3 = _createForOfIteratorHelper(flatLayers),
          _step3;

      try {
        for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
          var layer = _step3.value;
          layer.context = this.context;
        }
      } catch (err) {
        _iterator3.e(err);
      } finally {
        _iterator3.f();
      }

      this._updateLayers(this.layers, flatLayers);
    }
  }, {
    key: "updateLayers",
    value: function updateLayers() {
      var reason = this.needsUpdate();

      if (reason) {
        this.setNeedsRedraw("updating layers: ".concat(reason));
        this.setLayers(this._nextLayers || this._lastRenderedLayers, reason);
      }

      this._nextLayers = null;
    }
  }, {
    key: "_handleError",
    value: function _handleError(stage, error, layer) {
      layer.raiseError(error, "".concat(stage, " of ").concat(layer));
    }
  }, {
    key: "_updateLayers",
    value: function _updateLayers(oldLayers, newLayers) {
      var oldLayerMap = {};

      var _iterator4 = _createForOfIteratorHelper(oldLayers),
          _step4;

      try {
        for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
          var oldLayer = _step4.value;

          if (oldLayerMap[oldLayer.id]) {
            log.warn("Multiple old layers with same id ".concat(oldLayer.id))();
          } else {
            oldLayerMap[oldLayer.id] = oldLayer;
          }
        }
      } catch (err) {
        _iterator4.e(err);
      } finally {
        _iterator4.f();
      }

      var generatedLayers = [];

      this._updateSublayersRecursively(newLayers, oldLayerMap, generatedLayers);

      this._finalizeOldLayers(oldLayerMap);

      var needsUpdate = false;

      for (var _i = 0, _generatedLayers = generatedLayers; _i < _generatedLayers.length; _i++) {
        var layer = _generatedLayers[_i];

        if (layer.hasUniformTransition()) {
          needsUpdate = "Uniform transition in ".concat(layer);
          break;
        }
      }

      this._needsUpdate = needsUpdate;
      this.layers = generatedLayers;
    }
  }, {
    key: "_updateSublayersRecursively",
    value: function _updateSublayersRecursively(newLayers, oldLayerMap, generatedLayers) {
      var _iterator5 = _createForOfIteratorHelper(newLayers),
          _step5;

      try {
        for (_iterator5.s(); !(_step5 = _iterator5.n()).done;) {
          var newLayer = _step5.value;
          newLayer.context = this.context;
          var oldLayer = oldLayerMap[newLayer.id];

          if (oldLayer === null) {
            log.warn("Multiple new layers with same id ".concat(newLayer.id))();
          }

          oldLayerMap[newLayer.id] = null;
          var sublayers = null;

          try {
            if (this._debug && oldLayer !== newLayer) {
              newLayer.validateProps();
            }

            if (!oldLayer) {
              this._initializeLayer(newLayer);
            } else {
              this._transferLayerState(oldLayer, newLayer);

              this._updateLayer(newLayer);
            }

            generatedLayers.push(newLayer);
            sublayers = newLayer.isComposite ? newLayer.getSubLayers() : null;
          } catch (err) {
            this._handleError('matching', err, newLayer);
          }

          if (sublayers) {
            this._updateSublayersRecursively(sublayers, oldLayerMap, generatedLayers);
          }
        }
      } catch (err) {
        _iterator5.e(err);
      } finally {
        _iterator5.f();
      }
    }
  }, {
    key: "_finalizeOldLayers",
    value: function _finalizeOldLayers(oldLayerMap) {
      for (var _layerId in oldLayerMap) {
        var layer = oldLayerMap[_layerId];

        if (layer) {
          this._finalizeLayer(layer);
        }
      }
    }
  }, {
    key: "_initializeLayer",
    value: function _initializeLayer(layer) {
      try {
        layer._initialize();

        layer.lifecycle = LIFECYCLE.INITIALIZED;
      } catch (err) {
        this._handleError('initialization', err, layer);
      }
    }
  }, {
    key: "_transferLayerState",
    value: function _transferLayerState(oldLayer, newLayer) {
      newLayer._transferState(oldLayer);

      newLayer.lifecycle = LIFECYCLE.MATCHED;

      if (newLayer !== oldLayer) {
        oldLayer.lifecycle = LIFECYCLE.AWAITING_GC;
      }
    }
  }, {
    key: "_updateLayer",
    value: function _updateLayer(layer) {
      try {
        layer._update();
      } catch (err) {
        this._handleError('update', err, layer);
      }
    }
  }, {
    key: "_finalizeLayer",
    value: function _finalizeLayer(layer) {
      this._needsRedraw = this._needsRedraw || "finalized ".concat(layer);
      layer.lifecycle = LIFECYCLE.AWAITING_FINALIZATION;

      try {
        layer._finalize();

        layer.lifecycle = LIFECYCLE.FINALIZED;
      } catch (err) {
        this._handleError('finalization', err, layer);
      }
    }
  }]);

  return LayerManager;
}();

export { LayerManager as default };