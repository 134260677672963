import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _inherits from "@babel/runtime/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

import GeoCellLayer from '../geo-cell-layer/GeoCellLayer';
import { getS2Polygon } from './s2-utils';
var defaultProps = {
  getS2Token: {
    type: 'accessor',
    value: function value(d) {
      return d.token;
    }
  }
};

var S2Layer = function (_GeoCellLayer) {
  _inherits(S2Layer, _GeoCellLayer);

  var _super = _createSuper(S2Layer);

  function S2Layer() {
    _classCallCheck(this, S2Layer);

    return _super.apply(this, arguments);
  }

  _createClass(S2Layer, [{
    key: "indexToBounds",
    value: function indexToBounds() {
      var _this$props = this.props,
          data = _this$props.data,
          getS2Token = _this$props.getS2Token;
      return {
        data: data,
        _normalize: false,
        positionFormat: 'XY',
        getPolygon: function getPolygon(x, objectInfo) {
          return getS2Polygon(getS2Token(x, objectInfo));
        }
      };
    }
  }]);

  return S2Layer;
}(GeoCellLayer);

_defineProperty(S2Layer, "layerName", 'S2Layer');

_defineProperty(S2Layer, "defaultProps", defaultProps);

export { S2Layer as default };