export function filterProps(props, filterKeys) {
  var filteredProps = {};

  for (var key in props) {
    if (!filterKeys.includes(key)) {
      filteredProps[key] = props[key];
    }
  }

  return filteredProps;
}