import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { Deck } from '@deck.gl/core';
import { Matrix4, Vector2 } from '@math.gl/core';
var MAX_LATITUDE = 85.05113;
export function createDeckInstance(map, overlay, deck, props) {
  if (deck) {
    if (deck.userData._googleMap === map) {
      return deck;
    }

    destroyDeckInstance(deck);
  }

  var eventListeners = {
    click: null,
    rightclick: null,
    dblclick: null,
    mousemove: null,
    mouseout: null
  };
  var newDeck = new Deck(_objectSpread(_objectSpread({}, props), {}, {
    useDevicePixels: props.interleaved ? true : props.useDevicePixels,
    style: props.interleaved ? null : {
      pointerEvents: 'none'
    },
    parent: getContainer(overlay, props.style),
    initialViewState: {
      longitude: 0,
      latitude: 0,
      zoom: 1
    },
    controller: false
  }));

  var _loop = function _loop(eventType) {
    eventListeners[eventType] = map.addListener(eventType, function (evt) {
      return handleMouseEvent(newDeck, eventType, evt);
    });
  };

  for (var eventType in eventListeners) {
    _loop(eventType);
  }

  newDeck.userData._googleMap = map;
  newDeck.userData._eventListeners = eventListeners;
  return newDeck;
}

function getContainer(overlay, style) {
  var container = document.createElement('div');
  container.style.position = 'absolute';
  Object.assign(container.style, style);

  if ('getPanes' in overlay) {
    var _overlay$getPanes;

    (_overlay$getPanes = overlay.getPanes()) === null || _overlay$getPanes === void 0 ? void 0 : _overlay$getPanes.overlayLayer.appendChild(container);
  } else {
    var _overlay$getMap;

    (_overlay$getMap = overlay.getMap()) === null || _overlay$getMap === void 0 ? void 0 : _overlay$getMap.getDiv().appendChild(container);
  }

  return container;
}

export function destroyDeckInstance(deck) {
  var eventListeners = deck.userData._eventListeners;

  for (var eventType in eventListeners) {
    if (eventListeners[eventType]) {
      eventListeners[eventType].remove();
    }
  }

  deck.finalize();
}
export function getViewPropsFromOverlay(map, overlay) {
  var _getMapSize = getMapSize(map),
      width = _getMapSize.width,
      height = _getMapSize.height;

  var projection = overlay.getProjection();
  var bounds = map.getBounds();

  if (!bounds) {
    return {
      width: width,
      height: height,
      left: 0,
      top: 0
    };
  }

  var ne = bounds.getNorthEast();
  var sw = bounds.getSouthWest();
  var topRight = projection.fromLatLngToDivPixel(ne);
  var bottomLeft = projection.fromLatLngToDivPixel(sw);
  var centerLngLat = pixelToLngLat(projection, width / 2, height / 2);
  var centerH = new google.maps.LatLng(0, centerLngLat[0]);
  var centerContainerPx = projection.fromLatLngToContainerPixel(centerH);
  var centerDivPx = projection.fromLatLngToDivPixel(centerH);

  if (!topRight || !bottomLeft || !centerDivPx || !centerContainerPx) {
    return {
      width: width,
      height: height,
      left: 0,
      top: 0
    };
  }

  var leftOffset = Math.round(centerDivPx.x - centerContainerPx.x);
  var topOffset = centerDivPx.y - centerContainerPx.y;
  var topLngLat = pixelToLngLat(projection, width / 2, 0);
  var bottomLngLat = pixelToLngLat(projection, width / 2, height);
  var latitude = centerLngLat[1];
  var longitude = centerLngLat[0];

  if (Math.abs(latitude) > MAX_LATITUDE) {
    latitude = latitude > 0 ? MAX_LATITUDE : -MAX_LATITUDE;
    var center = new google.maps.LatLng(latitude, longitude);
    var centerPx = projection.fromLatLngToContainerPixel(center);
    topOffset += centerPx.y - height / 2;
  }

  topOffset = Math.round(topOffset);
  var delta = new Vector2(topLngLat).sub(bottomLngLat);
  var bearing = 180 * delta.verticalAngle() / Math.PI;
  if (bearing < 0) bearing += 360;
  var heading = map.getHeading() || 0;
  var zoom = map.getZoom() - 1;
  var scale;

  if (bearing === 0) {
    scale = height ? (bottomLeft.y - topRight.y) / height : 1;
  } else if (bearing === heading) {
    var viewDiagonal = new Vector2([topRight.x, topRight.y]).sub([bottomLeft.x, bottomLeft.y]).len();
    var mapDiagonal = new Vector2([width, -height]).len();
    scale = mapDiagonal ? viewDiagonal / mapDiagonal : 1;
  }

  zoom += Math.log2(scale || 1);
  return {
    width: width,
    height: height,
    left: leftOffset,
    top: topOffset,
    zoom: zoom,
    bearing: bearing,
    pitch: map.getTilt(),
    latitude: latitude,
    longitude: longitude
  };
}
export function getViewPropsFromCoordinateTransformer(map, transformer) {
  var _getMapSize2 = getMapSize(map),
      width = _getMapSize2.width,
      height = _getMapSize2.height;

  var _transformer$getCamer = transformer.getCameraParams(),
      center = _transformer$getCamer.center,
      bearing = _transformer$getCamer.heading,
      pitch = _transformer$getCamer.tilt,
      zoom = _transformer$getCamer.zoom;

  var fovy = 25;
  var aspect = height ? width / height : 1;
  var near = 0.75;
  var far = 300000000000000;
  var projectionMatrix = new Matrix4().perspective({
    fovy: fovy * Math.PI / 180,
    aspect: aspect,
    near: near,
    far: far
  });
  var focalDistance = 0.5 * projectionMatrix[5];
  return {
    width: width,
    height: height,
    viewState: {
      altitude: focalDistance,
      bearing: bearing,
      latitude: center.lat(),
      longitude: center.lng(),
      pitch: pitch,
      projectionMatrix: projectionMatrix,
      repeat: true,
      zoom: zoom - 1
    }
  };
}

function getMapSize(map) {
  var container = map.getDiv().firstChild;
  return {
    width: container.offsetWidth,
    height: container.offsetHeight
  };
}

function pixelToLngLat(projection, x, y) {
  var point = new google.maps.Point(x, y);
  var latLng = projection.fromContainerPixelToLatLng(point);
  return [latLng.lng(), latLng.lat()];
}

function getEventPixel(event, deck) {
  if (event.pixel) {
    return event.pixel;
  }

  var point = deck.getViewports()[0].project([event.latLng.lng(), event.latLng.lat()]);
  return {
    x: point[0],
    y: point[1]
  };
}

function handleMouseEvent(deck, type, event) {
  if (!deck.isInitialized) {
    return;
  }

  var mockEvent = {
    type: type,
    offsetCenter: getEventPixel(event, deck),
    srcEvent: event
  };

  switch (type) {
    case 'click':
    case 'rightclick':
      mockEvent.type = 'click';
      mockEvent.tapCount = 1;

      deck._onPointerDown(mockEvent);

      deck._onEvent(mockEvent);

      break;

    case 'dblclick':
      mockEvent.type = 'click';
      mockEvent.tapCount = 2;

      deck._onEvent(mockEvent);

      break;

    case 'mousemove':
      mockEvent.type = 'pointermove';

      deck._onPointerMove(mockEvent);

      break;

    case 'mouseout':
      mockEvent.type = 'pointerleave';

      deck._onPointerMove(mockEvent);

      break;

    default:
      return;
  }
}