import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { worldToLngLat } from '@math.gl/web-mercator';
var TILE_SIZE = 512;
export function quadkeyToWorldBounds(quadkey, coverage) {
  var x = 0;
  var y = 0;
  var mask = 1 << quadkey.length;
  var scale = mask / TILE_SIZE;

  for (var i = 0; i < quadkey.length; i++) {
    mask >>= 1;
    var q = parseInt(quadkey[i]);
    if (q % 2) x |= mask;
    if (q > 1) y |= mask;
  }

  return [[x / scale, TILE_SIZE - y / scale], [(x + coverage) / scale, TILE_SIZE - (y + coverage) / scale]];
}
export function getQuadkeyPolygon(quadkey) {
  var coverage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;

  var _quadkeyToWorldBounds = quadkeyToWorldBounds(quadkey, coverage),
      _quadkeyToWorldBounds2 = _slicedToArray(_quadkeyToWorldBounds, 2),
      topLeft = _quadkeyToWorldBounds2[0],
      bottomRight = _quadkeyToWorldBounds2[1];

  var _worldToLngLat = worldToLngLat(topLeft),
      _worldToLngLat2 = _slicedToArray(_worldToLngLat, 2),
      w = _worldToLngLat2[0],
      n = _worldToLngLat2[1];

  var _worldToLngLat3 = worldToLngLat(bottomRight),
      _worldToLngLat4 = _slicedToArray(_worldToLngLat3, 2),
      e = _worldToLngLat4[0],
      s = _worldToLngLat4[1];

  return [e, n, e, s, w, s, w, n, e, n];
}