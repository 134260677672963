import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _assertThisInitialized from "@babel/runtime/helpers/assertThisInitialized";
import _get from "@babel/runtime/helpers/get";
import _inherits from "@babel/runtime/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

import { Layer, project32, phongLighting, picking, log } from '@deck.gl/core';
import { Model, Geometry, Texture2D, isWebGL2 } from '@luma.gl/core';
import { hasFeature, FEATURES } from '@luma.gl/webgl';
import { MATRIX_ATTRIBUTES, shouldComposeModelMatrix } from '../utils/matrix';
import vs from './simple-mesh-layer-vertex.glsl';
import fs from './simple-mesh-layer-fragment.glsl';
import { getMeshBoundingBox } from '@loaders.gl/schema';

function validateGeometryAttributes(attributes, useMeshColors) {
  var hasColorAttribute = attributes.COLOR_0 || attributes.colors;
  var useColorAttribute = hasColorAttribute && useMeshColors;

  if (!useColorAttribute) {
    attributes.colors = {
      constant: true,
      value: new Float32Array([1, 1, 1])
    };
  }

  log.assert(attributes.positions || attributes.POSITION, 'no "postions" or "POSITION" attribute in mesh');
}

function getGeometry(data, useMeshColors) {
  if (data.attributes) {
    validateGeometryAttributes(data.attributes, useMeshColors);

    if (data instanceof Geometry) {
      return data;
    } else {
      return new Geometry(data);
    }
  } else if (data.positions || data.POSITION) {
    validateGeometryAttributes(data, useMeshColors);
    return new Geometry({
      attributes: data
    });
  }

  throw Error('Invalid mesh');
}

var DEFAULT_COLOR = [0, 0, 0, 255];
var defaultProps = {
  mesh: {
    type: 'object',
    value: null,
    async: true
  },
  texture: {
    type: 'image',
    value: null,
    async: true
  },
  sizeScale: {
    type: 'number',
    value: 1,
    min: 0
  },
  _useMeshColors: {
    type: 'boolean',
    value: false
  },
  _instanced: true,
  wireframe: false,
  material: true,
  getPosition: {
    type: 'accessor',
    value: function value(x) {
      return x.position;
    }
  },
  getColor: {
    type: 'accessor',
    value: DEFAULT_COLOR
  },
  getOrientation: {
    type: 'accessor',
    value: [0, 0, 0]
  },
  getScale: {
    type: 'accessor',
    value: [1, 1, 1]
  },
  getTranslation: {
    type: 'accessor',
    value: [0, 0, 0]
  },
  getTransformMatrix: {
    type: 'accessor',
    value: []
  },
  textureParameters: {
    type: 'object',
    ignore: true
  }
};

var SimpleMeshLayer = function (_Layer) {
  _inherits(SimpleMeshLayer, _Layer);

  var _super = _createSuper(SimpleMeshLayer);

  function SimpleMeshLayer() {
    var _this;

    _classCallCheck(this, SimpleMeshLayer);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));

    _defineProperty(_assertThisInitialized(_this), "state", void 0);

    return _this;
  }

  _createClass(SimpleMeshLayer, [{
    key: "getShaders",
    value: function getShaders() {
      var transpileToGLSL100 = !isWebGL2(this.context.gl);
      var defines = {};

      if (hasFeature(this.context.gl, FEATURES.GLSL_DERIVATIVES)) {
        defines.DERIVATIVES_AVAILABLE = 1;
      }

      return _get(_getPrototypeOf(SimpleMeshLayer.prototype), "getShaders", this).call(this, {
        vs: vs,
        fs: fs,
        modules: [project32, phongLighting, picking],
        transpileToGLSL100: transpileToGLSL100,
        defines: defines
      });
    }
  }, {
    key: "getBounds",
    value: function getBounds() {
      var _mesh$header;

      if (this.props._instanced) {
        return _get(_getPrototypeOf(SimpleMeshLayer.prototype), "getBounds", this).call(this);
      }

      var result = this.state.positionBounds;

      if (result) {
        return result;
      }

      var mesh = this.props.mesh;

      if (!mesh) {
        return null;
      }

      result = (_mesh$header = mesh.header) === null || _mesh$header === void 0 ? void 0 : _mesh$header.boundingBox;

      if (!result) {
        var _getGeometry = getGeometry(mesh, this.props._useMeshColors),
            attributes = _getGeometry.attributes;

        attributes.POSITION = attributes.POSITION || attributes.positions;
        result = getMeshBoundingBox(attributes);
      }

      this.state.positionBounds = result;
      return result;
    }
  }, {
    key: "initializeState",
    value: function initializeState() {
      var attributeManager = this.getAttributeManager();
      attributeManager.addInstanced({
        instancePositions: {
          transition: true,
          type: 5130,
          fp64: this.use64bitPositions(),
          size: 3,
          accessor: 'getPosition'
        },
        instanceColors: {
          type: 5121,
          transition: true,
          size: this.props.colorFormat.length,
          normalized: true,
          accessor: 'getColor',
          defaultValue: [0, 0, 0, 255]
        },
        instanceModelMatrix: MATRIX_ATTRIBUTES
      });
      this.setState({
        emptyTexture: new Texture2D(this.context.gl, {
          data: new Uint8Array(4),
          width: 1,
          height: 1
        })
      });
    }
  }, {
    key: "updateState",
    value: function updateState(params) {
      _get(_getPrototypeOf(SimpleMeshLayer.prototype), "updateState", this).call(this, params);

      var props = params.props,
          oldProps = params.oldProps,
          changeFlags = params.changeFlags;

      if (props.mesh !== oldProps.mesh || changeFlags.extensionsChanged) {
        var _this$state$model;

        this.state.positionBounds = null;
        (_this$state$model = this.state.model) === null || _this$state$model === void 0 ? void 0 : _this$state$model.delete();

        if (props.mesh) {
          this.state.model = this.getModel(props.mesh);
          var attributes = props.mesh.attributes || props.mesh;
          this.setState({
            hasNormals: Boolean(attributes.NORMAL || attributes.normals)
          });
        }

        this.getAttributeManager().invalidateAll();
      }

      if (props.texture !== oldProps.texture) {
        this.setTexture(props.texture);
      }

      if (this.state.model) {
        this.state.model.setDrawMode(this.props.wireframe ? 3 : 4);
      }
    }
  }, {
    key: "finalizeState",
    value: function finalizeState(context) {
      _get(_getPrototypeOf(SimpleMeshLayer.prototype), "finalizeState", this).call(this, context);

      this.state.emptyTexture.delete();
    }
  }, {
    key: "draw",
    value: function draw(_ref) {
      var uniforms = _ref.uniforms;

      if (!this.state.model) {
        return;
      }

      var viewport = this.context.viewport;
      var _this$props = this.props,
          sizeScale = _this$props.sizeScale,
          coordinateSystem = _this$props.coordinateSystem,
          _instanced = _this$props._instanced;
      this.state.model.setUniforms(uniforms).setUniforms({
        sizeScale: sizeScale,
        composeModelMatrix: !_instanced || shouldComposeModelMatrix(viewport, coordinateSystem),
        flatShading: !this.state.hasNormals
      }).draw();
    }
  }, {
    key: "isLoaded",
    get: function get() {
      var _this$state;

      return ((_this$state = this.state) === null || _this$state === void 0 ? void 0 : _this$state.model) && _get(_getPrototypeOf(SimpleMeshLayer.prototype), "isLoaded", this);
    }
  }, {
    key: "getModel",
    value: function getModel(mesh) {
      var model = new Model(this.context.gl, _objectSpread(_objectSpread({}, this.getShaders()), {}, {
        id: this.props.id,
        geometry: getGeometry(mesh, this.props._useMeshColors),
        isInstanced: true
      }));
      var texture = this.props.texture;
      var emptyTexture = this.state.emptyTexture;
      model.setUniforms({
        sampler: texture || emptyTexture,
        hasTexture: Boolean(texture)
      });
      return model;
    }
  }, {
    key: "setTexture",
    value: function setTexture(texture) {
      var _this$state2 = this.state,
          emptyTexture = _this$state2.emptyTexture,
          model = _this$state2.model;

      if (model) {
        model.setUniforms({
          sampler: texture || emptyTexture,
          hasTexture: Boolean(texture)
        });
      }
    }
  }]);

  return SimpleMeshLayer;
}(Layer);

_defineProperty(SimpleMeshLayer, "defaultProps", defaultProps);

_defineProperty(SimpleMeshLayer, "layerName", 'SimpleMeshLayer');

export { SimpleMeshLayer as default };