import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import log from '../../utils/log';
var NO_PICKED_OBJECT = {
  pickedColor: null,
  pickedObjectIndex: -1
};
export function getClosestObject(_ref) {
  var pickedColors = _ref.pickedColors,
      decodePickingColor = _ref.decodePickingColor,
      deviceX = _ref.deviceX,
      deviceY = _ref.deviceY,
      deviceRadius = _ref.deviceRadius,
      deviceRect = _ref.deviceRect;
  var x = deviceRect.x,
      y = deviceRect.y,
      width = deviceRect.width,
      height = deviceRect.height;
  var minSquareDistanceToCenter = deviceRadius * deviceRadius;
  var closestPixelIndex = -1;
  var i = 0;

  for (var row = 0; row < height; row++) {
    var dy = row + y - deviceY;
    var dy2 = dy * dy;

    if (dy2 > minSquareDistanceToCenter) {
      i += 4 * width;
    } else {
      for (var col = 0; col < width; col++) {
        var pickedLayerIndex = pickedColors[i + 3] - 1;

        if (pickedLayerIndex >= 0) {
          var dx = col + x - deviceX;
          var d2 = dx * dx + dy2;

          if (d2 <= minSquareDistanceToCenter) {
            minSquareDistanceToCenter = d2;
            closestPixelIndex = i;
          }
        }

        i += 4;
      }
    }
  }

  if (closestPixelIndex >= 0) {
    var pickedColor = pickedColors.slice(closestPixelIndex, closestPixelIndex + 4);
    var pickedObject = decodePickingColor(pickedColor);

    if (pickedObject) {
      var _dy = Math.floor(closestPixelIndex / 4 / width);

      var _dx = closestPixelIndex / 4 - _dy * width;

      return _objectSpread(_objectSpread({}, pickedObject), {}, {
        pickedColor: pickedColor,
        pickedX: x + _dx,
        pickedY: y + _dy
      });
    }

    log.error('Picked non-existent layer. Is picking buffer corrupt?')();
  }

  return NO_PICKED_OBJECT;
}
export function getUniqueObjects(_ref2) {
  var pickedColors = _ref2.pickedColors,
      decodePickingColor = _ref2.decodePickingColor;
  var uniqueColors = new Map();

  if (pickedColors) {
    for (var i = 0; i < pickedColors.length; i += 4) {
      var pickedLayerIndex = pickedColors[i + 3] - 1;

      if (pickedLayerIndex >= 0) {
        var pickedColor = pickedColors.slice(i, i + 4);
        var colorKey = pickedColor.join(',');

        if (!uniqueColors.has(colorKey)) {
          var pickedObject = decodePickingColor(pickedColor);

          if (pickedObject) {
            uniqueColors.set(colorKey, _objectSpread(_objectSpread({}, pickedObject), {}, {
              color: pickedColor
            }));
          } else {
            log.error('Picked non-existent layer. Is picking buffer corrupt?')();
          }
        }
      }
    }
  }

  return Array.from(uniqueColors.values());
}