import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _assertThisInitialized from "@babel/runtime/helpers/assertThisInitialized";
import _inherits from "@babel/runtime/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

import Viewport from '../viewports/viewport';
import { getMeterZoom } from '@math.gl/web-mercator';
import { Matrix4, _SphericalCoordinates as SphericalCoordinates } from '@math.gl/core';

var FirstPersonViewport = function (_Viewport) {
  _inherits(FirstPersonViewport, _Viewport);

  var _super = _createSuper(FirstPersonViewport);

  function FirstPersonViewport(props) {
    var _this;

    _classCallCheck(this, FirstPersonViewport);

    var longitude = props.longitude,
        latitude = props.latitude,
        modelMatrix = props.modelMatrix,
        _props$bearing = props.bearing,
        bearing = _props$bearing === void 0 ? 0 : _props$bearing,
        _props$pitch = props.pitch,
        pitch = _props$pitch === void 0 ? 0 : _props$pitch,
        _props$up = props.up,
        up = _props$up === void 0 ? [0, 0, 1] : _props$up;
    var spherical = new SphericalCoordinates({
      bearing: bearing,
      pitch: pitch === -90 ? 0.0001 : 90 + pitch
    });
    var dir = spherical.toVector3().normalize();
    var center = modelMatrix ? new Matrix4(modelMatrix).transformAsVector(dir) : dir;
    var zoom = Number.isFinite(latitude) ? getMeterZoom({
      latitude: latitude
    }) : 0;
    var scale = Math.pow(2, zoom);
    var viewMatrix = new Matrix4().lookAt({
      eye: [0, 0, 0],
      center: center,
      up: up
    }).scale(scale);
    _this = _super.call(this, _objectSpread(_objectSpread({}, props), {}, {
      zoom: zoom,
      viewMatrix: viewMatrix
    }));

    _defineProperty(_assertThisInitialized(_this), "longitude", void 0);

    _defineProperty(_assertThisInitialized(_this), "latitude", void 0);

    _this.latitude = latitude;
    _this.longitude = longitude;
    return _this;
  }

  return FirstPersonViewport;
}(Viewport);

export { FirstPersonViewport as default };