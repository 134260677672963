export var LIFECYCLE = {
  NO_STATE: 'Awaiting state',
  MATCHED: 'Matched. State transferred from previous layer',
  INITIALIZED: 'Initialized',
  AWAITING_GC: 'Discarded. Awaiting garbage collection',
  AWAITING_FINALIZATION: 'No longer matched. Awaiting garbage collection',
  FINALIZED: 'Finalized! Awaiting garbage collection'
};
export var COMPONENT_SYMBOL = Symbol.for('component');
export var PROP_TYPES_SYMBOL = Symbol.for('propTypes');
export var DEPRECATED_PROPS_SYMBOL = Symbol.for('deprecatedProps');
export var ASYNC_DEFAULTS_SYMBOL = Symbol.for('asyncPropDefaults');
export var ASYNC_ORIGINAL_SYMBOL = Symbol.for('asyncPropOriginal');
export var ASYNC_RESOLVED_SYMBOL = Symbol.for('asyncPropResolved');