function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import { log } from '@deck.gl/core';
export function getScale(domain, range, scaleFunction) {
  var scale = scaleFunction;

  scale.domain = function () {
    return domain;
  };

  scale.range = function () {
    return range;
  };

  return scale;
}
export function getQuantizeScale(domain, range) {
  var scaleFunction = function scaleFunction(value) {
    return quantizeScale(domain, range, value);
  };

  return getScale(domain, range, scaleFunction);
}
export function getLinearScale(domain, range) {
  var scaleFunction = function scaleFunction(value) {
    return linearScale(domain, range, value);
  };

  return getScale(domain, range, scaleFunction);
}
export function getQuantileScale(domain, range) {
  var sortedDomain = domain.sort(ascending);
  var i = 0;
  var n = Math.max(1, range.length);
  var thresholds = new Array(n - 1);

  while (++i < n) {
    thresholds[i - 1] = threshold(sortedDomain, i / n);
  }

  var scaleFunction = function scaleFunction(value) {
    return thresholdsScale(thresholds, range, value);
  };

  scaleFunction.thresholds = function () {
    return thresholds;
  };

  return getScale(domain, range, scaleFunction);
}

function ascending(a, b) {
  return a - b;
}

function threshold(domain, fraction) {
  var domainLength = domain.length;

  if (fraction <= 0 || domainLength < 2) {
    return domain[0];
  }

  if (fraction >= 1) {
    return domain[domainLength - 1];
  }

  var domainFraction = (domainLength - 1) * fraction;
  var lowIndex = Math.floor(domainFraction);
  var low = domain[lowIndex];
  var high = domain[lowIndex + 1];
  return low + (high - low) * (domainFraction - lowIndex);
}

function bisectRight(a, x) {
  var lo = 0;
  var hi = a.length;

  while (lo < hi) {
    var mid = lo + hi >>> 1;

    if (ascending(a[mid], x) > 0) {
      hi = mid;
    } else {
      lo = mid + 1;
    }
  }

  return lo;
}

function thresholdsScale(thresholds, range, value) {
  return range[bisectRight(thresholds, value)];
}

function ordinalScale(domain, domainMap, range, value) {
  var key = "".concat(value);
  var d = domainMap.get(key);

  if (d === undefined) {
    d = domain.push(value);
    domainMap.set(key, d);
  }

  return range[(d - 1) % range.length];
}

export function getOrdinalScale(domain, range) {
  var domainMap = new Map();
  var uniqueDomain = [];

  var _iterator = _createForOfIteratorHelper(domain),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var d = _step.value;
      var key = "".concat(d);

      if (!domainMap.has(key)) {
        domainMap.set(key, uniqueDomain.push(d));
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  var scaleFunction = function scaleFunction(value) {
    return ordinalScale(uniqueDomain, domainMap, range, value);
  };

  return getScale(domain, range, scaleFunction);
}
export function quantizeScale(domain, range, value) {
  var domainRange = domain[1] - domain[0];

  if (domainRange <= 0) {
    log.warn('quantizeScale: invalid domain, returning range[0]')();
    return range[0];
  }

  var step = domainRange / range.length;
  var idx = Math.floor((value - domain[0]) / step);
  var clampIdx = Math.max(Math.min(idx, range.length - 1), 0);
  return range[clampIdx];
}
export function linearScale(domain, range, value) {
  return (value - domain[0]) / (domain[1] - domain[0]) * (range[1] - range[0]) + range[0];
}

function notNullOrUndefined(d) {
  return d !== undefined && d !== null;
}

export function unique(values) {
  var results = [];
  values.forEach(function (v) {
    if (!results.includes(v) && notNullOrUndefined(v)) {
      results.push(v);
    }
  });
  return results;
}

function getTruthyValues(data, valueAccessor) {
  var values = typeof valueAccessor === 'function' ? data.map(valueAccessor) : data;
  return values.filter(notNullOrUndefined);
}

export function getLinearDomain(data, valueAccessor) {
  var sorted = getTruthyValues(data, valueAccessor).sort();
  return sorted.length ? [sorted[0], sorted[sorted.length - 1]] : [0, 0];
}
export function getQuantileDomain(data, valueAccessor) {
  return getTruthyValues(data, valueAccessor);
}
export function getOrdinalDomain(data, valueAccessor) {
  return unique(getTruthyValues(data, valueAccessor));
}
export function getScaleDomain(scaleType, data, valueAccessor) {
  switch (scaleType) {
    case 'quantize':
    case 'linear':
      return getLinearDomain(data, valueAccessor);

    case 'quantile':
      return getQuantileDomain(data, valueAccessor);

    case 'ordinal':
      return getOrdinalDomain(data, valueAccessor);

    default:
      return getLinearDomain(data, valueAccessor);
  }
}
export function clamp(value, min, max) {
  return Math.max(min, Math.min(max, value));
}
export function getScaleFunctionByScaleType(scaleType) {
  switch (scaleType) {
    case 'quantize':
      return getQuantizeScale;

    case 'linear':
      return getLinearScale;

    case 'quantile':
      return getQuantileScale;

    case 'ordinal':
      return getOrdinalScale;

    default:
      return getQuantizeScale;
  }
}