var scriptLoadPromises = {};
export function loadScript(url) {
  if (!scriptLoadPromises[url]) {
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    var head = document.querySelector('head');
    head.appendChild(script);
    scriptLoadPromises[url] = new Promise(function (resolve) {
      script.onload = resolve;
    });
  }

  return scriptLoadPromises[url];
}