import deckLog from '../utils/log';
import { getLoggers } from './loggers';
var loggers = {};

if (process.env.NODE_ENV !== 'production') {
  loggers = getLoggers(deckLog);
}

export function register(handlers) {
  loggers = handlers;
}
export default function debug(eventType, arg1, arg2, arg3) {
  if (deckLog.level > 0 && loggers[eventType]) {
    loggers[eventType].call(null, arg1, arg2, arg3);
  }
}