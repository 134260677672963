import { Layer } from '@deck.gl/core';
import { Transport } from '@deck.gl/json';
import { createContainer } from './utils/css-utils';
import { loadMapboxCSS } from './utils/mapbox-utils';
import { jsonConverter, createDeck } from './create-deck';
export function initPlayground() {
  Transport.setCallbacks({
    onInitialize: function onInitialize(_ref) {
      var transport = _ref.transport;

      var _getPlaygroundProps = getPlaygroundProps(transport),
          width = _getPlaygroundProps.width,
          height = _getPlaygroundProps.height,
          customLibraries = _getPlaygroundProps.customLibraries,
          mapboxApiKey = _getPlaygroundProps.mapboxApiKey,
          jsonInput = _getPlaygroundProps.jsonInput,
          tooltip = _getPlaygroundProps.tooltip;

      loadMapboxCSS();
      var transportRootElement = transport.getRootDOMElement();
      var deckContainer = createContainer(width, height);
      transportRootElement.appendChild(deckContainer);
      var jsonProps = JSON.parse(jsonInput);
      var deck = createDeck({
        mapboxApiKey: mapboxApiKey,
        container: deckContainer,
        jsonInput: jsonProps,
        tooltip: tooltip,
        handleEvent: function handleEvent(name, payload) {
          return sendEventViaTransport(transport, name, payload);
        },
        customLibraries: customLibraries
      });
      transport.userData.deck = deck;
    },
    onFinalize: function onFinalize(_ref2) {
      var transport = _ref2.transport;
      var deck = transport.userData.deck;
      deck.finalize();
    },
    onMessage: function onMessage(_ref3) {
      var transport = _ref3.transport,
          type = _ref3.type,
          json = _ref3.json,
          binary = _ref3.binary;
      var deck = transport.userData.deck;
      var convertedJson;

      switch (type) {
        case 'json':
          convertedJson = jsonConverter.convert(json);
          deck.setProps(convertedJson);
          break;

        case 'json-with-binary':
          convertedJson = jsonConverter.convert(json);
          var binaryData = transport.jupyterModel.get('data_buffer');
          var propsWithBinary = processDataBuffer({
            binary: binaryData,
            convertedJson: convertedJson
          });
          deck.setProps(propsWithBinary);
          break;

        default:
      }
    }
  });
}
export function processDataBuffer(_ref4) {
  var binary = _ref4.binary,
      convertedJson = _ref4.convertedJson;

  for (var i = 0; i < convertedJson.layers.length; i++) {
    var layerId = convertedJson.layers[i].id;
    var layer = convertedJson.layers[i];
    convertedJson.layers[i] = layer.clone({
      data: binary[layerId]
    });
  }

  return convertedJson;
}

function filterJsonValue(key, value) {
  return value instanceof Layer ? value.id : value;
}

function sendEventViaTransport(transport, eventName, data) {
  if (eventName === 'hover' && !data.picked && data.index === -1) {
    return;
  }

  var deckEvent = JSON.parse(JSON.stringify(data, filterJsonValue));
  transport.sendJSONMessage(eventName, deckEvent);
}

function getPlaygroundProps(transport) {
  var jupyterModel = transport.jupyterModel;

  if (!jupyterModel) {
    throw new Error('deck.gl playground currently only works with the Jupyter Widget Transport');
  }

  return {
    width: jupyterModel.get('width'),
    height: jupyterModel.get('height'),
    customLibraries: jupyterModel.get('custom_libraries'),
    mapboxApiKey: jupyterModel.get('mapbox_key'),
    jsonInput: jupyterModel.get('json_input'),
    tooltip: jupyterModel.get('tooltip')
  };
}