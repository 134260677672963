import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
var DEFAULT_LIGHT_COLOR = [255, 255, 255];
var DEFAULT_LIGHT_INTENSITY = 1.0;
var idCount = 0;
export var AmbientLight = function AmbientLight() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  _classCallCheck(this, AmbientLight);

  _defineProperty(this, "id", void 0);

  _defineProperty(this, "color", void 0);

  _defineProperty(this, "intensity", void 0);

  _defineProperty(this, "type", 'ambient');

  var _props$color = props.color,
      color = _props$color === void 0 ? DEFAULT_LIGHT_COLOR : _props$color;
  var _props$intensity = props.intensity,
      intensity = _props$intensity === void 0 ? DEFAULT_LIGHT_INTENSITY : _props$intensity;
  this.id = props.id || "ambient-".concat(idCount++);
  this.color = color;
  this.intensity = intensity;
};