import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import Transition from '../transitions/transition';

var noop = function noop() {};

export var TRANSITION_EVENTS = {
  BREAK: 1,
  SNAP_TO_END: 2,
  IGNORE: 3
};

var DEFAULT_EASING = function DEFAULT_EASING(t) {
  return t;
};

var DEFAULT_INTERRUPTION = TRANSITION_EVENTS.BREAK;

var TransitionManager = function () {
  function TransitionManager(opts) {
    var _this = this;

    _classCallCheck(this, TransitionManager);

    _defineProperty(this, "getControllerState", void 0);

    _defineProperty(this, "props", void 0);

    _defineProperty(this, "propsInTransition", void 0);

    _defineProperty(this, "transition", void 0);

    _defineProperty(this, "onViewStateChange", void 0);

    _defineProperty(this, "onStateChange", void 0);

    _defineProperty(this, "_onTransitionUpdate", function (transition) {
      var time = transition.time,
          _transition$settings = transition.settings,
          interpolator = _transition$settings.interpolator,
          startProps = _transition$settings.startProps,
          endProps = _transition$settings.endProps,
          duration = _transition$settings.duration,
          easing = _transition$settings.easing;
      var t = easing(time / duration);
      var viewport = interpolator.interpolateProps(startProps, endProps, t);
      _this.propsInTransition = _this.getControllerState(_objectSpread(_objectSpread({}, _this.props), viewport)).getViewportProps();

      _this.onViewStateChange({
        viewState: _this.propsInTransition,
        oldViewState: _this.props
      });
    });

    this.getControllerState = opts.getControllerState;
    this.propsInTransition = null;
    this.transition = new Transition(opts.timeline);
    this.onViewStateChange = opts.onViewStateChange || noop;
    this.onStateChange = opts.onStateChange || noop;
  }

  _createClass(TransitionManager, [{
    key: "finalize",
    value: function finalize() {
      this.transition.cancel();
    }
  }, {
    key: "getViewportInTransition",
    value: function getViewportInTransition() {
      return this.propsInTransition;
    }
  }, {
    key: "processViewStateChange",
    value: function processViewStateChange(nextProps) {
      var transitionTriggered = false;
      var currentProps = this.props;
      this.props = nextProps;

      if (!currentProps || this._shouldIgnoreViewportChange(currentProps, nextProps)) {
        return false;
      }

      if (this._isTransitionEnabled(nextProps)) {
        var startProps = currentProps;

        if (this.transition.inProgress) {
          var _ref = this.transition.settings,
              interruption = _ref.interruption,
              endProps = _ref.endProps;
          startProps = _objectSpread(_objectSpread({}, currentProps), interruption === TRANSITION_EVENTS.SNAP_TO_END ? endProps : this.propsInTransition || currentProps);
        }

        this._triggerTransition(startProps, nextProps);

        transitionTriggered = true;
      } else {
        this.transition.cancel();
      }

      return transitionTriggered;
    }
  }, {
    key: "updateTransition",
    value: function updateTransition() {
      this.transition.update();
    }
  }, {
    key: "_isTransitionEnabled",
    value: function _isTransitionEnabled(props) {
      var transitionDuration = props.transitionDuration,
          transitionInterpolator = props.transitionInterpolator;
      return (transitionDuration > 0 || transitionDuration === 'auto') && Boolean(transitionInterpolator);
    }
  }, {
    key: "_isUpdateDueToCurrentTransition",
    value: function _isUpdateDueToCurrentTransition(props) {
      if (this.transition.inProgress && this.propsInTransition) {
        return this.transition.settings.interpolator.arePropsEqual(props, this.propsInTransition);
      }

      return false;
    }
  }, {
    key: "_shouldIgnoreViewportChange",
    value: function _shouldIgnoreViewportChange(currentProps, nextProps) {
      if (this.transition.inProgress) {
        return this.transition.settings.interruption === TRANSITION_EVENTS.IGNORE || this._isUpdateDueToCurrentTransition(nextProps);
      }

      if (this._isTransitionEnabled(nextProps)) {
        return nextProps.transitionInterpolator.arePropsEqual(currentProps, nextProps);
      }

      return true;
    }
  }, {
    key: "_triggerTransition",
    value: function _triggerTransition(startProps, endProps) {
      var startViewstate = this.getControllerState(startProps);
      var endViewStateProps = this.getControllerState(endProps).shortestPathFrom(startViewstate);
      var transitionInterpolator = endProps.transitionInterpolator;
      var duration = transitionInterpolator.getDuration ? transitionInterpolator.getDuration(startProps, endProps) : endProps.transitionDuration;

      if (duration === 0) {
        return;
      }

      var initialProps = transitionInterpolator.initializeProps(startProps, endViewStateProps);
      this.propsInTransition = {};
      var transitionSettings = {
        duration: duration,
        easing: endProps.transitionEasing || DEFAULT_EASING,
        interpolator: transitionInterpolator,
        interruption: endProps.transitionInterruption || DEFAULT_INTERRUPTION,
        startProps: initialProps.start,
        endProps: initialProps.end,
        onStart: endProps.onTransitionStart,
        onUpdate: this._onTransitionUpdate,
        onInterrupt: this._onTransitionEnd(endProps.onTransitionInterrupt),
        onEnd: this._onTransitionEnd(endProps.onTransitionEnd)
      };
      this.transition.start(transitionSettings);
      this.onStateChange({
        inTransition: true
      });
      this.updateTransition();
    }
  }, {
    key: "_onTransitionEnd",
    value: function _onTransitionEnd(callback) {
      var _this2 = this;

      return function (transition) {
        _this2.propsInTransition = null;

        _this2.onStateChange({
          inTransition: false,
          isZooming: false,
          isPanning: false,
          isRotating: false
        });

        callback === null || callback === void 0 ? void 0 : callback(transition);
      };
    }
  }]);

  return TransitionManager;
}();

export { TransitionManager as default };