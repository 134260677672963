import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { registerLoaders } from '@loaders.gl/core';
import { ImageLoader } from '@loaders.gl/images';
import log from '../utils/log';
import { register } from '../debug';
import jsonLoader from '../utils/json-loader';

function checkVersion() {
  var version = typeof "8.9.36" !== 'undefined' ? "8.9.36" : globalThis.DECK_VERSION || 'untranspiled source';
  var existingVersion = globalThis.deck && globalThis.deck.VERSION;

  if (existingVersion && existingVersion !== version) {
    throw new Error("deck.gl - multiple versions detected: ".concat(existingVersion, " vs ").concat(version));
  }

  if (!existingVersion) {
    log.log(1, "deck.gl ".concat(version))();
    globalThis.deck = _objectSpread(_objectSpread({}, globalThis.deck), {}, {
      VERSION: version,
      version: version,
      log: log,
      _registerLoggers: register
    });
    registerLoaders([jsonLoader, [ImageLoader, {
      imagebitmap: {
        premultiplyAlpha: 'none'
      }
    }]]);
  }

  return version;
}

export var VERSION = checkVersion();