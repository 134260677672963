import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _assertThisInitialized from "@babel/runtime/helpers/assertThisInitialized";
import _get from "@babel/runtime/helpers/get";
import _inherits from "@babel/runtime/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

import DataColumn from './data-column';
import assert from '../../utils/assert';
import { createIterable, getAccessorFromBuffer } from '../../utils/iterable-utils';
import { fillArray } from '../../utils/flatten';
import * as range from '../../utils/range';
import { normalizeTransitionSettings } from './attribute-transition-utils';

var Attribute = function (_DataColumn) {
  _inherits(Attribute, _DataColumn);

  var _super = _createSuper(Attribute);

  function Attribute(gl, opts) {
    var _this;

    _classCallCheck(this, Attribute);

    _this = _super.call(this, gl, opts, {
      startIndices: null,
      lastExternalBuffer: null,
      binaryValue: null,
      binaryAccessor: null,
      needsUpdate: true,
      needsRedraw: false,
      updateRanges: range.FULL
    });

    _defineProperty(_assertThisInitialized(_this), "constant", false);

    _this.settings.update = opts.update || (opts.accessor ? _this._autoUpdater : undefined);
    Object.seal(_this.settings);
    Object.seal(_this.state);

    _this._validateAttributeUpdaters();

    return _this;
  }

  _createClass(Attribute, [{
    key: "startIndices",
    get: function get() {
      return this.state.startIndices;
    },
    set: function set(layout) {
      this.state.startIndices = layout;
    }
  }, {
    key: "needsUpdate",
    value: function needsUpdate() {
      return this.state.needsUpdate;
    }
  }, {
    key: "needsRedraw",
    value: function needsRedraw() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          _ref$clearChangedFlag = _ref.clearChangedFlags,
          clearChangedFlags = _ref$clearChangedFlag === void 0 ? false : _ref$clearChangedFlag;

      var needsRedraw = this.state.needsRedraw;
      this.state.needsRedraw = needsRedraw && !clearChangedFlags;
      return needsRedraw;
    }
  }, {
    key: "getUpdateTriggers",
    value: function getUpdateTriggers() {
      var accessor = this.settings.accessor;
      return [this.id].concat(typeof accessor !== 'function' && accessor || []);
    }
  }, {
    key: "supportsTransition",
    value: function supportsTransition() {
      return Boolean(this.settings.transition);
    }
  }, {
    key: "getTransitionSetting",
    value: function getTransitionSetting(opts) {
      if (!opts || !this.supportsTransition()) {
        return null;
      }

      var accessor = this.settings.accessor;
      var layerSettings = this.settings.transition;
      var userSettings = Array.isArray(accessor) ? opts[accessor.find(function (a) {
        return opts[a];
      })] : opts[accessor];
      return normalizeTransitionSettings(userSettings, layerSettings);
    }
  }, {
    key: "setNeedsUpdate",
    value: function setNeedsUpdate() {
      var reason = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.id;
      var dataRange = arguments.length > 1 ? arguments[1] : undefined;
      this.state.needsUpdate = this.state.needsUpdate || reason;
      this.setNeedsRedraw(reason);

      if (dataRange) {
        var _dataRange$startRow = dataRange.startRow,
            startRow = _dataRange$startRow === void 0 ? 0 : _dataRange$startRow,
            _dataRange$endRow = dataRange.endRow,
            endRow = _dataRange$endRow === void 0 ? Infinity : _dataRange$endRow;
        this.state.updateRanges = range.add(this.state.updateRanges, [startRow, endRow]);
      } else {
        this.state.updateRanges = range.FULL;
      }
    }
  }, {
    key: "clearNeedsUpdate",
    value: function clearNeedsUpdate() {
      this.state.needsUpdate = false;
      this.state.updateRanges = range.EMPTY;
    }
  }, {
    key: "setNeedsRedraw",
    value: function setNeedsRedraw() {
      var reason = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.id;
      this.state.needsRedraw = this.state.needsRedraw || reason;
    }
  }, {
    key: "allocate",
    value: function allocate(numInstances) {
      var state = this.state,
          settings = this.settings;

      if (settings.noAlloc) {
        return false;
      }

      if (settings.update) {
        _get(_getPrototypeOf(Attribute.prototype), "allocate", this).call(this, numInstances, state.updateRanges !== range.FULL);

        return true;
      }

      return false;
    }
  }, {
    key: "updateBuffer",
    value: function updateBuffer(_ref2) {
      var numInstances = _ref2.numInstances,
          data = _ref2.data,
          props = _ref2.props,
          context = _ref2.context;

      if (!this.needsUpdate()) {
        return false;
      }

      var updateRanges = this.state.updateRanges,
          _this$settings = this.settings,
          update = _this$settings.update,
          noAlloc = _this$settings.noAlloc;
      var updated = true;

      if (update) {
        var _iterator = _createForOfIteratorHelper(updateRanges),
            _step;

        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var _step$value = _slicedToArray(_step.value, 2),
                _startRow = _step$value[0],
                _endRow = _step$value[1];

            update.call(context, this, {
              data: data,
              startRow: _startRow,
              endRow: _endRow,
              props: props,
              numInstances: numInstances
            });
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }

        if (!this.value) {} else if (this.constant || this.buffer.byteLength < this.value.byteLength + this.byteOffset) {
          this.setData({
            value: this.value,
            constant: this.constant
          });
          this.constant = false;
        } else {
          var _iterator2 = _createForOfIteratorHelper(updateRanges),
              _step2;

          try {
            for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
              var _step2$value = _slicedToArray(_step2.value, 2),
                  startRow = _step2$value[0],
                  endRow = _step2$value[1];

              var startOffset = Number.isFinite(startRow) ? this.getVertexOffset(startRow) : 0;
              var endOffset = Number.isFinite(endRow) ? this.getVertexOffset(endRow) : noAlloc || !Number.isFinite(numInstances) ? this.value.length : numInstances * this.size;

              _get(_getPrototypeOf(Attribute.prototype), "updateSubBuffer", this).call(this, {
                startOffset: startOffset,
                endOffset: endOffset
              });
            }
          } catch (err) {
            _iterator2.e(err);
          } finally {
            _iterator2.f();
          }
        }

        this._checkAttributeArray();
      } else {
        updated = false;
      }

      this.clearNeedsUpdate();
      this.setNeedsRedraw();
      return updated;
    }
  }, {
    key: "setConstantValue",
    value: function setConstantValue(value) {
      if (value === undefined || typeof value === 'function') {
        return false;
      }

      var hasChanged = this.setData({
        constant: true,
        value: value
      });

      if (hasChanged) {
        this.setNeedsRedraw();
      }

      this.clearNeedsUpdate();
      return true;
    }
  }, {
    key: "setExternalBuffer",
    value: function setExternalBuffer(buffer) {
      var state = this.state;

      if (!buffer) {
        state.lastExternalBuffer = null;
        return false;
      }

      this.clearNeedsUpdate();

      if (state.lastExternalBuffer === buffer) {
        return true;
      }

      state.lastExternalBuffer = buffer;
      this.setNeedsRedraw();
      this.setData(buffer);
      return true;
    }
  }, {
    key: "setBinaryValue",
    value: function setBinaryValue(buffer) {
      var startIndices = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var state = this.state,
          settings = this.settings;

      if (!buffer) {
        state.binaryValue = null;
        state.binaryAccessor = null;
        return false;
      }

      if (settings.noAlloc) {
        return false;
      }

      if (state.binaryValue === buffer) {
        this.clearNeedsUpdate();
        return true;
      }

      state.binaryValue = buffer;
      this.setNeedsRedraw();
      var needsUpdate = settings.transform || startIndices !== this.startIndices;

      if (needsUpdate) {
        if (ArrayBuffer.isView(buffer)) {
          buffer = {
            value: buffer
          };
        }

        var binaryValue = buffer;
        assert(ArrayBuffer.isView(binaryValue.value), "invalid ".concat(settings.accessor));
        var needsNormalize = Boolean(binaryValue.size) && binaryValue.size !== this.size;
        state.binaryAccessor = getAccessorFromBuffer(binaryValue.value, {
          size: binaryValue.size || this.size,
          stride: binaryValue.stride,
          offset: binaryValue.offset,
          startIndices: startIndices,
          nested: needsNormalize
        });
        return false;
      }

      this.clearNeedsUpdate();
      this.setData(buffer);
      return true;
    }
  }, {
    key: "getVertexOffset",
    value: function getVertexOffset(row) {
      var startIndices = this.startIndices;
      var vertexIndex = startIndices ? row < startIndices.length ? startIndices[row] : this.numInstances : row;
      return vertexIndex * this.size;
    }
  }, {
    key: "getShaderAttributes",
    value: function getShaderAttributes() {
      var shaderAttributeDefs = this.settings.shaderAttributes || _defineProperty({}, this.id, null);

      var shaderAttributes = {};

      for (var shaderAttributeName in shaderAttributeDefs) {
        Object.assign(shaderAttributes, _get(_getPrototypeOf(Attribute.prototype), "getShaderAttributes", this).call(this, shaderAttributeName, shaderAttributeDefs[shaderAttributeName]));
      }

      return shaderAttributes;
    }
  }, {
    key: "_autoUpdater",
    value: function _autoUpdater(attribute, _ref4) {
      var data = _ref4.data,
          startRow = _ref4.startRow,
          endRow = _ref4.endRow,
          props = _ref4.props,
          numInstances = _ref4.numInstances;

      if (attribute.constant) {
        return;
      }

      var settings = attribute.settings,
          state = attribute.state,
          value = attribute.value,
          size = attribute.size,
          startIndices = attribute.startIndices;
      var accessor = settings.accessor,
          transform = settings.transform;
      var accessorFunc = state.binaryAccessor || (typeof accessor === 'function' ? accessor : props[accessor]);
      assert(typeof accessorFunc === 'function', "accessor \"".concat(accessor, "\" is not a function"));
      var i = attribute.getVertexOffset(startRow);

      var _createIterable = createIterable(data, startRow, endRow),
          iterable = _createIterable.iterable,
          objectInfo = _createIterable.objectInfo;

      var _iterator3 = _createForOfIteratorHelper(iterable),
          _step3;

      try {
        for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
          var _object = _step3.value;
          objectInfo.index++;
          var objectValue = accessorFunc(_object, objectInfo);

          if (transform) {
            objectValue = transform.call(this, objectValue);
          }

          if (startIndices) {
            var numVertices = (objectInfo.index < startIndices.length - 1 ? startIndices[objectInfo.index + 1] : numInstances) - startIndices[objectInfo.index];

            if (objectValue && Array.isArray(objectValue[0])) {
              var startIndex = i;

              var _iterator4 = _createForOfIteratorHelper(objectValue),
                  _step4;

              try {
                for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
                  var item = _step4.value;

                  attribute._normalizeValue(item, value, startIndex);

                  startIndex += size;
                }
              } catch (err) {
                _iterator4.e(err);
              } finally {
                _iterator4.f();
              }
            } else if (objectValue && objectValue.length > size) {
              value.set(objectValue, i);
            } else {
              attribute._normalizeValue(objectValue, objectInfo.target, 0);

              fillArray({
                target: value,
                source: objectInfo.target,
                start: i,
                count: numVertices
              });
            }

            i += numVertices * size;
          } else {
            attribute._normalizeValue(objectValue, value, i);

            i += size;
          }
        }
      } catch (err) {
        _iterator3.e(err);
      } finally {
        _iterator3.f();
      }
    }
  }, {
    key: "_validateAttributeUpdaters",
    value: function _validateAttributeUpdaters() {
      var settings = this.settings;
      var hasUpdater = settings.noAlloc || typeof settings.update === 'function';

      if (!hasUpdater) {
        throw new Error("Attribute ".concat(this.id, " missing update or accessor"));
      }
    }
  }, {
    key: "_checkAttributeArray",
    value: function _checkAttributeArray() {
      var value = this.value;
      var limit = Math.min(4, this.size);

      if (value && value.length >= limit) {
        var valid = true;

        switch (limit) {
          case 4:
            valid = valid && Number.isFinite(value[3]);

          case 3:
            valid = valid && Number.isFinite(value[2]);

          case 2:
            valid = valid && Number.isFinite(value[1]);

          case 1:
            valid = valid && Number.isFinite(value[0]);
            break;

          default:
            valid = false;
        }

        if (!valid) {
          throw new Error("Illegal attribute generated for ".concat(this.id));
        }
      }
    }
  }]);

  return Attribute;
}(DataColumn);

export { Attribute as default };