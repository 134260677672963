import _typeof from "@babel/runtime/helpers/typeof";
import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
var defaultStyle = {
  zIndex: '1',
  position: 'absolute',
  pointerEvents: 'none',
  color: '#a0a7b4',
  backgroundColor: '#29323c',
  padding: '10px',
  top: '0',
  left: '0',
  display: 'none'
};

var Tooltip = function () {
  function Tooltip(canvas) {
    _classCallCheck(this, Tooltip);

    _defineProperty(this, "el", null);

    _defineProperty(this, "isVisible", false);

    var canvasParent = canvas.parentElement;

    if (canvasParent) {
      this.el = document.createElement('div');
      this.el.className = 'deck-tooltip';
      Object.assign(this.el.style, defaultStyle);
      canvasParent.appendChild(this.el);
    }
  }

  _createClass(Tooltip, [{
    key: "setTooltip",
    value: function setTooltip(displayInfo, x, y) {
      var el = this.el;

      if (!el) {
        return;
      }

      if (typeof displayInfo === 'string') {
        el.innerText = displayInfo;
      } else if (!displayInfo) {
        this.isVisible = false;
        el.style.display = 'none';
        return;
      } else {
        if (displayInfo.text) {
          el.innerText = displayInfo.text;
        }

        if (displayInfo.html) {
          el.innerHTML = displayInfo.html;
        }

        if (displayInfo.className) {
          el.className = displayInfo.className;
        }
      }

      this.isVisible = true;
      el.style.display = 'block';
      el.style.transform = "translate(".concat(x, "px, ").concat(y, "px)");

      if (displayInfo && _typeof(displayInfo) === 'object' && 'style' in displayInfo) {
        Object.assign(el.style, displayInfo.style);
      }
    }
  }, {
    key: "remove",
    value: function remove() {
      if (this.el) {
        this.el.remove();
        this.el = null;
      }
    }
  }]);

  return Tooltip;
}();

export { Tooltip as default };