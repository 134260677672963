import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
var _excluded = ["width", "height", "left", "top"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { getParameters, setParameters, withParameters } from '@luma.gl/core';
import { createDeckInstance, destroyDeckInstance, getViewPropsFromOverlay, getViewPropsFromCoordinateTransformer } from './utils';

var HIDE_ALL_LAYERS = function HIDE_ALL_LAYERS() {
  return false;
};

var GL_STATE = {
  depthMask: true,
  depthTest: true,
  blend: true,
  blendFunc: [770, 771, 1, 771],
  blendEquation: 32774
};

function noop() {}

var defaultProps = {
  interleaved: true
};

var GoogleMapsOverlay = function () {
  function GoogleMapsOverlay(props) {
    _classCallCheck(this, GoogleMapsOverlay);

    _defineProperty(this, "props", {});

    _defineProperty(this, "_map", null);

    _defineProperty(this, "_deck", null);

    _defineProperty(this, "_overlay", null);

    this.setProps(_objectSpread(_objectSpread({}, defaultProps), props));
  }

  _createClass(GoogleMapsOverlay, [{
    key: "setMap",
    value: function setMap(map) {
      var _this = this;

      if (map === this._map) {
        return;
      }

      var _google$maps$Renderin = google.maps.RenderingType,
          VECTOR = _google$maps$Renderin.VECTOR,
          UNINITIALIZED = _google$maps$Renderin.UNINITIALIZED;

      if (this._map) {
        var _this$_overlay;

        if (!map && this._map.getRenderingType() === VECTOR && this.props.interleaved) {
          this._overlay.requestRedraw();
        }

        (_this$_overlay = this._overlay) === null || _this$_overlay === void 0 ? void 0 : _this$_overlay.setMap(null);
        this._map = null;
      }

      if (map) {
        this._map = map;
        var renderingType = map.getRenderingType();

        if (renderingType !== UNINITIALIZED) {
          this._createOverlay(map);
        } else {
          map.addListener('renderingtype_changed', function () {
            _this._createOverlay(map);
          });
        }
      }
    }
  }, {
    key: "setProps",
    value: function setProps(props) {
      Object.assign(this.props, props);

      if (this._deck) {
        var canvas = this._deck.getCanvas();

        if (props.style && canvas !== null && canvas !== void 0 && canvas.parentElement) {
          var parentStyle = canvas.parentElement.style;
          Object.assign(parentStyle, props.style);
          props.style = null;
        }

        this._deck.setProps(props);
      }
    }
  }, {
    key: "pickObject",
    value: function pickObject(params) {
      return this._deck && this._deck.pickObject(params);
    }
  }, {
    key: "pickMultipleObjects",
    value: function pickMultipleObjects(params) {
      return this._deck && this._deck.pickMultipleObjects(params);
    }
  }, {
    key: "pickObjects",
    value: function pickObjects(params) {
      return this._deck && this._deck.pickObjects(params);
    }
  }, {
    key: "finalize",
    value: function finalize() {
      this.setMap(null);

      if (this._deck) {
        destroyDeckInstance(this._deck);
        this._deck = null;
      }
    }
  }, {
    key: "_createOverlay",
    value: function _createOverlay(map) {
      var interleaved = this.props.interleaved;
      var _google$maps$Renderin2 = google.maps.RenderingType,
          VECTOR = _google$maps$Renderin2.VECTOR,
          UNINITIALIZED = _google$maps$Renderin2.UNINITIALIZED;
      var renderingType = map.getRenderingType();

      if (renderingType === UNINITIALIZED) {
        return;
      }

      var isVectorMap = renderingType === VECTOR && google.maps.WebGLOverlayView;
      var OverlayView = isVectorMap ? google.maps.WebGLOverlayView : google.maps.OverlayView;
      var overlay = new OverlayView();

      if (overlay instanceof google.maps.WebGLOverlayView) {
        if (interleaved) {
          overlay.onAdd = noop;
          overlay.onContextRestored = this._onContextRestored.bind(this);
          overlay.onDraw = this._onDrawVectorInterleaved.bind(this);
        } else {
          overlay.onAdd = this._onAdd.bind(this);
          overlay.onContextRestored = noop;
          overlay.onDraw = this._onDrawVectorOverlay.bind(this);
        }

        overlay.onContextLost = this._onContextLost.bind(this);
      } else {
        overlay.onAdd = this._onAdd.bind(this);
        overlay.draw = this._onDrawRaster.bind(this);
      }

      overlay.onRemove = this._onRemove.bind(this);
      this._overlay = overlay;

      this._overlay.setMap(map);
    }
  }, {
    key: "_onAdd",
    value: function _onAdd() {
      this._deck = createDeckInstance(this._map, this._overlay, this._deck, this.props);
    }
  }, {
    key: "_onContextRestored",
    value: function _onContextRestored(_ref) {
      var _this2 = this;

      var gl = _ref.gl;

      if (!this._map || !this._overlay) {
        return;
      }

      var _customRender = function _customRender() {
        if (_this2._overlay) {
          _this2._overlay.requestRedraw();
        }
      };

      var deck = createDeckInstance(this._map, this._overlay, this._deck, _objectSpread({
        gl: gl,
        _customRender: _customRender
      }, this.props));
      this._deck = deck;
      var animationLoop = deck.animationLoop;

      animationLoop._renderFrame = function () {
        var ab = gl.getParameter(34964);
        withParameters(gl, {}, function () {
          animationLoop.onRender();
        });
        gl.bindBuffer(34962, ab);
      };
    }
  }, {
    key: "_onContextLost",
    value: function _onContextLost() {
      if (this._deck) {
        destroyDeckInstance(this._deck);
        this._deck = null;
      }
    }
  }, {
    key: "_onRemove",
    value: function _onRemove() {
      var _this$_deck;

      (_this$_deck = this._deck) === null || _this$_deck === void 0 ? void 0 : _this$_deck.setProps({
        layerFilter: HIDE_ALL_LAYERS
      });
    }
  }, {
    key: "_onDrawRaster",
    value: function _onDrawRaster() {
      if (!this._deck || !this._map) {
        return;
      }

      var deck = this._deck;

      var _getViewPropsFromOver = getViewPropsFromOverlay(this._map, this._overlay),
          width = _getViewPropsFromOver.width,
          height = _getViewPropsFromOver.height,
          left = _getViewPropsFromOver.left,
          top = _getViewPropsFromOver.top,
          rest = _objectWithoutProperties(_getViewPropsFromOver, _excluded);

      var canvas = deck.getCanvas();

      if (canvas !== null && canvas !== void 0 && canvas.parentElement) {
        var parentStyle = canvas.parentElement.style;
        parentStyle.left = "".concat(left, "px");
        parentStyle.top = "".concat(top, "px");
      }

      var altitude = 10000;
      deck.setProps({
        width: width,
        height: height,
        viewState: _objectSpread({
          altitude: altitude,
          repeat: true
        }, rest)
      });
      deck.redraw();
    }
  }, {
    key: "_onDrawVectorInterleaved",
    value: function _onDrawVectorInterleaved(_ref2) {
      var gl = _ref2.gl,
          transformer = _ref2.transformer;

      if (!this._deck || !this._map) {
        return;
      }

      var deck = this._deck;
      deck.setProps(_objectSpread(_objectSpread({}, getViewPropsFromCoordinateTransformer(this._map, transformer)), {}, {
        width: null,
        height: null
      }));

      if (deck.isInitialized) {
        var _framebuffer = getParameters(gl, 36006);

        deck.setProps({
          _framebuffer: _framebuffer
        });
        deck.needsRedraw({
          clearRedrawFlags: true
        });
        setParameters(gl, {
          viewport: [0, 0, gl.canvas.width, gl.canvas.height],
          scissor: [0, 0, gl.canvas.width, gl.canvas.height],
          stencilFunc: [519, 0, 255, 519, 0, 255]
        });
        withParameters(gl, GL_STATE, function () {
          deck._drawLayers('google-vector', {
            clearCanvas: false
          });
        });
      }
    }
  }, {
    key: "_onDrawVectorOverlay",
    value: function _onDrawVectorOverlay(_ref3) {
      var transformer = _ref3.transformer;

      if (!this._deck || !this._map) {
        return;
      }

      var deck = this._deck;
      deck.setProps(_objectSpread({}, getViewPropsFromCoordinateTransformer(this._map, transformer)));
      deck.redraw();
    }
  }]);

  return GoogleMapsOverlay;
}();

export { GoogleMapsOverlay as default };