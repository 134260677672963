import { cutPolylineByGrid, cutPolylineByMercatorBounds } from '@math.gl/polygon';
export function normalizePath(path, size, gridResolution, wrapLongitude) {
  var flatPath;

  if (Array.isArray(path[0])) {
    var length = path.length * size;
    flatPath = new Array(length);

    for (var i = 0; i < path.length; i++) {
      for (var j = 0; j < size; j++) {
        flatPath[i * size + j] = path[i][j] || 0;
      }
    }
  } else {
    flatPath = path;
  }

  if (gridResolution) {
    return cutPolylineByGrid(flatPath, {
      size: size,
      gridResolution: gridResolution
    });
  }

  if (wrapLongitude) {
    return cutPolylineByMercatorBounds(flatPath, {
      size: size
    });
  }

  return flatPath;
}