import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _assertThisInitialized from "@babel/runtime/helpers/assertThisInitialized";
import _inherits from "@babel/runtime/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { CompositeLayer } from '@deck.gl/core';
import GPUGridAggregator from '../utils/gpu-grid-aggregation/gpu-grid-aggregator';
import GPUGridLayer from '../gpu-grid-layer/gpu-grid-layer';
import CPUGridLayer from '../cpu-grid-layer/cpu-grid-layer';

var defaultProps = _objectSpread(_objectSpread(_objectSpread({}, GPUGridLayer.defaultProps), CPUGridLayer.defaultProps), {}, {
  gpuAggregation: false
});

var GridLayer = function (_CompositeLayer) {
  _inherits(GridLayer, _CompositeLayer);

  var _super = _createSuper(GridLayer);

  function GridLayer() {
    var _this;

    _classCallCheck(this, GridLayer);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));

    _defineProperty(_assertThisInitialized(_this), "state", void 0);

    return _this;
  }

  _createClass(GridLayer, [{
    key: "initializeState",
    value: function initializeState() {
      this.state = {
        useGPUAggregation: true
      };
    }
  }, {
    key: "updateState",
    value: function updateState(_ref) {
      var props = _ref.props;
      this.setState({
        useGPUAggregation: this.canUseGPUAggregation(props)
      });
    }
  }, {
    key: "renderLayers",
    value: function renderLayers() {
      var _this$props = this.props,
          data = _this$props.data,
          updateTriggers = _this$props.updateTriggers;
      var id = this.state.useGPUAggregation ? 'GPU' : 'CPU';
      var LayerType = this.state.useGPUAggregation ? this.getSubLayerClass('GPU', GPUGridLayer) : this.getSubLayerClass('CPU', CPUGridLayer);
      return new LayerType(this.props, this.getSubLayerProps({
        id: id,
        updateTriggers: updateTriggers
      }), {
        data: data
      });
    }
  }, {
    key: "canUseGPUAggregation",
    value: function canUseGPUAggregation(props) {
      var gpuAggregation = props.gpuAggregation,
          lowerPercentile = props.lowerPercentile,
          upperPercentile = props.upperPercentile,
          getColorValue = props.getColorValue,
          getElevationValue = props.getElevationValue,
          colorScaleType = props.colorScaleType;

      if (!gpuAggregation) {
        return false;
      }

      if (!GPUGridAggregator.isSupported(this.context.gl)) {
        return false;
      }

      if (lowerPercentile !== 0 || upperPercentile !== 100) {
        return false;
      }

      if (getColorValue !== null || getElevationValue !== null) {
        return false;
      }

      if (colorScaleType === 'quantile' || colorScaleType === 'ordinal') {
        return false;
      }

      return true;
    }
  }]);

  return GridLayer;
}(CompositeLayer);

_defineProperty(GridLayer, "layerName", 'GridLayer');

_defineProperty(GridLayer, "defaultProps", defaultProps);

export { GridLayer as default };