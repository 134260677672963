import _asyncToGenerator from "@babel/runtime/helpers/asyncToGenerator";
import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _asyncIterator from "@babel/runtime/helpers/asyncIterator";
import _regeneratorRuntime from "@babel/runtime/regenerator";
import { isAsyncIterable } from '../utils/iterable-utils';
import { COMPONENT_SYMBOL, PROP_TYPES_SYMBOL, ASYNC_ORIGINAL_SYMBOL, ASYNC_RESOLVED_SYMBOL, ASYNC_DEFAULTS_SYMBOL } from './constants';
var EMPTY_PROPS = Object.freeze({});

var ComponentState = function () {
  function ComponentState(component) {
    _classCallCheck(this, ComponentState);

    _defineProperty(this, "component", void 0);

    _defineProperty(this, "onAsyncPropUpdated", void 0);

    _defineProperty(this, "asyncProps", void 0);

    _defineProperty(this, "oldProps", void 0);

    _defineProperty(this, "oldAsyncProps", void 0);

    this.component = component;
    this.asyncProps = {};

    this.onAsyncPropUpdated = function () {};

    this.oldProps = null;
    this.oldAsyncProps = null;
  }

  _createClass(ComponentState, [{
    key: "finalize",
    value: function finalize() {
      for (var _propName in this.asyncProps) {
        var asyncProp = this.asyncProps[_propName];

        if (asyncProp && asyncProp.type && asyncProp.type.release) {
          asyncProp.type.release(asyncProp.resolvedValue, asyncProp.type, this.component);
        }
      }

      this.asyncProps = {};
      this.component = null;
      this.resetOldProps();
    }
  }, {
    key: "getOldProps",
    value: function getOldProps() {
      return this.oldAsyncProps || this.oldProps || EMPTY_PROPS;
    }
  }, {
    key: "resetOldProps",
    value: function resetOldProps() {
      this.oldAsyncProps = null;
      this.oldProps = this.component ? this.component.props : null;
    }
  }, {
    key: "hasAsyncProp",
    value: function hasAsyncProp(propName) {
      return propName in this.asyncProps;
    }
  }, {
    key: "getAsyncProp",
    value: function getAsyncProp(propName) {
      var asyncProp = this.asyncProps[propName];
      return asyncProp && asyncProp.resolvedValue;
    }
  }, {
    key: "isAsyncPropLoading",
    value: function isAsyncPropLoading(propName) {
      if (propName) {
        var asyncProp = this.asyncProps[propName];
        return Boolean(asyncProp && asyncProp.pendingLoadCount > 0 && asyncProp.pendingLoadCount !== asyncProp.resolvedLoadCount);
      }

      for (var key in this.asyncProps) {
        if (this.isAsyncPropLoading(key)) {
          return true;
        }
      }

      return false;
    }
  }, {
    key: "reloadAsyncProp",
    value: function reloadAsyncProp(propName, value) {
      this._watchPromise(propName, Promise.resolve(value));
    }
  }, {
    key: "setAsyncProps",
    value: function setAsyncProps(props) {
      this.component = props[COMPONENT_SYMBOL] || this.component;
      var resolvedValues = props[ASYNC_RESOLVED_SYMBOL] || {};
      var originalValues = props[ASYNC_ORIGINAL_SYMBOL] || props;
      var defaultValues = props[ASYNC_DEFAULTS_SYMBOL] || {};

      for (var _propName2 in resolvedValues) {
        var _value = resolvedValues[_propName2];

        this._createAsyncPropData(_propName2, defaultValues[_propName2]);

        this._updateAsyncProp(_propName2, _value);

        resolvedValues[_propName2] = this.getAsyncProp(_propName2);
      }

      for (var _propName3 in originalValues) {
        var _value2 = originalValues[_propName3];

        this._createAsyncPropData(_propName3, defaultValues[_propName3]);

        this._updateAsyncProp(_propName3, _value2);
      }
    }
  }, {
    key: "_fetch",
    value: function _fetch(propName, url) {
      return null;
    }
  }, {
    key: "_onResolve",
    value: function _onResolve(propName, value) {}
  }, {
    key: "_onError",
    value: function _onError(propName, error) {}
  }, {
    key: "_updateAsyncProp",
    value: function _updateAsyncProp(propName, value) {
      if (!this._didAsyncInputValueChange(propName, value)) {
        return;
      }

      if (typeof value === 'string') {
        value = this._fetch(propName, value);
      }

      if (value instanceof Promise) {
        this._watchPromise(propName, value);

        return;
      }

      if (isAsyncIterable(value)) {
        this._resolveAsyncIterable(propName, value);

        return;
      }

      this._setPropValue(propName, value);
    }
  }, {
    key: "_freezeAsyncOldProps",
    value: function _freezeAsyncOldProps() {
      if (!this.oldAsyncProps && this.oldProps) {
        this.oldAsyncProps = Object.create(this.oldProps);

        for (var _propName4 in this.asyncProps) {
          Object.defineProperty(this.oldAsyncProps, _propName4, {
            enumerable: true,
            value: this.oldProps[_propName4]
          });
        }
      }
    }
  }, {
    key: "_didAsyncInputValueChange",
    value: function _didAsyncInputValueChange(propName, value) {
      var asyncProp = this.asyncProps[propName];

      if (value === asyncProp.resolvedValue || value === asyncProp.lastValue) {
        return false;
      }

      asyncProp.lastValue = value;
      return true;
    }
  }, {
    key: "_setPropValue",
    value: function _setPropValue(propName, value) {
      this._freezeAsyncOldProps();

      var asyncProp = this.asyncProps[propName];

      if (asyncProp) {
        value = this._postProcessValue(asyncProp, value);
        asyncProp.resolvedValue = value;
        asyncProp.pendingLoadCount++;
        asyncProp.resolvedLoadCount = asyncProp.pendingLoadCount;
      }
    }
  }, {
    key: "_setAsyncPropValue",
    value: function _setAsyncPropValue(propName, value, loadCount) {
      var asyncProp = this.asyncProps[propName];

      if (asyncProp && loadCount >= asyncProp.resolvedLoadCount && value !== undefined) {
        this._freezeAsyncOldProps();

        asyncProp.resolvedValue = value;
        asyncProp.resolvedLoadCount = loadCount;
        this.onAsyncPropUpdated(propName, value);
      }
    }
  }, {
    key: "_watchPromise",
    value: function _watchPromise(propName, promise) {
      var _this = this;

      var asyncProp = this.asyncProps[propName];

      if (asyncProp) {
        asyncProp.pendingLoadCount++;
        var loadCount = asyncProp.pendingLoadCount;
        promise.then(function (data) {
          if (!_this.component) {
            return;
          }

          data = _this._postProcessValue(asyncProp, data);

          _this._setAsyncPropValue(propName, data, loadCount);

          _this._onResolve(propName, data);
        }).catch(function (error) {
          _this._onError(propName, error);
        });
      }
    }
  }, {
    key: "_resolveAsyncIterable",
    value: function () {
      var _resolveAsyncIterable2 = _asyncToGenerator(_regeneratorRuntime.mark(function _callee(propName, iterable) {
        var asyncProp, loadCount, data, count, _iteratorAbruptCompletion, _didIteratorError, _iteratorError, _iterator, _step, chunk, dataTransform;

        return _regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                if (!(propName !== 'data')) {
                  _context.next = 3;
                  break;
                }

                this._setPropValue(propName, iterable);

                return _context.abrupt("return");

              case 3:
                asyncProp = this.asyncProps[propName];

                if (asyncProp) {
                  _context.next = 6;
                  break;
                }

                return _context.abrupt("return");

              case 6:
                asyncProp.pendingLoadCount++;
                loadCount = asyncProp.pendingLoadCount;
                data = [];
                count = 0;
                _iteratorAbruptCompletion = false;
                _didIteratorError = false;
                _context.prev = 12;
                _iterator = _asyncIterator(iterable);

              case 14:
                _context.next = 16;
                return _iterator.next();

              case 16:
                if (!(_iteratorAbruptCompletion = !(_step = _context.sent).done)) {
                  _context.next = 28;
                  break;
                }

                chunk = _step.value;

                if (this.component) {
                  _context.next = 20;
                  break;
                }

                return _context.abrupt("return");

              case 20:
                dataTransform = this.component.props.dataTransform;

                if (dataTransform) {
                  data = dataTransform(chunk, data);
                } else {
                  data = data.concat(chunk);
                }

                Object.defineProperty(data, '__diff', {
                  enumerable: false,
                  value: [{
                    startRow: count,
                    endRow: data.length
                  }]
                });
                count = data.length;

                this._setAsyncPropValue(propName, data, loadCount);

              case 25:
                _iteratorAbruptCompletion = false;
                _context.next = 14;
                break;

              case 28:
                _context.next = 34;
                break;

              case 30:
                _context.prev = 30;
                _context.t0 = _context["catch"](12);
                _didIteratorError = true;
                _iteratorError = _context.t0;

              case 34:
                _context.prev = 34;
                _context.prev = 35;

                if (!(_iteratorAbruptCompletion && _iterator.return != null)) {
                  _context.next = 39;
                  break;
                }

                _context.next = 39;
                return _iterator.return();

              case 39:
                _context.prev = 39;

                if (!_didIteratorError) {
                  _context.next = 42;
                  break;
                }

                throw _iteratorError;

              case 42:
                return _context.finish(39);

              case 43:
                return _context.finish(34);

              case 44:
                this._onResolve(propName, data);

              case 45:
              case "end":
                return _context.stop();
            }
          }
        }, _callee, this, [[12, 30, 34, 44], [35,, 39, 43]]);
      }));

      function _resolveAsyncIterable(_x, _x2) {
        return _resolveAsyncIterable2.apply(this, arguments);
      }

      return _resolveAsyncIterable;
    }()
  }, {
    key: "_postProcessValue",
    value: function _postProcessValue(asyncProp, value) {
      var propType = asyncProp.type;

      if (propType && this.component) {
        if (propType.release) {
          propType.release(asyncProp.resolvedValue, propType, this.component);
        }

        if (propType.transform) {
          return propType.transform(value, propType, this.component);
        }
      }

      return value;
    }
  }, {
    key: "_createAsyncPropData",
    value: function _createAsyncPropData(propName, defaultValue) {
      var asyncProp = this.asyncProps[propName];

      if (!asyncProp) {
        var propTypes = this.component && this.component.props[PROP_TYPES_SYMBOL];
        this.asyncProps[propName] = {
          type: propTypes && propTypes[propName],
          lastValue: null,
          resolvedValue: defaultValue,
          pendingLoadCount: 0,
          resolvedLoadCount: 0
        };
      }
    }
  }]);

  return ComponentState;
}();

export { ComponentState as default };