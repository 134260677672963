import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import Resource from './resource';

var ResourceManager = function () {
  function ResourceManager(_ref) {
    var gl = _ref.gl,
        protocol = _ref.protocol;

    _classCallCheck(this, ResourceManager);

    _defineProperty(this, "protocol", void 0);

    _defineProperty(this, "_context", void 0);

    _defineProperty(this, "_resources", void 0);

    _defineProperty(this, "_consumers", void 0);

    _defineProperty(this, "_pruneRequest", void 0);

    this.protocol = protocol || 'resource://';
    this._context = {
      gl: gl,
      resourceManager: this
    };
    this._resources = {};
    this._consumers = {};
    this._pruneRequest = null;
  }

  _createClass(ResourceManager, [{
    key: "contains",
    value: function contains(resourceId) {
      if (resourceId.startsWith(this.protocol)) {
        return true;
      }

      return resourceId in this._resources;
    }
  }, {
    key: "add",
    value: function add(_ref2) {
      var resourceId = _ref2.resourceId,
          data = _ref2.data,
          _ref2$forceUpdate = _ref2.forceUpdate,
          forceUpdate = _ref2$forceUpdate === void 0 ? false : _ref2$forceUpdate,
          _ref2$persistent = _ref2.persistent,
          persistent = _ref2$persistent === void 0 ? true : _ref2$persistent;
      var res = this._resources[resourceId];

      if (res) {
        res.setData(data, forceUpdate);
      } else {
        res = new Resource(resourceId, data, this._context);
        this._resources[resourceId] = res;
      }

      res.persistent = persistent;
    }
  }, {
    key: "remove",
    value: function remove(resourceId) {
      var res = this._resources[resourceId];

      if (res) {
        res.delete();
        delete this._resources[resourceId];
      }
    }
  }, {
    key: "unsubscribe",
    value: function unsubscribe(_ref3) {
      var consumerId = _ref3.consumerId;
      var consumer = this._consumers[consumerId];

      if (consumer) {
        for (var requestId in consumer) {
          var request = consumer[requestId];
          var resource = this._resources[request.resourceId];

          if (resource) {
            resource.unsubscribe(request);
          }
        }

        delete this._consumers[consumerId];
        this.prune();
      }
    }
  }, {
    key: "subscribe",
    value: function subscribe(_ref4) {
      var resourceId = _ref4.resourceId,
          onChange = _ref4.onChange,
          consumerId = _ref4.consumerId,
          _ref4$requestId = _ref4.requestId,
          requestId = _ref4$requestId === void 0 ? 'default' : _ref4$requestId;
      var resources = this._resources,
          protocol = this.protocol;

      if (resourceId.startsWith(protocol)) {
        resourceId = resourceId.replace(protocol, '');

        if (!resources[resourceId]) {
          this.add({
            resourceId: resourceId,
            data: null,
            persistent: false
          });
        }
      }

      var res = resources[resourceId];

      this._track(consumerId, requestId, res, onChange);

      if (res) {
        return res.getData();
      }

      return undefined;
    }
  }, {
    key: "prune",
    value: function prune() {
      var _this = this;

      if (!this._pruneRequest) {
        this._pruneRequest = setTimeout(function () {
          return _this._prune();
        }, 0);
      }
    }
  }, {
    key: "finalize",
    value: function finalize() {
      for (var key in this._resources) {
        this._resources[key].delete();
      }
    }
  }, {
    key: "_track",
    value: function _track(consumerId, requestId, resource, onChange) {
      var consumers = this._consumers;
      var consumer = consumers[consumerId] = consumers[consumerId] || {};
      var request = consumer[requestId] || {};
      var oldResource = request.resourceId && this._resources[request.resourceId];

      if (oldResource) {
        oldResource.unsubscribe(request);
        this.prune();
      }

      if (resource) {
        consumer[requestId] = request;
        request.onChange = onChange;
        request.resourceId = resource.id;
        resource.subscribe(request);
      }
    }
  }, {
    key: "_prune",
    value: function _prune() {
      this._pruneRequest = null;

      for (var _i = 0, _Object$keys = Object.keys(this._resources); _i < _Object$keys.length; _i++) {
        var key = _Object$keys[_i];
        var res = this._resources[key];

        if (!res.persistent && !res.inUse()) {
          res.delete();
          delete this._resources[key];
        }
      }
    }
  }]);

  return ResourceManager;
}();

export { ResourceManager as default };