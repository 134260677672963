import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { picking } from '@luma.gl/core';
export default _objectSpread({
  inject: {
    'vs:DECKGL_FILTER_GL_POSITION': "\n    // for picking depth values\n    picking_setPickingAttribute(position.z / position.w);\n  ",
    'vs:DECKGL_FILTER_COLOR': "\n  picking_setPickingColor(geometry.pickingColor);\n  ",
    'fs:#decl': "\nuniform bool picking_uAttribute;\n  ",
    'fs:DECKGL_FILTER_COLOR': {
      order: 99,
      injection: "\n  // use highlight color if this fragment belongs to the selected object.\n  color = picking_filterHighlightColor(color);\n\n  // use picking color if rendering to picking FBO.\n  color = picking_filterPickingColor(color);\n    "
    }
  }
}, picking);