function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import typedArrayManager from './typed-array-manager';
import { Vector3 } from '@math.gl/core';
export function createMat4() {
  return [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1];
}
export function mod(value, divisor) {
  var modulus = value % divisor;
  return modulus < 0 ? divisor + modulus : modulus;
}
export function getCameraPosition(viewMatrixInverse) {
  return [viewMatrixInverse[12], viewMatrixInverse[13], viewMatrixInverse[14]];
}
export function getFrustumPlanes(viewProjectionMatrix) {
  return {
    left: getFrustumPlane(viewProjectionMatrix[3] + viewProjectionMatrix[0], viewProjectionMatrix[7] + viewProjectionMatrix[4], viewProjectionMatrix[11] + viewProjectionMatrix[8], viewProjectionMatrix[15] + viewProjectionMatrix[12]),
    right: getFrustumPlane(viewProjectionMatrix[3] - viewProjectionMatrix[0], viewProjectionMatrix[7] - viewProjectionMatrix[4], viewProjectionMatrix[11] - viewProjectionMatrix[8], viewProjectionMatrix[15] - viewProjectionMatrix[12]),
    bottom: getFrustumPlane(viewProjectionMatrix[3] + viewProjectionMatrix[1], viewProjectionMatrix[7] + viewProjectionMatrix[5], viewProjectionMatrix[11] + viewProjectionMatrix[9], viewProjectionMatrix[15] + viewProjectionMatrix[13]),
    top: getFrustumPlane(viewProjectionMatrix[3] - viewProjectionMatrix[1], viewProjectionMatrix[7] - viewProjectionMatrix[5], viewProjectionMatrix[11] - viewProjectionMatrix[9], viewProjectionMatrix[15] - viewProjectionMatrix[13]),
    near: getFrustumPlane(viewProjectionMatrix[3] + viewProjectionMatrix[2], viewProjectionMatrix[7] + viewProjectionMatrix[6], viewProjectionMatrix[11] + viewProjectionMatrix[10], viewProjectionMatrix[15] + viewProjectionMatrix[14]),
    far: getFrustumPlane(viewProjectionMatrix[3] - viewProjectionMatrix[2], viewProjectionMatrix[7] - viewProjectionMatrix[6], viewProjectionMatrix[11] - viewProjectionMatrix[10], viewProjectionMatrix[15] - viewProjectionMatrix[14])
  };
}
var scratchVector = new Vector3();

function getFrustumPlane(a, b, c, d) {
  scratchVector.set(a, b, c);
  var L = scratchVector.len();
  return {
    distance: d / L,
    normal: new Vector3(-a / L, -b / L, -c / L)
  };
}

export function fp64LowPart(x) {
  return x - Math.fround(x);
}
var scratchArray;
export function toDoublePrecisionArray(typedArray, options) {
  var _options$size = options.size,
      size = _options$size === void 0 ? 1 : _options$size,
      _options$startIndex = options.startIndex,
      startIndex = _options$startIndex === void 0 ? 0 : _options$startIndex;
  var endIndex = options.endIndex !== undefined ? options.endIndex : typedArray.length;
  var count = (endIndex - startIndex) / size;
  scratchArray = typedArrayManager.allocate(scratchArray, count, {
    type: Float32Array,
    size: size * 2
  });
  var sourceIndex = startIndex;
  var targetIndex = 0;

  while (sourceIndex < endIndex) {
    for (var j = 0; j < size; j++) {
      var value = typedArray[sourceIndex++];
      scratchArray[targetIndex + j] = value;
      scratchArray[targetIndex + j + size] = fp64LowPart(value);
    }

    targetIndex += size * 2;
  }

  return scratchArray.subarray(0, count * size * 2);
}
export function mergeBounds(boundsList) {
  var mergedBounds = null;
  var isMerged = false;

  var _iterator = _createForOfIteratorHelper(boundsList),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var bounds = _step.value;
      if (!bounds) continue;

      if (!mergedBounds) {
        mergedBounds = bounds;
      } else {
        if (!isMerged) {
          mergedBounds = [[mergedBounds[0][0], mergedBounds[0][1]], [mergedBounds[1][0], mergedBounds[1][1]]];
          isMerged = true;
        }

        mergedBounds[0][0] = Math.min(mergedBounds[0][0], bounds[0][0]);
        mergedBounds[0][1] = Math.min(mergedBounds[0][1], bounds[0][1]);
        mergedBounds[1][0] = Math.max(mergedBounds[1][0], bounds[1][0]);
        mergedBounds[1][1] = Math.max(mergedBounds[1][1], bounds[1][1]);
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  return mergedBounds;
}