import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import { Texture2D, ProgramManager } from '@luma.gl/core';
import { AmbientLight } from './ambient-light';
import { DirectionalLight } from './directional-light';
import { Matrix4, Vector3 } from '@math.gl/core';
import ShadowPass from '../../passes/shadow-pass';
import shadow from '../../shaderlib/shadow/shadow';
var DEFAULT_AMBIENT_LIGHT_PROPS = {
  color: [255, 255, 255],
  intensity: 1.0
};
var DEFAULT_DIRECTIONAL_LIGHT_PROPS = [{
  color: [255, 255, 255],
  intensity: 1.0,
  direction: [-1, 3, -1]
}, {
  color: [255, 255, 255],
  intensity: 0.9,
  direction: [1, -8, -2.5]
}];
var DEFAULT_SHADOW_COLOR = [0, 0, 0, 200 / 255];

var LightingEffect = function () {
  function LightingEffect() {
    var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    _classCallCheck(this, LightingEffect);

    _defineProperty(this, "id", 'lighting-effect');

    _defineProperty(this, "props", void 0);

    _defineProperty(this, "shadowColor", DEFAULT_SHADOW_COLOR);

    _defineProperty(this, "shadow", void 0);

    _defineProperty(this, "ambientLight", void 0);

    _defineProperty(this, "directionalLights", void 0);

    _defineProperty(this, "pointLights", void 0);

    _defineProperty(this, "shadowPasses", []);

    _defineProperty(this, "shadowMaps", []);

    _defineProperty(this, "dummyShadowMap", null);

    _defineProperty(this, "programManager", void 0);

    _defineProperty(this, "shadowMatrices", void 0);

    this.setProps(props);
  }

  _createClass(LightingEffect, [{
    key: "setProps",
    value: function setProps(props) {
      this.ambientLight = null;
      this.directionalLights = [];
      this.pointLights = [];

      for (var key in props) {
        var lightSource = props[key];

        switch (lightSource.type) {
          case 'ambient':
            this.ambientLight = lightSource;
            break;

          case 'directional':
            this.directionalLights.push(lightSource);
            break;

          case 'point':
            this.pointLights.push(lightSource);
            break;

          default:
        }
      }

      this._applyDefaultLights();

      this.shadow = this.directionalLights.some(function (light) {
        return light.shadow;
      });
      this.props = props;
    }
  }, {
    key: "preRender",
    value: function preRender(gl, _ref) {
      var layers = _ref.layers,
          layerFilter = _ref.layerFilter,
          viewports = _ref.viewports,
          onViewportActive = _ref.onViewportActive,
          views = _ref.views;
      if (!this.shadow) return;
      this.shadowMatrices = this._calculateMatrices();

      if (this.shadowPasses.length === 0) {
        this._createShadowPasses(gl);
      }

      if (!this.programManager) {
        this.programManager = ProgramManager.getDefaultProgramManager(gl);

        if (shadow) {
          this.programManager.addDefaultModule(shadow);
        }
      }

      if (!this.dummyShadowMap) {
        this.dummyShadowMap = new Texture2D(gl, {
          width: 1,
          height: 1
        });
      }

      for (var i = 0; i < this.shadowPasses.length; i++) {
        var shadowPass = this.shadowPasses[i];
        shadowPass.render({
          layers: layers,
          layerFilter: layerFilter,
          viewports: viewports,
          onViewportActive: onViewportActive,
          views: views,
          moduleParameters: {
            shadowLightId: i,
            dummyShadowMap: this.dummyShadowMap,
            shadowMatrices: this.shadowMatrices
          }
        });
      }
    }
  }, {
    key: "getModuleParameters",
    value: function getModuleParameters(layer) {
      var parameters = this.shadow ? {
        shadowMaps: this.shadowMaps,
        dummyShadowMap: this.dummyShadowMap,
        shadowColor: this.shadowColor,
        shadowMatrices: this.shadowMatrices
      } : {};
      parameters.lightSources = {
        ambientLight: this.ambientLight,
        directionalLights: this.directionalLights.map(function (directionalLight) {
          return directionalLight.getProjectedLight({
            layer: layer
          });
        }),
        pointLights: this.pointLights.map(function (pointLight) {
          return pointLight.getProjectedLight({
            layer: layer
          });
        })
      };
      return parameters;
    }
  }, {
    key: "cleanup",
    value: function cleanup() {
      var _iterator = _createForOfIteratorHelper(this.shadowPasses),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var shadowPass = _step.value;
          shadowPass.delete();
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      this.shadowPasses.length = 0;
      this.shadowMaps.length = 0;

      if (this.dummyShadowMap) {
        this.dummyShadowMap.delete();
        this.dummyShadowMap = null;
      }

      if (this.shadow && this.programManager) {
        this.programManager.removeDefaultModule(shadow);
        this.programManager = null;
      }
    }
  }, {
    key: "_calculateMatrices",
    value: function _calculateMatrices() {
      var lightMatrices = [];

      var _iterator2 = _createForOfIteratorHelper(this.directionalLights),
          _step2;

      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var light = _step2.value;
          var viewMatrix = new Matrix4().lookAt({
            eye: new Vector3(light.direction).negate()
          });
          lightMatrices.push(viewMatrix);
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }

      return lightMatrices;
    }
  }, {
    key: "_createShadowPasses",
    value: function _createShadowPasses(gl) {
      for (var i = 0; i < this.directionalLights.length; i++) {
        var shadowPass = new ShadowPass(gl);
        this.shadowPasses[i] = shadowPass;
        this.shadowMaps[i] = shadowPass.shadowMap;
      }
    }
  }, {
    key: "_applyDefaultLights",
    value: function _applyDefaultLights() {
      var ambientLight = this.ambientLight,
          pointLights = this.pointLights,
          directionalLights = this.directionalLights;

      if (!ambientLight && pointLights.length === 0 && directionalLights.length === 0) {
        this.ambientLight = new AmbientLight(DEFAULT_AMBIENT_LIGHT_PROPS);
        this.directionalLights.push(new DirectionalLight(DEFAULT_DIRECTIONAL_LIGHT_PROPS[0]), new DirectionalLight(DEFAULT_DIRECTIONAL_LIGHT_PROPS[1]));
      }
    }
  }]);

  return LightingEffect;
}();

export { LightingEffect as default };