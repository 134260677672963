import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _assertThisInitialized from "@babel/runtime/helpers/assertThisInitialized";
import _inherits from "@babel/runtime/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { CompositeLayer, log } from '@deck.gl/core';
import { SimpleMeshLayer } from '@deck.gl/mesh-layers';
import { COORDINATE_SYSTEM } from '@deck.gl/core';
import { TerrainWorkerLoader } from '@loaders.gl/terrain';
import TileLayer from '../tile-layer/tile-layer';
import { urlType, getURLFromTemplate } from '../tileset-2d';
var DUMMY_DATA = [1];

var defaultProps = _objectSpread(_objectSpread({}, TileLayer.defaultProps), {}, {
  elevationData: urlType,
  texture: _objectSpread(_objectSpread({}, urlType), {}, {
    optional: true
  }),
  meshMaxError: {
    type: 'number',
    value: 4.0
  },
  bounds: {
    type: 'array',
    value: null,
    optional: true,
    compare: true
  },
  color: {
    type: 'color',
    value: [255, 255, 255]
  },
  elevationDecoder: {
    type: 'object',
    value: {
      rScaler: 1,
      gScaler: 0,
      bScaler: 0,
      offset: 0
    }
  },
  workerUrl: '',
  wireframe: false,
  material: true,
  loaders: [TerrainWorkerLoader]
});

function urlTemplateToUpdateTrigger(template) {
  if (Array.isArray(template)) {
    return template.join(';');
  }

  return template || '';
}

var TerrainLayer = function (_CompositeLayer) {
  _inherits(TerrainLayer, _CompositeLayer);

  var _super = _createSuper(TerrainLayer);

  function TerrainLayer() {
    var _this;

    _classCallCheck(this, TerrainLayer);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));

    _defineProperty(_assertThisInitialized(_this), "state", void 0);

    return _this;
  }

  _createClass(TerrainLayer, [{
    key: "updateState",
    value: function updateState(_ref) {
      var props = _ref.props,
          oldProps = _ref.oldProps;
      var elevationDataChanged = props.elevationData !== oldProps.elevationData;

      if (elevationDataChanged) {
        var elevationData = props.elevationData;
        var isTiled = elevationData && (Array.isArray(elevationData) || elevationData.includes('{x}') && elevationData.includes('{y}'));
        this.setState({
          isTiled: isTiled
        });
      }

      var shouldReload = elevationDataChanged || props.meshMaxError !== oldProps.meshMaxError || props.elevationDecoder !== oldProps.elevationDecoder || props.bounds !== oldProps.bounds;

      if (!this.state.isTiled && shouldReload) {
        var terrain = this.loadTerrain(props);
        this.setState({
          terrain: terrain
        });
      }

      if (props.workerUrl) {
        log.removed('workerUrl', 'loadOptions.terrain.workerUrl')();
      }
    }
  }, {
    key: "loadTerrain",
    value: function loadTerrain(_ref2) {
      var _loadOptions;

      var elevationData = _ref2.elevationData,
          bounds = _ref2.bounds,
          elevationDecoder = _ref2.elevationDecoder,
          meshMaxError = _ref2.meshMaxError,
          signal = _ref2.signal;

      if (!elevationData) {
        return null;
      }

      var loadOptions = this.getLoadOptions();
      loadOptions = _objectSpread(_objectSpread({}, loadOptions), {}, {
        terrain: _objectSpread(_objectSpread({
          skirtHeight: this.state.isTiled ? meshMaxError * 2 : 0
        }, (_loadOptions = loadOptions) === null || _loadOptions === void 0 ? void 0 : _loadOptions.terrain), {}, {
          bounds: bounds,
          meshMaxError: meshMaxError,
          elevationDecoder: elevationDecoder
        })
      });
      var fetch = this.props.fetch;
      return fetch(elevationData, {
        propName: 'elevationData',
        layer: this,
        loadOptions: loadOptions,
        signal: signal
      });
    }
  }, {
    key: "getTiledTerrainData",
    value: function getTiledTerrainData(tile) {
      var _this$props = this.props,
          elevationData = _this$props.elevationData,
          fetch = _this$props.fetch,
          texture = _this$props.texture,
          elevationDecoder = _this$props.elevationDecoder,
          meshMaxError = _this$props.meshMaxError;
      var viewport = this.context.viewport;
      var dataUrl = getURLFromTemplate(elevationData, tile);
      var textureUrl = texture && getURLFromTemplate(texture, tile);
      var signal = tile.signal;
      var bottomLeft = [0, 0];
      var topRight = [0, 0];

      if (viewport.isGeospatial) {
        var bbox = tile.bbox;
        bottomLeft = viewport.projectFlat([bbox.west, bbox.south]);
        topRight = viewport.projectFlat([bbox.east, bbox.north]);
      } else {
        var _bbox = tile.bbox;
        bottomLeft = [_bbox.left, _bbox.bottom];
        topRight = [_bbox.right, _bbox.top];
      }

      var bounds = [bottomLeft[0], bottomLeft[1], topRight[0], topRight[1]];
      var terrain = this.loadTerrain({
        elevationData: dataUrl,
        bounds: bounds,
        elevationDecoder: elevationDecoder,
        meshMaxError: meshMaxError,
        signal: signal
      });
      var surface = textureUrl ? fetch(textureUrl, {
        propName: 'texture',
        layer: this,
        loaders: [],
        signal: signal
      }).catch(function (_) {
        return null;
      }) : Promise.resolve(null);
      return Promise.all([terrain, surface]);
    }
  }, {
    key: "renderSubLayers",
    value: function renderSubLayers(props) {
      var SubLayerClass = this.getSubLayerClass('mesh', SimpleMeshLayer);
      var _this$props2 = this.props,
          color = _this$props2.color,
          wireframe = _this$props2.wireframe,
          material = _this$props2.material;
      var data = props.data;

      if (!data) {
        return null;
      }

      var _data = _slicedToArray(data, 2),
          mesh = _data[0],
          texture = _data[1];

      return new SubLayerClass(props, {
        data: DUMMY_DATA,
        mesh: mesh,
        texture: texture,
        _instanced: false,
        coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
        getPosition: function getPosition(d) {
          return [0, 0, 0];
        },
        getColor: color,
        wireframe: wireframe,
        material: material
      });
    }
  }, {
    key: "onViewportLoad",
    value: function onViewportLoad(tiles) {
      if (!tiles) {
        return;
      }

      var zRange = this.state.zRange;
      var ranges = tiles.map(function (tile) {
        return tile.content;
      }).filter(Boolean).map(function (arr) {
        var bounds = arr[0].header.boundingBox;
        return bounds.map(function (bound) {
          return bound[2];
        });
      });

      if (ranges.length === 0) {
        return;
      }

      var minZ = Math.min.apply(Math, _toConsumableArray(ranges.map(function (x) {
        return x[0];
      })));
      var maxZ = Math.max.apply(Math, _toConsumableArray(ranges.map(function (x) {
        return x[1];
      })));

      if (!zRange || minZ < zRange[0] || maxZ > zRange[1]) {
        this.setState({
          zRange: [minZ, maxZ]
        });
      }
    }
  }, {
    key: "renderLayers",
    value: function renderLayers() {
      var _this$props3 = this.props,
          color = _this$props3.color,
          material = _this$props3.material,
          elevationData = _this$props3.elevationData,
          texture = _this$props3.texture,
          wireframe = _this$props3.wireframe,
          meshMaxError = _this$props3.meshMaxError,
          elevationDecoder = _this$props3.elevationDecoder,
          tileSize = _this$props3.tileSize,
          maxZoom = _this$props3.maxZoom,
          minZoom = _this$props3.minZoom,
          extent = _this$props3.extent,
          maxRequests = _this$props3.maxRequests,
          onTileLoad = _this$props3.onTileLoad,
          onTileUnload = _this$props3.onTileUnload,
          onTileError = _this$props3.onTileError,
          maxCacheSize = _this$props3.maxCacheSize,
          maxCacheByteSize = _this$props3.maxCacheByteSize,
          refinementStrategy = _this$props3.refinementStrategy;

      if (this.state.isTiled) {
        return new TileLayer(this.getSubLayerProps({
          id: 'tiles'
        }), {
          getTileData: this.getTiledTerrainData.bind(this),
          renderSubLayers: this.renderSubLayers.bind(this),
          updateTriggers: {
            getTileData: {
              elevationData: urlTemplateToUpdateTrigger(elevationData),
              texture: urlTemplateToUpdateTrigger(texture),
              meshMaxError: meshMaxError,
              elevationDecoder: elevationDecoder
            }
          },
          onViewportLoad: this.onViewportLoad.bind(this),
          zRange: this.state.zRange || null,
          tileSize: tileSize,
          maxZoom: maxZoom,
          minZoom: minZoom,
          extent: extent,
          maxRequests: maxRequests,
          onTileLoad: onTileLoad,
          onTileUnload: onTileUnload,
          onTileError: onTileError,
          maxCacheSize: maxCacheSize,
          maxCacheByteSize: maxCacheByteSize,
          refinementStrategy: refinementStrategy
        });
      }

      if (!elevationData) {
        return null;
      }

      var SubLayerClass = this.getSubLayerClass('mesh', SimpleMeshLayer);
      return new SubLayerClass(this.getSubLayerProps({
        id: 'mesh'
      }), {
        data: DUMMY_DATA,
        mesh: this.state.terrain,
        texture: texture,
        _instanced: false,
        getPosition: function getPosition(d) {
          return [0, 0, 0];
        },
        getColor: color,
        material: material,
        wireframe: wireframe
      });
    }
  }]);

  return TerrainLayer;
}(CompositeLayer);

_defineProperty(TerrainLayer, "defaultProps", defaultProps);

_defineProperty(TerrainLayer, "layerName", 'TerrainLayer');

export { TerrainLayer as default };