import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { padArray } from '../../utils/array-utils';
var DEFAULT_TRANSITION_SETTINGS = {
  interpolation: {
    duration: 0,
    easing: function easing(t) {
      return t;
    }
  },
  spring: {
    stiffness: 0.05,
    damping: 0.5
  }
};
export function normalizeTransitionSettings(userSettings, layerSettings) {
  if (!userSettings) {
    return null;
  }

  if (Number.isFinite(userSettings)) {
    userSettings = {
      type: 'interpolation',
      duration: userSettings
    };
  }

  var type = userSettings.type || 'interpolation';
  return _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, DEFAULT_TRANSITION_SETTINGS[type]), layerSettings), userSettings), {}, {
    type: type
  });
}
export function getSourceBufferAttribute(gl, attribute) {
  var buffer = attribute.getBuffer();

  if (buffer) {
    return [buffer, {
      divisor: 0,
      size: attribute.size,
      normalized: attribute.settings.normalized
    }];
  }

  return attribute.value;
}
export function getAttributeTypeFromSize(size) {
  switch (size) {
    case 1:
      return 'float';

    case 2:
      return 'vec2';

    case 3:
      return 'vec3';

    case 4:
      return 'vec4';

    default:
      throw new Error("No defined attribute type for size \"".concat(size, "\""));
  }
}
export function cycleBuffers(buffers) {
  buffers.push(buffers.shift());
}
export function getAttributeBufferLength(attribute, numInstances) {
  var doublePrecision = attribute.doublePrecision,
      settings = attribute.settings,
      value = attribute.value,
      size = attribute.size;
  var multiplier = doublePrecision && value instanceof Float64Array ? 2 : 1;
  return (settings.noAlloc ? value.length : numInstances * size) * multiplier;
}
export function padBuffer(_ref) {
  var buffer = _ref.buffer,
      numInstances = _ref.numInstances,
      attribute = _ref.attribute,
      fromLength = _ref.fromLength,
      fromStartIndices = _ref.fromStartIndices,
      _ref$getData = _ref.getData,
      getData = _ref$getData === void 0 ? function (x) {
    return x;
  } : _ref$getData;
  var precisionMultiplier = attribute.doublePrecision && attribute.value instanceof Float64Array ? 2 : 1;
  var size = attribute.size * precisionMultiplier;
  var byteOffset = attribute.byteOffset;
  var toStartIndices = attribute.startIndices;
  var hasStartIndices = fromStartIndices && toStartIndices;
  var toLength = getAttributeBufferLength(attribute, numInstances);
  var isConstant = attribute.isConstant;

  if (!hasStartIndices && fromLength >= toLength) {
    return;
  }

  var toData = isConstant ? attribute.value : attribute.getBuffer().getData({
    srcByteOffset: byteOffset
  });

  if (attribute.settings.normalized && !isConstant) {
    var getter = getData;

    getData = function getData(value, chunk) {
      return attribute.normalizeConstant(getter(value, chunk));
    };
  }

  var getMissingData = isConstant ? function (i, chunk) {
    return getData(toData, chunk);
  } : function (i, chunk) {
    return getData(toData.subarray(i, i + size), chunk);
  };
  var source = buffer.getData({
    length: fromLength
  });
  var data = new Float32Array(toLength);
  padArray({
    source: source,
    target: data,
    sourceStartIndices: fromStartIndices,
    targetStartIndices: toStartIndices,
    size: size,
    getData: getMissingData
  });

  if (buffer.byteLength < data.byteLength + byteOffset) {
    buffer.reallocate(data.byteLength + byteOffset);
  }

  buffer.subData({
    data: data,
    offset: byteOffset
  });
}