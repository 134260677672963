import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _inherits from "@babel/runtime/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

import { LayerExtension } from '@deck.gl/core';
var defaultProps = {
  clipBounds: [0, 0, 1, 1],
  clipByInstance: undefined
};
var shaderFunction = "\nuniform vec4 clip_bounds;\n\nbool clip_isInBounds(vec2 position) {\n  return position.x >= clip_bounds[0] && position.y >= clip_bounds[1] && position.x < clip_bounds[2] && position.y < clip_bounds[3];\n}\n";
var shaderModuleVs = {
  name: 'clip-vs',
  vs: shaderFunction
};
var injectionVs = {
  'vs:#decl': "\nvarying float clip_isVisible;\n",
  'vs:DECKGL_FILTER_GL_POSITION': "\n  clip_isVisible = float(clip_isInBounds(geometry.worldPosition.xy));\n",
  'fs:#decl': "\nvarying float clip_isVisible;\n",
  'fs:DECKGL_FILTER_COLOR': "\n  if (clip_isVisible < 0.5) discard;\n"
};
var shaderModuleFs = {
  name: 'clip-fs',
  fs: shaderFunction
};
var injectionFs = {
  'vs:#decl': "\nvarying vec2 clip_commonPosition;\n",
  'vs:DECKGL_FILTER_GL_POSITION': "\n  clip_commonPosition = geometry.position.xy;\n",
  'fs:#decl': "\nvarying vec2 clip_commonPosition;\n",
  'fs:DECKGL_FILTER_COLOR': "\n  if (!clip_isInBounds(clip_commonPosition)) discard;\n"
};

var ClipExtension = function (_LayerExtension) {
  _inherits(ClipExtension, _LayerExtension);

  var _super = _createSuper(ClipExtension);

  function ClipExtension() {
    _classCallCheck(this, ClipExtension);

    return _super.apply(this, arguments);
  }

  _createClass(ClipExtension, [{
    key: "getShaders",
    value: function getShaders() {
      var clipByInstance = ('instancePositions' in this.getAttributeManager().attributes);

      if (this.props.clipByInstance !== undefined) {
        clipByInstance = Boolean(this.props.clipByInstance);
      }

      this.state.clipByInstance = clipByInstance;
      return clipByInstance ? {
        modules: [shaderModuleVs],
        inject: injectionVs
      } : {
        modules: [shaderModuleFs],
        inject: injectionFs
      };
    }
  }, {
    key: "draw",
    value: function draw(_ref) {
      var uniforms = _ref.uniforms;
      var clipBounds = this.props.clipBounds;

      if (this.state.clipByInstance) {
        uniforms.clip_bounds = clipBounds;
      } else {
        var corner0 = this.projectPosition([clipBounds[0], clipBounds[1], 0]);
        var corner1 = this.projectPosition([clipBounds[2], clipBounds[3], 0]);
        uniforms.clip_bounds = [Math.min(corner0[0], corner1[0]), Math.min(corner0[1], corner1[1]), Math.max(corner0[0], corner1[0]), Math.max(corner0[1], corner1[1])];
      }
    }
  }]);

  return ClipExtension;
}(LayerExtension);

_defineProperty(ClipExtension, "defaultProps", defaultProps);

_defineProperty(ClipExtension, "extensionName", 'ClipExtension');

export { ClipExtension as default };