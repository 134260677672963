import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _inherits from "@babel/runtime/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

import { Model, Geometry } from '@luma.gl/core';
import { Layer, project32 } from '@deck.gl/core';
import vs from './triangle-layer-vertex.glsl';
import fs from './triangle-layer-fragment.glsl';

var TriangleLayer = function (_Layer) {
  _inherits(TriangleLayer, _Layer);

  var _super = _createSuper(TriangleLayer);

  function TriangleLayer() {
    _classCallCheck(this, TriangleLayer);

    return _super.apply(this, arguments);
  }

  _createClass(TriangleLayer, [{
    key: "getShaders",
    value: function getShaders() {
      return {
        vs: vs,
        fs: fs,
        modules: [project32]
      };
    }
  }, {
    key: "initializeState",
    value: function initializeState(_ref) {
      var gl = _ref.gl;
      var attributeManager = this.getAttributeManager();
      attributeManager.add({
        positions: {
          size: 3,
          noAlloc: true
        },
        texCoords: {
          size: 2,
          noAlloc: true
        }
      });
      this.setState({
        model: this._getModel(gl)
      });
    }
  }, {
    key: "_getModel",
    value: function _getModel(gl) {
      var vertexCount = this.props.vertexCount;
      return new Model(gl, _objectSpread(_objectSpread({}, this.getShaders()), {}, {
        id: this.props.id,
        geometry: new Geometry({
          drawMode: 6,
          vertexCount: vertexCount
        })
      }));
    }
  }, {
    key: "draw",
    value: function draw(_ref2) {
      var uniforms = _ref2.uniforms;
      var model = this.state.model;
      var _this$props = this.props,
          texture = _this$props.texture,
          maxTexture = _this$props.maxTexture,
          colorTexture = _this$props.colorTexture,
          intensity = _this$props.intensity,
          threshold = _this$props.threshold,
          aggregationMode = _this$props.aggregationMode,
          colorDomain = _this$props.colorDomain;
      model.setUniforms(_objectSpread(_objectSpread({}, uniforms), {}, {
        texture: texture,
        maxTexture: maxTexture,
        colorTexture: colorTexture,
        intensity: intensity,
        threshold: threshold,
        aggregationMode: aggregationMode,
        colorDomain: colorDomain
      })).draw();
    }
  }]);

  return TriangleLayer;
}(Layer);

_defineProperty(TriangleLayer, "layerName", 'TriangleLayer');

export { TriangleLayer as default };