function dtypeToTypedArray(dtype) {
  switch (dtype) {
    case 'int8':
      return Int8Array;

    case 'uint8':
      return Uint8Array;

    case 'int16':
      return Int16Array;

    case 'uint16':
      return Uint16Array;

    case 'float32':
      return Float32Array;

    case 'float64':
      return Float64Array;

    case 'int32':
      return Int32Array;

    case 'uint32':
      return Uint32Array;

    case 'int64':
      return BigInt64Array;

    case 'uint64':
      return BigUint64Array;

    default:
      throw new Error("Unrecognized dtype ".concat(dtype));
  }
}

export function deserializeMatrix(obj, manager) {
  if (!obj) {
    return null;
  }

  for (var layerId in obj) {
    var attributes = obj[layerId].attributes;

    for (var accessorName in attributes) {
      var _attributes$accessorN = attributes[accessorName],
          dtype = _attributes$accessorN.dtype,
          value = _attributes$accessorN.value;
      var ArrayType = dtypeToTypedArray(dtype);
      attributes[accessorName].value = new ArrayType(value.buffer);
    }
  }

  return obj;
}