import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _get from "@babel/runtime/helpers/get";
import _inherits from "@babel/runtime/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

import Layer from './layer';
import debug from '../debug';
import { flatten } from '../utils/flatten';
import { PROP_TYPES_SYMBOL } from '../lifecycle/constants';
var TRACE_RENDER_LAYERS = 'compositeLayer.renderLayers';

var CompositeLayer = function (_Layer) {
  _inherits(CompositeLayer, _Layer);

  var _super = _createSuper(CompositeLayer);

  function CompositeLayer() {
    _classCallCheck(this, CompositeLayer);

    return _super.apply(this, arguments);
  }

  _createClass(CompositeLayer, [{
    key: "isComposite",
    get: function get() {
      return true;
    }
  }, {
    key: "isLoaded",
    get: function get() {
      return _get(_getPrototypeOf(CompositeLayer.prototype), "isLoaded", this) && this.getSubLayers().every(function (layer) {
        return layer.isLoaded;
      });
    }
  }, {
    key: "getSubLayers",
    value: function getSubLayers() {
      return this.internalState && this.internalState.subLayers || [];
    }
  }, {
    key: "initializeState",
    value: function initializeState(context) {}
  }, {
    key: "setState",
    value: function setState(updateObject) {
      _get(_getPrototypeOf(CompositeLayer.prototype), "setState", this).call(this, updateObject);

      this.setNeedsUpdate();
    }
  }, {
    key: "getPickingInfo",
    value: function getPickingInfo(_ref) {
      var info = _ref.info;
      var object = info.object;
      var isDataWrapped = object && object.__source && object.__source.parent && object.__source.parent.id === this.id;

      if (!isDataWrapped) {
        return info;
      }

      info.object = object.__source.object;
      info.index = object.__source.index;
      return info;
    }
  }, {
    key: "filterSubLayer",
    value: function filterSubLayer(context) {
      return true;
    }
  }, {
    key: "shouldRenderSubLayer",
    value: function shouldRenderSubLayer(subLayerId, data) {
      return data && data.length;
    }
  }, {
    key: "getSubLayerClass",
    value: function getSubLayerClass(subLayerId, DefaultLayerClass) {
      var overridingProps = this.props._subLayerProps;
      return overridingProps && overridingProps[subLayerId] && overridingProps[subLayerId].type || DefaultLayerClass;
    }
  }, {
    key: "getSubLayerRow",
    value: function getSubLayerRow(row, sourceObject, sourceObjectIndex) {
      row.__source = {
        parent: this,
        object: sourceObject,
        index: sourceObjectIndex
      };
      return row;
    }
  }, {
    key: "getSubLayerAccessor",
    value: function getSubLayerAccessor(accessor) {
      if (typeof accessor === 'function') {
        var objectInfo = {
          index: -1,
          data: this.props.data,
          target: []
        };
        return function (x, i) {
          if (x && x.__source) {
            objectInfo.index = x.__source.index;
            return accessor(x.__source.object, objectInfo);
          }

          return accessor(x, i);
        };
      }

      return accessor;
    }
  }, {
    key: "getSubLayerProps",
    value: function getSubLayerProps() {
      var _this$props$updateTri;

      var sublayerProps = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var _this$props = this.props,
          opacity = _this$props.opacity,
          pickable = _this$props.pickable,
          visible = _this$props.visible,
          parameters = _this$props.parameters,
          getPolygonOffset = _this$props.getPolygonOffset,
          highlightedObjectIndex = _this$props.highlightedObjectIndex,
          autoHighlight = _this$props.autoHighlight,
          highlightColor = _this$props.highlightColor,
          coordinateSystem = _this$props.coordinateSystem,
          coordinateOrigin = _this$props.coordinateOrigin,
          wrapLongitude = _this$props.wrapLongitude,
          positionFormat = _this$props.positionFormat,
          modelMatrix = _this$props.modelMatrix,
          extensions = _this$props.extensions,
          fetch = _this$props.fetch,
          operation = _this$props.operation,
          overridingProps = _this$props._subLayerProps;
      var newProps = {
        id: '',
        updateTriggers: {},
        opacity: opacity,
        pickable: pickable,
        visible: visible,
        parameters: parameters,
        getPolygonOffset: getPolygonOffset,
        highlightedObjectIndex: highlightedObjectIndex,
        autoHighlight: autoHighlight,
        highlightColor: highlightColor,
        coordinateSystem: coordinateSystem,
        coordinateOrigin: coordinateOrigin,
        wrapLongitude: wrapLongitude,
        positionFormat: positionFormat,
        modelMatrix: modelMatrix,
        extensions: extensions,
        fetch: fetch,
        operation: operation
      };
      var overridingSublayerProps = overridingProps && sublayerProps.id && overridingProps[sublayerProps.id];
      var overridingSublayerTriggers = overridingSublayerProps && overridingSublayerProps.updateTriggers;
      var sublayerId = sublayerProps.id || 'sublayer';

      if (overridingSublayerProps) {
        var propTypes = this.props[PROP_TYPES_SYMBOL];
        var subLayerPropTypes = sublayerProps.type ? sublayerProps.type._propTypes : {};

        for (var key in overridingSublayerProps) {
          var propType = subLayerPropTypes[key] || propTypes[key];

          if (propType && propType.type === 'accessor') {
            overridingSublayerProps[key] = this.getSubLayerAccessor(overridingSublayerProps[key]);
          }
        }
      }

      Object.assign(newProps, sublayerProps, overridingSublayerProps);
      newProps.id = "".concat(this.props.id, "-").concat(sublayerId);
      newProps.updateTriggers = _objectSpread(_objectSpread({
        all: (_this$props$updateTri = this.props.updateTriggers) === null || _this$props$updateTri === void 0 ? void 0 : _this$props$updateTri.all
      }, sublayerProps.updateTriggers), overridingSublayerTriggers);

      var _iterator = _createForOfIteratorHelper(extensions),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var extension = _step.value;
          var passThroughProps = extension.getSubLayerProps.call(this, extension);

          if (passThroughProps) {
            Object.assign(newProps, passThroughProps, {
              updateTriggers: Object.assign(newProps.updateTriggers, passThroughProps.updateTriggers)
            });
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      return newProps;
    }
  }, {
    key: "_updateAutoHighlight",
    value: function _updateAutoHighlight(info) {
      var _iterator2 = _createForOfIteratorHelper(this.getSubLayers()),
          _step2;

      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var layer = _step2.value;
          layer.updateAutoHighlight(info);
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }
    }
  }, {
    key: "_getAttributeManager",
    value: function _getAttributeManager() {
      return null;
    }
  }, {
    key: "_postUpdate",
    value: function _postUpdate(updateParams, forceUpdate) {
      var subLayers = this.internalState.subLayers;
      var shouldUpdate = !subLayers || this.needsUpdate();

      if (shouldUpdate) {
        var subLayersList = this.renderLayers();
        subLayers = flatten(subLayersList, Boolean);
        this.internalState.subLayers = subLayers;
      }

      debug(TRACE_RENDER_LAYERS, this, shouldUpdate, subLayers);

      var _iterator3 = _createForOfIteratorHelper(subLayers),
          _step3;

      try {
        for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
          var layer = _step3.value;
          layer.parent = this;
        }
      } catch (err) {
        _iterator3.e(err);
      } finally {
        _iterator3.f();
      }
    }
  }]);

  return CompositeLayer;
}(Layer);

_defineProperty(CompositeLayer, "layerName", 'CompositeLayer');

export { CompositeLayer as default };