import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
var BASE32_CODES = '0123456789bcdefghjkmnpqrstuvwxyz';
var BASE32_CODES_DICT = {};

for (var i = 0; i < BASE32_CODES.length; i++) {
  BASE32_CODES_DICT[BASE32_CODES.charAt(i)] = i;
}

var MIN_LAT = -90;
var MAX_LAT = 90;
var MIN_LON = -180;
var MAX_LON = 180;
export function getGeohashBounds(geohash) {
  var isLon = true;
  var maxLat = MAX_LAT;
  var minLat = MIN_LAT;
  var maxLon = MAX_LON;
  var minLon = MIN_LON;
  var mid;
  var hashValue = 0;

  for (var _i = 0, l = geohash.length; _i < l; _i++) {
    var code = geohash[_i].toLowerCase();

    hashValue = BASE32_CODES_DICT[code];

    for (var bits = 4; bits >= 0; bits--) {
      var bit = hashValue >> bits & 1;

      if (isLon) {
        mid = (maxLon + minLon) / 2;

        if (bit === 1) {
          minLon = mid;
        } else {
          maxLon = mid;
        }
      } else {
        mid = (maxLat + minLat) / 2;

        if (bit === 1) {
          minLat = mid;
        } else {
          maxLat = mid;
        }
      }

      isLon = !isLon;
    }
  }

  return [minLat, minLon, maxLat, maxLon];
}
export function getGeohashPolygon(geohash) {
  var _getGeohashBounds = getGeohashBounds(geohash),
      _getGeohashBounds2 = _slicedToArray(_getGeohashBounds, 4),
      s = _getGeohashBounds2[0],
      w = _getGeohashBounds2[1],
      n = _getGeohashBounds2[2],
      e = _getGeohashBounds2[3];

  return [e, n, e, s, w, s, w, n, e, n];
}