import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _typeof from "@babel/runtime/helpers/typeof";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import assert from './utils/assert';
import JSONConfiguration from './json-configuration';
import { instantiateClass } from './helpers/instantiate-class';
import { executeFunction } from './helpers/execute-function';
import { FUNCTION_IDENTIFIER, CONSTANT_IDENTIFIER, FUNCTION_KEY } from './syntactic-sugar';
import parseJSON from './helpers/parse-json';

var isObject = function isObject(value) {
  return value && _typeof(value) === 'object';
};

var JSONConverter = function () {
  function JSONConverter(props) {
    _classCallCheck(this, JSONConverter);

    this.log = console;
    this.configuration = {};

    this.onJSONChange = function () {};

    this.json = null;
    this.convertedJson = null;
    this.setProps(props);
  }

  _createClass(JSONConverter, [{
    key: "finalize",
    value: function finalize() {}
  }, {
    key: "setProps",
    value: function setProps(props) {
      if ('configuration' in props) {
        this.configuration = props.configuration instanceof JSONConfiguration ? props.configuration : new JSONConfiguration(props.configuration);
      }

      if ('onJSONChange' in props) {
        this.onJSONChange = props.onJSONChange;
      }
    }
  }, {
    key: "mergeConfiguration",
    value: function mergeConfiguration(config) {
      this.configuration.merge(config);
    }
  }, {
    key: "convert",
    value: function convert(json) {
      if (!json || json === this.json) {
        return this.convertedJson;
      }

      this.json = json;
      var parsedJSON = parseJSON(json);
      var convertedJson = convertJSON(parsedJSON, this.configuration);
      convertedJson = this.configuration.postProcessConvertedJson(convertedJson);
      this.convertedJson = convertedJson;
      return convertedJson;
    }
  }, {
    key: "convertJson",
    value: function convertJson(json) {
      return this.convert(json);
    }
  }]);

  return JSONConverter;
}();

export { JSONConverter as default };

function convertJSON(json, configuration) {
  configuration = new JSONConfiguration(configuration);
  return convertJSONRecursively(json, '', configuration);
}

function convertJSONRecursively(json, key, configuration) {
  if (Array.isArray(json)) {
    return json.map(function (element, i) {
      return convertJSONRecursively(element, String(i), configuration);
    });
  }

  if (isClassInstance(json, configuration)) {
    return convertClassInstance(json, configuration);
  }

  if (isObject(json)) {
    if (FUNCTION_KEY in json) {
      return convertFunctionObject(json, configuration);
    }

    return convertPlainObject(json, configuration);
  }

  if (typeof json === 'string') {
    return convertString(json, key, configuration);
  }

  return json;
}

function isClassInstance(json, configuration) {
  var typeKey = configuration.typeKey;
  var isClass = isObject(json) && Boolean(json[typeKey]);
  return isClass;
}

function convertClassInstance(json, configuration) {
  var typeKey = configuration.typeKey;
  var type = json[typeKey];

  var props = _objectSpread({}, json);

  delete props[typeKey];
  props = convertPlainObject(props, configuration);
  return instantiateClass(type, props, configuration);
}

function convertFunctionObject(json, configuration) {
  var functionKey = configuration.functionKey;
  var targetFunction = json[functionKey];

  var props = _objectSpread({}, json);

  delete props[functionKey];
  props = convertPlainObject(props, configuration);
  return executeFunction(targetFunction, props, configuration);
}

function convertPlainObject(json, configuration) {
  assert(isObject(json));
  var result = {};

  for (var key in json) {
    var value = json[key];
    result[key] = convertJSONRecursively(value, key, configuration);
  }

  return result;
}

function convertString(string, key, configuration) {
  if (string.startsWith(FUNCTION_IDENTIFIER) && configuration.convertFunction) {
    string = string.replace(FUNCTION_IDENTIFIER, '');
    return configuration.convertFunction(string, configuration);
  }

  if (string.startsWith(CONSTANT_IDENTIFIER)) {
    string = string.replace(CONSTANT_IDENTIFIER, '');

    if (configuration.constants[string]) {
      return configuration.constants[string];
    }

    var _string$split = string.split('.'),
        _string$split2 = _slicedToArray(_string$split, 2),
        enumVarName = _string$split2[0],
        enumValName = _string$split2[1];

    return configuration.enumerations[enumVarName][enumValName];
  }

  return string;
}