import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { deepEqual } from '../utils/deep-equal';

var LayerExtension = function () {
  function LayerExtension(opts) {
    _classCallCheck(this, LayerExtension);

    _defineProperty(this, "opts", void 0);

    if (opts) {
      this.opts = opts;
    }
  }

  _createClass(LayerExtension, [{
    key: "equals",
    value: function equals(extension) {
      if (this === extension) {
        return true;
      }

      return this.constructor === extension.constructor && deepEqual(this.opts, extension.opts, 1);
    }
  }, {
    key: "getShaders",
    value: function getShaders(extension) {
      return null;
    }
  }, {
    key: "getSubLayerProps",
    value: function getSubLayerProps(extension) {
      var _ref = extension.constructor,
          defaultProps = _ref.defaultProps;
      var newProps = {
        updateTriggers: {}
      };

      for (var key in defaultProps) {
        if (key in this.props) {
          var propDef = defaultProps[key];
          var propValue = this.props[key];
          newProps[key] = propValue;

          if (propDef && propDef.type === 'accessor') {
            newProps.updateTriggers[key] = this.props.updateTriggers[key];

            if (typeof propValue === 'function') {
              newProps[key] = this.getSubLayerAccessor(propValue);
            }
          }
        }
      }

      return newProps;
    }
  }, {
    key: "initializeState",
    value: function initializeState(context, extension) {}
  }, {
    key: "updateState",
    value: function updateState(params, extension) {}
  }, {
    key: "onNeedsRedraw",
    value: function onNeedsRedraw(extension) {}
  }, {
    key: "getNeedsPickingBuffer",
    value: function getNeedsPickingBuffer(extension) {
      return false;
    }
  }, {
    key: "draw",
    value: function draw(params, extension) {}
  }, {
    key: "finalizeState",
    value: function finalizeState(context, extension) {}
  }], [{
    key: "componentName",
    get: function get() {
      return Object.prototype.hasOwnProperty.call(this, 'extensionName') ? this.extensionName : '';
    }
  }]);

  return LayerExtension;
}();

_defineProperty(LayerExtension, "defaultProps", {});

_defineProperty(LayerExtension, "extensionName", 'LayerExtension');

export { LayerExtension as default };