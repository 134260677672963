function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import { getCode, getVertices, CONTOUR_TYPE } from './marching-squares';
export function generateContours(_ref) {
  var thresholdData = _ref.thresholdData,
      cellWeights = _ref.cellWeights,
      gridSize = _ref.gridSize,
      gridOrigin = _ref.gridOrigin,
      cellSize = _ref.cellSize;
  var contourSegments = [];
  var contourPolygons = [];
  var width = gridSize[0];
  var height = gridSize[1];
  var segmentIndex = 0;
  var polygonIndex = 0;

  var _iterator = _createForOfIteratorHelper(thresholdData),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var data = _step.value;
      var contour = data.contour;
      var threshold = contour.threshold;

      for (var x = -1; x < width; x++) {
        for (var y = -1; y < height; y++) {
          var _getCode = getCode({
            cellWeights: cellWeights,
            threshold: threshold,
            x: x,
            y: y,
            width: width,
            height: height
          }),
              code = _getCode.code,
              meanCode = _getCode.meanCode;

          var opts = {
            type: CONTOUR_TYPE.ISO_BANDS,
            gridOrigin: gridOrigin,
            cellSize: cellSize,
            x: x,
            y: y,
            width: width,
            height: height,
            code: code,
            meanCode: meanCode,
            thresholdData: data
          };

          if (Array.isArray(threshold)) {
            opts.type = CONTOUR_TYPE.ISO_BANDS;
            var polygons = getVertices(opts);

            var _iterator2 = _createForOfIteratorHelper(polygons),
                _step2;

            try {
              for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
                var polygon = _step2.value;
                contourPolygons[polygonIndex++] = {
                  vertices: polygon,
                  contour: contour
                };
              }
            } catch (err) {
              _iterator2.e(err);
            } finally {
              _iterator2.f();
            }
          } else {
            opts.type = CONTOUR_TYPE.ISO_LINES;
            var vertices = getVertices(opts);

            for (var i = 0; i < vertices.length; i += 2) {
              contourSegments[segmentIndex++] = {
                start: vertices[i],
                end: vertices[i + 1],
                contour: contour
              };
            }
          }
        }
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  return {
    contourSegments: contourSegments,
    contourPolygons: contourPolygons
  };
}