import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import { Buffer, Transform } from '@luma.gl/core';
import Attribute from '../lib/attribute/attribute';
import { padBuffer, getAttributeTypeFromSize, getSourceBufferAttribute, getAttributeBufferLength, cycleBuffers } from '../lib/attribute/attribute-transition-utils';
import Transition from './transition';

var GPUInterpolationTransition = function () {
  function GPUInterpolationTransition(_ref) {
    var gl = _ref.gl,
        attribute = _ref.attribute,
        timeline = _ref.timeline;

    _classCallCheck(this, GPUInterpolationTransition);

    _defineProperty(this, "gl", void 0);

    _defineProperty(this, "type", 'interpolation');

    _defineProperty(this, "attributeInTransition", void 0);

    _defineProperty(this, "settings", void 0);

    _defineProperty(this, "attribute", void 0);

    _defineProperty(this, "transition", void 0);

    _defineProperty(this, "currentStartIndices", void 0);

    _defineProperty(this, "currentLength", void 0);

    _defineProperty(this, "transform", void 0);

    _defineProperty(this, "buffers", void 0);

    this.gl = gl;
    this.transition = new Transition(timeline);
    this.attribute = attribute;
    this.attributeInTransition = new Attribute(gl, attribute.settings);
    this.currentStartIndices = attribute.startIndices;
    this.currentLength = 0;
    this.transform = getTransform(gl, attribute);
    var bufferOpts = {
      byteLength: 0,
      usage: 35050
    };
    this.buffers = [new Buffer(gl, bufferOpts), new Buffer(gl, bufferOpts)];
  }

  _createClass(GPUInterpolationTransition, [{
    key: "inProgress",
    get: function get() {
      return this.transition.inProgress;
    }
  }, {
    key: "start",
    value: function start(transitionSettings, numInstances) {
      if (transitionSettings.duration <= 0) {
        this.transition.cancel();
        return;
      }

      this.settings = transitionSettings;
      var gl = this.gl,
          buffers = this.buffers,
          attribute = this.attribute;
      cycleBuffers(buffers);
      var padBufferOpts = {
        numInstances: numInstances,
        attribute: attribute,
        fromLength: this.currentLength,
        fromStartIndices: this.currentStartIndices,
        getData: transitionSettings.enter
      };

      var _iterator = _createForOfIteratorHelper(buffers),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var buffer = _step.value;
          padBuffer(_objectSpread({
            buffer: buffer
          }, padBufferOpts));
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      this.currentStartIndices = attribute.startIndices;
      this.currentLength = getAttributeBufferLength(attribute, numInstances);
      this.attributeInTransition.setData({
        buffer: buffers[1],
        value: attribute.value
      });
      this.transition.start(transitionSettings);
      this.transform.update({
        elementCount: Math.floor(this.currentLength / attribute.size),
        sourceBuffers: {
          aFrom: buffers[0],
          aTo: getSourceBufferAttribute(gl, attribute)
        },
        feedbackBuffers: {
          vCurrent: buffers[1]
        }
      });
    }
  }, {
    key: "update",
    value: function update() {
      var updated = this.transition.update();

      if (updated) {
        var _ref2 = this.settings,
            duration = _ref2.duration,
            easing = _ref2.easing;
        var time = this.transition.time;
        var t = time / duration;

        if (easing) {
          t = easing(t);
        }

        this.transform.run({
          uniforms: {
            time: t
          }
        });
      }

      return updated;
    }
  }, {
    key: "cancel",
    value: function cancel() {
      this.transition.cancel();
      this.transform.delete();

      var _iterator2 = _createForOfIteratorHelper(this.buffers),
          _step2;

      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var buffer = _step2.value;
          buffer.delete();
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }

      this.buffers.length = 0;
    }
  }]);

  return GPUInterpolationTransition;
}();

export { GPUInterpolationTransition as default };
var vs = "\n#define SHADER_NAME interpolation-transition-vertex-shader\n\nuniform float time;\nattribute ATTRIBUTE_TYPE aFrom;\nattribute ATTRIBUTE_TYPE aTo;\nvarying ATTRIBUTE_TYPE vCurrent;\n\nvoid main(void) {\n  vCurrent = mix(aFrom, aTo, time);\n  gl_Position = vec4(0.0);\n}\n";

function getTransform(gl, attribute) {
  var attributeType = getAttributeTypeFromSize(attribute.size);
  return new Transform(gl, {
    vs: vs,
    defines: {
      ATTRIBUTE_TYPE: attributeType
    },
    varyings: ['vCurrent']
  });
}