import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

var Transition = function () {
  function Transition(timeline) {
    _classCallCheck(this, Transition);

    _defineProperty(this, "_inProgress", void 0);

    _defineProperty(this, "_handle", void 0);

    _defineProperty(this, "_timeline", void 0);

    _defineProperty(this, "time", void 0);

    _defineProperty(this, "settings", void 0);

    this._inProgress = false;
    this._handle = null;
    this._timeline = timeline;
    this.time = 0;
    this.settings = {
      duration: 0
    };
  }

  _createClass(Transition, [{
    key: "inProgress",
    get: function get() {
      return this._inProgress;
    }
  }, {
    key: "start",
    value: function start(settings) {
      var _this$settings$onStar, _this$settings;

      this.cancel();
      this.settings = settings;
      this._inProgress = true;
      (_this$settings$onStar = (_this$settings = this.settings).onStart) === null || _this$settings$onStar === void 0 ? void 0 : _this$settings$onStar.call(_this$settings, this);
    }
  }, {
    key: "end",
    value: function end() {
      if (this._inProgress) {
        var _this$settings$onEnd, _this$settings2;

        this._timeline.removeChannel(this._handle);

        this._handle = null;
        this._inProgress = false;
        (_this$settings$onEnd = (_this$settings2 = this.settings).onEnd) === null || _this$settings$onEnd === void 0 ? void 0 : _this$settings$onEnd.call(_this$settings2, this);
      }
    }
  }, {
    key: "cancel",
    value: function cancel() {
      if (this._inProgress) {
        var _this$settings$onInte, _this$settings3;

        (_this$settings$onInte = (_this$settings3 = this.settings).onInterrupt) === null || _this$settings$onInte === void 0 ? void 0 : _this$settings$onInte.call(_this$settings3, this);

        this._timeline.removeChannel(this._handle);

        this._handle = null;
        this._inProgress = false;
      }
    }
  }, {
    key: "update",
    value: function update() {
      var _this$settings$onUpda, _this$settings4;

      if (!this._inProgress) {
        return false;
      }

      if (this._handle === null) {
        var timeline = this._timeline,
            settings = this.settings;
        this._handle = timeline.addChannel({
          delay: timeline.getTime(),
          duration: settings.duration
        });
      }

      this.time = this._timeline.getTime(this._handle);

      this._onUpdate();

      (_this$settings$onUpda = (_this$settings4 = this.settings).onUpdate) === null || _this$settings$onUpda === void 0 ? void 0 : _this$settings$onUpda.call(_this$settings4, this);

      if (this._timeline.isFinished(this._handle)) {
        this.end();
      }

      return true;
    }
  }, {
    key: "_onUpdate",
    value: function _onUpdate() {}
  }]);

  return Transition;
}();

export { Transition as default };