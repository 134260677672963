import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

export function getEmptyPickingInfo(_ref) {
  var pickInfo = _ref.pickInfo,
      viewports = _ref.viewports,
      pixelRatio = _ref.pixelRatio,
      x = _ref.x,
      y = _ref.y,
      z = _ref.z;
  var pickedViewport = viewports[0];

  if (viewports.length > 1) {
    pickedViewport = getViewportFromCoordinates((pickInfo === null || pickInfo === void 0 ? void 0 : pickInfo.pickedViewports) || viewports, {
      x: x,
      y: y
    });
  }

  var coordinate;

  if (pickedViewport) {
    var point = [x - pickedViewport.x, y - pickedViewport.y];

    if (z !== undefined) {
      point[2] = z;
    }

    coordinate = pickedViewport.unproject(point);
  }

  return {
    color: null,
    layer: null,
    viewport: pickedViewport,
    index: -1,
    picked: false,
    x: x,
    y: y,
    pixel: [x, y],
    coordinate: coordinate,
    devicePixel: pickInfo && 'pickedX' in pickInfo ? [pickInfo.pickedX, pickInfo.pickedY] : undefined,
    pixelRatio: pixelRatio
  };
}
export function processPickInfo(opts) {
  var pickInfo = opts.pickInfo,
      lastPickedInfo = opts.lastPickedInfo,
      mode = opts.mode,
      layers = opts.layers;
  var pickedColor = pickInfo.pickedColor,
      pickedLayer = pickInfo.pickedLayer,
      pickedObjectIndex = pickInfo.pickedObjectIndex;
  var affectedLayers = pickedLayer ? [pickedLayer] : [];

  if (mode === 'hover') {
    var lastPickedPixelIndex = lastPickedInfo.index;
    var lastPickedLayerId = lastPickedInfo.layerId;
    var pickedLayerId = pickedLayer ? pickedLayer.props.id : null;

    if (pickedLayerId !== lastPickedLayerId || pickedObjectIndex !== lastPickedPixelIndex) {
      if (pickedLayerId !== lastPickedLayerId) {
        var lastPickedLayer = layers.find(function (layer) {
          return layer.props.id === lastPickedLayerId;
        });

        if (lastPickedLayer) {
          affectedLayers.unshift(lastPickedLayer);
        }
      }

      lastPickedInfo.layerId = pickedLayerId;
      lastPickedInfo.index = pickedObjectIndex;
      lastPickedInfo.info = null;
    }
  }

  var baseInfo = getEmptyPickingInfo(opts);
  var infos = new Map();
  infos.set(null, baseInfo);
  affectedLayers.forEach(function (layer) {
    var info = _objectSpread({}, baseInfo);

    if (layer === pickedLayer) {
      info.color = pickedColor;
      info.index = pickedObjectIndex;
      info.picked = true;
    }

    info = getLayerPickingInfo({
      layer: layer,
      info: info,
      mode: mode
    });
    var rootLayer = info.layer;

    if (layer === pickedLayer && mode === 'hover') {
      lastPickedInfo.info = info;
    }

    infos.set(rootLayer.id, info);

    if (mode === 'hover') {
      rootLayer.updateAutoHighlight(info);
    }
  });
  return infos;
}
export function getLayerPickingInfo(_ref2) {
  var layer = _ref2.layer,
      info = _ref2.info,
      mode = _ref2.mode;

  while (layer && info) {
    var sourceLayer = info.layer || null;
    info.sourceLayer = sourceLayer;
    info.layer = layer;
    info = layer.getPickingInfo({
      info: info,
      mode: mode,
      sourceLayer: sourceLayer
    });
    layer = layer.parent;
  }

  return info;
}

function getViewportFromCoordinates(viewports, pixel) {
  for (var i = viewports.length - 1; i >= 0; i--) {
    var viewport = viewports[i];

    if (viewport.containsPixel(pixel)) {
      return viewport;
    }
  }

  return viewports[0];
}