export var AGGREGATION_OPERATION = {
  SUM: 1,
  MEAN: 2,
  MIN: 3,
  MAX: 4
};

function sumReducer(accu, cur) {
  return accu + cur;
}

function maxReducer(accu, cur) {
  return cur > accu ? cur : accu;
}

function minReducer(accu, cur) {
  return cur < accu ? cur : accu;
}

export function getMean(pts, accessor) {
  if (Number.isFinite(accessor)) {
    return pts.length ? accessor : null;
  }

  var filtered = pts.map(accessor).filter(Number.isFinite);
  return filtered.length ? filtered.reduce(sumReducer, 0) / filtered.length : null;
}
export function getSum(pts, accessor) {
  if (Number.isFinite(accessor)) {
    return pts.length ? pts.length * accessor : null;
  }

  var filtered = pts.map(accessor).filter(Number.isFinite);
  return filtered.length ? filtered.reduce(sumReducer, 0) : null;
}
export function getMax(pts, accessor) {
  if (Number.isFinite(accessor)) {
    return pts.length ? accessor : null;
  }

  var filtered = pts.map(accessor).filter(Number.isFinite);
  return filtered.length ? filtered.reduce(maxReducer, -Infinity) : null;
}
export function getMin(pts, accessor) {
  if (Number.isFinite(accessor)) {
    return pts.length ? accessor : null;
  }

  var filtered = pts.map(accessor).filter(Number.isFinite);
  return filtered.length ? filtered.reduce(minReducer, Infinity) : null;
}
export function getValueFunc(aggregation, accessor, context) {
  var op = AGGREGATION_OPERATION[aggregation] || AGGREGATION_OPERATION.SUM;
  accessor = wrapAccessor(accessor, context);

  switch (op) {
    case AGGREGATION_OPERATION.MIN:
      return function (pts) {
        return getMin(pts, accessor);
      };

    case AGGREGATION_OPERATION.SUM:
      return function (pts) {
        return getSum(pts, accessor);
      };

    case AGGREGATION_OPERATION.MEAN:
      return function (pts) {
        return getMean(pts, accessor);
      };

    case AGGREGATION_OPERATION.MAX:
      return function (pts) {
        return getMax(pts, accessor);
      };

    default:
      return null;
  }
}

function wrapAccessor(accessor) {
  var context = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  if (Number.isFinite(accessor)) {
    return accessor;
  }

  return function (pt) {
    context.index = pt.index;
    return accessor(pt.source, context);
  };
}

export function wrapGetValueFunc(getValue) {
  var context = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return function (pts) {
    context.indices = pts.map(function (pt) {
      return pt.index;
    });
    return getValue(pts.map(function (pt) {
      return pt.source;
    }), context);
  };
}