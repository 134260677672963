import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import { CSVLoader } from '@loaders.gl/csv';
import { registerLoaders } from '@loaders.gl/core';
import GLConstants from '@luma.gl/constants';
import makeTooltip from './widget-tooltip';
import mapboxgl, { modifyMapboxElements } from './utils/mapbox-utils';
import { loadScript } from './utils/script-utils';
import { createGoogleMapsDeckOverlay } from './utils/google-maps-utils';
import { addSupportComponents } from '../lib/components/index';
import * as deckExports from '../deck-bundle';
import { COORDINATE_SYSTEM, log, WebMercatorViewport } from '@deck.gl/core';
import { JSONConverter } from '@deck.gl/json';
import DeckGL from '@deck.gl/core/scripting/deckgl';

var classesFilter = function classesFilter(x) {
  return x.charAt(0) === x.charAt(0).toUpperCase();
};

var functionsFilter = function functionsFilter(x) {
  return x.charAt(0) === x.charAt(0).toLowerCase() && x.charAt(0) !== '_';
};

function extractElements() {
  var library = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var filter = arguments.length > 1 ? arguments[1] : undefined;
  var dict = {};
  var elements = Object.keys(library).filter(filter);

  var _iterator = _createForOfIteratorHelper(elements),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var el = _step.value;
      dict[el] = library[el];
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  return dict;
}

var jsonConverterConfiguration = {
  classes: extractElements(deckExports, classesFilter),
  enumerations: {
    COORDINATE_SYSTEM: COORDINATE_SYSTEM,
    GL: GLConstants
  }
};
registerLoaders([CSVLoader]);
var jsonConverter = new JSONConverter({
  configuration: jsonConverterConfiguration
});

function addModuleToConverter(module, converter) {
  var newConfiguration = {
    classes: extractElements(module, classesFilter),
    functions: extractElements(module, functionsFilter)
  };
  converter.mergeConfiguration(newConfiguration);
}

export function addCustomLibraries(customLibraries, onComplete) {
  if (!customLibraries) {
    return;
  }

  var loaded = {};

  function onEachFinish() {
    if (Object.values(loaded).every(function (f) {
      return f;
    })) {
      if (typeof onComplete === 'function') onComplete();
    }
  }

  function onModuleLoaded(libraryName, module) {
    addModuleToConverter(module, jsonConverter);
    loaded[libraryName] = module;
    onEachFinish();
  }

  customLibraries.forEach(function (_ref) {
    var libraryName = _ref.libraryName,
        resourceUri = _ref.resourceUri;
    loaded[libraryName] = false;

    if (libraryName in window) {
      onModuleLoaded(libraryName, window[libraryName]);
      return;
    }

    Object.defineProperty(window, libraryName, {
      set: function set(module) {
        return onModuleLoaded(libraryName, module);
      },
      get: function get() {
        return loaded[libraryName];
      }
    });
    loadScript(resourceUri);
  });
}

function updateDeck(inputJson, deckgl) {
  var results = jsonConverter.convert(inputJson);
  deckgl.setProps(results);
}

function missingLayers(oldLayers, newLayers) {
  return oldLayers.filter(function (ol) {
    return ol && ol.id && !newLayers.find(function (nl) {
      return nl.id === ol.id;
    });
  });
}

function createStandaloneFromProvider(_ref2) {
  var mapProvider = _ref2.mapProvider,
      props = _ref2.props,
      mapboxApiKey = _ref2.mapboxApiKey,
      googleMapsKey = _ref2.googleMapsKey,
      handleEvent = _ref2.handleEvent,
      getTooltip = _ref2.getTooltip,
      container = _ref2.container;
  var handlers = handleEvent ? {
    onClick: function onClick(info) {
      return handleEvent('deck-click-event', info);
    },
    onHover: function onHover(info) {
      return handleEvent('deck-hover-event', info);
    },
    onResize: function onResize(size) {
      return handleEvent('deck-resize-event', size);
    },
    onViewStateChange: function onViewStateChange(_ref3) {
      var viewState = _ref3.viewState,
          interactionState = _ref3.interactionState,
          oldViewState = _ref3.oldViewState;
      var viewport = new WebMercatorViewport(viewState);
      viewState.nw = viewport.unproject([0, 0]);
      viewState.se = viewport.unproject([viewport.width, viewport.height]);
      handleEvent('deck-view-state-change-event', viewState);
    },
    onDragStart: function onDragStart(info) {
      return handleEvent('deck-drag-start-event', info);
    },
    onDrag: function onDrag(info) {
      return handleEvent('deck-drag-event', info);
    },
    onDragEnd: function onDragEnd(info) {
      return handleEvent('deck-drag-end-event', info);
    }
  } : null;

  var sharedProps = _objectSpread(_objectSpread({}, handlers), {}, {
    getTooltip: getTooltip,
    container: container
  });

  switch (mapProvider) {
    case 'mapbox':
      log.info('Using Mapbox base maps')();
      return new DeckGL(_objectSpread(_objectSpread(_objectSpread({}, sharedProps), props), {}, {
        map: mapboxgl,
        mapboxApiAccessToken: mapboxApiKey,
        onLoad: modifyMapboxElements
      }));

    case 'carto':
      log.info('Using Carto base maps')();
      return new DeckGL(_objectSpread(_objectSpread({
        map: mapboxgl
      }, sharedProps), props));

    case 'google_maps':
      log.info('Using Google Maps base maps')();
      return createGoogleMapsDeckOverlay(_objectSpread(_objectSpread(_objectSpread({}, sharedProps), props), {}, {
        googleMapsKey: googleMapsKey
      }));

    default:
      log.info('No recognized map provider specified')();
      return new DeckGL(_objectSpread(_objectSpread(_objectSpread({}, sharedProps), props), {}, {
        map: null,
        mapboxApiAccessToken: null
      }));
  }
}

function createDeck(_ref4) {
  var mapboxApiKey = _ref4.mapboxApiKey,
      googleMapsKey = _ref4.googleMapsKey,
      container = _ref4.container,
      jsonInput = _ref4.jsonInput,
      tooltip = _ref4.tooltip,
      handleEvent = _ref4.handleEvent,
      customLibraries = _ref4.customLibraries,
      configuration = _ref4.configuration;
  var deckgl;

  try {
    if (configuration) {
      jsonConverter.mergeConfiguration(configuration);
    }

    var oldLayers = jsonInput.layers || [];
    var props = jsonConverter.convert(jsonInput);
    addSupportComponents(container, props);
    var convertedLayers = (props.layers || []).filter(function (l) {
      return l;
    });
    var layerToLoad = missingLayers(oldLayers, convertedLayers);
    var getTooltip = makeTooltip(tooltip);
    var mapProvider = props.mapProvider;
    deckgl = createStandaloneFromProvider({
      mapProvider: mapProvider,
      props: props,
      mapboxApiKey: mapboxApiKey,
      googleMapsKey: googleMapsKey,
      handleEvent: handleEvent,
      getTooltip: getTooltip,
      container: container
    });

    var onComplete = function onComplete() {
      if (layerToLoad.length) {
        var newProps = jsonConverter.convert({
          layers: jsonInput.layers
        });
        var newLayers = (newProps.layers || []).filter(function (l) {
          return l;
        });

        if (newLayers.length > convertedLayers.length) {
          deckgl.setProps({
            layers: newLayers
          });
        }
      }
    };

    addCustomLibraries(customLibraries, onComplete);
  } catch (err) {
    console.error(err);
  }

  return deckgl;
}

export { createDeck, updateDeck, jsonConverter };