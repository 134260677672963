import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import LayerManager from './layer-manager';
import ViewManager from './view-manager';
import MapView from '../views/map-view';
import EffectManager from './effect-manager';
import DeckRenderer from './deck-renderer';
import DeckPicker from './deck-picker';
import Tooltip from './tooltip';
import log from '../utils/log';
import { deepEqual } from '../utils/deep-equal';
import typedArrayManager from '../utils/typed-array-manager';
import { VERSION } from './init';
import { getBrowser } from '@probe.gl/env';
import { AnimationLoop, createGLContext, instrumentGLContext, setParameters, Timeline, lumaStats } from '@luma.gl/core';
import { Stats } from '@probe.gl/stats';
import { EventManager } from 'mjolnir.js';
import assert from '../utils/assert';
import { EVENTS } from './constants';

function noop() {}

var getCursor = function getCursor(_ref) {
  var isDragging = _ref.isDragging;
  return isDragging ? 'grabbing' : 'grab';
};

var defaultProps = {
  id: '',
  width: '100%',
  height: '100%',
  style: null,
  viewState: null,
  initialViewState: null,
  pickingRadius: 0,
  layerFilter: null,
  glOptions: {},
  parameters: {},
  parent: null,
  gl: null,
  canvas: null,
  layers: [],
  effects: [],
  views: null,
  controller: null,
  useDevicePixels: true,
  touchAction: 'none',
  eventRecognizerOptions: {},
  _framebuffer: null,
  _animate: false,
  _pickable: true,
  _typedArrayManagerProps: {},
  _customRender: null,
  onWebGLInitialized: noop,
  onResize: noop,
  onViewStateChange: noop,
  onInteractionStateChange: noop,
  onBeforeRender: noop,
  onAfterRender: noop,
  onLoad: noop,
  onError: function onError(error) {
    return log.error(error.message, error.cause)();
  },
  onHover: null,
  onClick: null,
  onDragStart: null,
  onDrag: null,
  onDragEnd: null,
  _onMetrics: null,
  getCursor: getCursor,
  getTooltip: null,
  debug: false,
  drawPickingColors: false
};

var Deck = function () {
  function Deck(props) {
    var _this = this;

    _classCallCheck(this, Deck);

    _defineProperty(this, "props", void 0);

    _defineProperty(this, "width", 0);

    _defineProperty(this, "height", 0);

    _defineProperty(this, "userData", {});

    _defineProperty(this, "canvas", null);

    _defineProperty(this, "viewManager", null);

    _defineProperty(this, "layerManager", null);

    _defineProperty(this, "effectManager", null);

    _defineProperty(this, "deckRenderer", null);

    _defineProperty(this, "deckPicker", null);

    _defineProperty(this, "eventManager", null);

    _defineProperty(this, "tooltip", null);

    _defineProperty(this, "metrics", void 0);

    _defineProperty(this, "animationLoop", void 0);

    _defineProperty(this, "stats", void 0);

    _defineProperty(this, "viewState", void 0);

    _defineProperty(this, "cursorState", void 0);

    _defineProperty(this, "_needsRedraw", void 0);

    _defineProperty(this, "_pickRequest", void 0);

    _defineProperty(this, "_lastPointerDownInfo", null);

    _defineProperty(this, "_metricsCounter", void 0);

    _defineProperty(this, "_onPointerMove", function (event) {
      var _pickRequest = _this._pickRequest;

      if (event.type === 'pointerleave') {
        _pickRequest.x = -1;
        _pickRequest.y = -1;
        _pickRequest.radius = 0;
      } else if (event.leftButton || event.rightButton) {
        return;
      } else {
        var pos = event.offsetCenter;

        if (!pos) {
          return;
        }

        _pickRequest.x = pos.x;
        _pickRequest.y = pos.y;
        _pickRequest.radius = _this.props.pickingRadius;
      }

      if (_this.layerManager) {
        _this.layerManager.context.mousePosition = {
          x: _pickRequest.x,
          y: _pickRequest.y
        };
      }

      _pickRequest.event = event;
    });

    _defineProperty(this, "_onEvent", function (event) {
      var eventOptions = EVENTS[event.type];
      var pos = event.offsetCenter;

      if (!eventOptions || !pos || !_this.layerManager) {
        return;
      }

      var layers = _this.layerManager.getLayers();

      var info = _this.deckPicker.getLastPickedObject({
        x: pos.x,
        y: pos.y,
        layers: layers,
        viewports: _this.getViewports(pos)
      }, _this._lastPointerDownInfo);

      var layer = info.layer;
      var layerHandler = layer && (layer[eventOptions.handler] || layer.props[eventOptions.handler]);
      var rootHandler = _this.props[eventOptions.handler];
      var handled = false;

      if (layerHandler) {
        handled = layerHandler.call(layer, info, event);
      }

      if (!handled && rootHandler) {
        rootHandler(info, event);
      }
    });

    _defineProperty(this, "_onPointerDown", function (event) {
      var pos = event.offsetCenter;

      var pickedInfo = _this._pick('pickObject', 'pickObject Time', {
        x: pos.x,
        y: pos.y,
        radius: _this.props.pickingRadius
      });

      _this._lastPointerDownInfo = pickedInfo.result[0] || pickedInfo.emptyInfo;
    });

    this.props = _objectSpread(_objectSpread({}, defaultProps), props);
    props = this.props;
    this._needsRedraw = 'Initial render';
    this._pickRequest = {
      mode: 'hover',
      x: -1,
      y: -1,
      radius: 0,
      event: null
    };
    this.cursorState = {
      isHovering: false,
      isDragging: false
    };

    if (props.viewState && props.initialViewState) {
      log.warn('View state tracking is disabled. Use either `initialViewState` for auto update or `viewState` for manual update.')();
    }

    if (getBrowser() === 'IE') {
      log.warn('IE 11 is not supported')();
    }

    this.viewState = props.initialViewState;

    if (!props.gl) {
      if (typeof document !== 'undefined') {
        this.canvas = this._createCanvas(props);
      }
    }

    this.animationLoop = this._createAnimationLoop(props);
    this.stats = new Stats({
      id: 'deck.gl'
    });
    this.metrics = {
      fps: 0,
      setPropsTime: 0,
      updateAttributesTime: 0,
      framesRedrawn: 0,
      pickTime: 0,
      pickCount: 0,
      gpuTime: 0,
      gpuTimePerFrame: 0,
      cpuTime: 0,
      cpuTimePerFrame: 0,
      bufferMemory: 0,
      textureMemory: 0,
      renderbufferMemory: 0,
      gpuMemory: 0
    };
    this._metricsCounter = 0;
    this.setProps(props);

    if (props._typedArrayManagerProps) {
      typedArrayManager.setOptions(props._typedArrayManagerProps);
    }

    this.animationLoop.start();
  }

  _createClass(Deck, [{
    key: "finalize",
    value: function finalize() {
      var _this$animationLoop, _this$layerManager, _this$viewManager, _this$effectManager, _this$deckRenderer, _this$deckPicker, _this$eventManager, _this$tooltip;

      (_this$animationLoop = this.animationLoop) === null || _this$animationLoop === void 0 ? void 0 : _this$animationLoop.stop();
      this.animationLoop = null;
      this._lastPointerDownInfo = null;
      (_this$layerManager = this.layerManager) === null || _this$layerManager === void 0 ? void 0 : _this$layerManager.finalize();
      this.layerManager = null;
      (_this$viewManager = this.viewManager) === null || _this$viewManager === void 0 ? void 0 : _this$viewManager.finalize();
      this.viewManager = null;
      (_this$effectManager = this.effectManager) === null || _this$effectManager === void 0 ? void 0 : _this$effectManager.finalize();
      this.effectManager = null;
      (_this$deckRenderer = this.deckRenderer) === null || _this$deckRenderer === void 0 ? void 0 : _this$deckRenderer.finalize();
      this.deckRenderer = null;
      (_this$deckPicker = this.deckPicker) === null || _this$deckPicker === void 0 ? void 0 : _this$deckPicker.finalize();
      this.deckPicker = null;
      (_this$eventManager = this.eventManager) === null || _this$eventManager === void 0 ? void 0 : _this$eventManager.destroy();
      this.eventManager = null;
      (_this$tooltip = this.tooltip) === null || _this$tooltip === void 0 ? void 0 : _this$tooltip.remove();
      this.tooltip = null;

      if (!this.props.canvas && !this.props.gl && this.canvas) {
        var _this$canvas$parentEl;

        (_this$canvas$parentEl = this.canvas.parentElement) === null || _this$canvas$parentEl === void 0 ? void 0 : _this$canvas$parentEl.removeChild(this.canvas);
        this.canvas = null;
      }
    }
  }, {
    key: "setProps",
    value: function setProps(props) {
      this.stats.get('setProps Time').timeStart();

      if ('onLayerHover' in props) {
        log.removed('onLayerHover', 'onHover')();
      }

      if ('onLayerClick' in props) {
        log.removed('onLayerClick', 'onClick')();
      }

      if (props.initialViewState && !deepEqual(this.props.initialViewState, props.initialViewState, 3)) {
        this.viewState = props.initialViewState;
      }

      Object.assign(this.props, props);

      this._setCanvasSize(this.props);

      var resolvedProps = Object.create(this.props);
      Object.assign(resolvedProps, {
        views: this._getViews(),
        width: this.width,
        height: this.height,
        viewState: this._getViewState()
      });
      this.animationLoop.setProps(resolvedProps);

      if (this.layerManager) {
        this.viewManager.setProps(resolvedProps);
        this.layerManager.activateViewport(this.getViewports()[0]);
        this.layerManager.setProps(resolvedProps);
        this.effectManager.setProps(resolvedProps);
        this.deckRenderer.setProps(resolvedProps);
        this.deckPicker.setProps(resolvedProps);
      }

      this.stats.get('setProps Time').timeEnd();
    }
  }, {
    key: "needsRedraw",
    value: function needsRedraw() {
      var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
        clearRedrawFlags: false
      };

      if (!this.layerManager) {
        return false;
      }

      if (this.props._animate) {
        return 'Deck._animate';
      }

      var redraw = this._needsRedraw;

      if (opts.clearRedrawFlags) {
        this._needsRedraw = false;
      }

      var viewManagerNeedsRedraw = this.viewManager.needsRedraw(opts);
      var layerManagerNeedsRedraw = this.layerManager.needsRedraw(opts);
      var effectManagerNeedsRedraw = this.effectManager.needsRedraw(opts);
      var deckRendererNeedsRedraw = this.deckRenderer.needsRedraw(opts);
      redraw = redraw || viewManagerNeedsRedraw || layerManagerNeedsRedraw || effectManagerNeedsRedraw || deckRendererNeedsRedraw;
      return redraw;
    }
  }, {
    key: "redraw",
    value: function redraw(reason) {
      if (!this.layerManager) {
        return;
      }

      var redrawReason = this.needsRedraw({
        clearRedrawFlags: true
      });
      redrawReason = reason || redrawReason;

      if (!redrawReason) {
        return;
      }

      this.stats.get('Redraw Count').incrementCount();

      if (this.props._customRender) {
        this.props._customRender(redrawReason);
      } else {
        this._drawLayers(redrawReason);
      }
    }
  }, {
    key: "isInitialized",
    get: function get() {
      return this.viewManager !== null;
    }
  }, {
    key: "getViews",
    value: function getViews() {
      assert(this.viewManager);
      return this.viewManager.views;
    }
  }, {
    key: "getViewports",
    value: function getViewports(rect) {
      assert(this.viewManager);
      return this.viewManager.getViewports(rect);
    }
  }, {
    key: "getCanvas",
    value: function getCanvas() {
      return this.canvas;
    }
  }, {
    key: "pickObject",
    value: function pickObject(opts) {
      var infos = this._pick('pickObject', 'pickObject Time', opts).result;

      return infos.length ? infos[0] : null;
    }
  }, {
    key: "pickMultipleObjects",
    value: function pickMultipleObjects(opts) {
      opts.depth = opts.depth || 10;
      return this._pick('pickObject', 'pickMultipleObjects Time', opts).result;
    }
  }, {
    key: "pickObjects",
    value: function pickObjects(opts) {
      return this._pick('pickObjects', 'pickObjects Time', opts);
    }
  }, {
    key: "_addResources",
    value: function _addResources(resources) {
      var forceUpdate = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      for (var _id in resources) {
        this.layerManager.resourceManager.add({
          resourceId: _id,
          data: resources[_id],
          forceUpdate: forceUpdate
        });
      }
    }
  }, {
    key: "_removeResources",
    value: function _removeResources(resourceIds) {
      var _iterator = _createForOfIteratorHelper(resourceIds),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var _id2 = _step.value;
          this.layerManager.resourceManager.remove(_id2);
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    }
  }, {
    key: "_addDefaultEffect",
    value: function _addDefaultEffect(effect) {
      this.effectManager.addDefaultEffect(effect);
    }
  }, {
    key: "_pick",
    value: function _pick(method, statKey, opts) {
      assert(this.deckPicker);
      var stats = this.stats;
      stats.get('Pick Count').incrementCount();
      stats.get(statKey).timeStart();
      var infos = this.deckPicker[method](_objectSpread({
        layers: this.layerManager.getLayers(opts),
        views: this.viewManager.getViews(),
        viewports: this.getViewports(opts),
        onViewportActive: this.layerManager.activateViewport,
        effects: this.effectManager.getEffects()
      }, opts));
      stats.get(statKey).timeEnd();
      return infos;
    }
  }, {
    key: "_createCanvas",
    value: function _createCanvas(props) {
      var canvas = props.canvas;

      if (typeof canvas === 'string') {
        canvas = document.getElementById(canvas);
        assert(canvas);
      }

      if (!canvas) {
        canvas = document.createElement('canvas');
        canvas.id = props.id || 'deckgl-overlay';
        var parent = props.parent || document.body;
        parent.appendChild(canvas);
      }

      Object.assign(canvas.style, props.style);
      return canvas;
    }
  }, {
    key: "_setCanvasSize",
    value: function _setCanvasSize(props) {
      if (!this.canvas) {
        return;
      }

      var width = props.width,
          height = props.height;

      if (width || width === 0) {
        var cssWidth = Number.isFinite(width) ? "".concat(width, "px") : width;
        this.canvas.style.width = cssWidth;
      }

      if (height || height === 0) {
        var _props$style;

        var cssHeight = Number.isFinite(height) ? "".concat(height, "px") : height;
        this.canvas.style.position = ((_props$style = props.style) === null || _props$style === void 0 ? void 0 : _props$style.position) || 'absolute';
        this.canvas.style.height = cssHeight;
      }
    }
  }, {
    key: "_updateCanvasSize",
    value: function _updateCanvasSize() {
      var _canvas$clientWidth, _canvas$clientHeight;

      var canvas = this.canvas;

      if (!canvas) {
        return;
      }

      var newWidth = (_canvas$clientWidth = canvas.clientWidth) !== null && _canvas$clientWidth !== void 0 ? _canvas$clientWidth : canvas.width;
      var newHeight = (_canvas$clientHeight = canvas.clientHeight) !== null && _canvas$clientHeight !== void 0 ? _canvas$clientHeight : canvas.height;

      if (newWidth !== this.width || newHeight !== this.height) {
        var _this$viewManager2, _this$layerManager2;

        this.width = newWidth;
        this.height = newHeight;
        (_this$viewManager2 = this.viewManager) === null || _this$viewManager2 === void 0 ? void 0 : _this$viewManager2.setProps({
          width: newWidth,
          height: newHeight
        });
        (_this$layerManager2 = this.layerManager) === null || _this$layerManager2 === void 0 ? void 0 : _this$layerManager2.activateViewport(this.getViewports()[0]);
        this.props.onResize({
          width: newWidth,
          height: newHeight
        });
      }
    }
  }, {
    key: "_createAnimationLoop",
    value: function _createAnimationLoop(props) {
      var _this2 = this;

      var width = props.width,
          height = props.height,
          gl = props.gl,
          glOptions = props.glOptions,
          debug = props.debug,
          onError = props.onError,
          onBeforeRender = props.onBeforeRender,
          onAfterRender = props.onAfterRender,
          useDevicePixels = props.useDevicePixels;
      return new AnimationLoop({
        width: width,
        height: height,
        useDevicePixels: useDevicePixels,
        autoResizeDrawingBuffer: !gl,
        autoResizeViewport: false,
        gl: gl,
        onCreateContext: function onCreateContext(opts) {
          return createGLContext(_objectSpread(_objectSpread(_objectSpread({}, glOptions), opts), {}, {
            canvas: _this2.canvas,
            debug: debug,
            onContextLost: function onContextLost() {
              return _this2._onContextLost();
            }
          }));
        },
        onInitialize: function onInitialize(context) {
          return _this2._setGLContext(context.gl);
        },
        onRender: this._onRenderFrame.bind(this),
        onBeforeRender: onBeforeRender,
        onAfterRender: onAfterRender,
        onError: onError
      });
    }
  }, {
    key: "_getViewState",
    value: function _getViewState() {
      return this.props.viewState || this.viewState;
    }
  }, {
    key: "_getViews",
    value: function _getViews() {
      var views = this.props.views || [new MapView({
        id: 'default-view'
      })];
      views = Array.isArray(views) ? views : [views];

      if (views.length && this.props.controller) {
        views[0].props.controller = this.props.controller;
      }

      return views;
    }
  }, {
    key: "_onContextLost",
    value: function _onContextLost() {
      var onError = this.props.onError;

      if (this.animationLoop && onError) {
        onError(new Error('WebGL context is lost'));
      }
    }
  }, {
    key: "_pickAndCallback",
    value: function _pickAndCallback() {
      var _pickRequest = this._pickRequest;

      if (_pickRequest.event) {
        var _this$_pick = this._pick('pickObject', 'pickObject Time', _pickRequest),
            result = _this$_pick.result,
            emptyInfo = _this$_pick.emptyInfo;

        this.cursorState.isHovering = result.length > 0;
        var pickedInfo = emptyInfo;
        var handled = false;

        var _iterator2 = _createForOfIteratorHelper(result),
            _step2;

        try {
          for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
            var _info$layer;

            var _info = _step2.value;
            pickedInfo = _info;
            handled = ((_info$layer = _info.layer) === null || _info$layer === void 0 ? void 0 : _info$layer.onHover(_info, _pickRequest.event)) || handled;
          }
        } catch (err) {
          _iterator2.e(err);
        } finally {
          _iterator2.f();
        }

        if (!handled && this.props.onHover) {
          this.props.onHover(pickedInfo, _pickRequest.event);
        }

        if (this.props.getTooltip && this.tooltip) {
          var displayInfo = this.props.getTooltip(pickedInfo);
          this.tooltip.setTooltip(displayInfo, pickedInfo.x, pickedInfo.y);
        }

        _pickRequest.event = null;
      }
    }
  }, {
    key: "_updateCursor",
    value: function _updateCursor() {
      var container = this.props.parent || this.canvas;

      if (container) {
        container.style.cursor = this.props.getCursor(this.cursorState);
      }
    }
  }, {
    key: "_setGLContext",
    value: function _setGLContext(gl) {
      if (this.layerManager) {
        return;
      }

      if (!this.canvas) {
        this.canvas = gl.canvas;
        instrumentGLContext(gl, {
          enable: true,
          copyState: true
        });
      }

      this.tooltip = new Tooltip(this.canvas);
      setParameters(gl, {
        blend: true,
        blendFunc: [770, 771, 1, 771],
        polygonOffsetFill: true,
        depthTest: true,
        depthFunc: 515
      });
      this.props.onWebGLInitialized(gl);
      var timeline = new Timeline();
      timeline.play();
      this.animationLoop.attachTimeline(timeline);
      this.eventManager = new EventManager(this.props.parent || gl.canvas, {
        touchAction: this.props.touchAction,
        recognizerOptions: this.props.eventRecognizerOptions,
        events: {
          pointerdown: this._onPointerDown,
          pointermove: this._onPointerMove,
          pointerleave: this._onPointerMove
        }
      });

      for (var eventType in EVENTS) {
        this.eventManager.on(eventType, this._onEvent);
      }

      this.viewManager = new ViewManager({
        timeline: timeline,
        eventManager: this.eventManager,
        onViewStateChange: this._onViewStateChange.bind(this),
        onInteractionStateChange: this._onInteractionStateChange.bind(this),
        views: this._getViews(),
        viewState: this._getViewState(),
        width: this.width,
        height: this.height
      });
      var viewport = this.viewManager.getViewports()[0];
      this.layerManager = new LayerManager(gl, {
        deck: this,
        stats: this.stats,
        viewport: viewport,
        timeline: timeline
      });
      this.effectManager = new EffectManager();
      this.deckRenderer = new DeckRenderer(gl);
      this.deckPicker = new DeckPicker(gl);
      this.setProps(this.props);

      this._updateCanvasSize();

      this.props.onLoad();
    }
  }, {
    key: "_drawLayers",
    value: function _drawLayers(redrawReason, renderOptions) {
      var gl = this.layerManager.context.gl;
      setParameters(gl, this.props.parameters);
      this.props.onBeforeRender({
        gl: gl
      });
      this.deckRenderer.renderLayers(_objectSpread({
        target: this.props._framebuffer,
        layers: this.layerManager.getLayers(),
        viewports: this.viewManager.getViewports(),
        onViewportActive: this.layerManager.activateViewport,
        views: this.viewManager.getViews(),
        pass: 'screen',
        effects: this.effectManager.getEffects()
      }, renderOptions));
      this.props.onAfterRender({
        gl: gl
      });
    }
  }, {
    key: "_onRenderFrame",
    value: function _onRenderFrame(animationProps) {
      this._getFrameStats();

      if (this._metricsCounter++ % 60 === 0) {
        this._getMetrics();

        this.stats.reset();
        log.table(4, this.metrics)();

        if (this.props._onMetrics) {
          this.props._onMetrics(this.metrics);
        }
      }

      this._updateCanvasSize();

      this._updateCursor();

      if (this.tooltip.isVisible && this.viewManager.needsRedraw()) {
        this.tooltip.setTooltip(null);
      }

      this.layerManager.updateLayers();

      this._pickAndCallback();

      this.redraw();

      if (this.viewManager) {
        this.viewManager.updateViewStates();
      }
    }
  }, {
    key: "_onViewStateChange",
    value: function _onViewStateChange(params) {
      var viewState = this.props.onViewStateChange(params) || params.viewState;

      if (this.viewState) {
        this.viewState = _objectSpread(_objectSpread({}, this.viewState), {}, _defineProperty({}, params.viewId, viewState));

        if (!this.props.viewState) {
          if (this.viewManager) {
            this.viewManager.setProps({
              viewState: this.viewState
            });
          }
        }
      }
    }
  }, {
    key: "_onInteractionStateChange",
    value: function _onInteractionStateChange(interactionState) {
      this.cursorState.isDragging = interactionState.isDragging || false;
      this.props.onInteractionStateChange(interactionState);
    }
  }, {
    key: "_getFrameStats",
    value: function _getFrameStats() {
      var stats = this.stats;
      stats.get('frameRate').timeEnd();
      stats.get('frameRate').timeStart();
      var animationLoopStats = this.animationLoop.stats;
      stats.get('GPU Time').addTime(animationLoopStats.get('GPU Time').lastTiming);
      stats.get('CPU Time').addTime(animationLoopStats.get('CPU Time').lastTiming);
    }
  }, {
    key: "_getMetrics",
    value: function _getMetrics() {
      var metrics = this.metrics,
          stats = this.stats;
      metrics.fps = stats.get('frameRate').getHz();
      metrics.setPropsTime = stats.get('setProps Time').time;
      metrics.updateAttributesTime = stats.get('Update Attributes').time;
      metrics.framesRedrawn = stats.get('Redraw Count').count;
      metrics.pickTime = stats.get('pickObject Time').time + stats.get('pickMultipleObjects Time').time + stats.get('pickObjects Time').time;
      metrics.pickCount = stats.get('Pick Count').count;
      metrics.gpuTime = stats.get('GPU Time').time;
      metrics.cpuTime = stats.get('CPU Time').time;
      metrics.gpuTimePerFrame = stats.get('GPU Time').getAverageTime();
      metrics.cpuTimePerFrame = stats.get('CPU Time').getAverageTime();
      var memoryStats = lumaStats.get('Memory Usage');
      metrics.bufferMemory = memoryStats.get('Buffer Memory').count;
      metrics.textureMemory = memoryStats.get('Texture Memory').count;
      metrics.renderbufferMemory = memoryStats.get('Renderbuffer Memory').count;
      metrics.gpuMemory = memoryStats.get('GPU Memory').count;
    }
  }]);

  return Deck;
}();

_defineProperty(Deck, "defaultProps", defaultProps);

_defineProperty(Deck, "VERSION", VERSION);

export { Deck as default };