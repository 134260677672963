import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _get from "@babel/runtime/helpers/get";
import _inherits from "@babel/runtime/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

import { LineLayer, SolidPolygonLayer } from '@deck.gl/layers';
import { generateContours } from './contour-utils';
import { log } from '@deck.gl/core';
import GPUGridAggregator from '../utils/gpu-grid-aggregation/gpu-grid-aggregator';
import { AGGREGATION_OPERATION, getValueFunc } from '../utils/aggregation-operation-utils';
import { getBoundingBox, getGridParams } from '../utils/grid-aggregation-utils';
import GridAggregationLayer from '../grid-aggregation-layer';
var DEFAULT_COLOR = [255, 255, 255, 255];
var DEFAULT_STROKE_WIDTH = 1;
var DEFAULT_THRESHOLD = 1;
var defaultProps = {
  cellSize: {
    type: 'number',
    min: 1,
    max: 1000,
    value: 1000
  },
  getPosition: {
    type: 'accessor',
    value: function value(x) {
      return x.position;
    }
  },
  getWeight: {
    type: 'accessor',
    value: 1
  },
  gpuAggregation: true,
  aggregation: 'SUM',
  contours: {
    type: 'object',
    value: [{
      threshold: DEFAULT_THRESHOLD
    }],
    optional: true,
    compare: 3
  },
  zOffset: 0.005
};
var POSITION_ATTRIBUTE_NAME = 'positions';
var DIMENSIONS = {
  data: {
    props: ['cellSize']
  },
  weights: {
    props: ['aggregation'],
    accessors: ['getWeight']
  }
};

var ContourLayer = function (_GridAggregationLayer) {
  _inherits(ContourLayer, _GridAggregationLayer);

  var _super = _createSuper(ContourLayer);

  function ContourLayer() {
    _classCallCheck(this, ContourLayer);

    return _super.apply(this, arguments);
  }

  _createClass(ContourLayer, [{
    key: "initializeState",
    value: function initializeState() {
      var _attributeManager$add;

      _get(_getPrototypeOf(ContourLayer.prototype), "initializeAggregationLayer", this).call(this, {
        dimensions: DIMENSIONS
      });

      this.setState({
        contourData: {},
        projectPoints: false,
        weights: {
          count: {
            size: 1,
            operation: AGGREGATION_OPERATION.SUM
          }
        }
      });
      var attributeManager = this.getAttributeManager();
      attributeManager.add((_attributeManager$add = {}, _defineProperty(_attributeManager$add, POSITION_ATTRIBUTE_NAME, {
        size: 3,
        accessor: 'getPosition',
        type: 5130,
        fp64: this.use64bitPositions()
      }), _defineProperty(_attributeManager$add, "count", {
        size: 3,
        accessor: 'getWeight'
      }), _attributeManager$add));
    }
  }, {
    key: "updateState",
    value: function updateState(opts) {
      _get(_getPrototypeOf(ContourLayer.prototype), "updateState", this).call(this, opts);

      var contoursChanged = false;
      var oldProps = opts.oldProps,
          props = opts.props;
      var aggregationDirty = this.state.aggregationDirty;

      if (oldProps.contours !== props.contours || oldProps.zOffset !== props.zOffset) {
        contoursChanged = true;

        this._updateThresholdData(opts.props);
      }

      if (this.getNumInstances() > 0 && (aggregationDirty || contoursChanged)) {
        this._generateContours();
      }
    }
  }, {
    key: "renderLayers",
    value: function renderLayers() {
      var _this$state$contourDa = this.state.contourData,
          contourSegments = _this$state$contourDa.contourSegments,
          contourPolygons = _this$state$contourDa.contourPolygons;
      var LinesSubLayerClass = this.getSubLayerClass('lines', LineLayer);
      var BandsSubLayerClass = this.getSubLayerClass('bands', SolidPolygonLayer);
      var lineLayer = contourSegments && contourSegments.length > 0 && new LinesSubLayerClass(this.getSubLayerProps({
        id: 'lines'
      }), {
        data: this.state.contourData.contourSegments,
        getSourcePosition: function getSourcePosition(d) {
          return d.start;
        },
        getTargetPosition: function getTargetPosition(d) {
          return d.end;
        },
        getColor: function getColor(d) {
          return d.contour.color || DEFAULT_COLOR;
        },
        getWidth: function getWidth(d) {
          return d.contour.strokeWidth || DEFAULT_STROKE_WIDTH;
        }
      });
      var bandsLayer = contourPolygons && contourPolygons.length > 0 && new BandsSubLayerClass(this.getSubLayerProps({
        id: 'bands'
      }), {
        data: this.state.contourData.contourPolygons,
        getPolygon: function getPolygon(d) {
          return d.vertices;
        },
        getFillColor: function getFillColor(d) {
          return d.contour.color || DEFAULT_COLOR;
        }
      });
      return [lineLayer, bandsLayer];
    }
  }, {
    key: "updateAggregationState",
    value: function updateAggregationState(opts) {
      var props = opts.props,
          oldProps = opts.oldProps;
      var cellSize = props.cellSize,
          coordinateSystem = props.coordinateSystem;
      var viewport = this.context.viewport;
      var cellSizeChanged = oldProps.cellSize !== cellSize;
      var gpuAggregation = props.gpuAggregation;

      if (this.state.gpuAggregation !== props.gpuAggregation) {
        if (gpuAggregation && !GPUGridAggregator.isSupported(this.context.gl)) {
          log.warn('GPU Grid Aggregation not supported, falling back to CPU')();
          gpuAggregation = false;
        }
      }

      var gpuAggregationChanged = gpuAggregation !== this.state.gpuAggregation;
      this.setState({
        gpuAggregation: gpuAggregation
      });
      var dimensions = this.state.dimensions;
      var positionsChanged = this.isAttributeChanged(POSITION_ATTRIBUTE_NAME);
      var data = dimensions.data,
          weights = dimensions.weights;
      var boundingBox = this.state.boundingBox;

      if (positionsChanged) {
        boundingBox = getBoundingBox(this.getAttributes(), this.getNumInstances());
        this.setState({
          boundingBox: boundingBox
        });
      }

      if (positionsChanged || cellSizeChanged) {
        var _getGridParams = getGridParams(boundingBox, cellSize, viewport, coordinateSystem),
            gridOffset = _getGridParams.gridOffset,
            translation = _getGridParams.translation,
            width = _getGridParams.width,
            height = _getGridParams.height,
            numCol = _getGridParams.numCol,
            numRow = _getGridParams.numRow;

        this.allocateResources(numRow, numCol);
        this.setState({
          gridOffset: gridOffset,
          boundingBox: boundingBox,
          translation: translation,
          posOffset: translation.slice(),
          gridOrigin: [-1 * translation[0], -1 * translation[1]],
          width: width,
          height: height,
          numCol: numCol,
          numRow: numRow
        });
      }

      var aggregationDataDirty = positionsChanged || gpuAggregationChanged || this.isAggregationDirty(opts, {
        dimension: data,
        compareAll: gpuAggregation
      });
      var aggregationWeightsDirty = this.isAggregationDirty(opts, {
        dimension: weights
      });

      if (aggregationWeightsDirty) {
        this._updateAccessors(opts);
      }

      if (aggregationDataDirty || aggregationWeightsDirty) {
        this._resetResults();
      }

      this.setState({
        aggregationDataDirty: aggregationDataDirty,
        aggregationWeightsDirty: aggregationWeightsDirty
      });
    }
  }, {
    key: "_updateAccessors",
    value: function _updateAccessors(opts) {
      var _opts$props = opts.props,
          getWeight = _opts$props.getWeight,
          aggregation = _opts$props.aggregation,
          data = _opts$props.data;
      var count = this.state.weights.count;

      if (count) {
        count.getWeight = getWeight;
        count.operation = AGGREGATION_OPERATION[aggregation];
      }

      this.setState({
        getValue: getValueFunc(aggregation, getWeight, {
          data: data
        })
      });
    }
  }, {
    key: "_resetResults",
    value: function _resetResults() {
      var count = this.state.weights.count;

      if (count) {
        count.aggregationData = null;
      }
    }
  }, {
    key: "_generateContours",
    value: function _generateContours() {
      var _this$state = this.state,
          numCol = _this$state.numCol,
          numRow = _this$state.numRow,
          gridOrigin = _this$state.gridOrigin,
          gridOffset = _this$state.gridOffset,
          thresholdData = _this$state.thresholdData;
      var count = this.state.weights.count;
      var aggregationData = count.aggregationData;

      if (!aggregationData) {
        aggregationData = count.aggregationBuffer.getData();
        count.aggregationData = aggregationData;
      }

      var _GPUGridAggregator$ge = GPUGridAggregator.getCellData({
        countsData: aggregationData
      }),
          cellWeights = _GPUGridAggregator$ge.cellWeights;

      var contourData = generateContours({
        thresholdData: thresholdData,
        cellWeights: cellWeights,
        gridSize: [numCol, numRow],
        gridOrigin: gridOrigin,
        cellSize: [gridOffset.xOffset, gridOffset.yOffset]
      });
      this.setState({
        contourData: contourData
      });
    }
  }, {
    key: "_updateThresholdData",
    value: function _updateThresholdData(props) {
      var contours = props.contours,
          zOffset = props.zOffset;
      var count = contours.length;
      var thresholdData = new Array(count);

      for (var i = 0; i < count; i++) {
        var contour = contours[i];
        thresholdData[i] = {
          contour: contour,
          zIndex: contour.zIndex || i,
          zOffset: zOffset
        };
      }

      this.setState({
        thresholdData: thresholdData
      });
    }
  }]);

  return ContourLayer;
}(GridAggregationLayer);

_defineProperty(ContourLayer, "layerName", 'ContourLayer');

_defineProperty(ContourLayer, "defaultProps", defaultProps);

export { ContourLayer as default };