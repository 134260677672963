import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _inherits from "@babel/runtime/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

import View from './view';
import OrbitViewport from '../viewports/orbit-viewport';
import OrbitController from '../controllers/orbit-controller';

var OrbitView = function (_View) {
  _inherits(OrbitView, _View);

  var _super = _createSuper(OrbitView);

  function OrbitView() {
    var _this;

    var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    _classCallCheck(this, OrbitView);

    _this = _super.call(this, props);
    _this.props.orbitAxis = props.orbitAxis || 'Z';
    return _this;
  }

  _createClass(OrbitView, [{
    key: "ViewportType",
    get: function get() {
      return OrbitViewport;
    }
  }, {
    key: "ControllerType",
    get: function get() {
      return OrbitController;
    }
  }]);

  return OrbitView;
}(View);

_defineProperty(OrbitView, "displayName", 'OrbitView');

export { OrbitView as default };