import _assertThisInitialized from "@babel/runtime/helpers/assertThisInitialized";
import _get from "@babel/runtime/helpers/get";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _inherits from "@babel/runtime/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

import { clamp } from '@math.gl/core';
import Controller from './controller';
import { MapState } from './map-controller';
import { mod } from '../utils/math-utils';
import LinearInterpolator from '../transitions/linear-interpolator';

var GlobeState = function (_MapState) {
  _inherits(GlobeState, _MapState);

  var _super = _createSuper(GlobeState);

  function GlobeState() {
    _classCallCheck(this, GlobeState);

    return _super.apply(this, arguments);
  }

  _createClass(GlobeState, [{
    key: "applyConstraints",
    value: function applyConstraints(props) {
      var maxZoom = props.maxZoom,
          minZoom = props.minZoom,
          zoom = props.zoom;
      props.zoom = clamp(zoom, minZoom, maxZoom);
      var longitude = props.longitude,
          latitude = props.latitude;

      if (longitude < -180 || longitude > 180) {
        props.longitude = mod(longitude + 180, 360) - 180;
      }

      props.latitude = clamp(latitude, -89, 89);
      return props;
    }
  }]);

  return GlobeState;
}(MapState);

var GlobeController = function (_Controller) {
  _inherits(GlobeController, _Controller);

  var _super2 = _createSuper(GlobeController);

  function GlobeController() {
    var _this;

    _classCallCheck(this, GlobeController);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super2.call.apply(_super2, [this].concat(args));

    _defineProperty(_assertThisInitialized(_this), "ControllerState", GlobeState);

    _defineProperty(_assertThisInitialized(_this), "transition", {
      transitionDuration: 300,
      transitionInterpolator: new LinearInterpolator(['longitude', 'latitude', 'zoom'])
    });

    _defineProperty(_assertThisInitialized(_this), "dragMode", 'pan');

    return _this;
  }

  _createClass(GlobeController, [{
    key: "setProps",
    value: function setProps(props) {
      _get(_getPrototypeOf(GlobeController.prototype), "setProps", this).call(this, props);

      this.dragRotate = false;
      this.touchRotate = false;
    }
  }]);

  return GlobeController;
}(Controller);

export { GlobeController as default };