import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import { isWebGL2 } from '@luma.gl/core';
export function getBounds(points) {
  var x = points.map(function (p) {
    return p[0];
  });
  var y = points.map(function (p) {
    return p[1];
  });
  var xMin = Math.min.apply(null, x);
  var xMax = Math.max.apply(null, x);
  var yMin = Math.min.apply(null, y);
  var yMax = Math.max.apply(null, y);
  return [xMin, yMin, xMax, yMax];
}
export function boundsContain(currentBounds, targetBounds) {
  if (targetBounds[0] >= currentBounds[0] && targetBounds[2] <= currentBounds[2] && targetBounds[1] >= currentBounds[1] && targetBounds[3] <= currentBounds[3]) {
    return true;
  }

  return false;
}
var scratchArray = new Float32Array(12);
export function packVertices(points) {
  var dimensions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  var index = 0;

  var _iterator = _createForOfIteratorHelper(points),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var point = _step.value;

      for (var i = 0; i < dimensions; i++) {
        scratchArray[index++] = point[i] || 0;
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  return scratchArray;
}
export function scaleToAspectRatio(boundingBox, width, height) {
  var _boundingBox = _slicedToArray(boundingBox, 4),
      xMin = _boundingBox[0],
      yMin = _boundingBox[1],
      xMax = _boundingBox[2],
      yMax = _boundingBox[3];

  var currentWidth = xMax - xMin;
  var currentHeight = yMax - yMin;
  var newWidth = currentWidth;
  var newHeight = currentHeight;

  if (currentWidth / currentHeight < width / height) {
    newWidth = width / height * currentHeight;
  } else {
    newHeight = height / width * currentWidth;
  }

  if (newWidth < width) {
    newWidth = width;
    newHeight = height;
  }

  var xCenter = (xMax + xMin) / 2;
  var yCenter = (yMax + yMin) / 2;
  return [xCenter - newWidth / 2, yCenter - newHeight / 2, xCenter + newWidth / 2, yCenter + newHeight / 2];
}
export function getTextureCoordinates(point, bounds) {
  var _bounds = _slicedToArray(bounds, 4),
      xMin = _bounds[0],
      yMin = _bounds[1],
      xMax = _bounds[2],
      yMax = _bounds[3];

  return [(point[0] - xMin) / (xMax - xMin), (point[1] - yMin) / (yMax - yMin)];
}
export function getTextureParams(_ref) {
  var gl = _ref.gl,
      floatTargetSupport = _ref.floatTargetSupport;
  return floatTargetSupport ? {
    format: isWebGL2(gl) ? 34836 : 6408,
    type: 5126
  } : {
    format: 6408,
    type: 5121
  };
}