import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _inherits from "@babel/runtime/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

import { Transport } from '@deck.gl/json';

var JupyterTransport = function (_Transport) {
  _inherits(JupyterTransport, _Transport);

  var _super = _createSuper(JupyterTransport);

  function JupyterTransport() {
    var _this;

    _classCallCheck(this, JupyterTransport);

    _this = _super.call(this, 'Jupyter Transport (JavaScript <=> Jupyter Kernel)');
    _this.jupyterModel = null;
    _this.jupyterView = null;
    return _this;
  }

  _createClass(JupyterTransport, [{
    key: "getRootDOMElement",
    value: function getRootDOMElement() {
      return this.jupyterView.el;
    }
  }, {
    key: "sendJSONMessage",
    value: function sendJSONMessage(type, data) {
      var string = Transport._stringifyJSONSafe({
        type: type,
        data: data
      });

      this.jupyterModel.send(string);
    }
  }]);

  return JupyterTransport;
}(Transport);

export { JupyterTransport as default };