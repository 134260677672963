import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _typeof from "@babel/runtime/helpers/typeof";
import _asyncToGenerator from "@babel/runtime/helpers/asyncToGenerator";
import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _assertThisInitialized from "@babel/runtime/helpers/assertThisInitialized";
import _get from "@babel/runtime/helpers/get";
import _inherits from "@babel/runtime/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import _regeneratorRuntime from "@babel/runtime/regenerator";

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { log, COORDINATE_SYSTEM } from '@deck.gl/core';
import { GeoJsonLayer } from '@deck.gl/layers';
import { ClipExtension } from '@deck.gl/extensions';
import { Matrix4 } from '@math.gl/core';
import { MVTWorkerLoader } from '@loaders.gl/mvt';
import { binaryToGeojson } from '@loaders.gl/gis';
import { transform } from './coordinate-transform';
import findIndexBinary from './find-index-binary';
import TileLayer from '../tile-layer/tile-layer';
import { urlType, getURLFromTemplate, isGeoBoundingBox, isURLTemplate } from '../tileset-2d';
var WORLD_SIZE = 512;

var defaultProps = _objectSpread(_objectSpread({}, GeoJsonLayer.defaultProps), {}, {
  data: urlType,
  onDataLoad: {
    type: 'function',
    value: null,
    optional: true,
    compare: false
  },
  uniqueIdProperty: '',
  highlightedFeatureId: null,
  loaders: [MVTWorkerLoader],
  binary: true
});

var MVTLayer = function (_TileLayer) {
  _inherits(MVTLayer, _TileLayer);

  var _super = _createSuper(MVTLayer);

  function MVTLayer() {
    var _this;

    _classCallCheck(this, MVTLayer);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));

    _defineProperty(_assertThisInitialized(_this), "state", void 0);

    return _this;
  }

  _createClass(MVTLayer, [{
    key: "initializeState",
    value: function initializeState() {
      _get(_getPrototypeOf(MVTLayer.prototype), "initializeState", this).call(this);

      var binary = this.context.viewport.resolution !== undefined ? false : this.props.binary;
      this.setState({
        binary: binary,
        data: null,
        tileJSON: null,
        hoveredFeatureId: null,
        hoveredFeatureLayerName: null
      });
    }
  }, {
    key: "isLoaded",
    get: function get() {
      var _this$state;

      return Boolean(((_this$state = this.state) === null || _this$state === void 0 ? void 0 : _this$state.data) && _get(_getPrototypeOf(MVTLayer.prototype), "isLoaded", this));
    }
  }, {
    key: "updateState",
    value: function updateState(_ref) {
      var _this$state2;

      var props = _ref.props,
          oldProps = _ref.oldProps,
          context = _ref.context,
          changeFlags = _ref.changeFlags;

      if (changeFlags.dataChanged) {
        this._updateTileData();
      }

      if ((_this$state2 = this.state) !== null && _this$state2 !== void 0 && _this$state2.data) {
        _get(_getPrototypeOf(MVTLayer.prototype), "updateState", this).call(this, {
          props: props,
          oldProps: oldProps,
          context: context,
          changeFlags: changeFlags
        });

        this._setWGS84PropertyForTiles();
      }

      var highlightColor = props.highlightColor;

      if (highlightColor !== oldProps.highlightColor && Array.isArray(highlightColor)) {
        this.setState({
          highlightColor: highlightColor
        });
      }
    }
  }, {
    key: "_updateTileData",
    value: function () {
      var _updateTileData2 = _asyncToGenerator(_regeneratorRuntime.mark(function _callee() {
        var data, tileJSON, _this$props, onDataLoad, fetch;

        return _regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                data = this.props.data;
                tileJSON = null;

                if (!(typeof data === 'string' && !isURLTemplate(data))) {
                  _context.next = 18;
                  break;
                }

                _this$props = this.props, onDataLoad = _this$props.onDataLoad, fetch = _this$props.fetch;
                this.setState({
                  data: null,
                  tileJSON: null
                });
                _context.prev = 5;
                _context.next = 8;
                return fetch(data, {
                  propName: 'data',
                  layer: this,
                  loaders: []
                });

              case 8:
                tileJSON = _context.sent;
                _context.next = 15;
                break;

              case 11:
                _context.prev = 11;
                _context.t0 = _context["catch"](5);
                this.raiseError(_context.t0, 'loading TileJSON');
                data = null;

              case 15:
                if (onDataLoad) {
                  onDataLoad(tileJSON, {
                    propName: 'data',
                    layer: this
                  });
                }

                _context.next = 19;
                break;

              case 18:
                if (data && _typeof(data) === 'object' && 'tilejson' in data) {
                  tileJSON = data;
                }

              case 19:
                if (tileJSON) {
                  data = tileJSON.tiles;
                }

                this.setState({
                  data: data,
                  tileJSON: tileJSON
                });

              case 21:
              case "end":
                return _context.stop();
            }
          }
        }, _callee, this, [[5, 11]]);
      }));

      function _updateTileData() {
        return _updateTileData2.apply(this, arguments);
      }

      return _updateTileData;
    }()
  }, {
    key: "_getTilesetOptions",
    value: function _getTilesetOptions() {
      var opts = _get(_getPrototypeOf(MVTLayer.prototype), "_getTilesetOptions", this).call(this);

      var tileJSON = this.state.tileJSON;
      var _this$props2 = this.props,
          minZoom = _this$props2.minZoom,
          maxZoom = _this$props2.maxZoom;

      if (tileJSON) {
        if (Number.isFinite(tileJSON.minzoom) && tileJSON.minzoom > minZoom) {
          opts.minZoom = tileJSON.minzoom;
        }

        if (Number.isFinite(tileJSON.maxzoom) && (!Number.isFinite(maxZoom) || tileJSON.maxzoom < maxZoom)) {
          opts.maxZoom = tileJSON.maxzoom;
        }
      }

      return opts;
    }
  }, {
    key: "renderLayers",
    value: function renderLayers() {
      var _this$state3;

      if (!((_this$state3 = this.state) !== null && _this$state3 !== void 0 && _this$state3.data)) return null;
      return _get(_getPrototypeOf(MVTLayer.prototype), "renderLayers", this).call(this);
    }
  }, {
    key: "getTileData",
    value: function getTileData(loadProps) {
      var _loadOptions;

      var _this$state4 = this.state,
          data = _this$state4.data,
          binary = _this$state4.binary;
      var index = loadProps.index,
          signal = loadProps.signal;
      var url = getURLFromTemplate(data, loadProps);

      if (!url) {
        return Promise.reject('Invalid URL');
      }

      var loadOptions = this.getLoadOptions();
      var fetch = this.props.fetch;
      loadOptions = _objectSpread(_objectSpread({}, loadOptions), {}, {
        mimeType: 'application/x-protobuf',
        mvt: _objectSpread(_objectSpread({}, (_loadOptions = loadOptions) === null || _loadOptions === void 0 ? void 0 : _loadOptions.mvt), {}, {
          coordinates: this.context.viewport.resolution ? 'wgs84' : 'local',
          tileIndex: index
        }),
        gis: binary ? {
          format: 'binary'
        } : {}
      });
      return fetch(url, {
        propName: 'data',
        layer: this,
        loadOptions: loadOptions,
        signal: signal
      });
    }
  }, {
    key: "renderSubLayers",
    value: function renderSubLayers(props) {
      var _props$tile$index = props.tile.index,
          x = _props$tile$index.x,
          y = _props$tile$index.y,
          z = _props$tile$index.z;
      var worldScale = Math.pow(2, z);
      var xScale = WORLD_SIZE / worldScale;
      var yScale = -xScale;
      var xOffset = WORLD_SIZE * x / worldScale;
      var yOffset = WORLD_SIZE * (1 - y / worldScale);
      var modelMatrix = new Matrix4().scale([xScale, yScale, 1]);
      props.autoHighlight = false;

      if (!this.context.viewport.resolution) {
        props.modelMatrix = modelMatrix;
        props.coordinateOrigin = [xOffset, yOffset, 0];
        props.coordinateSystem = COORDINATE_SYSTEM.CARTESIAN;
        props.extensions = [].concat(_toConsumableArray(props.extensions || []), [new ClipExtension()]);
      }

      var subLayers = _get(_getPrototypeOf(MVTLayer.prototype), "renderSubLayers", this).call(this, props);

      if (this.state.binary && !(subLayers instanceof GeoJsonLayer)) {
        log.warn('renderSubLayers() must return GeoJsonLayer when using binary:true')();
      }

      return subLayers;
    }
  }, {
    key: "_updateAutoHighlight",
    value: function _updateAutoHighlight(info) {
      var uniqueIdProperty = this.props.uniqueIdProperty;
      var _this$state5 = this.state,
          hoveredFeatureId = _this$state5.hoveredFeatureId,
          hoveredFeatureLayerName = _this$state5.hoveredFeatureLayerName;
      var hoveredFeature = info.object;
      var newHoveredFeatureId = null;
      var newHoveredFeatureLayerName = null;

      if (hoveredFeature) {
        newHoveredFeatureId = getFeatureUniqueId(hoveredFeature, uniqueIdProperty);
        newHoveredFeatureLayerName = getFeatureLayerName(hoveredFeature);
      }

      var highlightColor = this.props.highlightColor;

      if (typeof highlightColor === 'function') {
        highlightColor = highlightColor(info);
      }

      if (hoveredFeatureId !== newHoveredFeatureId || hoveredFeatureLayerName !== newHoveredFeatureLayerName) {
        this.setState({
          highlightColor: highlightColor,
          hoveredFeatureId: newHoveredFeatureId,
          hoveredFeatureLayerName: newHoveredFeatureLayerName
        });
      }
    }
  }, {
    key: "getPickingInfo",
    value: function getPickingInfo(params) {
      var info = _get(_getPrototypeOf(MVTLayer.prototype), "getPickingInfo", this).call(this, params);

      var isWGS84 = Boolean(this.context.viewport.resolution);

      if (this.state.binary && info.index !== -1) {
        var data = params.sourceLayer.props.data;
        info.object = binaryToGeojson(data, {
          globalFeatureId: info.index
        });
      }

      if (info.object && !isWGS84) {
        info.object = transformTileCoordsToWGS84(info.object, info.tile.bbox, this.context.viewport);
      }

      return info;
    }
  }, {
    key: "getSubLayerPropsByTile",
    value: function getSubLayerPropsByTile(tile) {
      return {
        highlightedObjectIndex: this.getHighlightedObjectIndex(tile),
        highlightColor: this.state.highlightColor
      };
    }
  }, {
    key: "getHighlightedObjectIndex",
    value: function getHighlightedObjectIndex(tile) {
      var _this$state6 = this.state,
          hoveredFeatureId = _this$state6.hoveredFeatureId,
          hoveredFeatureLayerName = _this$state6.hoveredFeatureLayerName,
          binary = _this$state6.binary;
      var _this$props3 = this.props,
          uniqueIdProperty = _this$props3.uniqueIdProperty,
          highlightedFeatureId = _this$props3.highlightedFeatureId;
      var data = tile.content;
      var isHighlighted = isFeatureIdDefined(highlightedFeatureId);
      var isFeatureIdPresent = isFeatureIdDefined(hoveredFeatureId) || isHighlighted;

      if (!isFeatureIdPresent) {
        return -1;
      }

      var featureIdToHighlight = isHighlighted ? highlightedFeatureId : hoveredFeatureId;

      if (Array.isArray(data)) {
        return data.findIndex(function (feature) {
          var isMatchingId = getFeatureUniqueId(feature, uniqueIdProperty) === featureIdToHighlight;
          var isMatchingLayer = isHighlighted || getFeatureLayerName(feature) === hoveredFeatureLayerName;
          return isMatchingId && isMatchingLayer;
        });
      } else if (data && binary) {
        return findIndexBinary(data, uniqueIdProperty, featureIdToHighlight, isHighlighted ? '' : hoveredFeatureLayerName);
      }

      return -1;
    }
  }, {
    key: "_pickObjects",
    value: function _pickObjects(maxObjects) {
      var _this$context = this.context,
          deck = _this$context.deck,
          viewport = _this$context.viewport;
      var width = viewport.width;
      var height = viewport.height;
      var x = viewport.x;
      var y = viewport.y;
      var layerIds = [this.id];
      return deck.pickObjects({
        x: x,
        y: y,
        width: width,
        height: height,
        layerIds: layerIds,
        maxObjects: maxObjects
      });
    }
  }, {
    key: "getRenderedFeatures",
    value: function getRenderedFeatures() {
      var maxFeatures = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

      var features = this._pickObjects(maxFeatures);

      var featureCache = new Set();
      var renderedFeatures = [];

      var _iterator = _createForOfIteratorHelper(features),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var f = _step.value;
          var featureId = getFeatureUniqueId(f.object, this.props.uniqueIdProperty);

          if (featureId === undefined) {
            renderedFeatures.push(f.object);
          } else if (!featureCache.has(featureId)) {
            featureCache.add(featureId);
            renderedFeatures.push(f.object);
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      return renderedFeatures;
    }
  }, {
    key: "_setWGS84PropertyForTiles",
    value: function _setWGS84PropertyForTiles() {
      var _this2 = this;

      var propName = 'dataInWGS84';
      var tileset = this.state.tileset;
      tileset.selectedTiles.forEach(function (tile) {
        if (!tile.hasOwnProperty(propName)) {
          Object.defineProperty(tile, propName, {
            get: function get() {
              if (!tile.content) {
                return null;
              }

              if (_this2.state.binary && Array.isArray(tile.content) && !tile.content.length) {
                return [];
              }

              var bbox = tile.bbox;

              if (tile._contentWGS84 === undefined && isGeoBoundingBox(bbox)) {
                var content = _this2.state.binary ? binaryToGeojson(tile.content) : tile.content;
                tile._contentWGS84 = content.map(function (feature) {
                  return transformTileCoordsToWGS84(feature, bbox, _this2.context.viewport);
                });
              }

              return tile._contentWGS84;
            }
          });
        }
      });
    }
  }]);

  return MVTLayer;
}(TileLayer);

_defineProperty(MVTLayer, "layerName", 'MVTLayer');

_defineProperty(MVTLayer, "defaultProps", defaultProps);

export { MVTLayer as default };

function getFeatureUniqueId(feature, uniqueIdProperty) {
  if (feature.properties && uniqueIdProperty) {
    return feature.properties[uniqueIdProperty];
  }

  if ('id' in feature) {
    return feature.id;
  }

  return undefined;
}

function getFeatureLayerName(feature) {
  var _feature$properties;

  return ((_feature$properties = feature.properties) === null || _feature$properties === void 0 ? void 0 : _feature$properties.layerName) || null;
}

function isFeatureIdDefined(value) {
  return value !== undefined && value !== null && value !== '';
}

function transformTileCoordsToWGS84(object, bbox, viewport) {
  var feature = _objectSpread(_objectSpread({}, object), {}, {
    geometry: {
      type: object.geometry.type
    }
  });

  Object.defineProperty(feature.geometry, 'coordinates', {
    get: function get() {
      var wgs84Geom = transform(object.geometry, bbox, viewport);
      return wgs84Geom.coordinates;
    }
  });
  return feature;
}