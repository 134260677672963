import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import Long from 'long';
var FACE_BITS = 3;
var MAX_LEVEL = 30;
var POS_BITS = 2 * MAX_LEVEL + 1;
var RADIAN_TO_DEGREE = 180 / Math.PI;
export function IJToST(ij, order, offsets) {
  var maxSize = 1 << order;
  return [(ij[0] + offsets[0]) / maxSize, (ij[1] + offsets[1]) / maxSize];
}

function singleSTtoUV(st) {
  if (st >= 0.5) {
    return 1 / 3.0 * (4 * st * st - 1);
  }

  return 1 / 3.0 * (1 - 4 * (1 - st) * (1 - st));
}

export function STToUV(st) {
  return [singleSTtoUV(st[0]), singleSTtoUV(st[1])];
}
export function FaceUVToXYZ(face, _ref) {
  var _ref2 = _slicedToArray(_ref, 2),
      u = _ref2[0],
      v = _ref2[1];

  switch (face) {
    case 0:
      return [1, u, v];

    case 1:
      return [-u, 1, v];

    case 2:
      return [-u, -v, 1];

    case 3:
      return [-1, -v, -u];

    case 4:
      return [v, -1, -u];

    case 5:
      return [v, u, -1];

    default:
      throw new Error('Invalid face');
  }
}
export function XYZToLngLat(_ref3) {
  var _ref4 = _slicedToArray(_ref3, 3),
      x = _ref4[0],
      y = _ref4[1],
      z = _ref4[2];

  var lat = Math.atan2(z, Math.sqrt(x * x + y * y));
  var lng = Math.atan2(y, x);
  return [lng * RADIAN_TO_DEGREE, lat * RADIAN_TO_DEGREE];
}
export function toHilbertQuadkey(idS) {
  var bin = Long.fromString(idS, true, 10).toString(2);

  while (bin.length < FACE_BITS + POS_BITS) {
    bin = '0' + bin;
  }

  var lsbIndex = bin.lastIndexOf('1');
  var faceB = bin.substring(0, 3);
  var posB = bin.substring(3, lsbIndex);
  var levelN = posB.length / 2;
  var faceS = Long.fromString(faceB, true, 2).toString(10);
  var posS = Long.fromString(posB, true, 2).toString(4);

  while (posS.length < levelN) {
    posS = '0' + posS;
  }

  return "".concat(faceS, "/").concat(posS);
}

function rotateAndFlipQuadrant(n, point, rx, ry) {
  if (ry === 0) {
    if (rx === 1) {
      point[0] = n - 1 - point[0];
      point[1] = n - 1 - point[1];
    }

    var x = point[0];
    point[0] = point[1];
    point[1] = x;
  }
}

export function FromHilbertQuadKey(hilbertQuadkey) {
  var parts = hilbertQuadkey.split('/');
  var face = parseInt(parts[0], 10);
  var position = parts[1];
  var maxLevel = position.length;
  var point = [0, 0];
  var level;

  for (var i = maxLevel - 1; i >= 0; i--) {
    level = maxLevel - i;
    var bit = position[i];
    var rx = 0;
    var ry = 0;

    if (bit === '1') {
      ry = 1;
    } else if (bit === '2') {
      rx = 1;
      ry = 1;
    } else if (bit === '3') {
      rx = 1;
    }

    var val = Math.pow(2, level - 1);
    rotateAndFlipQuadrant(val, point, rx, ry);
    point[0] += val * rx;
    point[1] += val * ry;
  }

  if (face % 2 === 1) {
    var t = point[0];
    point[0] = point[1];
    point[1] = t;
  }

  return {
    face: face,
    ij: point,
    level: level
  };
}