import { loadCSS } from './css-utils';
import mapboxgl from 'mapbox-gl';
var MAPBOX_CSS_URL = 'https://api.tiles.mapbox.com/mapbox-gl-js/v1.13.2/mapbox-gl.css';
export default mapboxgl;
export function loadMapboxCSS() {
  var url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : MAPBOX_CSS_URL;
  loadCSS(url);
}
export function modifyMapboxElements() {
  var classes = ['mapboxgl-missing-css'];

  for (var _i = 0, _classes = classes; _i < _classes.length; _i++) {
    var c = _classes[_i];
    var el = document.getElementsByClassName(c)[0];

    if (el && el.style) {
      el.style.display = 'none';
    }
  }
}