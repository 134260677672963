import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _get from "@babel/runtime/helpers/get";
import _inherits from "@babel/runtime/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

import { PathLayer } from '@deck.gl/layers';
var defaultProps = {
  fadeTrail: true,
  trailLength: {
    type: 'number',
    value: 120,
    min: 0
  },
  currentTime: {
    type: 'number',
    value: 0,
    min: 0
  },
  getTimestamps: {
    type: 'accessor',
    value: function value(d) {
      return d.timestamps;
    }
  }
};

var TripsLayer = function (_PathLayer) {
  _inherits(TripsLayer, _PathLayer);

  var _super = _createSuper(TripsLayer);

  function TripsLayer() {
    _classCallCheck(this, TripsLayer);

    return _super.apply(this, arguments);
  }

  _createClass(TripsLayer, [{
    key: "getShaders",
    value: function getShaders() {
      var shaders = _get(_getPrototypeOf(TripsLayer.prototype), "getShaders", this).call(this);

      shaders.inject = {
        'vs:#decl': "uniform float trailLength;\nattribute float instanceTimestamps;\nattribute float instanceNextTimestamps;\nvarying float vTime;\n",
        'vs:#main-end': "vTime = instanceTimestamps + (instanceNextTimestamps - instanceTimestamps) * vPathPosition.y / vPathLength;\n",
        'fs:#decl': "uniform bool fadeTrail;\nuniform float trailLength;\nuniform float currentTime;\nvarying float vTime;\n",
        'fs:#main-start': "if(vTime > currentTime || (fadeTrail && (vTime < currentTime - trailLength))) {\n  discard;\n}\n",
        'fs:DECKGL_FILTER_COLOR': "if(fadeTrail) {\n  color.a *= 1.0 - (currentTime - vTime) / trailLength;\n}\n"
      };
      return shaders;
    }
  }, {
    key: "initializeState",
    value: function initializeState() {
      _get(_getPrototypeOf(TripsLayer.prototype), "initializeState", this).call(this);

      var attributeManager = this.getAttributeManager();
      attributeManager.addInstanced({
        timestamps: {
          size: 1,
          accessor: 'getTimestamps',
          shaderAttributes: {
            instanceTimestamps: {
              vertexOffset: 0
            },
            instanceNextTimestamps: {
              vertexOffset: 1
            }
          }
        }
      });
    }
  }, {
    key: "draw",
    value: function draw(params) {
      var _this$props = this.props,
          fadeTrail = _this$props.fadeTrail,
          trailLength = _this$props.trailLength,
          currentTime = _this$props.currentTime;
      params.uniforms = _objectSpread(_objectSpread({}, params.uniforms), {}, {
        fadeTrail: fadeTrail,
        trailLength: trailLength,
        currentTime: currentTime
      });

      _get(_getPrototypeOf(TripsLayer.prototype), "draw", this).call(this, params);
    }
  }]);

  return TripsLayer;
}(PathLayer);

_defineProperty(TripsLayer, "layerName", 'TripsLayer');

_defineProperty(TripsLayer, "defaultProps", defaultProps);

export { TripsLayer as default };