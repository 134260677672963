import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _assertThisInitialized from "@babel/runtime/helpers/assertThisInitialized";
import _get from "@babel/runtime/helpers/get";
import _inherits from "@babel/runtime/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

import { Model, Geometry, FEATURES, hasFeatures } from '@luma.gl/core';
import { Layer, log, picking } from '@deck.gl/core';
import { defaultColorRange, colorRangeToFlatArray } from '../utils/color-utils';
import vs from './screen-grid-layer-vertex.glsl';
import fs from './screen-grid-layer-fragment.glsl';
var DEFAULT_MINCOLOR = [0, 0, 0, 0];
var DEFAULT_MAXCOLOR = [0, 255, 0, 255];
var COLOR_PROPS = ['minColor', 'maxColor', 'colorRange', 'colorDomain'];
var defaultProps = {
  cellSizePixels: {
    value: 100,
    min: 1
  },
  cellMarginPixels: {
    value: 2,
    min: 0,
    max: 5
  },
  colorDomain: null,
  colorRange: defaultColorRange
};

var ScreenGridCellLayer = function (_Layer) {
  _inherits(ScreenGridCellLayer, _Layer);

  var _super = _createSuper(ScreenGridCellLayer);

  function ScreenGridCellLayer() {
    var _this;

    _classCallCheck(this, ScreenGridCellLayer);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));

    _defineProperty(_assertThisInitialized(_this), "state", void 0);

    return _this;
  }

  _createClass(ScreenGridCellLayer, [{
    key: "getShaders",
    value: function getShaders() {
      return {
        vs: vs,
        fs: fs,
        modules: [picking]
      };
    }
  }, {
    key: "initializeState",
    value: function initializeState() {
      var gl = this.context.gl;
      var attributeManager = this.getAttributeManager();
      attributeManager.addInstanced({
        instancePositions: {
          size: 3,
          update: this.calculateInstancePositions
        },
        instanceCounts: {
          size: 4,
          noAlloc: true
        }
      });
      this.setState({
        model: this._getModel(gl)
      });
    }
  }, {
    key: "shouldUpdateState",
    value: function shouldUpdateState(_ref) {
      var changeFlags = _ref.changeFlags;
      return changeFlags.somethingChanged;
    }
  }, {
    key: "updateState",
    value: function updateState(params) {
      _get(_getPrototypeOf(ScreenGridCellLayer.prototype), "updateState", this).call(this, params);

      var oldProps = params.oldProps,
          props = params.props,
          changeFlags = params.changeFlags;
      var attributeManager = this.getAttributeManager();

      if (props.numInstances !== oldProps.numInstances) {
        attributeManager.invalidateAll();
      } else if (oldProps.cellSizePixels !== props.cellSizePixels) {
        attributeManager.invalidate('instancePositions');
      }

      this._updateUniforms(oldProps, props, changeFlags);
    }
  }, {
    key: "draw",
    value: function draw(_ref2) {
      var uniforms = _ref2.uniforms;
      var _this$props = this.props,
          parameters = _this$props.parameters,
          maxTexture = _this$props.maxTexture;
      var minColor = this.props.minColor || DEFAULT_MINCOLOR;
      var maxColor = this.props.maxColor || DEFAULT_MAXCOLOR;
      var colorDomain = this.props.colorDomain || [1, 0];
      var model = this.state.model;
      model.setUniforms(uniforms).setUniforms({
        minColor: minColor,
        maxColor: maxColor,
        maxTexture: maxTexture,
        colorDomain: colorDomain
      }).draw({
        parameters: _objectSpread({
          depthTest: false,
          depthMask: false
        }, parameters)
      });
    }
  }, {
    key: "calculateInstancePositions",
    value: function calculateInstancePositions(attribute, _ref3) {
      var numInstances = _ref3.numInstances;
      var _this$context$viewpor = this.context.viewport,
          width = _this$context$viewpor.width,
          height = _this$context$viewpor.height;
      var cellSizePixels = this.props.cellSizePixels;
      var numCol = Math.ceil(width / cellSizePixels);
      var value = attribute.value,
          size = attribute.size;

      for (var i = 0; i < numInstances; i++) {
        var x = i % numCol;
        var y = Math.floor(i / numCol);
        value[i * size + 0] = x * cellSizePixels / width * 2 - 1;
        value[i * size + 1] = 1 - y * cellSizePixels / height * 2;
        value[i * size + 2] = 0;
      }
    }
  }, {
    key: "_getModel",
    value: function _getModel(gl) {
      return new Model(gl, _objectSpread(_objectSpread({}, this.getShaders()), {}, {
        id: this.props.id,
        geometry: new Geometry({
          drawMode: 6,
          attributes: {
            positions: new Float32Array([0, 0, 0, 1, 0, 0, 1, 1, 0, 0, 1, 0])
          }
        }),
        isInstanced: true
      }));
    }
  }, {
    key: "_shouldUseMinMax",
    value: function _shouldUseMinMax() {
      var _this$props2 = this.props,
          minColor = _this$props2.minColor,
          maxColor = _this$props2.maxColor,
          colorDomain = _this$props2.colorDomain,
          colorRange = _this$props2.colorRange;

      if (minColor || maxColor) {
        log.deprecated('ScreenGridLayer props: minColor and maxColor', 'colorRange, colorDomain')();
        return true;
      }

      if (colorDomain || colorRange) {
        return false;
      }

      return true;
    }
  }, {
    key: "_updateUniforms",
    value: function _updateUniforms(oldProps, props, changeFlags) {
      var model = this.state.model;

      if (COLOR_PROPS.some(function (key) {
        return oldProps[key] !== props[key];
      })) {
        model.setUniforms({
          shouldUseMinMax: this._shouldUseMinMax()
        });
      }

      if (oldProps.colorRange !== props.colorRange) {
        model.setUniforms({
          colorRange: colorRangeToFlatArray(props.colorRange)
        });
      }

      if (oldProps.cellMarginPixels !== props.cellMarginPixels || oldProps.cellSizePixels !== props.cellSizePixels || changeFlags.viewportChanged) {
        var _this$context$viewpor2 = this.context.viewport,
            width = _this$context$viewpor2.width,
            height = _this$context$viewpor2.height;
        var _this$props3 = this.props,
            cellSizePixels = _this$props3.cellSizePixels,
            cellMarginPixels = _this$props3.cellMarginPixels;
        var margin = cellSizePixels > cellMarginPixels ? cellMarginPixels : 0;
        var cellScale = new Float32Array([(cellSizePixels - margin) / width * 2, -(cellSizePixels - margin) / height * 2, 1]);
        model.setUniforms({
          cellScale: cellScale
        });
      }
    }
  }], [{
    key: "isSupported",
    value: function isSupported(gl) {
      return hasFeatures(gl, [FEATURES.TEXTURE_FLOAT]);
    }
  }]);

  return ScreenGridCellLayer;
}(Layer);

_defineProperty(ScreenGridCellLayer, "layerName", 'ScreenGridCellLayer');

_defineProperty(ScreenGridCellLayer, "defaultProps", defaultProps);

export { ScreenGridCellLayer as default };