import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { hasFeature, FEATURES, Buffer } from '@luma.gl/core';
import ShaderAttribute from './shader-attribute';
import { glArrayFromType } from './gl-utils';
import typedArrayManager from '../../utils/typed-array-manager';
import { toDoublePrecisionArray } from '../../utils/math-utils';
import log from '../../utils/log';

function getStride(accessor) {
  return accessor.stride || accessor.size * accessor.bytesPerElement;
}

function resolveShaderAttribute(baseAccessor, shaderAttributeOptions) {
  if (shaderAttributeOptions.offset) {
    log.removed('shaderAttribute.offset', 'vertexOffset, elementOffset')();
  }

  var stride = getStride(baseAccessor);
  var vertexOffset = shaderAttributeOptions.vertexOffset !== undefined ? shaderAttributeOptions.vertexOffset : baseAccessor.vertexOffset || 0;
  var elementOffset = shaderAttributeOptions.elementOffset || 0;
  var offset = vertexOffset * stride + elementOffset * baseAccessor.bytesPerElement + (baseAccessor.offset || 0);
  return _objectSpread(_objectSpread({}, shaderAttributeOptions), {}, {
    offset: offset,
    stride: stride
  });
}

function resolveDoublePrecisionShaderAttributes(baseAccessor, shaderAttributeOptions) {
  var resolvedOptions = resolveShaderAttribute(baseAccessor, shaderAttributeOptions);
  return {
    high: resolvedOptions,
    low: _objectSpread(_objectSpread({}, resolvedOptions), {}, {
      offset: resolvedOptions.offset + baseAccessor.size * 4
    })
  };
}

var DataColumn = function () {
  function DataColumn(gl, opts, state) {
    _classCallCheck(this, DataColumn);

    _defineProperty(this, "gl", void 0);

    _defineProperty(this, "id", void 0);

    _defineProperty(this, "size", void 0);

    _defineProperty(this, "settings", void 0);

    _defineProperty(this, "value", void 0);

    _defineProperty(this, "doublePrecision", void 0);

    _defineProperty(this, "_buffer", void 0);

    _defineProperty(this, "state", void 0);

    this.gl = gl;
    this.id = opts.id || '';
    this.size = opts.size || 1;
    var logicalType = opts.logicalType || opts.type;
    var doublePrecision = logicalType === 5130;
    var defaultValue = opts.defaultValue;
    defaultValue = Number.isFinite(defaultValue) ? [defaultValue] : defaultValue || new Array(this.size).fill(0);
    var bufferType;

    if (doublePrecision) {
      bufferType = 5126;
    } else if (!logicalType && opts.isIndexed) {
      bufferType = gl && hasFeature(gl, FEATURES.ELEMENT_INDEX_UINT32) ? 5125 : 5123;
    } else {
      bufferType = logicalType || 5126;
    }

    var defaultType = glArrayFromType(logicalType || bufferType || 5126);
    this.doublePrecision = doublePrecision;

    if (doublePrecision && opts.fp64 === false) {
      defaultType = Float32Array;
    }

    this.value = null;
    this.settings = _objectSpread(_objectSpread({}, opts), {}, {
      defaultType: defaultType,
      defaultValue: defaultValue,
      logicalType: logicalType,
      type: bufferType,
      size: this.size,
      bytesPerElement: defaultType.BYTES_PER_ELEMENT
    });
    this.state = _objectSpread(_objectSpread({}, state), {}, {
      externalBuffer: null,
      bufferAccessor: this.settings,
      allocatedValue: null,
      numInstances: 0,
      bounds: null,
      constant: false
    });
    this._buffer = null;
  }

  _createClass(DataColumn, [{
    key: "isConstant",
    get: function get() {
      return this.state.constant;
    }
  }, {
    key: "buffer",
    get: function get() {
      if (!this._buffer) {
        var _this$settings = this.settings,
            isIndexed = _this$settings.isIndexed,
            type = _this$settings.type;
        this._buffer = new Buffer(this.gl, {
          id: this.id,
          target: isIndexed ? 34963 : 34962,
          accessor: {
            type: type
          }
        });
      }

      return this._buffer;
    }
  }, {
    key: "byteOffset",
    get: function get() {
      var accessor = this.getAccessor();

      if (accessor.vertexOffset) {
        return accessor.vertexOffset * getStride(accessor);
      }

      return 0;
    }
  }, {
    key: "numInstances",
    get: function get() {
      return this.state.numInstances;
    },
    set: function set(n) {
      this.state.numInstances = n;
    }
  }, {
    key: "delete",
    value: function _delete() {
      if (this._buffer) {
        this._buffer.delete();

        this._buffer = null;
      }

      typedArrayManager.release(this.state.allocatedValue);
    }
  }, {
    key: "getShaderAttributes",
    value: function getShaderAttributes(id, options) {
      if (this.doublePrecision) {
        var shaderAttributes = {};
        var isBuffer64Bit = this.value instanceof Float64Array;
        var doubleShaderAttributeDefs = resolveDoublePrecisionShaderAttributes(this.getAccessor(), options || {});
        shaderAttributes[id] = new ShaderAttribute(this, doubleShaderAttributeDefs.high);
        shaderAttributes["".concat(id, "64Low")] = isBuffer64Bit ? new ShaderAttribute(this, doubleShaderAttributeDefs.low) : new Float32Array(this.size);
        return shaderAttributes;
      }

      if (options) {
        var shaderAttributeDef = resolveShaderAttribute(this.getAccessor(), options);
        return _defineProperty({}, id, new ShaderAttribute(this, shaderAttributeDef));
      }

      return _defineProperty({}, id, this);
    }
  }, {
    key: "getBuffer",
    value: function getBuffer() {
      if (this.state.constant) {
        return null;
      }

      return this.state.externalBuffer || this._buffer;
    }
  }, {
    key: "getValue",
    value: function getValue() {
      if (this.state.constant) {
        return this.value;
      }

      return [this.getBuffer(), this.getAccessor()];
    }
  }, {
    key: "getAccessor",
    value: function getAccessor() {
      return this.state.bufferAccessor;
    }
  }, {
    key: "getBounds",
    value: function getBounds() {
      if (this.state.bounds) {
        return this.state.bounds;
      }

      var result = null;

      if (this.state.constant && this.value) {
        var min = Array.from(this.value);
        result = [min, min];
      } else {
        var value = this.value,
            numInstances = this.numInstances,
            size = this.size;
        var len = numInstances * size;

        if (value && len && value.length >= len) {
          var _min = new Array(size).fill(Infinity);

          var max = new Array(size).fill(-Infinity);

          for (var i = 0; i < len;) {
            for (var j = 0; j < size; j++) {
              var v = value[i++];
              if (v < _min[j]) _min[j] = v;
              if (v > max[j]) max[j] = v;
            }
          }

          result = [_min, max];
        }
      }

      this.state.bounds = result;
      return result;
    }
  }, {
    key: "setData",
    value: function setData(data) {
      var state = this.state;
      var opts;

      if (ArrayBuffer.isView(data)) {
        opts = {
          value: data
        };
      } else if (data instanceof Buffer) {
        opts = {
          buffer: data
        };
      } else {
        opts = data;
      }

      var accessor = _objectSpread(_objectSpread({}, this.settings), opts);

      state.bufferAccessor = accessor;
      state.bounds = null;

      if (opts.constant) {
        var value = opts.value;
        value = this._normalizeValue(value, [], 0);

        if (this.settings.normalized) {
          value = this.normalizeConstant(value);
        }

        var hasChanged = !state.constant || !this._areValuesEqual(value, this.value);

        if (!hasChanged) {
          return false;
        }

        state.externalBuffer = null;
        state.constant = true;
        this.value = value;
      } else if (opts.buffer) {
        var buffer = opts.buffer;
        state.externalBuffer = buffer;
        state.constant = false;
        this.value = opts.value || null;
        var isBuffer64Bit = opts.value instanceof Float64Array;
        accessor.type = opts.type || buffer.accessor.type;
        accessor.bytesPerElement = buffer.accessor.BYTES_PER_ELEMENT * (isBuffer64Bit ? 2 : 1);
        accessor.stride = getStride(accessor);
      } else if (opts.value) {
        this._checkExternalBuffer(opts);

        var _value = opts.value;
        state.externalBuffer = null;
        state.constant = false;
        this.value = _value;
        accessor.bytesPerElement = _value.BYTES_PER_ELEMENT;
        accessor.stride = getStride(accessor);
        var _buffer = this.buffer,
            byteOffset = this.byteOffset;

        if (this.doublePrecision && _value instanceof Float64Array) {
          _value = toDoublePrecisionArray(_value, accessor);
        }

        var requiredBufferSize = _value.byteLength + byteOffset + accessor.stride * 2;

        if (_buffer.byteLength < requiredBufferSize) {
          _buffer.reallocate(requiredBufferSize);
        }

        _buffer.setAccessor(null);

        _buffer.subData({
          data: _value,
          offset: byteOffset
        });

        accessor.type = opts.type || _buffer.accessor.type;
      }

      return true;
    }
  }, {
    key: "updateSubBuffer",
    value: function updateSubBuffer() {
      var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.state.bounds = null;
      var value = this.value;
      var _opts$startOffset = opts.startOffset,
          startOffset = _opts$startOffset === void 0 ? 0 : _opts$startOffset,
          endOffset = opts.endOffset;
      this.buffer.subData({
        data: this.doublePrecision && value instanceof Float64Array ? toDoublePrecisionArray(value, {
          size: this.size,
          startIndex: startOffset,
          endIndex: endOffset
        }) : value.subarray(startOffset, endOffset),
        offset: startOffset * value.BYTES_PER_ELEMENT + this.byteOffset
      });
    }
  }, {
    key: "allocate",
    value: function allocate(numInstances) {
      var copy = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var state = this.state;
      var oldValue = state.allocatedValue;
      var value = typedArrayManager.allocate(oldValue, numInstances + 1, {
        size: this.size,
        type: this.settings.defaultType,
        copy: copy
      });
      this.value = value;
      var buffer = this.buffer,
          byteOffset = this.byteOffset;

      if (buffer.byteLength < value.byteLength + byteOffset) {
        buffer.reallocate(value.byteLength + byteOffset);

        if (copy && oldValue) {
          buffer.subData({
            data: oldValue instanceof Float64Array ? toDoublePrecisionArray(oldValue, this) : oldValue,
            offset: byteOffset
          });
        }
      }

      state.allocatedValue = value;
      state.constant = false;
      state.externalBuffer = null;
      state.bufferAccessor = this.settings;
      return true;
    }
  }, {
    key: "_checkExternalBuffer",
    value: function _checkExternalBuffer(opts) {
      var value = opts.value;

      if (!ArrayBuffer.isView(value)) {
        throw new Error("Attribute ".concat(this.id, " value is not TypedArray"));
      }

      var ArrayType = this.settings.defaultType;
      var illegalArrayType = false;

      if (this.doublePrecision) {
        illegalArrayType = value.BYTES_PER_ELEMENT < 4;
      }

      if (illegalArrayType) {
        throw new Error("Attribute ".concat(this.id, " does not support ").concat(value.constructor.name));
      }

      if (!(value instanceof ArrayType) && this.settings.normalized && !('normalized' in opts)) {
        log.warn("Attribute ".concat(this.id, " is normalized"))();
      }
    }
  }, {
    key: "normalizeConstant",
    value: function normalizeConstant(value) {
      switch (this.settings.type) {
        case 5120:
          return new Float32Array(value).map(function (x) {
            return (x + 128) / 255 * 2 - 1;
          });

        case 5122:
          return new Float32Array(value).map(function (x) {
            return (x + 32768) / 65535 * 2 - 1;
          });

        case 5121:
          return new Float32Array(value).map(function (x) {
            return x / 255;
          });

        case 5123:
          return new Float32Array(value).map(function (x) {
            return x / 65535;
          });

        default:
          return value;
      }
    }
  }, {
    key: "_normalizeValue",
    value: function _normalizeValue(value, out, start) {
      var _this$settings2 = this.settings,
          defaultValue = _this$settings2.defaultValue,
          size = _this$settings2.size;

      if (Number.isFinite(value)) {
        out[start] = value;
        return out;
      }

      if (!value) {
        var i = size;

        while (--i >= 0) {
          out[start + i] = defaultValue[i];
        }

        return out;
      }

      switch (size) {
        case 4:
          out[start + 3] = Number.isFinite(value[3]) ? value[3] : defaultValue[3];

        case 3:
          out[start + 2] = Number.isFinite(value[2]) ? value[2] : defaultValue[2];

        case 2:
          out[start + 1] = Number.isFinite(value[1]) ? value[1] : defaultValue[1];

        case 1:
          out[start + 0] = Number.isFinite(value[0]) ? value[0] : defaultValue[0];
          break;

        default:
          var _i = size;

          while (--_i >= 0) {
            out[start + _i] = Number.isFinite(value[_i]) ? value[_i] : defaultValue[_i];
          }

      }

      return out;
    }
  }, {
    key: "_areValuesEqual",
    value: function _areValuesEqual(value1, value2) {
      if (!value1 || !value2) {
        return false;
      }

      var size = this.size;

      for (var i = 0; i < size; i++) {
        if (value1[i] !== value2[i]) {
          return false;
        }
      }

      return true;
    }
  }]);

  return DataColumn;
}();

export { DataColumn as default };