import _typeof from "@babel/runtime/helpers/typeof";
import { get } from '../utils/get';
import expressionEval from 'expression-eval';
var cachedExpressionMap = {
  '-': function _(object) {
    return object;
  }
};
export default function parseExpressionString(propValue, configuration) {
  if (propValue in cachedExpressionMap) {
    return cachedExpressionMap[propValue];
  }

  var func;
  var ast = expressionEval.parse(propValue);

  if (!ast.right && !ast.left && ast.type === 'Identifier') {
    func = function func(row) {
      return get(row, propValue);
    };
  } else {
    traverse(ast, function (node) {
      if (node.type === 'CallExpression') {
        throw new Error('Function calls not allowed in JSON expressions');
      }
    });

    func = function func(row) {
      return expressionEval.eval(ast, row);
    };
  }

  cachedExpressionMap[propValue] = func;
  return func;
}

function traverse(node, visitor) {
  if (Array.isArray(node)) {
    node.forEach(function (element) {
      return traverse(element, visitor);
    });
  } else if (node && _typeof(node) === 'object') {
    if (node.type) {
      visitor(node);
    }

    for (var key in node) {
      traverse(node[key], visitor);
    }
  }
}