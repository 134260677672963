import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

var ViewState = function () {
  function ViewState(props, state) {
    _classCallCheck(this, ViewState);

    _defineProperty(this, "_viewportProps", void 0);

    _defineProperty(this, "_state", void 0);

    this._viewportProps = this.applyConstraints(props);
    this._state = state;
  }

  _createClass(ViewState, [{
    key: "getViewportProps",
    value: function getViewportProps() {
      return this._viewportProps;
    }
  }, {
    key: "getState",
    value: function getState() {
      return this._state;
    }
  }]);

  return ViewState;
}();

export { ViewState as default };