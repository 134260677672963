import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _assertThisInitialized from "@babel/runtime/helpers/assertThisInitialized";
import _inherits from "@babel/runtime/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

import Viewport from '../viewports/viewport';
import { Matrix4 } from '@math.gl/core';
import { pixelsToWorld, fovyToAltitude } from '@math.gl/web-mercator';
var DEGREES_TO_RADIANS = Math.PI / 180;

function getViewMatrix(_ref) {
  var height = _ref.height,
      focalDistance = _ref.focalDistance,
      orbitAxis = _ref.orbitAxis,
      rotationX = _ref.rotationX,
      rotationOrbit = _ref.rotationOrbit,
      zoom = _ref.zoom;
  var up = orbitAxis === 'Z' ? [0, 0, 1] : [0, 1, 0];
  var eye = orbitAxis === 'Z' ? [0, -focalDistance, 0] : [0, 0, focalDistance];
  var viewMatrix = new Matrix4().lookAt({
    eye: eye,
    up: up
  });
  viewMatrix.rotateX(rotationX * DEGREES_TO_RADIANS);

  if (orbitAxis === 'Z') {
    viewMatrix.rotateZ(rotationOrbit * DEGREES_TO_RADIANS);
  } else {
    viewMatrix.rotateY(rotationOrbit * DEGREES_TO_RADIANS);
  }

  var projectionScale = Math.pow(2, zoom) / height;
  viewMatrix.scale(projectionScale);
  return viewMatrix;
}

var OrbitViewport = function (_Viewport) {
  _inherits(OrbitViewport, _Viewport);

  var _super = _createSuper(OrbitViewport);

  function OrbitViewport(props) {
    var _this;

    _classCallCheck(this, OrbitViewport);

    var height = props.height,
        projectionMatrix = props.projectionMatrix,
        _props$fovy = props.fovy,
        fovy = _props$fovy === void 0 ? 50 : _props$fovy,
        _props$orbitAxis = props.orbitAxis,
        orbitAxis = _props$orbitAxis === void 0 ? 'Z' : _props$orbitAxis,
        _props$target = props.target,
        target = _props$target === void 0 ? [0, 0, 0] : _props$target,
        _props$rotationX = props.rotationX,
        rotationX = _props$rotationX === void 0 ? 0 : _props$rotationX,
        _props$rotationOrbit = props.rotationOrbit,
        rotationOrbit = _props$rotationOrbit === void 0 ? 0 : _props$rotationOrbit,
        _props$zoom = props.zoom,
        zoom = _props$zoom === void 0 ? 0 : _props$zoom;
    var focalDistance = projectionMatrix ? projectionMatrix[5] / 2 : fovyToAltitude(fovy);
    _this = _super.call(this, _objectSpread(_objectSpread({}, props), {}, {
      longitude: undefined,
      viewMatrix: getViewMatrix({
        height: height || 1,
        focalDistance: focalDistance,
        orbitAxis: orbitAxis,
        rotationX: rotationX,
        rotationOrbit: rotationOrbit,
        zoom: zoom
      }),
      fovy: fovy,
      focalDistance: focalDistance,
      position: target,
      zoom: zoom
    }));

    _defineProperty(_assertThisInitialized(_this), "projectedCenter", void 0);

    _this.projectedCenter = _this.project(_this.center);
    return _this;
  }

  _createClass(OrbitViewport, [{
    key: "unproject",
    value: function unproject(xyz) {
      var _ref2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
          _ref2$topLeft = _ref2.topLeft,
          topLeft = _ref2$topLeft === void 0 ? true : _ref2$topLeft;

      var _xyz = _slicedToArray(xyz, 3),
          x = _xyz[0],
          y = _xyz[1],
          _xyz$ = _xyz[2],
          z = _xyz$ === void 0 ? this.projectedCenter[2] : _xyz$;

      var y2 = topLeft ? y : this.height - y;

      var _pixelsToWorld = pixelsToWorld([x, y2, z], this.pixelUnprojectionMatrix),
          _pixelsToWorld2 = _slicedToArray(_pixelsToWorld, 3),
          X = _pixelsToWorld2[0],
          Y = _pixelsToWorld2[1],
          Z = _pixelsToWorld2[2];

      return [X, Y, Z];
    }
  }, {
    key: "panByPosition",
    value: function panByPosition(coords, pixel) {
      var p0 = this.project(coords);
      var nextCenter = [this.width / 2 + p0[0] - pixel[0], this.height / 2 + p0[1] - pixel[1], this.projectedCenter[2]];
      return {
        target: this.unproject(nextCenter)
      };
    }
  }]);

  return OrbitViewport;
}(Viewport);

export { OrbitViewport as default };