import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { normalizeTransitionSettings } from './attribute/attribute-transition-utils';
import CPUInterpolationTransition from '../transitions/cpu-interpolation-transition';
import CPUSpringTransition from '../transitions/cpu-spring-transition';
import log from '../utils/log';
var TRANSITION_TYPES = {
  interpolation: CPUInterpolationTransition,
  spring: CPUSpringTransition
};

var UniformTransitionManager = function () {
  function UniformTransitionManager(timeline) {
    _classCallCheck(this, UniformTransitionManager);

    this.transitions = new Map();
    this.timeline = timeline;
  }

  _createClass(UniformTransitionManager, [{
    key: "active",
    get: function get() {
      return this.transitions.size > 0;
    }
  }, {
    key: "add",
    value: function add(key, fromValue, toValue, settings) {
      var transitions = this.transitions;

      if (transitions.has(key)) {
        var _transition = transitions.get(key);

        var _transition$value = _transition.value,
            value = _transition$value === void 0 ? _transition.settings.fromValue : _transition$value;
        fromValue = value;
        this.remove(key);
      }

      settings = normalizeTransitionSettings(settings);

      if (!settings) {
        return;
      }

      var TransitionType = TRANSITION_TYPES[settings.type];

      if (!TransitionType) {
        log.error("unsupported transition type '".concat(settings.type, "'"))();
        return;
      }

      var transition = new TransitionType(this.timeline);
      transition.start(_objectSpread(_objectSpread({}, settings), {}, {
        fromValue: fromValue,
        toValue: toValue
      }));
      transitions.set(key, transition);
    }
  }, {
    key: "remove",
    value: function remove(key) {
      var transitions = this.transitions;

      if (transitions.has(key)) {
        transitions.get(key).cancel();
        transitions.delete(key);
      }
    }
  }, {
    key: "update",
    value: function update() {
      var propsInTransition = {};

      var _iterator = _createForOfIteratorHelper(this.transitions),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var _step$value = _slicedToArray(_step.value, 2),
              key = _step$value[0],
              transition = _step$value[1];

          transition.update();
          propsInTransition[key] = transition.value;

          if (!transition.inProgress) {
            this.remove(key);
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      return propsInTransition;
    }
  }, {
    key: "clear",
    value: function clear() {
      var _iterator2 = _createForOfIteratorHelper(this.transitions.keys()),
          _step2;

      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var key = _step2.value;
          this.remove(key);
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }
    }
  }]);

  return UniformTransitionManager;
}();

export { UniformTransitionManager as default };