import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import { createIterable } from '@deck.gl/core';
import { getGridOffset } from '../utils/grid-aggregation-utils';
export function pointToDensityGridDataCPU(props, aggregationParams) {
  var hashInfo = pointsToGridHashing(props, aggregationParams);
  var result = getGridLayerDataFromGridHash(hashInfo);
  return {
    gridHash: hashInfo.gridHash,
    gridOffset: hashInfo.gridOffset,
    data: result
  };
}

function pointsToGridHashing(props, aggregationParams) {
  var _props$data = props.data,
      data = _props$data === void 0 ? [] : _props$data,
      cellSize = props.cellSize;
  var attributes = aggregationParams.attributes,
      viewport = aggregationParams.viewport,
      projectPoints = aggregationParams.projectPoints,
      numInstances = aggregationParams.numInstances;
  var positions = attributes.positions.value;

  var _attributes$positions = attributes.positions.getAccessor(),
      size = _attributes$positions.size;

  var boundingBox = aggregationParams.boundingBox || getPositionBoundingBox(attributes.positions, numInstances);
  var offsets = aggregationParams.posOffset || [180, 90];
  var gridOffset = aggregationParams.gridOffset || getGridOffset(boundingBox, cellSize);

  if (gridOffset.xOffset <= 0 || gridOffset.yOffset <= 0) {
    return {
      gridHash: {},
      gridOffset: gridOffset
    };
  }

  var width = viewport.width,
      height = viewport.height;
  var numCol = Math.ceil(width / gridOffset.xOffset);
  var numRow = Math.ceil(height / gridOffset.yOffset);
  var gridHash = {};

  var _createIterable = createIterable(data),
      iterable = _createIterable.iterable,
      objectInfo = _createIterable.objectInfo;

  var position = new Array(3);

  var _iterator = _createForOfIteratorHelper(iterable),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var pt = _step.value;
      objectInfo.index++;
      position[0] = positions[objectInfo.index * size];
      position[1] = positions[objectInfo.index * size + 1];
      position[2] = size >= 3 ? positions[objectInfo.index * size + 2] : 0;

      var _ref = projectPoints ? viewport.project(position) : position,
          _ref2 = _slicedToArray(_ref, 2),
          x = _ref2[0],
          y = _ref2[1];

      if (Number.isFinite(x) && Number.isFinite(y)) {
        var yIndex = Math.floor((y + offsets[1]) / gridOffset.yOffset);
        var xIndex = Math.floor((x + offsets[0]) / gridOffset.xOffset);

        if (!projectPoints || xIndex >= 0 && xIndex < numCol && yIndex >= 0 && yIndex < numRow) {
          var key = "".concat(yIndex, "-").concat(xIndex);
          gridHash[key] = gridHash[key] || {
            count: 0,
            points: [],
            lonIdx: xIndex,
            latIdx: yIndex
          };
          gridHash[key].count += 1;
          gridHash[key].points.push({
            source: pt,
            index: objectInfo.index
          });
        }
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  return {
    gridHash: gridHash,
    gridOffset: gridOffset,
    offsets: [offsets[0] * -1, offsets[1] * -1]
  };
}

function getGridLayerDataFromGridHash(_ref3) {
  var gridHash = _ref3.gridHash,
      gridOffset = _ref3.gridOffset,
      offsets = _ref3.offsets;
  var data = new Array(Object.keys(gridHash).length);
  var i = 0;

  for (var key in gridHash) {
    var idxs = key.split('-');
    var latIdx = parseInt(idxs[0], 10);
    var lonIdx = parseInt(idxs[1], 10);
    var index = i++;
    data[index] = _objectSpread({
      index: index,
      position: [offsets[0] + gridOffset.xOffset * lonIdx, offsets[1] + gridOffset.yOffset * latIdx]
    }, gridHash[key]);
  }

  return data;
}

function getPositionBoundingBox(positionAttribute, numInstance) {
  var positions = positionAttribute.value;

  var _positionAttribute$ge = positionAttribute.getAccessor(),
      size = _positionAttribute$ge.size;

  var yMin = Infinity;
  var yMax = -Infinity;
  var xMin = Infinity;
  var xMax = -Infinity;
  var y;
  var x;

  for (var i = 0; i < numInstance; i++) {
    x = positions[i * size];
    y = positions[i * size + 1];

    if (Number.isFinite(x) && Number.isFinite(y)) {
      yMin = y < yMin ? y : yMin;
      yMax = y > yMax ? y : yMax;
      xMin = x < xMin ? x : xMin;
      xMax = x > xMax ? x : xMax;
    }
  }

  return {
    xMin: xMin,
    xMax: xMax,
    yMin: yMin,
    yMax: yMax
  };
}