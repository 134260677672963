import { toHilbertQuadkey, FromHilbertQuadKey, IJToST, STToUV, FaceUVToXYZ, XYZToLngLat } from './s2-geometry';
import Long from 'long';

function getIdFromToken(token) {
  var paddedToken = token.padEnd(16, '0');
  return Long.fromString(paddedToken, 16);
}

var MAX_RESOLUTION = 100;

function getGeoBounds(_ref) {
  var face = _ref.face,
      ij = _ref.ij,
      level = _ref.level;
  var offsets = [[0, 0], [0, 1], [1, 1], [1, 0], [0, 0]];
  var resolution = Math.max(1, Math.ceil(MAX_RESOLUTION * Math.pow(2, -level)));
  var result = new Float64Array(4 * resolution * 2 + 2);
  var ptIndex = 0;
  var prevLng = 0;

  for (var i = 0; i < 4; i++) {
    var offset = offsets[i].slice(0);
    var nextOffset = offsets[i + 1];
    var stepI = (nextOffset[0] - offset[0]) / resolution;
    var stepJ = (nextOffset[1] - offset[1]) / resolution;

    for (var j = 0; j < resolution; j++) {
      offset[0] += stepI;
      offset[1] += stepJ;
      var st = IJToST(ij, level, offset);
      var uv = STToUV(st);
      var xyz = FaceUVToXYZ(face, uv);
      var lngLat = XYZToLngLat(xyz);

      if (Math.abs(lngLat[1]) > 89.999) {
        lngLat[0] = prevLng;
      }

      var deltaLng = lngLat[0] - prevLng;
      lngLat[0] += deltaLng > 180 ? -360 : deltaLng < -180 ? 360 : 0;
      result[ptIndex++] = lngLat[0];
      result[ptIndex++] = lngLat[1];
      prevLng = lngLat[0];
    }
  }

  result[ptIndex++] = result[0];
  result[ptIndex++] = result[1];
  return result;
}

export function getS2QuadKey(token) {
  if (typeof token === 'string') {
    if (token.indexOf('/') > 0) {
      return token;
    }

    token = getIdFromToken(token);
  }

  return toHilbertQuadkey(token.toString());
}
export function getS2Polygon(token) {
  var key = getS2QuadKey(token);
  var s2cell = FromHilbertQuadKey(key);
  return getGeoBounds(s2cell);
}