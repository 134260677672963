import _typeof from "@babel/runtime/helpers/typeof";
import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import Viewport from '../viewports/viewport';
import { parsePosition, getPosition } from '../utils/positions';
import { deepEqual } from '../utils/deep-equal';
import assert from '../utils/assert';

var View = function () {
  function View(props) {
    _classCallCheck(this, View);

    _defineProperty(this, "id", void 0);

    _defineProperty(this, "viewportInstance", void 0);

    _defineProperty(this, "_x", void 0);

    _defineProperty(this, "_y", void 0);

    _defineProperty(this, "_width", void 0);

    _defineProperty(this, "_height", void 0);

    _defineProperty(this, "_padding", void 0);

    _defineProperty(this, "props", void 0);

    var _ref = props || {},
        id = _ref.id,
        _ref$x = _ref.x,
        x = _ref$x === void 0 ? 0 : _ref$x,
        _ref$y = _ref.y,
        y = _ref$y === void 0 ? 0 : _ref$y,
        _ref$width = _ref.width,
        width = _ref$width === void 0 ? '100%' : _ref$width,
        _ref$height = _ref.height,
        height = _ref$height === void 0 ? '100%' : _ref$height,
        _ref$padding = _ref.padding,
        padding = _ref$padding === void 0 ? null : _ref$padding,
        viewportInstance = _ref.viewportInstance;

    assert(!viewportInstance || viewportInstance instanceof Viewport);
    this.viewportInstance = viewportInstance;
    this.id = id || this.constructor.displayName || 'view';
    this.props = _objectSpread(_objectSpread({}, props), {}, {
      id: this.id
    });
    this._x = parsePosition(x);
    this._y = parsePosition(y);
    this._width = parsePosition(width);
    this._height = parsePosition(height);
    this._padding = padding && {
      left: parsePosition(padding.left || 0),
      right: parsePosition(padding.right || 0),
      top: parsePosition(padding.top || 0),
      bottom: parsePosition(padding.bottom || 0)
    };
    this.equals = this.equals.bind(this);
    Object.seal(this);
  }

  _createClass(View, [{
    key: "equals",
    value: function equals(view) {
      if (this === view) {
        return true;
      }

      if (this.viewportInstance) {
        return view.viewportInstance ? this.viewportInstance.equals(view.viewportInstance) : false;
      }

      return this.ViewportType === view.ViewportType && deepEqual(this.props, view.props, 2);
    }
  }, {
    key: "makeViewport",
    value: function makeViewport(_ref2) {
      var width = _ref2.width,
          height = _ref2.height,
          viewState = _ref2.viewState;

      if (this.viewportInstance) {
        return this.viewportInstance;
      }

      viewState = this.filterViewState(viewState);
      var viewportDimensions = this.getDimensions({
        width: width,
        height: height
      });

      if (!viewportDimensions.height || !viewportDimensions.width) {
        return null;
      }

      return new this.ViewportType(_objectSpread(_objectSpread(_objectSpread({}, viewState), this.props), viewportDimensions));
    }
  }, {
    key: "getViewStateId",
    value: function getViewStateId() {
      var viewState = this.props.viewState;

      if (typeof viewState === 'string') {
        return viewState;
      }

      return (viewState === null || viewState === void 0 ? void 0 : viewState.id) || this.id;
    }
  }, {
    key: "filterViewState",
    value: function filterViewState(viewState) {
      if (this.props.viewState && _typeof(this.props.viewState) === 'object') {
        if (!this.props.viewState.id) {
          return this.props.viewState;
        }

        var newViewState = _objectSpread({}, viewState);

        for (var key in this.props.viewState) {
          if (key !== 'id') {
            newViewState[key] = this.props.viewState[key];
          }
        }

        return newViewState;
      }

      return viewState;
    }
  }, {
    key: "getDimensions",
    value: function getDimensions(_ref3) {
      var width = _ref3.width,
          height = _ref3.height;
      var dimensions = {
        x: getPosition(this._x, width),
        y: getPosition(this._y, height),
        width: getPosition(this._width, width),
        height: getPosition(this._height, height)
      };

      if (this._padding) {
        dimensions.padding = {
          left: getPosition(this._padding.left, width),
          top: getPosition(this._padding.top, height),
          right: getPosition(this._padding.right, width),
          bottom: getPosition(this._padding.bottom, height)
        };
      }

      return dimensions;
    }
  }, {
    key: "controller",
    get: function get() {
      var opts = this.props.controller;

      if (!opts) {
        return null;
      }

      if (opts === true) {
        return {
          type: this.ControllerType
        };
      }

      if (typeof opts === 'function') {
        return {
          type: opts
        };
      }

      return _objectSpread({
        type: this.ControllerType
      }, opts);
    }
  }]);

  return View;
}();

export { View as default };