import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _inherits from "@babel/runtime/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

import Transition from './transition';
var EPSILON = 1e-5;

function updateSpringElement(prev, cur, dest, damping, stiffness) {
  var velocity = cur - prev;
  var delta = dest - cur;
  var spring = delta * stiffness;
  var damper = -velocity * damping;
  return spring + damper + velocity + cur;
}

function updateSpring(prev, cur, dest, damping, stiffness) {
  if (Array.isArray(dest)) {
    var next = [];

    for (var i = 0; i < dest.length; i++) {
      next[i] = updateSpringElement(prev[i], cur[i], dest[i], damping, stiffness);
    }

    return next;
  }

  return updateSpringElement(prev, cur, dest, damping, stiffness);
}

function distance(value1, value2) {
  if (Array.isArray(value1)) {
    var distanceSquare = 0;

    for (var i = 0; i < value1.length; i++) {
      var d = value1[i] - value2[i];
      distanceSquare += d * d;
    }

    return Math.sqrt(distanceSquare);
  }

  return Math.abs(value1 - value2);
}

var CPUSpringTransition = function (_Transition) {
  _inherits(CPUSpringTransition, _Transition);

  var _super = _createSuper(CPUSpringTransition);

  function CPUSpringTransition() {
    _classCallCheck(this, CPUSpringTransition);

    return _super.apply(this, arguments);
  }

  _createClass(CPUSpringTransition, [{
    key: "value",
    get: function get() {
      return this._currValue;
    }
  }, {
    key: "_onUpdate",
    value: function _onUpdate() {
      var _this$settings = this.settings,
          fromValue = _this$settings.fromValue,
          toValue = _this$settings.toValue,
          damping = _this$settings.damping,
          stiffness = _this$settings.stiffness;

      var _this$_prevValue = this._prevValue,
          _prevValue = _this$_prevValue === void 0 ? fromValue : _this$_prevValue,
          _this$_currValue = this._currValue,
          _currValue = _this$_currValue === void 0 ? fromValue : _this$_currValue;

      var nextValue = updateSpring(_prevValue, _currValue, toValue, damping, stiffness);
      var delta = distance(nextValue, toValue);
      var velocity = distance(nextValue, _currValue);

      if (delta < EPSILON && velocity < EPSILON) {
        nextValue = toValue;
        this.end();
      }

      this._prevValue = _currValue;
      this._currValue = nextValue;
    }
  }]);

  return CPUSpringTransition;
}(Transition);

export { CPUSpringTransition as default };