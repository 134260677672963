import log from '../utils/log';
export var COORDINATE_SYSTEM = {
  DEFAULT: -1,
  LNGLAT: 1,
  METER_OFFSETS: 2,
  LNGLAT_OFFSETS: 3,
  CARTESIAN: 0
};
Object.defineProperty(COORDINATE_SYSTEM, 'IDENTITY', {
  get: function get() {
    log.deprecated('COORDINATE_SYSTEM.IDENTITY', 'COORDINATE_SYSTEM.CARTESIAN')();
    return 0;
  }
});
export var PROJECTION_MODE = {
  WEB_MERCATOR: 1,
  GLOBE: 2,
  WEB_MERCATOR_AUTO_OFFSET: 4,
  IDENTITY: 0
};
export var UNIT = {
  common: 0,
  meters: 1,
  pixels: 2
};
export var EVENTS = {
  click: {
    handler: 'onClick'
  },
  panstart: {
    handler: 'onDragStart'
  },
  panmove: {
    handler: 'onDrag'
  },
  panend: {
    handler: 'onDragEnd'
  }
};
export var OPERATION = {
  DRAW: 'draw',
  MASK: 'mask',
  TERRAIN: 'terrain'
};