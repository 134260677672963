import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _inherits from "@babel/runtime/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

import { PointLight } from './point-light';
import { getUniformsFromViewport } from '../../shaderlib/project/viewport-uniforms';

var CameraLight = function (_PointLight) {
  _inherits(CameraLight, _PointLight);

  var _super = _createSuper(CameraLight);

  function CameraLight() {
    _classCallCheck(this, CameraLight);

    return _super.apply(this, arguments);
  }

  _createClass(CameraLight, [{
    key: "getProjectedLight",
    value: function getProjectedLight(_ref) {
      var layer = _ref.layer;
      var projectedLight = this.projectedLight;
      var viewport = layer.context.viewport;
      var _layer$props = layer.props,
          coordinateSystem = _layer$props.coordinateSystem,
          coordinateOrigin = _layer$props.coordinateOrigin,
          modelMatrix = _layer$props.modelMatrix;

      var _getUniformsFromViewp = getUniformsFromViewport({
        viewport: viewport,
        modelMatrix: modelMatrix,
        coordinateSystem: coordinateSystem,
        coordinateOrigin: coordinateOrigin
      }),
          project_uCameraPosition = _getUniformsFromViewp.project_uCameraPosition;

      projectedLight.color = this.color;
      projectedLight.intensity = this.intensity;
      projectedLight.position = project_uCameraPosition;
      return projectedLight;
    }
  }]);

  return CameraLight;
}(PointLight);

export { CameraLight as default };