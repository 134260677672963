import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _assertThisInitialized from "@babel/runtime/helpers/assertThisInitialized";
import _get from "@babel/runtime/helpers/get";
import _inherits from "@babel/runtime/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

import LayersPass from './layers-pass';
import { withParameters } from '@luma.gl/core';
import log from '../utils/log';
var PICKING_PARAMETERS = {
  blendFunc: [1, 0, 32771, 0],
  blendEquation: 32774
};

var PickLayersPass = function (_LayersPass) {
  _inherits(PickLayersPass, _LayersPass);

  var _super = _createSuper(PickLayersPass);

  function PickLayersPass() {
    var _this;

    _classCallCheck(this, PickLayersPass);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));

    _defineProperty(_assertThisInitialized(_this), "pickZ", void 0);

    _defineProperty(_assertThisInitialized(_this), "_colorEncoderState", null);

    return _this;
  }

  _createClass(PickLayersPass, [{
    key: "render",
    value: function render(props) {
      if ('pickingFBO' in props) {
        return this._drawPickingBuffer(props);
      }

      return _get(_getPrototypeOf(PickLayersPass.prototype), "render", this).call(this, props);
    }
  }, {
    key: "_drawPickingBuffer",
    value: function _drawPickingBuffer(_ref) {
      var _this2 = this;

      var layers = _ref.layers,
          layerFilter = _ref.layerFilter,
          views = _ref.views,
          viewports = _ref.viewports,
          onViewportActive = _ref.onViewportActive,
          pickingFBO = _ref.pickingFBO,
          _ref$deviceRect = _ref.deviceRect,
          x = _ref$deviceRect.x,
          y = _ref$deviceRect.y,
          width = _ref$deviceRect.width,
          height = _ref$deviceRect.height,
          cullRect = _ref.cullRect,
          effects = _ref.effects,
          _ref$pass = _ref.pass,
          pass = _ref$pass === void 0 ? 'picking' : _ref$pass,
          pickZ = _ref.pickZ,
          moduleParameters = _ref.moduleParameters;
      var gl = this.gl;
      this.pickZ = pickZ;

      var colorEncoderState = this._resetColorEncoder(pickZ);

      var renderStatus = withParameters(gl, _objectSpread(_objectSpread({
        scissorTest: true,
        scissor: [x, y, width, height],
        clearColor: [0, 0, 0, 0],
        depthMask: true,
        depthTest: true,
        depthRange: [0, 1],
        colorMask: [true, true, true, true]
      }, PICKING_PARAMETERS), {}, {
        blend: !pickZ
      }), function () {
        return _get(_getPrototypeOf(PickLayersPass.prototype), "render", _this2).call(_this2, {
          target: pickingFBO,
          layers: layers,
          layerFilter: layerFilter,
          views: views,
          viewports: viewports,
          onViewportActive: onViewportActive,
          cullRect: cullRect,
          effects: effects === null || effects === void 0 ? void 0 : effects.filter(function (e) {
            return e.useInPicking;
          }),
          pass: pass,
          isPicking: true,
          moduleParameters: moduleParameters
        });
      });
      this._colorEncoderState = null;
      var decodePickingColor = colorEncoderState && decodeColor.bind(null, colorEncoderState);
      return {
        decodePickingColor: decodePickingColor,
        stats: renderStatus
      };
    }
  }, {
    key: "shouldDrawLayer",
    value: function shouldDrawLayer(layer) {
      var _layer$props = layer.props,
          pickable = _layer$props.pickable,
          operation = _layer$props.operation;
      return pickable && operation.includes('draw') || operation.includes('terrain') || operation.includes('mask');
    }
  }, {
    key: "getModuleParameters",
    value: function getModuleParameters() {
      return {
        pickingActive: 1,
        pickingAttribute: this.pickZ,
        lightSources: {}
      };
    }
  }, {
    key: "getLayerParameters",
    value: function getLayerParameters(layer, layerIndex, viewport) {
      var pickParameters = _objectSpread({}, layer.props.parameters);

      var _layer$props2 = layer.props,
          pickable = _layer$props2.pickable,
          operation = _layer$props2.operation;

      if (!this._colorEncoderState) {
        pickParameters.blend = false;
      } else if (pickable && operation.includes('draw')) {
        Object.assign(pickParameters, PICKING_PARAMETERS);
        pickParameters.blend = true;
        pickParameters.blendColor = encodeColor(this._colorEncoderState, layer, viewport);
      }

      if (operation.includes('terrain')) {
        pickParameters.blend = false;
      }

      return pickParameters;
    }
  }, {
    key: "_resetColorEncoder",
    value: function _resetColorEncoder(pickZ) {
      this._colorEncoderState = pickZ ? null : {
        byLayer: new Map(),
        byAlpha: []
      };
      return this._colorEncoderState;
    }
  }]);

  return PickLayersPass;
}(LayersPass);

export { PickLayersPass as default };

function encodeColor(encoded, layer, viewport) {
  var byLayer = encoded.byLayer,
      byAlpha = encoded.byAlpha;
  var a;
  var entry = byLayer.get(layer);

  if (entry) {
    entry.viewports.push(viewport);
    a = entry.a;
  } else {
    a = byLayer.size + 1;

    if (a <= 255) {
      entry = {
        a: a,
        layer: layer,
        viewports: [viewport]
      };
      byLayer.set(layer, entry);
      byAlpha[a] = entry;
    } else {
      log.warn('Too many pickable layers, only picking the first 255')();
      a = 0;
    }
  }

  return [0, 0, 0, a / 255];
}

function decodeColor(encoded, pickedColor) {
  var entry = encoded.byAlpha[pickedColor[3]];
  return entry && {
    pickedLayer: entry.layer,
    pickedViewports: entry.viewports,
    pickedObjectIndex: entry.layer.decodePickingColor(pickedColor)
  };
}