import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var ShaderAttribute = function () {
  function ShaderAttribute(dataColumn, opts) {
    _classCallCheck(this, ShaderAttribute);

    _defineProperty(this, "opts", void 0);

    _defineProperty(this, "source", void 0);

    this.opts = opts;
    this.source = dataColumn;
  }

  _createClass(ShaderAttribute, [{
    key: "value",
    get: function get() {
      return this.source.value;
    }
  }, {
    key: "getValue",
    value: function getValue() {
      var buffer = this.source.getBuffer();
      var accessor = this.getAccessor();

      if (buffer) {
        return [buffer, accessor];
      }

      var value = this.source.value;
      var size = accessor.size;
      var constantValue = value;

      if (value && value.length !== size) {
        constantValue = new Float32Array(size);
        var index = accessor.elementOffset || 0;

        for (var i = 0; i < size; ++i) {
          constantValue[i] = value[index + i];
        }
      }

      return constantValue;
    }
  }, {
    key: "getAccessor",
    value: function getAccessor() {
      return _objectSpread(_objectSpread({}, this.source.getAccessor()), this.opts);
    }
  }]);

  return ShaderAttribute;
}();

export { ShaderAttribute as default };