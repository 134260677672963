import { log } from '@deck.gl/core';
import { GoogleMapsOverlay } from '@deck.gl/google-maps';
export function createGoogleMapsDeckOverlay(_ref) {
  var container = _ref.container,
      onClick = _ref.onClick,
      onComplete = _ref.onComplete,
      getTooltip = _ref.getTooltip,
      googleMapsKey = _ref.googleMapsKey,
      layers = _ref.layers,
      _ref$mapStyle = _ref.mapStyle,
      mapStyle = _ref$mapStyle === void 0 ? 'satellite' : _ref$mapStyle,
      _ref$initialViewState = _ref.initialViewState,
      initialViewState = _ref$initialViewState === void 0 ? {
    latitude: 0,
    longitude: 0,
    zoom: 1
  } : _ref$initialViewState;

  if (!googleMapsKey) {
    log.warn('No Google Maps API key set')();
    return null;
  }

  var deckOverlay = new GoogleMapsOverlay({
    layers: layers
  });
  var view = {
    center: {
      lat: initialViewState.latitude,
      lng: initialViewState.longitude
    },
    mapTypeId: mapStyle,
    zoom: initialViewState.zoom
  };
  var map = new window.google.maps.Map(container, view);
  deckOverlay.setMap(map);
  return deckOverlay;
}