function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var GEOM_TYPES = ['points', 'lines', 'polygons'];
export default function findIndexBinary(data, uniqueIdProperty, featureId, layerName) {
  var _iterator = _createForOfIteratorHelper(GEOM_TYPES),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var gt = _step.value;

      var _index = data[gt] && findIndexByType(data[gt], uniqueIdProperty, featureId, layerName);

      if (_index >= 0) {
        return _index;
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  return -1;
}

function findIndexByType(geomData, uniqueIdProperty, featureId, layerName) {
  var featureIds = geomData.featureIds.value;

  if (!featureIds.length) {
    return -1;
  }

  var startFeatureIndex = 0;
  var endFeatureIndex = featureIds[featureIds.length - 1] + 1;

  if (layerName) {
    var layerRange = getLayerRange(geomData, layerName);

    if (layerRange) {
      startFeatureIndex = layerRange[0];
      endFeatureIndex = layerRange[1] + 1;
    } else {
      return -1;
    }
  }

  var featureIndex = -1;

  if (uniqueIdProperty in geomData.numericProps) {
    var vertexIndex = geomData.numericProps[uniqueIdProperty].value.findIndex(function (x, i) {
      return x === featureId && featureIds[i] >= startFeatureIndex && featureIds[i] < endFeatureIndex;
    });
    return vertexIndex >= 0 ? geomData.globalFeatureIds.value[vertexIndex] : -1;
  } else if (uniqueIdProperty) {
    featureIndex = findIndex(geomData.properties, function (elem) {
      return elem[uniqueIdProperty] === featureId;
    }, startFeatureIndex, endFeatureIndex);
  } else if (geomData.fields) {
    featureIndex = findIndex(geomData.fields, function (elem) {
      return elem.id === featureId;
    }, startFeatureIndex, endFeatureIndex);
  }

  return featureIndex >= 0 ? getGlobalFeatureId(geomData, featureIndex) : -1;
}

function getLayerRange(geomData, layerName) {
  if (!geomData.__layers) {
    var layerNames = {};
    var properties = geomData.properties;

    for (var i = 0; i < properties.length; i++) {
      var _ref = properties[i],
          key = _ref.layerName;

      if (!key) {} else if (layerNames[key]) {
        layerNames[key][1] = i;
      } else {
        layerNames[key] = [i, i];
      }
    }

    geomData.__layers = layerNames;
  }

  return geomData.__layers[layerName];
}

function getGlobalFeatureId(geomData, featureIndex) {
  if (!geomData.__ids) {
    var result = [];
    var featureIds = geomData.featureIds.value;
    var globalFeatureIds = geomData.globalFeatureIds.value;

    for (var i = 0; i < featureIds.length; i++) {
      result[featureIds[i]] = globalFeatureIds[i];
    }

    geomData.__ids = result;
  }

  return geomData.__ids[featureIndex];
}

function findIndex(array, predicate, startIndex, endIndex) {
  for (var i = startIndex; i < endIndex; i++) {
    if (predicate(array[i], i)) {
      return i;
    }
  }

  return -1;
}