import DescriptionCard from './description-card';
export function addSupportComponents(parentContainer, props) {
  var uiElementsOverlay = document.createElement('div');
  uiElementsOverlay.className = 'deckgl-ui-elements-overlay';
  uiElementsOverlay.style.zIndex = 1;
  parentContainer.insertAdjacentElement('beforebegin', uiElementsOverlay);
  var components = [];

  for (var _i = 0, _Object$keys = Object.keys(props); _i < _Object$keys.length; _i++) {
    var key = _Object$keys[_i];

    switch (key) {
      case 'description':
        var el = new DescriptionCard({
          container: uiElementsOverlay,
          props: props
        });
        components.push(el);
        break;

      default:
        break;
    }
  }

  return components;
}