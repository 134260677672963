import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var defaultGetValue = function defaultGetValue(points) {
  return points.length;
};

import { clamp, getQuantileDomain, getOrdinalDomain } from './scale-utils';
var MAX_32_BIT_FLOAT = 3.402823466e38;

var defaultGetPoints = function defaultGetPoints(bin) {
  return bin.points;
};

var defaultGetIndex = function defaultGetIndex(bin) {
  return bin.index;
};

var ascending = function ascending(a, b) {
  return a < b ? -1 : a > b ? 1 : a >= b ? 0 : NaN;
};

var defaultProps = {
  getValue: defaultGetValue,
  getPoints: defaultGetPoints,
  getIndex: defaultGetIndex,
  filterData: null
};

var BinSorter = function () {
  function BinSorter() {
    var bins = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var props = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : defaultProps;

    _classCallCheck(this, BinSorter);

    _defineProperty(this, "maxCount", void 0);

    _defineProperty(this, "maxValue", void 0);

    _defineProperty(this, "minValue", void 0);

    _defineProperty(this, "totalCount", void 0);

    _defineProperty(this, "aggregatedBins", void 0);

    _defineProperty(this, "sortedBins", void 0);

    _defineProperty(this, "binMap", void 0);

    this.aggregatedBins = this.getAggregatedBins(bins, props);

    this._updateMinMaxValues();

    this.binMap = this.getBinMap();
  }

  _createClass(BinSorter, [{
    key: "getAggregatedBins",
    value: function getAggregatedBins(bins, props) {
      var _props$getValue = props.getValue,
          getValue = _props$getValue === void 0 ? defaultGetValue : _props$getValue,
          _props$getPoints = props.getPoints,
          getPoints = _props$getPoints === void 0 ? defaultGetPoints : _props$getPoints,
          _props$getIndex = props.getIndex,
          getIndex = _props$getIndex === void 0 ? defaultGetIndex : _props$getIndex,
          filterData = props.filterData;
      var hasFilter = typeof filterData === 'function';
      var binCount = bins.length;
      var aggregatedBins = [];
      var index = 0;

      for (var binIndex = 0; binIndex < binCount; binIndex++) {
        var bin = bins[binIndex];
        var points = getPoints(bin);
        var i = getIndex(bin);
        var filteredPoints = hasFilter ? points.filter(filterData) : points;
        bin.filteredPoints = hasFilter ? filteredPoints : null;
        var value = filteredPoints.length ? getValue(filteredPoints) : null;

        if (value !== null && value !== undefined) {
          aggregatedBins[index] = {
            i: Number.isFinite(i) ? i : binIndex,
            value: value,
            counts: filteredPoints.length
          };
          index++;
        }
      }

      return aggregatedBins;
    }
  }, {
    key: "_percentileToIndex",
    value: function _percentileToIndex(percentileRange) {
      var len = this.sortedBins.length;

      if (len < 2) {
        return [0, 0];
      }

      var _percentileRange$map = percentileRange.map(function (n) {
        return clamp(n, 0, 100);
      }),
          _percentileRange$map2 = _slicedToArray(_percentileRange$map, 2),
          lower = _percentileRange$map2[0],
          upper = _percentileRange$map2[1];

      var lowerIdx = Math.ceil(lower / 100 * (len - 1));
      var upperIdx = Math.floor(upper / 100 * (len - 1));
      return [lowerIdx, upperIdx];
    }
  }, {
    key: "getBinMap",
    value: function getBinMap() {
      var binMap = {};

      var _iterator = _createForOfIteratorHelper(this.aggregatedBins),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var bin = _step.value;
          binMap[bin.i] = bin;
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      return binMap;
    }
  }, {
    key: "_updateMinMaxValues",
    value: function _updateMinMaxValues() {
      var maxCount = 0;
      var maxValue = 0;
      var minValue = MAX_32_BIT_FLOAT;
      var totalCount = 0;

      var _iterator2 = _createForOfIteratorHelper(this.aggregatedBins),
          _step2;

      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var x = _step2.value;
          maxCount = maxCount > x.counts ? maxCount : x.counts;
          maxValue = maxValue > x.value ? maxValue : x.value;
          minValue = minValue < x.value ? minValue : x.value;
          totalCount += x.counts;
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }

      this.maxCount = maxCount;
      this.maxValue = maxValue;
      this.minValue = minValue;
      this.totalCount = totalCount;
    }
  }, {
    key: "getValueRange",
    value: function getValueRange(percentileRange) {
      if (!this.sortedBins) {
        this.sortedBins = this.aggregatedBins.sort(function (a, b) {
          return ascending(a.value, b.value);
        });
      }

      if (!this.sortedBins.length) {
        return [];
      }

      var lowerIdx = 0;
      var upperIdx = this.sortedBins.length - 1;

      if (Array.isArray(percentileRange)) {
        var idxRange = this._percentileToIndex(percentileRange);

        lowerIdx = idxRange[0];
        upperIdx = idxRange[1];
      }

      return [this.sortedBins[lowerIdx].value, this.sortedBins[upperIdx].value];
    }
  }, {
    key: "getValueDomainByScale",
    value: function getValueDomainByScale(scale) {
      var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [],
          _ref2 = _slicedToArray(_ref, 2),
          _ref2$ = _ref2[0],
          lower = _ref2$ === void 0 ? 0 : _ref2$,
          _ref2$2 = _ref2[1],
          upper = _ref2$2 === void 0 ? 100 : _ref2$2;

      if (!this.sortedBins) {
        this.sortedBins = this.aggregatedBins.sort(function (a, b) {
          return ascending(a.value, b.value);
        });
      }

      if (!this.sortedBins.length) {
        return [];
      }

      var indexEdge = this._percentileToIndex([lower, upper]);

      return this._getScaleDomain(scale, indexEdge);
    }
  }, {
    key: "_getScaleDomain",
    value: function _getScaleDomain(scaleType, _ref3) {
      var _ref4 = _slicedToArray(_ref3, 2),
          lowerIdx = _ref4[0],
          upperIdx = _ref4[1];

      var bins = this.sortedBins;

      switch (scaleType) {
        case 'quantize':
        case 'linear':
          return [bins[lowerIdx].value, bins[upperIdx].value];

        case 'quantile':
          return getQuantileDomain(bins.slice(lowerIdx, upperIdx + 1), function (d) {
            return d.value;
          });

        case 'ordinal':
          return getOrdinalDomain(bins, function (d) {
            return d.value;
          });

        default:
          return [bins[lowerIdx].value, bins[upperIdx].value];
      }
    }
  }]);

  return BinSorter;
}();

export { BinSorter as default };