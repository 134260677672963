import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _assertThisInitialized from "@babel/runtime/helpers/assertThisInitialized";
import _inherits from "@babel/runtime/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { COORDINATE_SYSTEM } from './constants';
import AttributeManager from './attribute/attribute-manager';
import UniformTransitionManager from './uniform-transition-manager';
import { diffProps, validateProps as _validateProps } from '../lifecycle/props';
import { LIFECYCLE } from '../lifecycle/constants';
import { count } from '../utils/count';
import log from '../utils/log';
import debug from '../debug';
import { withParameters, setParameters } from '@luma.gl/core';
import assert from '../utils/assert';
import memoize from '../utils/memoize';
import { mergeShaders } from '../utils/shader';
import { projectPosition as _projectPosition, getWorldPosition } from '../shaderlib/project/project-functions';
import typedArrayManager from '../utils/typed-array-manager';
import Component from '../lifecycle/component';
import LayerState from './layer-state';
import { worldToPixels } from '@math.gl/web-mercator';
import { load } from '@loaders.gl/core';
var TRACE_CHANGE_FLAG = 'layer.changeFlag';
var TRACE_INITIALIZE = 'layer.initialize';
var TRACE_UPDATE = 'layer.update';
var TRACE_FINALIZE = 'layer.finalize';
var TRACE_MATCHED = 'layer.matched';
var MAX_PICKING_COLOR_CACHE_SIZE = Math.pow(2, 24) - 1;
var EMPTY_ARRAY = Object.freeze([]);
var areViewportsEqual = memoize(function (_ref) {
  var oldViewport = _ref.oldViewport,
      viewport = _ref.viewport;
  return oldViewport.equals(viewport);
});
var pickingColorCache = new Uint8ClampedArray(0);
var defaultProps = {
  data: {
    type: 'data',
    value: EMPTY_ARRAY,
    async: true
  },
  dataComparator: {
    type: 'function',
    value: null,
    optional: true
  },
  _dataDiff: {
    type: 'function',
    value: function value(data) {
      return data && data.__diff;
    },
    optional: true
  },
  dataTransform: {
    type: 'function',
    value: null,
    optional: true
  },
  onDataLoad: {
    type: 'function',
    value: null,
    optional: true
  },
  onError: {
    type: 'function',
    value: null,
    optional: true
  },
  fetch: {
    type: 'function',
    value: function value(url, _ref2) {
      var propName = _ref2.propName,
          layer = _ref2.layer,
          loaders = _ref2.loaders,
          loadOptions = _ref2.loadOptions,
          signal = _ref2.signal;
      var resourceManager = layer.context.resourceManager;
      loadOptions = loadOptions || layer.getLoadOptions();
      loaders = loaders || layer.props.loaders;

      if (signal) {
        var _loadOptions;

        loadOptions = _objectSpread(_objectSpread({}, loadOptions), {}, {
          fetch: _objectSpread(_objectSpread({}, (_loadOptions = loadOptions) === null || _loadOptions === void 0 ? void 0 : _loadOptions.fetch), {}, {
            signal: signal
          })
        });
      }

      var inResourceManager = resourceManager.contains(url);

      if (!inResourceManager && !loadOptions) {
        resourceManager.add({
          resourceId: url,
          data: load(url, loaders),
          persistent: false
        });
        inResourceManager = true;
      }

      if (inResourceManager) {
        return resourceManager.subscribe({
          resourceId: url,
          onChange: function onChange(data) {
            var _layer$internalState;

            return (_layer$internalState = layer.internalState) === null || _layer$internalState === void 0 ? void 0 : _layer$internalState.reloadAsyncProp(propName, data);
          },
          consumerId: layer.id,
          requestId: propName
        });
      }

      return load(url, loaders, loadOptions);
    }
  },
  updateTriggers: {},
  visible: true,
  pickable: false,
  opacity: {
    type: 'number',
    min: 0,
    max: 1,
    value: 1
  },
  operation: 'draw',
  onHover: {
    type: 'function',
    value: null,
    optional: true
  },
  onClick: {
    type: 'function',
    value: null,
    optional: true
  },
  onDragStart: {
    type: 'function',
    value: null,
    optional: true
  },
  onDrag: {
    type: 'function',
    value: null,
    optional: true
  },
  onDragEnd: {
    type: 'function',
    value: null,
    optional: true
  },
  coordinateSystem: COORDINATE_SYSTEM.DEFAULT,
  coordinateOrigin: {
    type: 'array',
    value: [0, 0, 0],
    compare: true
  },
  modelMatrix: {
    type: 'array',
    value: null,
    compare: true,
    optional: true
  },
  wrapLongitude: false,
  positionFormat: 'XYZ',
  colorFormat: 'RGBA',
  parameters: {
    type: 'object',
    value: {},
    optional: true,
    compare: 2
  },
  loadOptions: {
    type: 'object',
    value: null,
    optional: true,
    ignore: true
  },
  transitions: null,
  extensions: [],
  loaders: {
    type: 'array',
    value: [],
    optional: true,
    ignore: true
  },
  getPolygonOffset: {
    type: 'function',
    value: function value(_ref3) {
      var layerIndex = _ref3.layerIndex;
      return [0, -layerIndex * 100];
    }
  },
  highlightedObjectIndex: null,
  autoHighlight: false,
  highlightColor: {
    type: 'accessor',
    value: [0, 0, 128, 128]
  }
};

var Layer = function (_Component) {
  _inherits(Layer, _Component);

  var _super = _createSuper(Layer);

  function Layer() {
    var _this;

    _classCallCheck(this, Layer);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));

    _defineProperty(_assertThisInitialized(_this), "internalState", null);

    _defineProperty(_assertThisInitialized(_this), "lifecycle", LIFECYCLE.NO_STATE);

    _defineProperty(_assertThisInitialized(_this), "context", void 0);

    _defineProperty(_assertThisInitialized(_this), "state", void 0);

    _defineProperty(_assertThisInitialized(_this), "parent", null);

    return _this;
  }

  _createClass(Layer, [{
    key: "root",
    get: function get() {
      var layer = this;

      while (layer.parent) {
        layer = layer.parent;
      }

      return layer;
    }
  }, {
    key: "toString",
    value: function toString() {
      var className = this.constructor.layerName || this.constructor.name;
      return "".concat(className, "({id: '").concat(this.props.id, "'})");
    }
  }, {
    key: "project",
    value: function project(xyz) {
      assert(this.internalState);
      var viewport = this.internalState.viewport || this.context.viewport;
      var worldPosition = getWorldPosition(xyz, {
        viewport: viewport,
        modelMatrix: this.props.modelMatrix,
        coordinateOrigin: this.props.coordinateOrigin,
        coordinateSystem: this.props.coordinateSystem
      });

      var _worldToPixels = worldToPixels(worldPosition, viewport.pixelProjectionMatrix),
          _worldToPixels2 = _slicedToArray(_worldToPixels, 3),
          x = _worldToPixels2[0],
          y = _worldToPixels2[1],
          z = _worldToPixels2[2];

      return xyz.length === 2 ? [x, y] : [x, y, z];
    }
  }, {
    key: "unproject",
    value: function unproject(xy) {
      assert(this.internalState);
      var viewport = this.internalState.viewport || this.context.viewport;
      return viewport.unproject(xy);
    }
  }, {
    key: "projectPosition",
    value: function projectPosition(xyz, params) {
      assert(this.internalState);
      var viewport = this.internalState.viewport || this.context.viewport;
      return _projectPosition(xyz, _objectSpread({
        viewport: viewport,
        modelMatrix: this.props.modelMatrix,
        coordinateOrigin: this.props.coordinateOrigin,
        coordinateSystem: this.props.coordinateSystem
      }, params));
    }
  }, {
    key: "isComposite",
    get: function get() {
      return false;
    }
  }, {
    key: "setState",
    value: function setState(partialState) {
      this.setChangeFlags({
        stateChanged: true
      });
      Object.assign(this.state, partialState);
      this.setNeedsRedraw();
    }
  }, {
    key: "setNeedsRedraw",
    value: function setNeedsRedraw() {
      if (this.internalState) {
        this.internalState.needsRedraw = true;
      }
    }
  }, {
    key: "setNeedsUpdate",
    value: function setNeedsUpdate() {
      if (this.internalState) {
        this.context.layerManager.setNeedsUpdate(String(this));
        this.internalState.needsUpdate = true;
      }
    }
  }, {
    key: "isLoaded",
    get: function get() {
      return this.internalState ? !this.internalState.isAsyncPropLoading() : false;
    }
  }, {
    key: "wrapLongitude",
    get: function get() {
      return this.props.wrapLongitude;
    }
  }, {
    key: "isPickable",
    value: function isPickable() {
      return this.props.pickable && this.props.visible;
    }
  }, {
    key: "getModels",
    value: function getModels() {
      return this.state && (this.state.models || this.state.model && [this.state.model]) || [];
    }
  }, {
    key: "setModuleParameters",
    value: function setModuleParameters(moduleParameters) {
      var _iterator = _createForOfIteratorHelper(this.getModels()),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var model = _step.value;
          model.updateModuleSettings(moduleParameters);
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    }
  }, {
    key: "getAttributeManager",
    value: function getAttributeManager() {
      return this.internalState && this.internalState.attributeManager;
    }
  }, {
    key: "getCurrentLayer",
    value: function getCurrentLayer() {
      return this.internalState && this.internalState.layer;
    }
  }, {
    key: "getLoadOptions",
    value: function getLoadOptions() {
      return this.props.loadOptions;
    }
  }, {
    key: "use64bitPositions",
    value: function use64bitPositions() {
      var coordinateSystem = this.props.coordinateSystem;
      return coordinateSystem === COORDINATE_SYSTEM.DEFAULT || coordinateSystem === COORDINATE_SYSTEM.LNGLAT || coordinateSystem === COORDINATE_SYSTEM.CARTESIAN;
    }
  }, {
    key: "onHover",
    value: function onHover(info, pickingEvent) {
      if (this.props.onHover) {
        return this.props.onHover(info, pickingEvent) || false;
      }

      return false;
    }
  }, {
    key: "onClick",
    value: function onClick(info, pickingEvent) {
      if (this.props.onClick) {
        return this.props.onClick(info, pickingEvent) || false;
      }

      return false;
    }
  }, {
    key: "nullPickingColor",
    value: function nullPickingColor() {
      return [0, 0, 0];
    }
  }, {
    key: "encodePickingColor",
    value: function encodePickingColor(i) {
      var target = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      target[0] = i + 1 & 255;
      target[1] = i + 1 >> 8 & 255;
      target[2] = i + 1 >> 8 >> 8 & 255;
      return target;
    }
  }, {
    key: "decodePickingColor",
    value: function decodePickingColor(color) {
      assert(color instanceof Uint8Array);

      var _color = _slicedToArray(color, 3),
          i1 = _color[0],
          i2 = _color[1],
          i3 = _color[2];

      var index = i1 + i2 * 256 + i3 * 65536 - 1;
      return index;
    }
  }, {
    key: "getNumInstances",
    value: function getNumInstances() {
      if (Number.isFinite(this.props.numInstances)) {
        return this.props.numInstances;
      }

      if (this.state && this.state.numInstances !== undefined) {
        return this.state.numInstances;
      }

      return count(this.props.data);
    }
  }, {
    key: "getStartIndices",
    value: function getStartIndices() {
      if (this.props.startIndices) {
        return this.props.startIndices;
      }

      if (this.state && this.state.startIndices) {
        return this.state.startIndices;
      }

      return null;
    }
  }, {
    key: "getBounds",
    value: function getBounds() {
      var _this$getAttributeMan;

      return (_this$getAttributeMan = this.getAttributeManager()) === null || _this$getAttributeMan === void 0 ? void 0 : _this$getAttributeMan.getBounds(['positions', 'instancePositions']);
    }
  }, {
    key: "getShaders",
    value: function getShaders(shaders) {
      var _iterator2 = _createForOfIteratorHelper(this.props.extensions),
          _step2;

      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var extension = _step2.value;
          shaders = mergeShaders(shaders, extension.getShaders.call(this, extension));
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }

      return shaders;
    }
  }, {
    key: "shouldUpdateState",
    value: function shouldUpdateState(params) {
      return params.changeFlags.propsOrDataChanged;
    }
  }, {
    key: "updateState",
    value: function updateState(params) {
      var _this2 = this;

      var attributeManager = this.getAttributeManager();
      var dataChanged = params.changeFlags.dataChanged;

      if (dataChanged && attributeManager) {
        if (Array.isArray(dataChanged)) {
          var _iterator3 = _createForOfIteratorHelper(dataChanged),
              _step3;

          try {
            for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
              var dataRange = _step3.value;
              attributeManager.invalidateAll(dataRange);
            }
          } catch (err) {
            _iterator3.e(err);
          } finally {
            _iterator3.f();
          }
        } else {
          attributeManager.invalidateAll();
        }
      }

      if (attributeManager) {
        var props = params.props;
        var hasPickingBuffer = this.internalState.hasPickingBuffer;
        var needsPickingBuffer = Number.isInteger(props.highlightedObjectIndex) || props.pickable || props.extensions.some(function (extension) {
          return extension.getNeedsPickingBuffer.call(_this2, extension);
        });

        if (hasPickingBuffer !== needsPickingBuffer) {
          this.internalState.hasPickingBuffer = needsPickingBuffer;
          var _attributeManager$att = attributeManager.attributes,
              pickingColors = _attributeManager$att.pickingColors,
              instancePickingColors = _attributeManager$att.instancePickingColors;
          var pickingColorsAttribute = pickingColors || instancePickingColors;

          if (pickingColorsAttribute) {
            if (needsPickingBuffer && pickingColorsAttribute.constant) {
              pickingColorsAttribute.constant = false;
              attributeManager.invalidate(pickingColorsAttribute.id);
            }

            if (!pickingColorsAttribute.value && !needsPickingBuffer) {
              pickingColorsAttribute.constant = true;
              pickingColorsAttribute.value = [0, 0, 0];
            }
          }
        }
      }
    }
  }, {
    key: "finalizeState",
    value: function finalizeState(context) {
      var _iterator4 = _createForOfIteratorHelper(this.getModels()),
          _step4;

      try {
        for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
          var model = _step4.value;
          model.delete();
        }
      } catch (err) {
        _iterator4.e(err);
      } finally {
        _iterator4.f();
      }

      var attributeManager = this.getAttributeManager();

      if (attributeManager) {
        attributeManager.finalize();
      }

      if (this.context) {
        this.context.resourceManager.unsubscribe({
          consumerId: this.id
        });
      }

      if (this.internalState) {
        this.internalState.uniformTransitions.clear();
        this.internalState.finalize();
      }
    }
  }, {
    key: "draw",
    value: function draw(opts) {
      var _iterator5 = _createForOfIteratorHelper(this.getModels()),
          _step5;

      try {
        for (_iterator5.s(); !(_step5 = _iterator5.n()).done;) {
          var model = _step5.value;
          model.draw(opts);
        }
      } catch (err) {
        _iterator5.e(err);
      } finally {
        _iterator5.f();
      }
    }
  }, {
    key: "getPickingInfo",
    value: function getPickingInfo(_ref4) {
      var info = _ref4.info,
          mode = _ref4.mode,
          sourceLayer = _ref4.sourceLayer;
      var index = info.index;

      if (index >= 0) {
        if (Array.isArray(this.props.data)) {
          info.object = this.props.data[index];
        }
      }

      return info;
    }
  }, {
    key: "raiseError",
    value: function raiseError(error, message) {
      var _this$props$onError, _this$props;

      if (message) {
        error = new Error("".concat(message, ": ").concat(error.message), {
          cause: error
        });
      }

      if (!((_this$props$onError = (_this$props = this.props).onError) !== null && _this$props$onError !== void 0 && _this$props$onError.call(_this$props, error))) {
        var _this$context, _this$context$onError;

        (_this$context = this.context) === null || _this$context === void 0 ? void 0 : (_this$context$onError = _this$context.onError) === null || _this$context$onError === void 0 ? void 0 : _this$context$onError.call(_this$context, error, this);
      }
    }
  }, {
    key: "getNeedsRedraw",
    value: function getNeedsRedraw() {
      var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
        clearRedrawFlags: false
      };
      return this._getNeedsRedraw(opts);
    }
  }, {
    key: "needsUpdate",
    value: function needsUpdate() {
      if (!this.internalState) {
        return false;
      }

      return this.internalState.needsUpdate || this.hasUniformTransition() || this.shouldUpdateState(this._getUpdateParams());
    }
  }, {
    key: "hasUniformTransition",
    value: function hasUniformTransition() {
      var _this$internalState;

      return ((_this$internalState = this.internalState) === null || _this$internalState === void 0 ? void 0 : _this$internalState.uniformTransitions.active) || false;
    }
  }, {
    key: "activateViewport",
    value: function activateViewport(viewport) {
      if (!this.internalState) {
        return;
      }

      var oldViewport = this.internalState.viewport;
      this.internalState.viewport = viewport;

      if (!oldViewport || !areViewportsEqual({
        oldViewport: oldViewport,
        viewport: viewport
      })) {
        this.setChangeFlags({
          viewportChanged: true
        });

        if (this.isComposite) {
          if (this.needsUpdate()) {
            this.setNeedsUpdate();
          }
        } else {
          this._update();
        }
      }
    }
  }, {
    key: "invalidateAttribute",
    value: function invalidateAttribute() {
      var name = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'all';
      var attributeManager = this.getAttributeManager();

      if (!attributeManager) {
        return;
      }

      if (name === 'all') {
        attributeManager.invalidateAll();
      } else {
        attributeManager.invalidate(name);
      }
    }
  }, {
    key: "updateAttributes",
    value: function updateAttributes(changedAttributes) {
      var _iterator6 = _createForOfIteratorHelper(this.getModels()),
          _step6;

      try {
        for (_iterator6.s(); !(_step6 = _iterator6.n()).done;) {
          var model = _step6.value;

          this._setModelAttributes(model, changedAttributes);
        }
      } catch (err) {
        _iterator6.e(err);
      } finally {
        _iterator6.f();
      }
    }
  }, {
    key: "_updateAttributes",
    value: function _updateAttributes() {
      var attributeManager = this.getAttributeManager();

      if (!attributeManager) {
        return;
      }

      var props = this.props;
      var numInstances = this.getNumInstances();
      var startIndices = this.getStartIndices();
      attributeManager.update({
        data: props.data,
        numInstances: numInstances,
        startIndices: startIndices,
        props: props,
        transitions: props.transitions,
        buffers: props.data.attributes,
        context: this
      });
      var changedAttributes = attributeManager.getChangedAttributes({
        clearChangedFlags: true
      });
      this.updateAttributes(changedAttributes);
    }
  }, {
    key: "_updateAttributeTransition",
    value: function _updateAttributeTransition() {
      var attributeManager = this.getAttributeManager();

      if (attributeManager) {
        attributeManager.updateTransition();
      }
    }
  }, {
    key: "_updateUniformTransition",
    value: function _updateUniformTransition() {
      var uniformTransitions = this.internalState.uniformTransitions;

      if (uniformTransitions.active) {
        var propsInTransition = uniformTransitions.update();
        var props = Object.create(this.props);

        for (var key in propsInTransition) {
          Object.defineProperty(props, key, {
            value: propsInTransition[key]
          });
        }

        return props;
      }

      return this.props;
    }
  }, {
    key: "calculateInstancePickingColors",
    value: function calculateInstancePickingColors(attribute, _ref5) {
      var numInstances = _ref5.numInstances;

      if (attribute.constant) {
        return;
      }

      var cacheSize = Math.floor(pickingColorCache.length / 3);
      this.internalState.usesPickingColorCache = true;

      if (cacheSize < numInstances) {
        if (numInstances > MAX_PICKING_COLOR_CACHE_SIZE) {
          log.warn('Layer has too many data objects. Picking might not be able to distinguish all objects.')();
        }

        pickingColorCache = typedArrayManager.allocate(pickingColorCache, numInstances, {
          size: 3,
          copy: true,
          maxCount: Math.max(numInstances, MAX_PICKING_COLOR_CACHE_SIZE)
        });
        var newCacheSize = Math.floor(pickingColorCache.length / 3);
        var pickingColor = [];

        for (var i = cacheSize; i < newCacheSize; i++) {
          this.encodePickingColor(i, pickingColor);
          pickingColorCache[i * 3 + 0] = pickingColor[0];
          pickingColorCache[i * 3 + 1] = pickingColor[1];
          pickingColorCache[i * 3 + 2] = pickingColor[2];
        }
      }

      attribute.value = pickingColorCache.subarray(0, numInstances * 3);
    }
  }, {
    key: "_setModelAttributes",
    value: function _setModelAttributes(model, changedAttributes) {
      var attributeManager = this.getAttributeManager();
      var excludeAttributes = model.userData.excludeAttributes || {};
      var shaderAttributes = attributeManager.getShaderAttributes(changedAttributes, excludeAttributes);
      model.setAttributes(shaderAttributes);
    }
  }, {
    key: "disablePickingIndex",
    value: function disablePickingIndex(objectIndex) {
      var data = this.props.data;

      if (!('attributes' in data)) {
        this._disablePickingIndex(objectIndex);

        return;
      }

      var _this$getAttributeMan2 = this.getAttributeManager().attributes,
          pickingColors = _this$getAttributeMan2.pickingColors,
          instancePickingColors = _this$getAttributeMan2.instancePickingColors;
      var colors = pickingColors || instancePickingColors;
      var externalColorAttribute = colors && data.attributes && data.attributes[colors.id];

      if (externalColorAttribute && externalColorAttribute.value) {
        var values = externalColorAttribute.value;
        var objectColor = this.encodePickingColor(objectIndex);

        for (var index = 0; index < data.length; index++) {
          var i = colors.getVertexOffset(index);

          if (values[i] === objectColor[0] && values[i + 1] === objectColor[1] && values[i + 2] === objectColor[2]) {
            this._disablePickingIndex(index);
          }
        }
      } else {
        this._disablePickingIndex(objectIndex);
      }
    }
  }, {
    key: "_disablePickingIndex",
    value: function _disablePickingIndex(objectIndex) {
      var _this$getAttributeMan3 = this.getAttributeManager().attributes,
          pickingColors = _this$getAttributeMan3.pickingColors,
          instancePickingColors = _this$getAttributeMan3.instancePickingColors;
      var colors = pickingColors || instancePickingColors;

      if (!colors) {
        return;
      }

      var start = colors.getVertexOffset(objectIndex);
      var end = colors.getVertexOffset(objectIndex + 1);
      colors.buffer.subData({
        data: new Uint8Array(end - start),
        offset: start
      });
    }
  }, {
    key: "restorePickingColors",
    value: function restorePickingColors() {
      var _this$getAttributeMan4 = this.getAttributeManager().attributes,
          pickingColors = _this$getAttributeMan4.pickingColors,
          instancePickingColors = _this$getAttributeMan4.instancePickingColors;
      var colors = pickingColors || instancePickingColors;

      if (!colors) {
        return;
      }

      if (this.internalState.usesPickingColorCache && colors.value.buffer !== pickingColorCache.buffer) {
        colors.value = pickingColorCache.subarray(0, colors.value.length);
      }

      colors.updateSubBuffer({
        startOffset: 0
      });
    }
  }, {
    key: "_initialize",
    value: function _initialize() {
      assert(!this.internalState);
      assert(Number.isFinite(this.props.coordinateSystem));
      debug(TRACE_INITIALIZE, this);

      var attributeManager = this._getAttributeManager();

      if (attributeManager) {
        attributeManager.addInstanced({
          instancePickingColors: {
            type: 5121,
            size: 3,
            noAlloc: true,
            update: this.calculateInstancePickingColors
          }
        });
      }

      this.internalState = new LayerState({
        attributeManager: attributeManager,
        layer: this
      });

      this._clearChangeFlags();

      this.state = {};
      Object.defineProperty(this.state, 'attributeManager', {
        get: function get() {
          log.deprecated('layer.state.attributeManager', 'layer.getAttributeManager()')();
          return attributeManager;
        }
      });
      this.internalState.uniformTransitions = new UniformTransitionManager(this.context.timeline);
      this.internalState.onAsyncPropUpdated = this._onAsyncPropUpdated.bind(this);
      this.internalState.setAsyncProps(this.props);
      this.initializeState(this.context);

      var _iterator7 = _createForOfIteratorHelper(this.props.extensions),
          _step7;

      try {
        for (_iterator7.s(); !(_step7 = _iterator7.n()).done;) {
          var extension = _step7.value;
          extension.initializeState.call(this, this.context, extension);
        }
      } catch (err) {
        _iterator7.e(err);
      } finally {
        _iterator7.f();
      }

      this.setChangeFlags({
        dataChanged: 'init',
        propsChanged: 'init',
        viewportChanged: true,
        extensionsChanged: true
      });

      this._update();
    }
  }, {
    key: "_transferState",
    value: function _transferState(oldLayer) {
      debug(TRACE_MATCHED, this, this === oldLayer);
      var state = oldLayer.state,
          internalState = oldLayer.internalState;

      if (this === oldLayer) {
        return;
      }

      this.internalState = internalState;
      this.state = state;
      this.internalState.setAsyncProps(this.props);

      this._diffProps(this.props, this.internalState.getOldProps());
    }
  }, {
    key: "_update",
    value: function _update() {
      var stateNeedsUpdate = this.needsUpdate();
      debug(TRACE_UPDATE, this, stateNeedsUpdate);

      if (!stateNeedsUpdate) {
        return;
      }

      var currentProps = this.props;
      var context = this.context;
      var internalState = this.internalState;
      var currentViewport = context.viewport;

      var propsInTransition = this._updateUniformTransition();

      internalState.propsInTransition = propsInTransition;
      context.viewport = internalState.viewport || currentViewport;
      this.props = propsInTransition;

      try {
        var updateParams = this._getUpdateParams();

        var oldModels = this.getModels();

        if (context.gl) {
          this.updateState(updateParams);
        } else {
          try {
            this.updateState(updateParams);
          } catch (error) {}
        }

        var _iterator8 = _createForOfIteratorHelper(this.props.extensions),
            _step8;

        try {
          for (_iterator8.s(); !(_step8 = _iterator8.n()).done;) {
            var extension = _step8.value;
            extension.updateState.call(this, updateParams, extension);
          }
        } catch (err) {
          _iterator8.e(err);
        } finally {
          _iterator8.f();
        }

        var modelChanged = this.getModels()[0] !== oldModels[0];

        this._postUpdate(updateParams, modelChanged);
      } finally {
        context.viewport = currentViewport;
        this.props = currentProps;

        this._clearChangeFlags();

        internalState.needsUpdate = false;
        internalState.resetOldProps();
      }
    }
  }, {
    key: "_finalize",
    value: function _finalize() {
      debug(TRACE_FINALIZE, this);
      this.finalizeState(this.context);

      var _iterator9 = _createForOfIteratorHelper(this.props.extensions),
          _step9;

      try {
        for (_iterator9.s(); !(_step9 = _iterator9.n()).done;) {
          var extension = _step9.value;
          extension.finalizeState.call(this, this.context, extension);
        }
      } catch (err) {
        _iterator9.e(err);
      } finally {
        _iterator9.f();
      }
    }
  }, {
    key: "_drawLayer",
    value: function _drawLayer(_ref6) {
      var _this3 = this;

      var _ref6$moduleParameter = _ref6.moduleParameters,
          moduleParameters = _ref6$moduleParameter === void 0 ? null : _ref6$moduleParameter,
          _ref6$uniforms = _ref6.uniforms,
          uniforms = _ref6$uniforms === void 0 ? {} : _ref6$uniforms,
          _ref6$parameters = _ref6.parameters,
          parameters = _ref6$parameters === void 0 ? {} : _ref6$parameters;

      this._updateAttributeTransition();

      var currentProps = this.props;
      var context = this.context;
      this.props = this.internalState.propsInTransition || currentProps;
      var opacity = this.props.opacity;
      uniforms.opacity = Math.pow(opacity, 1 / 2.2);

      try {
        if (moduleParameters) {
          this.setModuleParameters(moduleParameters);
        }

        var getPolygonOffset = this.props.getPolygonOffset;
        var offsets = getPolygonOffset && getPolygonOffset(uniforms) || [0, 0];
        setParameters(context.gl, {
          polygonOffset: offsets
        });
        withParameters(context.gl, parameters, function () {
          var opts = {
            moduleParameters: moduleParameters,
            uniforms: uniforms,
            parameters: parameters,
            context: context
          };

          var _iterator10 = _createForOfIteratorHelper(_this3.props.extensions),
              _step10;

          try {
            for (_iterator10.s(); !(_step10 = _iterator10.n()).done;) {
              var extension = _step10.value;
              extension.draw.call(_this3, opts, extension);
            }
          } catch (err) {
            _iterator10.e(err);
          } finally {
            _iterator10.f();
          }

          _this3.draw(opts);
        });
      } finally {
        this.props = currentProps;
      }
    }
  }, {
    key: "getChangeFlags",
    value: function getChangeFlags() {
      var _this$internalState2;

      return (_this$internalState2 = this.internalState) === null || _this$internalState2 === void 0 ? void 0 : _this$internalState2.changeFlags;
    }
  }, {
    key: "setChangeFlags",
    value: function setChangeFlags(flags) {
      if (!this.internalState) {
        return;
      }

      var changeFlags = this.internalState.changeFlags;

      for (var key in flags) {
        if (flags[key]) {
          var flagChanged = false;

          switch (key) {
            case 'dataChanged':
              var dataChangedReason = flags[key];
              var prevDataChangedReason = changeFlags[key];

              if (dataChangedReason && Array.isArray(prevDataChangedReason)) {
                changeFlags.dataChanged = Array.isArray(dataChangedReason) ? prevDataChangedReason.concat(dataChangedReason) : dataChangedReason;
                flagChanged = true;
              }

            default:
              if (!changeFlags[key]) {
                changeFlags[key] = flags[key];
                flagChanged = true;
              }

          }

          if (flagChanged) {
            debug(TRACE_CHANGE_FLAG, this, key, flags);
          }
        }
      }

      var propsOrDataChanged = Boolean(changeFlags.dataChanged || changeFlags.updateTriggersChanged || changeFlags.propsChanged || changeFlags.extensionsChanged);
      changeFlags.propsOrDataChanged = propsOrDataChanged;
      changeFlags.somethingChanged = propsOrDataChanged || changeFlags.viewportChanged || changeFlags.stateChanged;
    }
  }, {
    key: "_clearChangeFlags",
    value: function _clearChangeFlags() {
      this.internalState.changeFlags = {
        dataChanged: false,
        propsChanged: false,
        updateTriggersChanged: false,
        viewportChanged: false,
        stateChanged: false,
        extensionsChanged: false,
        propsOrDataChanged: false,
        somethingChanged: false
      };
    }
  }, {
    key: "_diffProps",
    value: function _diffProps(newProps, oldProps) {
      var changeFlags = diffProps(newProps, oldProps);

      if (changeFlags.updateTriggersChanged) {
        for (var key in changeFlags.updateTriggersChanged) {
          if (changeFlags.updateTriggersChanged[key]) {
            this.invalidateAttribute(key);
          }
        }
      }

      if (changeFlags.transitionsChanged) {
        for (var _key2 in changeFlags.transitionsChanged) {
          var _newProps$transitions;

          this.internalState.uniformTransitions.add(_key2, oldProps[_key2], newProps[_key2], (_newProps$transitions = newProps.transitions) === null || _newProps$transitions === void 0 ? void 0 : _newProps$transitions[_key2]);
        }
      }

      return this.setChangeFlags(changeFlags);
    }
  }, {
    key: "validateProps",
    value: function validateProps() {
      _validateProps(this.props);
    }
  }, {
    key: "updateAutoHighlight",
    value: function updateAutoHighlight(info) {
      if (this.props.autoHighlight && !Number.isInteger(this.props.highlightedObjectIndex)) {
        this._updateAutoHighlight(info);
      }
    }
  }, {
    key: "_updateAutoHighlight",
    value: function _updateAutoHighlight(info) {
      var pickingModuleParameters = {
        pickingSelectedColor: info.picked ? info.color : null
      };
      var highlightColor = this.props.highlightColor;

      if (info.picked && typeof highlightColor === 'function') {
        pickingModuleParameters.pickingHighlightColor = highlightColor(info);
      }

      this.setModuleParameters(pickingModuleParameters);
      this.setNeedsRedraw();
    }
  }, {
    key: "_getAttributeManager",
    value: function _getAttributeManager() {
      var context = this.context;
      return new AttributeManager(context.gl, {
        id: this.props.id,
        stats: context.stats,
        timeline: context.timeline
      });
    }
  }, {
    key: "_postUpdate",
    value: function _postUpdate(updateParams, forceUpdate) {
      var props = updateParams.props,
          oldProps = updateParams.oldProps;
      this.setNeedsRedraw();

      this._updateAttributes();

      var model = this.state.model;
      model === null || model === void 0 ? void 0 : model.setInstanceCount(this.getNumInstances());
      var autoHighlight = props.autoHighlight,
          highlightedObjectIndex = props.highlightedObjectIndex,
          highlightColor = props.highlightColor;

      if (forceUpdate || oldProps.autoHighlight !== autoHighlight || oldProps.highlightedObjectIndex !== highlightedObjectIndex || oldProps.highlightColor !== highlightColor) {
        var parameters = {};

        if (!autoHighlight) {
          parameters.pickingSelectedColor = null;
        }

        if (Array.isArray(highlightColor)) {
          parameters.pickingHighlightColor = highlightColor;
        }

        if (forceUpdate || highlightedObjectIndex !== oldProps.highlightedObjectIndex) {
          parameters.pickingSelectedColor = Number.isFinite(highlightedObjectIndex) && highlightedObjectIndex >= 0 ? this.encodePickingColor(highlightedObjectIndex) : null;
        }

        this.setModuleParameters(parameters);
      }
    }
  }, {
    key: "_getUpdateParams",
    value: function _getUpdateParams() {
      return {
        props: this.props,
        oldProps: this.internalState.getOldProps(),
        context: this.context,
        changeFlags: this.internalState.changeFlags
      };
    }
  }, {
    key: "_getNeedsRedraw",
    value: function _getNeedsRedraw(opts) {
      if (!this.internalState) {
        return false;
      }

      var redraw = false;
      redraw = redraw || this.internalState.needsRedraw && this.id;
      var attributeManager = this.getAttributeManager();
      var attributeManagerNeedsRedraw = attributeManager ? attributeManager.getNeedsRedraw(opts) : false;
      redraw = redraw || attributeManagerNeedsRedraw;

      if (redraw) {
        var _iterator11 = _createForOfIteratorHelper(this.props.extensions),
            _step11;

        try {
          for (_iterator11.s(); !(_step11 = _iterator11.n()).done;) {
            var extension = _step11.value;
            extension.onNeedsRedraw.call(this, extension);
          }
        } catch (err) {
          _iterator11.e(err);
        } finally {
          _iterator11.f();
        }
      }

      this.internalState.needsRedraw = this.internalState.needsRedraw && !opts.clearRedrawFlags;
      return redraw;
    }
  }, {
    key: "_onAsyncPropUpdated",
    value: function _onAsyncPropUpdated() {
      this._diffProps(this.props, this.internalState.getOldProps());

      this.setNeedsUpdate();
    }
  }], [{
    key: "componentName",
    get: function get() {
      return Object.prototype.hasOwnProperty.call(this, 'layerName') ? this.layerName : '';
    }
  }]);

  return Layer;
}(Component);

_defineProperty(Layer, "defaultProps", defaultProps);

_defineProperty(Layer, "layerName", 'Layer');

export { Layer as default };