var EMPTY_ARRAY = [];
var placeholderArray = [];
export function createIterable(data) {
  var startRow = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var endRow = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : Infinity;
  var iterable = EMPTY_ARRAY;
  var objectInfo = {
    index: -1,
    data: data,
    target: []
  };

  if (!data) {
    iterable = EMPTY_ARRAY;
  } else if (typeof data[Symbol.iterator] === 'function') {
    iterable = data;
  } else if (data.length > 0) {
    placeholderArray.length = data.length;
    iterable = placeholderArray;
  }

  if (startRow > 0 || Number.isFinite(endRow)) {
    iterable = (Array.isArray(iterable) ? iterable : Array.from(iterable)).slice(startRow, endRow);
    objectInfo.index = startRow - 1;
  }

  return {
    iterable: iterable,
    objectInfo: objectInfo
  };
}
export function isAsyncIterable(data) {
  return data && data[Symbol.asyncIterator];
}
export function getAccessorFromBuffer(typedArray, options) {
  var size = options.size,
      stride = options.stride,
      offset = options.offset,
      startIndices = options.startIndices,
      nested = options.nested;
  var bytesPerElement = typedArray.BYTES_PER_ELEMENT;
  var elementStride = stride ? stride / bytesPerElement : size;
  var elementOffset = offset ? offset / bytesPerElement : 0;
  var vertexCount = Math.floor((typedArray.length - elementOffset) / elementStride);
  return function (_, _ref) {
    var index = _ref.index,
        target = _ref.target;

    if (!startIndices) {
      var sourceIndex = index * elementStride + elementOffset;

      for (var j = 0; j < size; j++) {
        target[j] = typedArray[sourceIndex + j];
      }

      return target;
    }

    var startIndex = startIndices[index];
    var endIndex = startIndices[index + 1] || vertexCount;
    var result;

    if (nested) {
      result = new Array(endIndex - startIndex);

      for (var i = startIndex; i < endIndex; i++) {
        var _sourceIndex = i * elementStride + elementOffset;

        target = new Array(size);

        for (var _j = 0; _j < size; _j++) {
          target[_j] = typedArray[_sourceIndex + _j];
        }

        result[i - startIndex] = target;
      }
    } else if (elementStride === size) {
      result = typedArray.subarray(startIndex * size + elementOffset, endIndex * size + elementOffset);
    } else {
      result = new typedArray.constructor((endIndex - startIndex) * size);
      var targetIndex = 0;

      for (var _i = startIndex; _i < endIndex; _i++) {
        var _sourceIndex2 = _i * elementStride + elementOffset;

        for (var _j2 = 0; _j2 < size; _j2++) {
          result[targetIndex++] = typedArray[_sourceIndex2 + _j2];
        }
      }
    }

    return result;
  };
}