export function glArrayFromType(glType) {
  switch (glType) {
    case 5126:
      return Float32Array;

    case 5130:
      return Float64Array;

    case 5123:
    case 33635:
    case 32819:
    case 32820:
      return Uint16Array;

    case 5125:
      return Uint32Array;

    case 5121:
      return Uint8ClampedArray;

    case 5120:
      return Int8Array;

    case 5122:
      return Int16Array;

    case 5124:
      return Int32Array;

    default:
      throw new Error('Unknown GL type');
  }
}