import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import ScreenPass from '../passes/screen-pass';
import { normalizeShaderModule } from '@luma.gl/core';

var PostProcessEffect = function () {
  function PostProcessEffect(module) {
    var props = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    _classCallCheck(this, PostProcessEffect);

    _defineProperty(this, "id", void 0);

    _defineProperty(this, "props", void 0);

    _defineProperty(this, "module", void 0);

    _defineProperty(this, "passes", void 0);

    this.id = "".concat(module.name, "-pass");
    this.props = props;
    normalizeShaderModule(module);
    this.module = module;
  }

  _createClass(PostProcessEffect, [{
    key: "setProps",
    value: function setProps(props) {
      this.props = props;
    }
  }, {
    key: "preRender",
    value: function preRender() {}
  }, {
    key: "postRender",
    value: function postRender(gl, params) {
      var passes = this.passes || createPasses(gl, this.module, this.id);
      this.passes = passes;
      var target = params.target;
      var inputBuffer = params.inputBuffer;
      var outputBuffer = params.swapBuffer;

      for (var index = 0; index < this.passes.length; index++) {
        if (target && index === this.passes.length - 1) {
          outputBuffer = target;
        }

        this.passes[index].render({
          inputBuffer: inputBuffer,
          outputBuffer: outputBuffer,
          moduleSettings: this.props
        });
        var switchBuffer = outputBuffer;
        outputBuffer = inputBuffer;
        inputBuffer = switchBuffer;
      }

      return inputBuffer;
    }
  }, {
    key: "cleanup",
    value: function cleanup() {
      if (this.passes) {
        var _iterator = _createForOfIteratorHelper(this.passes),
            _step;

        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var pass = _step.value;
            pass.delete();
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }

        this.passes = undefined;
      }
    }
  }]);

  return PostProcessEffect;
}();

export { PostProcessEffect as default };

function createPasses(gl, module, id) {
  if (!module.passes) {
    var fs = getFragmentShaderForRenderPass(module);
    var pass = new ScreenPass(gl, {
      id: id,
      module: module,
      fs: fs
    });
    return [pass];
  }

  return module.passes.map(function (pass, index) {
    var fs = getFragmentShaderForRenderPass(module, pass);
    var idn = "".concat(id, "-").concat(index);
    return new ScreenPass(gl, {
      id: idn,
      module: module,
      fs: fs
    });
  });
}

var FILTER_FS_TEMPLATE = function FILTER_FS_TEMPLATE(func) {
  return "uniform sampler2D texture;\nuniform vec2 texSize;\n\nvarying vec2 position;\nvarying vec2 coordinate;\nvarying vec2 uv;\n\nvoid main() {\n  vec2 texCoord = coordinate;\n\n  gl_FragColor = texture2D(texture, texCoord);\n  gl_FragColor = ".concat(func, "(gl_FragColor, texSize, texCoord);\n}\n");
};

var SAMPLER_FS_TEMPLATE = function SAMPLER_FS_TEMPLATE(func) {
  return "uniform sampler2D texture;\nuniform vec2 texSize;\n\nvarying vec2 position;\nvarying vec2 coordinate;\nvarying vec2 uv;\n\nvoid main() {\n  vec2 texCoord = coordinate;\n\n  gl_FragColor = ".concat(func, "(texture, texSize, texCoord);\n}\n");
};

function getFragmentShaderForRenderPass(module) {
  var pass = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : module;

  if (pass.filter) {
    var func = typeof pass.filter === 'string' ? pass.filter : "".concat(module.name, "_filterColor");
    return FILTER_FS_TEMPLATE(func);
  }

  if (pass.sampler) {
    var _func = typeof pass.sampler === 'string' ? pass.sampler : "".concat(module.name, "_sampleColor");

    return SAMPLER_FS_TEMPLATE(_func);
  }

  return null;
}