import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _assertThisInitialized from "@babel/runtime/helpers/assertThisInitialized";
import _inherits from "@babel/runtime/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

import { ClipSpace, setParameters, withParameters, clear } from '@luma.gl/core';
import Pass from './pass';

var ScreenPass = function (_Pass) {
  _inherits(ScreenPass, _Pass);

  var _super = _createSuper(ScreenPass);

  function ScreenPass(gl, props) {
    var _this;

    _classCallCheck(this, ScreenPass);

    _this = _super.call(this, gl, props);

    _defineProperty(_assertThisInitialized(_this), "model", void 0);

    var module = props.module,
        fs = props.fs,
        id = props.id;
    _this.model = new ClipSpace(gl, {
      id: id,
      fs: fs,
      modules: [module]
    });
    return _this;
  }

  _createClass(ScreenPass, [{
    key: "render",
    value: function render(params) {
      var _this2 = this;

      var gl = this.gl;
      setParameters(gl, {
        viewport: [0, 0, gl.drawingBufferWidth, gl.drawingBufferHeight]
      });
      withParameters(gl, {
        framebuffer: params.outputBuffer,
        clearColor: [0, 0, 0, 0]
      }, function () {
        return _this2._renderPass(gl, params);
      });
    }
  }, {
    key: "delete",
    value: function _delete() {
      this.model.delete();
      this.model = null;
    }
  }, {
    key: "_renderPass",
    value: function _renderPass(gl, options) {
      var inputBuffer = options.inputBuffer;
      clear(gl, {
        color: true
      });
      this.model.draw({
        moduleSettings: options.moduleSettings,
        uniforms: {
          texture: inputBuffer,
          texSize: [inputBuffer.width, inputBuffer.height]
        },
        parameters: {
          depthWrite: false,
          depthTest: false
        }
      });
    }
  }]);

  return ScreenPass;
}(Pass);

export { ScreenPass as default };