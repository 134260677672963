import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _inherits from "@babel/runtime/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

import View from './view';
import OrthographicViewport from '../viewports/orthographic-viewport';
import OrthographicController from '../controllers/orthographic-controller';

var OrthographicView = function (_View) {
  _inherits(OrthographicView, _View);

  var _super = _createSuper(OrthographicView);

  function OrthographicView() {
    _classCallCheck(this, OrthographicView);

    return _super.apply(this, arguments);
  }

  _createClass(OrthographicView, [{
    key: "ViewportType",
    get: function get() {
      return OrthographicViewport;
    }
  }, {
    key: "ControllerType",
    get: function get() {
      return OrthographicController;
    }
  }]);

  return OrthographicView;
}(View);

_defineProperty(OrthographicView, "displayName", 'OrthographicView');

export { OrthographicView as default };