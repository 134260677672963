function padArrayChunk(options) {
  var source = options.source,
      target = options.target,
      _options$start = options.start,
      start = _options$start === void 0 ? 0 : _options$start,
      size = options.size,
      getData = options.getData;
  var end = options.end || target.length;
  var sourceLength = source.length;
  var targetLength = end - start;

  if (sourceLength > targetLength) {
    target.set(source.subarray(0, targetLength), start);
    return;
  }

  target.set(source, start);

  if (!getData) {
    return;
  }

  var i = sourceLength;

  while (i < targetLength) {
    var datum = getData(i, source);

    for (var j = 0; j < size; j++) {
      target[start + i] = datum[j] || 0;
      i++;
    }
  }
}

export function padArray(_ref) {
  var source = _ref.source,
      target = _ref.target,
      size = _ref.size,
      getData = _ref.getData,
      sourceStartIndices = _ref.sourceStartIndices,
      targetStartIndices = _ref.targetStartIndices;

  if (!Array.isArray(targetStartIndices)) {
    padArrayChunk({
      source: source,
      target: target,
      size: size,
      getData: getData
    });
    return target;
  }

  var sourceIndex = 0;
  var targetIndex = 0;

  var getChunkData = getData && function (i, chunk) {
    return getData(i + targetIndex, chunk);
  };

  var n = Math.min(sourceStartIndices.length, targetStartIndices.length);

  for (var i = 1; i < n; i++) {
    var nextSourceIndex = sourceStartIndices[i] * size;
    var nextTargetIndex = targetStartIndices[i] * size;
    padArrayChunk({
      source: source.subarray(sourceIndex, nextSourceIndex),
      target: target,
      start: targetIndex,
      end: nextTargetIndex,
      size: size,
      getData: getChunkData
    });
    sourceIndex = nextSourceIndex;
    targetIndex = nextTargetIndex;
  }

  if (targetIndex < target.length) {
    padArrayChunk({
      source: [],
      target: target,
      start: targetIndex,
      size: size,
      getData: getChunkData
    });
  }

  return target;
}