import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _assertThisInitialized from "@babel/runtime/helpers/assertThisInitialized";
import _get from "@babel/runtime/helpers/get";
import _inherits from "@babel/runtime/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { log } from '@deck.gl/core';
import GPUGridAggregator from '../utils/gpu-grid-aggregation/gpu-grid-aggregator';
import { AGGREGATION_OPERATION, getValueFunc } from '../utils/aggregation-operation-utils';
import ScreenGridCellLayer from './screen-grid-cell-layer';
import GridAggregationLayer from '../grid-aggregation-layer';
import { getFloatTexture } from '../utils/resource-utils.js';

var defaultProps = _objectSpread(_objectSpread({}, ScreenGridCellLayer.defaultProps), {}, {
  getPosition: {
    type: 'accessor',
    value: function value(d) {
      return d.position;
    }
  },
  getWeight: {
    type: 'accessor',
    value: 1
  },
  gpuAggregation: true,
  aggregation: 'SUM'
});

var POSITION_ATTRIBUTE_NAME = 'positions';
var DIMENSIONS = {
  data: {
    props: ['cellSizePixels']
  },
  weights: {
    props: ['aggregation'],
    accessors: ['getWeight']
  }
};

var ScreenGridLayer = function (_GridAggregationLayer) {
  _inherits(ScreenGridLayer, _GridAggregationLayer);

  var _super = _createSuper(ScreenGridLayer);

  function ScreenGridLayer() {
    var _this;

    _classCallCheck(this, ScreenGridLayer);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));

    _defineProperty(_assertThisInitialized(_this), "state", void 0);

    return _this;
  }

  _createClass(ScreenGridLayer, [{
    key: "initializeState",
    value: function initializeState() {
      var _attributeManager$add;

      var gl = this.context.gl;

      if (!ScreenGridCellLayer.isSupported(gl)) {
        this.setState({
          supported: false
        });
        log.error("ScreenGridLayer: ".concat(this.id, " is not supported on this browser"))();
        return;
      }

      _get(_getPrototypeOf(ScreenGridLayer.prototype), "initializeAggregationLayer", this).call(this, {
        dimensions: DIMENSIONS,
        getCellSize: function getCellSize(props) {
          return props.cellSizePixels;
        }
      });

      var weights = {
        count: {
          size: 1,
          operation: AGGREGATION_OPERATION.SUM,
          needMax: true,
          maxTexture: getFloatTexture(gl, {
            id: "".concat(this.id, "-max-texture")
          })
        }
      };
      this.setState({
        supported: true,
        projectPoints: true,
        weights: weights,
        subLayerData: {
          attributes: {}
        },
        maxTexture: weights.count.maxTexture,
        positionAttributeName: 'positions',
        posOffset: [0, 0],
        translation: [1, -1]
      });
      var attributeManager = this.getAttributeManager();
      attributeManager.add((_attributeManager$add = {}, _defineProperty(_attributeManager$add, POSITION_ATTRIBUTE_NAME, {
        size: 3,
        accessor: 'getPosition',
        type: 5130,
        fp64: this.use64bitPositions()
      }), _defineProperty(_attributeManager$add, "count", {
        size: 3,
        accessor: 'getWeight'
      }), _attributeManager$add));
    }
  }, {
    key: "shouldUpdateState",
    value: function shouldUpdateState(_ref) {
      var changeFlags = _ref.changeFlags;
      return this.state.supported && changeFlags.somethingChanged;
    }
  }, {
    key: "updateState",
    value: function updateState(opts) {
      _get(_getPrototypeOf(ScreenGridLayer.prototype), "updateState", this).call(this, opts);
    }
  }, {
    key: "renderLayers",
    value: function renderLayers() {
      if (!this.state.supported) {
        return [];
      }

      var _this$state = this.state,
          maxTexture = _this$state.maxTexture,
          numRow = _this$state.numRow,
          numCol = _this$state.numCol,
          weights = _this$state.weights;
      var updateTriggers = this.props.updateTriggers;
      var aggregationBuffer = weights.count.aggregationBuffer;
      var CellLayerClass = this.getSubLayerClass('cells', ScreenGridCellLayer);
      return new CellLayerClass(this.props, this.getSubLayerProps({
        id: 'cell-layer',
        updateTriggers: updateTriggers
      }), {
        data: {
          attributes: {
            instanceCounts: aggregationBuffer
          }
        },
        maxTexture: maxTexture,
        numInstances: numRow * numCol
      });
    }
  }, {
    key: "finalizeState",
    value: function finalizeState(context) {
      _get(_getPrototypeOf(ScreenGridLayer.prototype), "finalizeState", this).call(this, context);

      var _this$state2 = this.state,
          aggregationBuffer = _this$state2.aggregationBuffer,
          maxBuffer = _this$state2.maxBuffer,
          maxTexture = _this$state2.maxTexture;
      aggregationBuffer === null || aggregationBuffer === void 0 ? void 0 : aggregationBuffer.delete();
      maxBuffer === null || maxBuffer === void 0 ? void 0 : maxBuffer.delete();
      maxTexture === null || maxTexture === void 0 ? void 0 : maxTexture.delete();
    }
  }, {
    key: "getPickingInfo",
    value: function getPickingInfo(_ref2) {
      var info = _ref2.info;
      var index = info.index;

      if (index >= 0) {
        var _this$state3 = this.state,
            gpuGridAggregator = _this$state3.gpuGridAggregator,
            gpuAggregation = _this$state3.gpuAggregation,
            weights = _this$state3.weights;
        var aggregationResults = gpuAggregation ? gpuGridAggregator.getData('count') : weights.count;
        info.object = GPUGridAggregator.getAggregationData(_objectSpread({
          pixelIndex: index
        }, aggregationResults));
      }

      return info;
    }
  }, {
    key: "updateResults",
    value: function updateResults(_ref3) {
      var aggregationData = _ref3.aggregationData,
          maxData = _ref3.maxData;
      var count = this.state.weights.count;
      count.aggregationData = aggregationData;
      count.aggregationBuffer.setData({
        data: aggregationData
      });
      count.maxData = maxData;
      count.maxTexture.setImageData({
        data: maxData
      });
    }
  }, {
    key: "updateAggregationState",
    value: function updateAggregationState(opts) {
      var cellSize = opts.props.cellSizePixels;
      var cellSizeChanged = opts.oldProps.cellSizePixels !== cellSize;
      var viewportChanged = opts.changeFlags.viewportChanged;
      var gpuAggregation = opts.props.gpuAggregation;

      if (this.state.gpuAggregation !== opts.props.gpuAggregation) {
        if (gpuAggregation && !GPUGridAggregator.isSupported(this.context.gl)) {
          log.warn('GPU Grid Aggregation not supported, falling back to CPU')();
          gpuAggregation = false;
        }
      }

      var gpuAggregationChanged = gpuAggregation !== this.state.gpuAggregation;
      this.setState({
        gpuAggregation: gpuAggregation
      });
      var positionsChanged = this.isAttributeChanged(POSITION_ATTRIBUTE_NAME);
      var dimensions = this.state.dimensions;
      var data = dimensions.data,
          weights = dimensions.weights;
      var aggregationDataDirty = positionsChanged || gpuAggregationChanged || viewportChanged || this.isAggregationDirty(opts, {
        compareAll: gpuAggregation,
        dimension: data
      });
      var aggregationWeightsDirty = this.isAggregationDirty(opts, {
        dimension: weights
      });
      this.setState({
        aggregationDataDirty: aggregationDataDirty,
        aggregationWeightsDirty: aggregationWeightsDirty
      });
      var viewport = this.context.viewport;

      if (viewportChanged || cellSizeChanged) {
        var width = viewport.width,
            height = viewport.height;
        var numCol = Math.ceil(width / cellSize);
        var numRow = Math.ceil(height / cellSize);
        this.allocateResources(numRow, numCol);
        this.setState({
          scaling: [width / 2, -height / 2, 1],
          gridOffset: {
            xOffset: cellSize,
            yOffset: cellSize
          },
          width: width,
          height: height,
          numCol: numCol,
          numRow: numRow
        });
      }

      if (aggregationWeightsDirty) {
        this._updateAccessors(opts);
      }

      if (aggregationDataDirty || aggregationWeightsDirty) {
        this._resetResults();
      }
    }
  }, {
    key: "_updateAccessors",
    value: function _updateAccessors(opts) {
      var _opts$props = opts.props,
          getWeight = _opts$props.getWeight,
          aggregation = _opts$props.aggregation,
          data = _opts$props.data;
      var count = this.state.weights.count;

      if (count) {
        count.getWeight = getWeight;
        count.operation = AGGREGATION_OPERATION[aggregation];
      }

      this.setState({
        getValue: getValueFunc(aggregation, getWeight, {
          data: data
        })
      });
    }
  }, {
    key: "_resetResults",
    value: function _resetResults() {
      var count = this.state.weights.count;

      if (count) {
        count.aggregationData = null;
      }
    }
  }]);

  return ScreenGridLayer;
}(GridAggregationLayer);

_defineProperty(ScreenGridLayer, "layerName", 'ScreenGridLayer');

_defineProperty(ScreenGridLayer, "defaultProps", defaultProps);

export { ScreenGridLayer as default };