import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Vector3 } from '@math.gl/core';
var DEFAULT_LIGHT_COLOR = [255, 255, 255];
var DEFAULT_LIGHT_INTENSITY = 1.0;
var DEFAULT_LIGHT_DIRECTION = [0.0, 0.0, -1.0];
var idCount = 0;
export var DirectionalLight = function () {
  function DirectionalLight() {
    var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    _classCallCheck(this, DirectionalLight);

    _defineProperty(this, "id", void 0);

    _defineProperty(this, "color", void 0);

    _defineProperty(this, "intensity", void 0);

    _defineProperty(this, "type", 'directional');

    _defineProperty(this, "direction", void 0);

    _defineProperty(this, "shadow", void 0);

    var _props$color = props.color,
        color = _props$color === void 0 ? DEFAULT_LIGHT_COLOR : _props$color;
    var _props$intensity = props.intensity,
        intensity = _props$intensity === void 0 ? DEFAULT_LIGHT_INTENSITY : _props$intensity;
    var _props$direction = props.direction,
        direction = _props$direction === void 0 ? DEFAULT_LIGHT_DIRECTION : _props$direction;

    var _props$_shadow = props._shadow,
        _shadow = _props$_shadow === void 0 ? false : _props$_shadow;

    this.id = props.id || "directional-".concat(idCount++);
    this.color = color;
    this.intensity = intensity;
    this.type = 'directional';
    this.direction = new Vector3(direction).normalize().toArray();
    this.shadow = _shadow;
  }

  _createClass(DirectionalLight, [{
    key: "getProjectedLight",
    value: function getProjectedLight(opts) {
      return this;
    }
  }]);

  return DirectionalLight;
}();