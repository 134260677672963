import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _assertThisInitialized from "@babel/runtime/helpers/assertThisInitialized";
import _get from "@babel/runtime/helpers/get";
import _inherits from "@babel/runtime/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

import ComponentState from '../lifecycle/component-state';

var LayerState = function (_ComponentState) {
  _inherits(LayerState, _ComponentState);

  var _super = _createSuper(LayerState);

  function LayerState(_ref) {
    var _this;

    var attributeManager = _ref.attributeManager,
        layer = _ref.layer;

    _classCallCheck(this, LayerState);

    _this = _super.call(this, layer);

    _defineProperty(_assertThisInitialized(_this), "attributeManager", void 0);

    _defineProperty(_assertThisInitialized(_this), "needsRedraw", void 0);

    _defineProperty(_assertThisInitialized(_this), "needsUpdate", void 0);

    _defineProperty(_assertThisInitialized(_this), "subLayers", void 0);

    _defineProperty(_assertThisInitialized(_this), "usesPickingColorCache", void 0);

    _defineProperty(_assertThisInitialized(_this), "hasPickingBuffer", void 0);

    _defineProperty(_assertThisInitialized(_this), "changeFlags", void 0);

    _defineProperty(_assertThisInitialized(_this), "viewport", void 0);

    _defineProperty(_assertThisInitialized(_this), "uniformTransitions", void 0);

    _defineProperty(_assertThisInitialized(_this), "propsInTransition", void 0);

    _this.attributeManager = attributeManager;
    _this.needsRedraw = true;
    _this.needsUpdate = true;
    _this.subLayers = null;
    _this.usesPickingColorCache = false;
    return _this;
  }

  _createClass(LayerState, [{
    key: "layer",
    get: function get() {
      return this.component;
    }
  }, {
    key: "_fetch",
    value: function _fetch(propName, url) {
      var layer = this.layer;
      var fetch = layer === null || layer === void 0 ? void 0 : layer.props.fetch;

      if (fetch) {
        return fetch(url, {
          propName: propName,
          layer: layer
        });
      }

      return _get(_getPrototypeOf(LayerState.prototype), "_fetch", this).call(this, propName, url);
    }
  }, {
    key: "_onResolve",
    value: function _onResolve(propName, value) {
      var layer = this.layer;

      if (layer) {
        var onDataLoad = layer.props.onDataLoad;

        if (propName === 'data' && onDataLoad) {
          onDataLoad(value, {
            propName: propName,
            layer: layer
          });
        }
      }
    }
  }, {
    key: "_onError",
    value: function _onError(propName, error) {
      var layer = this.layer;

      if (layer) {
        layer.raiseError(error, "loading ".concat(propName, " of ").concat(this.layer));
      }
    }
  }]);

  return LayerState;
}(ComponentState);

export { LayerState as default };