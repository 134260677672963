import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var lastPickedObject;
var lastTooltip;
var DEFAULT_STYLE = {
  fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  display: 'flex',
  flex: 'wrap',
  maxWidth: '500px',
  flexDirection: 'column',
  zIndex: 2
};

function getDiv() {
  return document.createElement('div');
}

export function getTooltipDefault(pickedInfo) {
  if (!pickedInfo.picked) {
    return null;
  }

  if (pickedInfo.object === lastPickedObject) {
    return lastTooltip;
  }

  var tooltip = {
    html: tabularize(pickedInfo.object),
    style: DEFAULT_STYLE
  };
  lastTooltip = tooltip;
  lastPickedObject = pickedInfo.object;
  return tooltip;
}
var EXCLUDES = new Set(['position', 'index']);
export function tabularize(json) {
  var dataTable = getDiv();

  for (var key in json) {
    if (EXCLUDES.has(key)) {
      continue;
    }

    var header = getDiv();
    header.className = 'header';
    header.textContent = key;
    var valueElement = getDiv();
    valueElement.className = 'value';
    valueElement.textContent = toText(json[key]);
    var row = getDiv();
    setStyles(row, header, valueElement);
    row.appendChild(header);
    row.appendChild(valueElement);
    dataTable.appendChild(row);
  }

  return dataTable.innerHTML;
}

function setStyles(row, header, value) {
  Object.assign(header.style, {
    fontWeight: 700,
    marginRight: '10px',
    flex: '1 1 0%'
  });
  Object.assign(value.style, {
    flex: 'none',
    maxWidth: '250px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  });
  Object.assign(row.style, {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'stretch'
  });
}

export function toText(jsonValue) {
  var text;

  if (Array.isArray(jsonValue) && jsonValue.length > 4) {
    text = "Array<".concat(jsonValue.length, ">");
  } else if (typeof jsonValue === 'string') {
    text = jsonValue;
  } else if (typeof jsonValue === 'number') {
    text = String(jsonValue);
  } else {
    try {
      text = JSON.stringify(jsonValue);
    } catch (err) {
      text = '<Non-Serializable Object>';
    }
  }

  var MAX_LENGTH = 50;

  if (text.length > MAX_LENGTH) {
    text = text.slice(0, MAX_LENGTH);
  }

  return text;
}
export function substituteIn(template, json) {
  var value;
  var output = template;
  var propsKey = 'properties';
  var keyPattern = /{[^}]*}/g;

  var cleanKey = function cleanKey(k) {
    return k.replace(/[{}]/g, '');
  };

  var keys = _toConsumableArray(new Set(template.match(keyPattern).map(cleanKey)));

  var _iterator = _createForOfIteratorHelper(keys),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var key = _step.value;

      if (key.includes('.')) {
        value = json;
        var subkeys = key.split('.');

        var _iterator2 = _createForOfIteratorHelper(subkeys),
            _step2;

        try {
          for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
            var subkey = _step2.value;

            if (value.hasOwnProperty(subkey)) {
              value = value[subkey];
            } else {
              value = undefined;
              break;
            }
          }
        } catch (err) {
          _iterator2.e(err);
        } finally {
          _iterator2.f();
        }
      } else if (json.hasOwnProperty(key)) {
        value = json[key];
      } else if (json[propsKey] && json[propsKey].hasOwnProperty(key)) {
        value = json[propsKey][key];
      } else {
        value = undefined;
      }

      output = output.replaceAll("{".concat(key, "}"), value);
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  return output;
}
export default function makeTooltip(tooltip) {
  if (!tooltip) {
    return null;
  }

  if (tooltip.html || tooltip.text) {
    return function (pickedInfo) {
      if (!pickedInfo.picked) {
        return null;
      }

      var formattedTooltip = {
        style: tooltip.style || DEFAULT_STYLE
      };

      if (tooltip.html) {
        formattedTooltip.html = substituteIn(tooltip.html, pickedInfo.object);
      } else {
        formattedTooltip.text = substituteIn(tooltip.text, pickedInfo.object);
      }

      return formattedTooltip;
    };
  }

  return getTooltipDefault;
}