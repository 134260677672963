import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _assertThisInitialized from "@babel/runtime/helpers/assertThisInitialized";
import _inherits from "@babel/runtime/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

import { DirectionalLight } from './directional-light';
import { getSunDirection } from '@math.gl/sun';

var SunLight = function (_DirectionalLight) {
  _inherits(SunLight, _DirectionalLight);

  var _super = _createSuper(SunLight);

  function SunLight(opts) {
    var _this;

    _classCallCheck(this, SunLight);

    _this = _super.call(this, opts);

    _defineProperty(_assertThisInitialized(_this), "timestamp", void 0);

    _this.timestamp = opts.timestamp;
    return _this;
  }

  _createClass(SunLight, [{
    key: "getProjectedLight",
    value: function getProjectedLight(_ref) {
      var layer = _ref.layer;
      var viewport = layer.context.viewport;
      var isGlobe = viewport.resolution && viewport.resolution > 0;

      if (isGlobe) {
        var _getSunDirection = getSunDirection(this.timestamp, 0, 0),
            _getSunDirection2 = _slicedToArray(_getSunDirection, 3),
            x = _getSunDirection2[0],
            y = _getSunDirection2[1],
            z = _getSunDirection2[2];

        this.direction = [x, -z, y];
      } else {
        var latitude = viewport.latitude,
            longitude = viewport.longitude;
        this.direction = getSunDirection(this.timestamp, latitude, longitude);
      }

      return this;
    }
  }]);

  return SunLight;
}(DirectionalLight);

export { SunLight as default };