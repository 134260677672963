import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _assertThisInitialized from "@babel/runtime/helpers/assertThisInitialized";
import _get from "@babel/runtime/helpers/get";
import _inherits from "@babel/runtime/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

import { default as LayersPass } from './layers-pass';
import { Framebuffer, Texture2D, Renderbuffer, withParameters, cssToDeviceRatio } from '@luma.gl/core';

var ShadowPass = function (_LayersPass) {
  _inherits(ShadowPass, _LayersPass);

  var _super = _createSuper(ShadowPass);

  function ShadowPass(gl, props) {
    var _parameters, _attachments;

    var _this;

    _classCallCheck(this, ShadowPass);

    _this = _super.call(this, gl, props);

    _defineProperty(_assertThisInitialized(_this), "shadowMap", void 0);

    _defineProperty(_assertThisInitialized(_this), "depthBuffer", void 0);

    _defineProperty(_assertThisInitialized(_this), "fbo", void 0);

    _this.shadowMap = new Texture2D(gl, {
      width: 1,
      height: 1,
      parameters: (_parameters = {}, _defineProperty(_parameters, 10241, 9729), _defineProperty(_parameters, 10240, 9729), _defineProperty(_parameters, 10242, 33071), _defineProperty(_parameters, 10243, 33071), _parameters)
    });
    _this.depthBuffer = new Renderbuffer(gl, {
      format: 33189,
      width: 1,
      height: 1
    });
    _this.fbo = new Framebuffer(gl, {
      id: 'shadowmap',
      width: 1,
      height: 1,
      attachments: (_attachments = {}, _defineProperty(_attachments, 36064, _this.shadowMap), _defineProperty(_attachments, 36096, _this.depthBuffer), _attachments)
    });
    return _this;
  }

  _createClass(ShadowPass, [{
    key: "render",
    value: function render(params) {
      var _this2 = this;

      var target = this.fbo;
      withParameters(this.gl, {
        depthRange: [0, 1],
        depthTest: true,
        blend: false,
        clearColor: [1, 1, 1, 1]
      }, function () {
        var viewport = params.viewports[0];
        var pixelRatio = cssToDeviceRatio(_this2.gl);
        var width = viewport.width * pixelRatio;
        var height = viewport.height * pixelRatio;

        if (width !== target.width || height !== target.height) {
          target.resize({
            width: width,
            height: height
          });
        }

        _get(_getPrototypeOf(ShadowPass.prototype), "render", _this2).call(_this2, _objectSpread(_objectSpread({}, params), {}, {
          target: target,
          pass: 'shadow'
        }));
      });
    }
  }, {
    key: "shouldDrawLayer",
    value: function shouldDrawLayer(layer) {
      return layer.props.shadowEnabled !== false;
    }
  }, {
    key: "getModuleParameters",
    value: function getModuleParameters() {
      return {
        drawToShadowMap: true
      };
    }
  }, {
    key: "delete",
    value: function _delete() {
      if (this.fbo) {
        this.fbo.delete();
        this.fbo = null;
      }

      if (this.shadowMap) {
        this.shadowMap.delete();
        this.shadowMap = null;
      }

      if (this.depthBuffer) {
        this.depthBuffer.delete();
        this.depthBuffer = null;
      }
    }
  }]);

  return ShadowPass;
}(LayersPass);

export { ShadowPass as default };