import convertFunctions from './convert-functions';
export function instantiateClass(type, props, configuration) {
  var Class = configuration.classes[type];
  var Component = configuration.reactComponents[type];

  if (!Class && !Component) {
    var log = configuration.log;

    if (log) {
      var stringProps = JSON.stringify(props, null, 0).slice(0, 40);
      log.warn("JSON converter: No registered class of type ".concat(type, "(").concat(stringProps, "...)  "));
    }

    return null;
  }

  if (Class) {
    return instantiateJavaScriptClass(Class, props, configuration);
  }

  return instantiateReactComponent(Component, props, configuration);
}

function instantiateJavaScriptClass(Class, props, configuration) {
  if (configuration.preProcessClassProps) {
    props = configuration.preProcessClassProps(Class, props, configuration);
  }

  props = convertFunctions(props, configuration);
  return new Class(props);
}

function instantiateReactComponent(Component, props, configuration) {
  var React = configuration.React;
  var _props = props,
      _props$children = _props.children,
      children = _props$children === void 0 ? [] : _props$children;
  delete props.children;

  if (configuration.preProcessClassProps) {
    props = configuration.preProcessClassProps(Component, props, configuration);
  }

  props = convertFunctions(props, configuration);
  return React.createElement(Component, props, children);
}