export function loadCSS(url) {
  var link = document.createElement('link');
  link.type = 'text/css';
  link.rel = 'stylesheet';
  link.href = url;
  document.getElementsByTagName('head')[0].appendChild(link);
}
export function createContainer(width, height) {
  var container = document.createElement('div');
  container.style.width = Number.isFinite(width) ? "".concat(width, "px") : width;
  container.style.height = "".concat(height, "px");
  container.style.position = 'relative';
  return container;
}