import _typeof from "@babel/runtime/helpers/typeof";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var state = {
  onIninitialize: function onIninitialize(_) {
    return _;
  },
  onFinalize: function onFinalize(_) {
    return _;
  },
  onMessage: null
};

var Transport = function () {
  function Transport() {
    var name = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'Transport';

    _classCallCheck(this, Transport);

    this.name = name;
    this._messageQueue = [];
    this.userData = {};
  }

  _createClass(Transport, [{
    key: "getRootDOMElement",
    value: function getRootDOMElement() {
      return typeof document !== 'undefined' ? document.body : null;
    }
  }, {
    key: "sendJSONMessage",
    value: function sendJSONMessage() {
      console.error('Back-channel not implemented for this transport');
    }
  }, {
    key: "sendBinaryMessage",
    value: function sendBinaryMessage() {
      console.error('Back-channel not implemented for this transport');
    }
  }, {
    key: "_initialize",
    value: function _initialize() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var message = _objectSpread({
        transport: this
      }, options);

      state.onInitialize(message);
    }
  }, {
    key: "_finalize",
    value: function _finalize() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var message = _objectSpread({
        transport: this
      }, options);

      state.onFinalize(message);
      this._destroyed = true;
    }
  }, {
    key: "_messageReceived",
    value: function _messageReceived() {
      var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      message = _objectSpread({
        transport: this
      }, message);
      console.debug('Delivering transport message', message);
      state.onMessage(message);
    }
  }], [{
    key: "setCallbacks",
    value: function setCallbacks(_ref) {
      var onInitialize = _ref.onInitialize,
          onFinalize = _ref.onFinalize,
          onMessage = _ref.onMessage;

      if (onInitialize) {
        state.onInitialize = onInitialize;
      }

      if (onFinalize) {
        state.onFinalize = onFinalize;
      }

      if (onMessage) {
        state.onMessage = onMessage;
      }
    }
  }, {
    key: "_stringifyJSONSafe",
    value: function _stringifyJSONSafe(v) {
      var cache = new Set();
      return JSON.stringify(v, function (key, value) {
        if (_typeof(value) === 'object' && value !== null) {
          if (cache.has(value)) {
            try {
              return JSON.parse(JSON.stringify(value));
            } catch (err) {
              return undefined;
            }
          }

          cache.add(value);
        }

        return value;
      });
    }
  }]);

  return Transport;
}();

export { Transport as default };