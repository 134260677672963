import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { Model, Transform, FEATURES, hasFeatures, isWebGL2, readPixelsToBuffer, withParameters } from '@luma.gl/core';
import { fp64arithmetic } from '@luma.gl/shadertools';
import { log, project32, _mergeShaders as mergeShaders } from '@deck.gl/core';
import { DEFAULT_RUN_PARAMS, MAX_32_BIT_FLOAT, MIN_BLEND_EQUATION, MAX_BLEND_EQUATION, MAX_MIN_BLEND_EQUATION, EQUATION_MAP, DEFAULT_WEIGHT_PARAMS, PIXEL_SIZE } from './gpu-grid-aggregator-constants';
import { AGGREGATION_OPERATION } from '../aggregation-operation-utils';
import AGGREGATE_TO_GRID_VS from './aggregate-to-grid-vs.glsl';
import AGGREGATE_TO_GRID_FS from './aggregate-to-grid-fs.glsl';
import AGGREGATE_ALL_VS from './aggregate-all-vs.glsl';
import AGGREGATE_ALL_FS from './aggregate-all-fs.glsl';
import TRANSFORM_MEAN_VS from './transform-mean-vs.glsl';
import { getFloatTexture, getFramebuffer } from './../resource-utils.js';
var BUFFER_NAMES = ['aggregationBuffer', 'maxMinBuffer', 'minBuffer', 'maxBuffer'];
var ARRAY_BUFFER_MAP = {
  maxData: 'maxBuffer',
  minData: 'minBuffer',
  maxMinData: 'maxMinBuffer'
};
var REQUIRED_FEATURES = [FEATURES.WEBGL2, FEATURES.COLOR_ATTACHMENT_RGBA32F, FEATURES.BLEND_EQUATION_MINMAX, FEATURES.FLOAT_BLEND, FEATURES.TEXTURE_FLOAT];

var GPUGridAggregator = function () {
  function GPUGridAggregator(gl) {
    var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    _classCallCheck(this, GPUGridAggregator);

    this.id = opts.id || 'gpu-grid-aggregator';
    this.gl = gl;
    this.state = {
      weightAttributes: {},
      textures: {},
      meanTextures: {},
      buffers: {},
      framebuffers: {},
      maxMinFramebuffers: {},
      minFramebuffers: {},
      maxFramebuffers: {},
      equations: {},
      resources: {},
      results: {}
    };
    this._hasGPUSupport = isWebGL2(gl) && hasFeatures(this.gl, FEATURES.BLEND_EQUATION_MINMAX, FEATURES.COLOR_ATTACHMENT_RGBA32F, FEATURES.TEXTURE_FLOAT);

    if (this._hasGPUSupport) {
      this._setupModels();
    }
  }

  _createClass(GPUGridAggregator, [{
    key: "delete",
    value: function _delete() {
      var gridAggregationModel = this.gridAggregationModel,
          allAggregationModel = this.allAggregationModel,
          meanTransform = this.meanTransform;
      var _this$state = this.state,
          textures = _this$state.textures,
          framebuffers = _this$state.framebuffers,
          maxMinFramebuffers = _this$state.maxMinFramebuffers,
          minFramebuffers = _this$state.minFramebuffers,
          maxFramebuffers = _this$state.maxFramebuffers,
          meanTextures = _this$state.meanTextures,
          resources = _this$state.resources;
      gridAggregationModel === null || gridAggregationModel === void 0 ? void 0 : gridAggregationModel.delete();
      allAggregationModel === null || allAggregationModel === void 0 ? void 0 : allAggregationModel.delete();
      meanTransform === null || meanTransform === void 0 ? void 0 : meanTransform.delete();
      deleteResources([framebuffers, textures, maxMinFramebuffers, minFramebuffers, maxFramebuffers, meanTextures, resources]);
    }
  }, {
    key: "run",
    value: function run() {
      var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.setState({
        results: {}
      });

      var aggregationParams = this._normalizeAggregationParams(opts);

      if (!this._hasGPUSupport) {
        log.log(1, 'GPUGridAggregator: not supported')();
      }

      return this._runAggregation(aggregationParams);
    }
  }, {
    key: "getData",
    value: function getData(weightId) {
      var data = {};
      var results = this.state.results;

      if (!results[weightId].aggregationData) {
        results[weightId].aggregationData = results[weightId].aggregationBuffer.getData();
      }

      data.aggregationData = results[weightId].aggregationData;

      for (var arrayName in ARRAY_BUFFER_MAP) {
        var bufferName = ARRAY_BUFFER_MAP[arrayName];

        if (results[weightId][arrayName] || results[weightId][bufferName]) {
          results[weightId][arrayName] = results[weightId][arrayName] || results[weightId][bufferName].getData();
          data[arrayName] = results[weightId][arrayName];
        }
      }

      return data;
    }
  }, {
    key: "updateShaders",
    value: function updateShaders() {
      var shaderOptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.setState({
        shaderOptions: shaderOptions,
        modelDirty: true
      });
    }
  }, {
    key: "_normalizeAggregationParams",
    value: function _normalizeAggregationParams(opts) {
      var aggregationParams = _objectSpread(_objectSpread({}, DEFAULT_RUN_PARAMS), opts);

      var weights = aggregationParams.weights;

      if (weights) {
        aggregationParams.weights = normalizeWeightParams(weights);
      }

      return aggregationParams;
    }
  }, {
    key: "setState",
    value: function setState(updateObject) {
      Object.assign(this.state, updateObject);
    }
  }, {
    key: "_getAggregateData",
    value: function _getAggregateData(opts) {
      var results = {};
      var _this$state2 = this.state,
          textures = _this$state2.textures,
          framebuffers = _this$state2.framebuffers,
          maxMinFramebuffers = _this$state2.maxMinFramebuffers,
          minFramebuffers = _this$state2.minFramebuffers,
          maxFramebuffers = _this$state2.maxFramebuffers,
          resources = _this$state2.resources;
      var weights = opts.weights;

      for (var id in weights) {
        results[id] = {};
        var _weights$id = weights[id],
            needMin = _weights$id.needMin,
            needMax = _weights$id.needMax,
            combineMaxMin = _weights$id.combineMaxMin;
        results[id].aggregationTexture = textures[id];
        results[id].aggregationBuffer = readPixelsToBuffer(framebuffers[id], {
          target: weights[id].aggregationBuffer,
          sourceType: 5126
        });

        if (needMin && needMax && combineMaxMin) {
          results[id].maxMinBuffer = readPixelsToBuffer(maxMinFramebuffers[id], {
            target: weights[id].maxMinBuffer,
            sourceType: 5126
          });
          results[id].maxMinTexture = resources["".concat(id, "-maxMinTexture")];
        } else {
          if (needMin) {
            results[id].minBuffer = readPixelsToBuffer(minFramebuffers[id], {
              target: weights[id].minBuffer,
              sourceType: 5126
            });
            results[id].minTexture = resources["".concat(id, "-minTexture")];
          }

          if (needMax) {
            results[id].maxBuffer = readPixelsToBuffer(maxFramebuffers[id], {
              target: weights[id].maxBuffer,
              sourceType: 5126
            });
            results[id].maxTexture = resources["".concat(id, "-maxTexture")];
          }
        }
      }

      this._trackGPUResultBuffers(results, weights);

      return results;
    }
  }, {
    key: "_renderAggregateData",
    value: function _renderAggregateData(opts) {
      var cellSize = opts.cellSize,
          projectPoints = opts.projectPoints,
          attributes = opts.attributes,
          moduleSettings = opts.moduleSettings,
          numCol = opts.numCol,
          numRow = opts.numRow,
          weights = opts.weights,
          translation = opts.translation,
          scaling = opts.scaling;
      var _this$state3 = this.state,
          maxMinFramebuffers = _this$state3.maxMinFramebuffers,
          minFramebuffers = _this$state3.minFramebuffers,
          maxFramebuffers = _this$state3.maxFramebuffers;
      var gridSize = [numCol, numRow];
      var parameters = {
        blend: true,
        depthTest: false,
        blendFunc: [1, 1]
      };
      var uniforms = {
        cellSize: cellSize,
        gridSize: gridSize,
        projectPoints: projectPoints,
        translation: translation,
        scaling: scaling
      };

      for (var id in weights) {
        var _weights$id2 = weights[id],
            needMin = _weights$id2.needMin,
            needMax = _weights$id2.needMax;
        var combineMaxMin = needMin && needMax && weights[id].combineMaxMin;

        this._renderToWeightsTexture({
          id: id,
          parameters: parameters,
          moduleSettings: moduleSettings,
          uniforms: uniforms,
          gridSize: gridSize,
          attributes: attributes,
          weights: weights
        });

        if (combineMaxMin) {
          this._renderToMaxMinTexture({
            id: id,
            parameters: _objectSpread(_objectSpread({}, parameters), {}, {
              blendEquation: MAX_MIN_BLEND_EQUATION
            }),
            gridSize: gridSize,
            minOrMaxFb: maxMinFramebuffers[id],
            clearParams: {
              clearColor: [0, 0, 0, MAX_32_BIT_FLOAT]
            },
            combineMaxMin: combineMaxMin
          });
        } else {
          if (needMin) {
            this._renderToMaxMinTexture({
              id: id,
              parameters: _objectSpread(_objectSpread({}, parameters), {}, {
                blendEquation: MIN_BLEND_EQUATION
              }),
              gridSize: gridSize,
              minOrMaxFb: minFramebuffers[id],
              clearParams: {
                clearColor: [MAX_32_BIT_FLOAT, MAX_32_BIT_FLOAT, MAX_32_BIT_FLOAT, 0]
              },
              combineMaxMin: combineMaxMin
            });
          }

          if (needMax) {
            this._renderToMaxMinTexture({
              id: id,
              parameters: _objectSpread(_objectSpread({}, parameters), {}, {
                blendEquation: MAX_BLEND_EQUATION
              }),
              gridSize: gridSize,
              minOrMaxFb: maxFramebuffers[id],
              clearParams: {
                clearColor: [0, 0, 0, 0]
              },
              combineMaxMin: combineMaxMin
            });
          }
        }
      }
    }
  }, {
    key: "_renderToMaxMinTexture",
    value: function _renderToMaxMinTexture(opts) {
      var id = opts.id,
          parameters = opts.parameters,
          gridSize = opts.gridSize,
          minOrMaxFb = opts.minOrMaxFb,
          combineMaxMin = opts.combineMaxMin,
          _opts$clearParams = opts.clearParams,
          clearParams = _opts$clearParams === void 0 ? {} : _opts$clearParams;
      var framebuffers = this.state.framebuffers;
      var gl = this.gl,
          allAggregationModel = this.allAggregationModel;
      withParameters(gl, _objectSpread(_objectSpread({}, clearParams), {}, {
        framebuffer: minOrMaxFb,
        viewport: [0, 0, gridSize[0], gridSize[1]]
      }), function () {
        gl.clear(16384);
        allAggregationModel.draw({
          parameters: parameters,
          uniforms: {
            uSampler: framebuffers[id].texture,
            gridSize: gridSize,
            combineMaxMin: combineMaxMin
          }
        });
      });
    }
  }, {
    key: "_renderToWeightsTexture",
    value: function _renderToWeightsTexture(opts) {
      var id = opts.id,
          parameters = opts.parameters,
          moduleSettings = opts.moduleSettings,
          uniforms = opts.uniforms,
          gridSize = opts.gridSize,
          weights = opts.weights;
      var _this$state4 = this.state,
          framebuffers = _this$state4.framebuffers,
          equations = _this$state4.equations,
          weightAttributes = _this$state4.weightAttributes;
      var gl = this.gl,
          gridAggregationModel = this.gridAggregationModel;
      var operation = weights[id].operation;
      var clearColor = operation === AGGREGATION_OPERATION.MIN ? [MAX_32_BIT_FLOAT, MAX_32_BIT_FLOAT, MAX_32_BIT_FLOAT, 0] : [0, 0, 0, 0];
      withParameters(gl, {
        framebuffer: framebuffers[id],
        viewport: [0, 0, gridSize[0], gridSize[1]],
        clearColor: clearColor
      }, function () {
        gl.clear(16384);
        var attributes = {
          weights: weightAttributes[id]
        };
        gridAggregationModel.draw({
          parameters: _objectSpread(_objectSpread({}, parameters), {}, {
            blendEquation: equations[id]
          }),
          moduleSettings: moduleSettings,
          uniforms: uniforms,
          attributes: attributes
        });
      });

      if (operation === AGGREGATION_OPERATION.MEAN) {
        var _this$state5 = this.state,
            meanTextures = _this$state5.meanTextures,
            textures = _this$state5.textures;
        var transformOptions = {
          _sourceTextures: {
            aggregationValues: meanTextures[id]
          },
          _targetTexture: textures[id],
          elementCount: textures[id].width * textures[id].height
        };

        if (this.meanTransform) {
          this.meanTransform.update(transformOptions);
        } else {
          this.meanTransform = getMeanTransform(gl, transformOptions);
        }

        this.meanTransform.run({
          parameters: {
            blend: false,
            depthTest: false
          }
        });
        framebuffers[id].attach(_defineProperty({}, 36064, textures[id]));
      }
    }
  }, {
    key: "_runAggregation",
    value: function _runAggregation(opts) {
      this._updateModels(opts);

      this._setupFramebuffers(opts);

      this._renderAggregateData(opts);

      var results = this._getAggregateData(opts);

      this.setState({
        results: results
      });
      return results;
    }
  }, {
    key: "_setupFramebuffers",
    value: function _setupFramebuffers(opts) {
      var _this$state6 = this.state,
          textures = _this$state6.textures,
          framebuffers = _this$state6.framebuffers,
          maxMinFramebuffers = _this$state6.maxMinFramebuffers,
          minFramebuffers = _this$state6.minFramebuffers,
          maxFramebuffers = _this$state6.maxFramebuffers,
          meanTextures = _this$state6.meanTextures,
          equations = _this$state6.equations;
      var weights = opts.weights;
      var numCol = opts.numCol,
          numRow = opts.numRow;
      var framebufferSize = {
        width: numCol,
        height: numRow
      };

      for (var id in weights) {
        var _weights$id3 = weights[id],
            needMin = _weights$id3.needMin,
            needMax = _weights$id3.needMax,
            combineMaxMin = _weights$id3.combineMaxMin,
            operation = _weights$id3.operation;
        textures[id] = weights[id].aggregationTexture || textures[id] || getFloatTexture(this.gl, {
          id: "".concat(id, "-texture"),
          width: numCol,
          height: numRow
        });
        textures[id].resize(framebufferSize);
        var texture = textures[id];

        if (operation === AGGREGATION_OPERATION.MEAN) {
          meanTextures[id] = meanTextures[id] || getFloatTexture(this.gl, {
            id: "".concat(id, "-mean-texture"),
            width: numCol,
            height: numRow
          });
          meanTextures[id].resize(framebufferSize);
          texture = meanTextures[id];
        }

        if (framebuffers[id]) {
          framebuffers[id].attach(_defineProperty({}, 36064, texture));
        } else {
          framebuffers[id] = getFramebuffer(this.gl, {
            id: "".concat(id, "-fb"),
            width: numCol,
            height: numRow,
            texture: texture
          });
        }

        framebuffers[id].resize(framebufferSize);
        equations[id] = EQUATION_MAP[operation] || EQUATION_MAP.SUM;

        if (needMin || needMax) {
          if (needMin && needMax && combineMaxMin) {
            if (!maxMinFramebuffers[id]) {
              texture = weights[id].maxMinTexture || this._getMinMaxTexture("".concat(id, "-maxMinTexture"));
              maxMinFramebuffers[id] = getFramebuffer(this.gl, {
                id: "".concat(id, "-maxMinFb"),
                texture: texture
              });
            }
          } else {
            if (needMin) {
              if (!minFramebuffers[id]) {
                texture = weights[id].minTexture || this._getMinMaxTexture("".concat(id, "-minTexture"));
                minFramebuffers[id] = getFramebuffer(this.gl, {
                  id: "".concat(id, "-minFb"),
                  texture: texture
                });
              }
            }

            if (needMax) {
              if (!maxFramebuffers[id]) {
                texture = weights[id].maxTexture || this._getMinMaxTexture("".concat(id, "-maxTexture"));
                maxFramebuffers[id] = getFramebuffer(this.gl, {
                  id: "".concat(id, "-maxFb"),
                  texture: texture
                });
              }
            }
          }
        }
      }
    }
  }, {
    key: "_getMinMaxTexture",
    value: function _getMinMaxTexture(name) {
      var resources = this.state.resources;

      if (!resources[name]) {
        resources[name] = getFloatTexture(this.gl, {
          id: "resourceName"
        });
      }

      return resources[name];
    }
  }, {
    key: "_setupModels",
    value: function _setupModels() {
      var _this$gridAggregation;

      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          _ref$numCol = _ref.numCol,
          numCol = _ref$numCol === void 0 ? 0 : _ref$numCol,
          _ref$numRow = _ref.numRow,
          numRow = _ref$numRow === void 0 ? 0 : _ref$numRow;

      var gl = this.gl;
      var shaderOptions = this.state.shaderOptions;
      (_this$gridAggregation = this.gridAggregationModel) === null || _this$gridAggregation === void 0 ? void 0 : _this$gridAggregation.delete();
      this.gridAggregationModel = getAggregationModel(gl, shaderOptions);

      if (!this.allAggregationModel) {
        var instanceCount = numCol * numRow;
        this.allAggregationModel = getAllAggregationModel(gl, instanceCount);
      }
    }
  }, {
    key: "_setupWeightAttributes",
    value: function _setupWeightAttributes(opts) {
      var weightAttributes = this.state.weightAttributes;
      var weights = opts.weights;

      for (var id in weights) {
        weightAttributes[id] = opts.attributes[id];
      }
    }
  }, {
    key: "_trackGPUResultBuffers",
    value: function _trackGPUResultBuffers(results, weights) {
      var resources = this.state.resources;

      for (var id in results) {
        if (results[id]) {
          var _iterator = _createForOfIteratorHelper(BUFFER_NAMES),
              _step;

          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var bufferName = _step.value;

              if (results[id][bufferName] && weights[id][bufferName] !== results[id][bufferName]) {
                var name = "gpu-result-".concat(id, "-").concat(bufferName);

                if (resources[name]) {
                  resources[name].delete();
                }

                resources[name] = results[id][bufferName];
              }
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }
        }
      }
    }
  }, {
    key: "_updateModels",
    value: function _updateModels(opts) {
      var vertexCount = opts.vertexCount,
          attributes = opts.attributes,
          numCol = opts.numCol,
          numRow = opts.numRow;
      var modelDirty = this.state.modelDirty;

      if (modelDirty) {
        this._setupModels(opts);

        this.setState({
          modelDirty: false
        });
      }

      this._setupWeightAttributes(opts);

      this.gridAggregationModel.setVertexCount(vertexCount);
      this.gridAggregationModel.setAttributes(attributes);
      this.allAggregationModel.setInstanceCount(numCol * numRow);
    }
  }], [{
    key: "getAggregationData",
    value: function getAggregationData(_ref2) {
      var aggregationData = _ref2.aggregationData,
          maxData = _ref2.maxData,
          minData = _ref2.minData,
          maxMinData = _ref2.maxMinData,
          pixelIndex = _ref2.pixelIndex;
      var index = pixelIndex * PIXEL_SIZE;
      var results = {};

      if (aggregationData) {
        results.cellCount = aggregationData[index + 3];
        results.cellWeight = aggregationData[index];
      }

      if (maxMinData) {
        results.maxCellWieght = maxMinData[0];
        results.minCellWeight = maxMinData[3];
      } else {
        if (maxData) {
          results.maxCellWieght = maxData[0];
          results.totalCount = maxData[3];
        }

        if (minData) {
          results.minCellWeight = minData[0];
          results.totalCount = maxData[3];
        }
      }

      return results;
    }
  }, {
    key: "getCellData",
    value: function getCellData(_ref3) {
      var countsData = _ref3.countsData,
          _ref3$size = _ref3.size,
          size = _ref3$size === void 0 ? 1 : _ref3$size;
      var numCells = countsData.length / 4;
      var cellWeights = new Float32Array(numCells * size);
      var cellCounts = new Uint32Array(numCells);

      for (var i = 0; i < numCells; i++) {
        for (var sizeIndex = 0; sizeIndex < size; sizeIndex++) {
          cellWeights[i * size + sizeIndex] = countsData[i * 4 + sizeIndex];
        }

        cellCounts[i] = countsData[i * 4 + 3];
      }

      return {
        cellCounts: cellCounts,
        cellWeights: cellWeights
      };
    }
  }, {
    key: "isSupported",
    value: function isSupported(gl) {
      return hasFeatures(gl, REQUIRED_FEATURES);
    }
  }]);

  return GPUGridAggregator;
}();

export { GPUGridAggregator as default };

function normalizeWeightParams(weights) {
  var result = {};

  for (var id in weights) {
    result[id] = _objectSpread(_objectSpread({}, DEFAULT_WEIGHT_PARAMS), weights[id]);
  }

  return result;
}

function deleteResources(resources) {
  resources = Array.isArray(resources) ? resources : [resources];
  resources.forEach(function (obj) {
    for (var name in obj) {
      obj[name].delete();
    }
  });
}

function getAggregationModel(gl, shaderOptions) {
  var shaders = mergeShaders({
    vs: AGGREGATE_TO_GRID_VS,
    fs: AGGREGATE_TO_GRID_FS,
    modules: [fp64arithmetic, project32]
  }, shaderOptions);
  return new Model(gl, _objectSpread({
    id: 'Gird-Aggregation-Model',
    vertexCount: 1,
    drawMode: 0
  }, shaders));
}

function getAllAggregationModel(gl, instanceCount) {
  return new Model(gl, {
    id: 'All-Aggregation-Model',
    vs: AGGREGATE_ALL_VS,
    fs: AGGREGATE_ALL_FS,
    modules: [fp64arithmetic],
    vertexCount: 1,
    drawMode: 0,
    isInstanced: true,
    instanceCount: instanceCount,
    attributes: {
      position: [0, 0]
    }
  });
}

function getMeanTransform(gl, opts) {
  return new Transform(gl, _objectSpread({
    vs: TRANSFORM_MEAN_VS,
    _targetTextureVarying: 'meanValues'
  }, opts));
}