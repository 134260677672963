import _asyncToGenerator from "@babel/runtime/helpers/asyncToGenerator";
import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _assertThisInitialized from "@babel/runtime/helpers/assertThisInitialized";
import _get from "@babel/runtime/helpers/get";
import _inherits from "@babel/runtime/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _regeneratorRuntime from "@babel/runtime/regenerator";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

import { Geometry } from '@luma.gl/core';
import { CompositeLayer, COORDINATE_SYSTEM, log } from '@deck.gl/core';
import { PointCloudLayer } from '@deck.gl/layers';
import { ScenegraphLayer } from '@deck.gl/mesh-layers';
import { default as MeshLayer } from '../mesh-layer/mesh-layer';
import { load } from '@loaders.gl/core';
import { Tileset3D, TILE_TYPE } from '@loaders.gl/tiles';
import { Tiles3DLoader } from '@loaders.gl/3d-tiles';
var SINGLE_DATA = [0];
var defaultProps = {
  getPointColor: {
    type: 'accessor',
    value: [0, 0, 0, 255]
  },
  pointSize: 1.0,
  data: '',
  loader: Tiles3DLoader,
  onTilesetLoad: {
    type: 'function',
    value: function value(tileset3d) {}
  },
  onTileLoad: {
    type: 'function',
    value: function value(tileHeader) {}
  },
  onTileUnload: {
    type: 'function',
    value: function value(tileHeader) {}
  },
  onTileError: {
    type: 'function',
    value: function value(tile, message, url) {}
  },
  _getMeshColor: {
    type: 'function',
    value: function value(tileHeader) {
      return [255, 255, 255];
    }
  }
};

var Tile3DLayer = function (_CompositeLayer) {
  _inherits(Tile3DLayer, _CompositeLayer);

  var _super = _createSuper(Tile3DLayer);

  function Tile3DLayer() {
    var _this;

    _classCallCheck(this, Tile3DLayer);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));

    _defineProperty(_assertThisInitialized(_this), "state", void 0);

    return _this;
  }

  _createClass(Tile3DLayer, [{
    key: "initializeState",
    value: function initializeState() {
      if ('onTileLoadFail' in this.props) {
        log.removed('onTileLoadFail', 'onTileError')();
      }

      this.state = {
        layerMap: {},
        tileset3d: null,
        activeViewports: {},
        lastUpdatedViewports: null
      };
    }
  }, {
    key: "isLoaded",
    get: function get() {
      var _this$state, _this$state$tileset3d;

      return Boolean(((_this$state = this.state) === null || _this$state === void 0 ? void 0 : (_this$state$tileset3d = _this$state.tileset3d) === null || _this$state$tileset3d === void 0 ? void 0 : _this$state$tileset3d.isLoaded()) && _get(_getPrototypeOf(Tile3DLayer.prototype), "isLoaded", this));
    }
  }, {
    key: "shouldUpdateState",
    value: function shouldUpdateState(_ref) {
      var changeFlags = _ref.changeFlags;
      return changeFlags.somethingChanged;
    }
  }, {
    key: "updateState",
    value: function updateState(_ref2) {
      var props = _ref2.props,
          oldProps = _ref2.oldProps,
          changeFlags = _ref2.changeFlags;

      if (props.data && props.data !== oldProps.data) {
        this._loadTileset(props.data);
      }

      if (changeFlags.viewportChanged) {
        var activeViewports = this.state.activeViewports;
        var viewportsNumber = Object.keys(activeViewports).length;

        if (viewportsNumber) {
          this._updateTileset(activeViewports);

          this.state.lastUpdatedViewports = activeViewports;
          this.state.activeViewports = {};
        }
      }

      if (changeFlags.propsChanged) {
        var layerMap = this.state.layerMap;

        for (var key in layerMap) {
          layerMap[key].needsUpdate = true;
        }
      }
    }
  }, {
    key: "activateViewport",
    value: function activateViewport(viewport) {
      var _this$state2 = this.state,
          activeViewports = _this$state2.activeViewports,
          lastUpdatedViewports = _this$state2.lastUpdatedViewports;
      this.internalState.viewport = viewport;
      activeViewports[viewport.id] = viewport;
      var lastViewport = lastUpdatedViewports === null || lastUpdatedViewports === void 0 ? void 0 : lastUpdatedViewports[viewport.id];

      if (!lastViewport || !viewport.equals(lastViewport)) {
        this.setChangeFlags({
          viewportChanged: true
        });
        this.setNeedsUpdate();
      }
    }
  }, {
    key: "getPickingInfo",
    value: function getPickingInfo(_ref3) {
      var info = _ref3.info,
          sourceLayer = _ref3.sourceLayer;
      var sourceTile = sourceLayer && sourceLayer.props.tile;

      if (info.picked) {
        info.object = sourceTile;
      }

      info.sourceTile = sourceTile;
      return info;
    }
  }, {
    key: "filterSubLayer",
    value: function filterSubLayer(_ref4) {
      var layer = _ref4.layer,
          viewport = _ref4.viewport;
      var _ref5 = layer.props,
          tile = _ref5.tile;
      var viewportId = viewport.id;
      return tile.selected && tile.viewportIds.includes(viewportId);
    }
  }, {
    key: "_updateAutoHighlight",
    value: function _updateAutoHighlight(info) {
      var sourceTile = info.sourceTile;
      var layerCache = this.state.layerMap[sourceTile === null || sourceTile === void 0 ? void 0 : sourceTile.id];

      if (layerCache && layerCache.layer) {
        layerCache.layer.updateAutoHighlight(info);
      }
    }
  }, {
    key: "_loadTileset",
    value: function () {
      var _loadTileset2 = _asyncToGenerator(_regeneratorRuntime.mark(function _callee(tilesetUrl) {
        var _this$props$loadOptio, loadOptions, loader, options, actualTilesetUrl, preloadOptions, tilesetJson, tileset3d;

        return _regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _this$props$loadOptio = this.props.loadOptions, loadOptions = _this$props$loadOptio === void 0 ? {} : _this$props$loadOptio;
                loader = this.props.loader || this.props.loaders;

                if (Array.isArray(loader)) {
                  loader = loader[0];
                }

                options = {
                  loadOptions: _objectSpread({}, loadOptions)
                };
                actualTilesetUrl = tilesetUrl;

                if (!loader.preload) {
                  _context.next = 12;
                  break;
                }

                _context.next = 8;
                return loader.preload(tilesetUrl, loadOptions);

              case 8:
                preloadOptions = _context.sent;

                if (preloadOptions.url) {
                  actualTilesetUrl = preloadOptions.url;
                }

                if (preloadOptions.headers) {
                  options.loadOptions.fetch = _objectSpread(_objectSpread({}, options.loadOptions.fetch), {}, {
                    headers: preloadOptions.headers
                  });
                }

                Object.assign(options, preloadOptions);

              case 12:
                _context.next = 14;
                return load(actualTilesetUrl, loader, options.loadOptions);

              case 14:
                tilesetJson = _context.sent;
                tileset3d = new Tileset3D(tilesetJson, _objectSpread({
                  onTileLoad: this._onTileLoad.bind(this),
                  onTileUnload: this._onTileUnload.bind(this),
                  onTileError: this.props.onTileError
                }, options));
                this.setState({
                  tileset3d: tileset3d,
                  layerMap: {}
                });

                this._updateTileset(this.state.activeViewports);

                this.props.onTilesetLoad(tileset3d);

              case 19:
              case "end":
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function _loadTileset(_x) {
        return _loadTileset2.apply(this, arguments);
      }

      return _loadTileset;
    }()
  }, {
    key: "_onTileLoad",
    value: function _onTileLoad(tileHeader) {
      var lastUpdatedViewports = this.state.lastUpdatedViewports;
      this.props.onTileLoad(tileHeader);

      this._updateTileset(lastUpdatedViewports);

      this.setNeedsUpdate();
    }
  }, {
    key: "_onTileUnload",
    value: function _onTileUnload(tileHeader) {
      delete this.state.layerMap[tileHeader.id];
      this.props.onTileUnload(tileHeader);
    }
  }, {
    key: "_updateTileset",
    value: function _updateTileset(viewports) {
      var _this2 = this;

      if (!viewports) {
        return;
      }

      var tileset3d = this.state.tileset3d;
      var timeline = this.context.timeline;
      var viewportsNumber = Object.keys(viewports).length;

      if (!timeline || !viewportsNumber || !tileset3d) {
        return;
      }

      tileset3d.selectTiles(Object.values(viewports)).then(function (frameNumber) {
        var tilesetChanged = _this2.state.frameNumber !== frameNumber;

        if (tilesetChanged) {
          _this2.setState({
            frameNumber: frameNumber
          });
        }
      });
    }
  }, {
    key: "_getSubLayer",
    value: function _getSubLayer(tileHeader, oldLayer) {
      if (!tileHeader.content) {
        return null;
      }

      switch (tileHeader.type) {
        case TILE_TYPE.POINTCLOUD:
          return this._makePointCloudLayer(tileHeader, oldLayer);

        case TILE_TYPE.SCENEGRAPH:
          return this._make3DModelLayer(tileHeader);

        case TILE_TYPE.MESH:
          return this._makeSimpleMeshLayer(tileHeader, oldLayer);

        default:
          throw new Error("Tile3DLayer: Failed to render layer of type ".concat(tileHeader.content.type));
      }
    }
  }, {
    key: "_makePointCloudLayer",
    value: function _makePointCloudLayer(tileHeader, oldLayer) {
      var _tileHeader$content = tileHeader.content,
          attributes = _tileHeader$content.attributes,
          pointCount = _tileHeader$content.pointCount,
          constantRGBA = _tileHeader$content.constantRGBA,
          cartographicOrigin = _tileHeader$content.cartographicOrigin,
          modelMatrix = _tileHeader$content.modelMatrix;
      var positions = attributes.positions,
          normals = attributes.normals,
          colors = attributes.colors;

      if (!positions) {
        return null;
      }

      var data = oldLayer && oldLayer.props.data || {
        header: {
          vertexCount: pointCount
        },
        attributes: {
          POSITION: positions,
          NORMAL: normals,
          COLOR_0: colors
        }
      };
      var _this$props = this.props,
          pointSize = _this$props.pointSize,
          getPointColor = _this$props.getPointColor;
      var SubLayerClass = this.getSubLayerClass('pointcloud', PointCloudLayer);
      return new SubLayerClass({
        pointSize: pointSize
      }, this.getSubLayerProps({
        id: 'pointcloud'
      }), {
        id: "".concat(this.id, "-pointcloud-").concat(tileHeader.id),
        tile: tileHeader,
        data: data,
        coordinateSystem: COORDINATE_SYSTEM.METER_OFFSETS,
        coordinateOrigin: cartographicOrigin,
        modelMatrix: modelMatrix,
        getColor: constantRGBA || getPointColor,
        _offset: 0
      });
    }
  }, {
    key: "_make3DModelLayer",
    value: function _make3DModelLayer(tileHeader) {
      var _tileHeader$content2 = tileHeader.content,
          gltf = _tileHeader$content2.gltf,
          instances = _tileHeader$content2.instances,
          cartographicOrigin = _tileHeader$content2.cartographicOrigin,
          modelMatrix = _tileHeader$content2.modelMatrix;
      var SubLayerClass = this.getSubLayerClass('scenegraph', ScenegraphLayer);
      return new SubLayerClass({
        _lighting: 'pbr'
      }, this.getSubLayerProps({
        id: 'scenegraph'
      }), {
        id: "".concat(this.id, "-scenegraph-").concat(tileHeader.id),
        tile: tileHeader,
        data: instances || SINGLE_DATA,
        scenegraph: gltf,
        coordinateSystem: COORDINATE_SYSTEM.METER_OFFSETS,
        coordinateOrigin: cartographicOrigin,
        modelMatrix: modelMatrix,
        getTransformMatrix: function getTransformMatrix(instance) {
          return instance.modelMatrix;
        },
        getPosition: [0, 0, 0],
        _offset: 0
      });
    }
  }, {
    key: "_makeSimpleMeshLayer",
    value: function _makeSimpleMeshLayer(tileHeader, oldLayer) {
      var content = tileHeader.content;
      var attributes = content.attributes,
          indices = content.indices,
          modelMatrix = content.modelMatrix,
          cartographicOrigin = content.cartographicOrigin,
          _content$coordinateSy = content.coordinateSystem,
          coordinateSystem = _content$coordinateSy === void 0 ? COORDINATE_SYSTEM.METER_OFFSETS : _content$coordinateSy,
          material = content.material,
          featureIds = content.featureIds;
      var _getMeshColor = this.props._getMeshColor;
      var geometry = oldLayer && oldLayer.props.mesh || new Geometry({
        drawMode: 4,
        attributes: getMeshGeometry(attributes),
        indices: indices
      });
      var SubLayerClass = this.getSubLayerClass('mesh', MeshLayer);
      return new SubLayerClass(this.getSubLayerProps({
        id: 'mesh'
      }), {
        id: "".concat(this.id, "-mesh-").concat(tileHeader.id),
        tile: tileHeader,
        mesh: geometry,
        data: SINGLE_DATA,
        getColor: _getMeshColor(tileHeader),
        pbrMaterial: material,
        modelMatrix: modelMatrix,
        coordinateOrigin: cartographicOrigin,
        coordinateSystem: coordinateSystem,
        featureIds: featureIds,
        _offset: 0
      });
    }
  }, {
    key: "renderLayers",
    value: function renderLayers() {
      var _this3 = this;

      var _this$state3 = this.state,
          tileset3d = _this$state3.tileset3d,
          layerMap = _this$state3.layerMap;

      if (!tileset3d) {
        return null;
      }

      return tileset3d.tiles.map(function (tile) {
        var layerCache = layerMap[tile.id] = layerMap[tile.id] || {
          tile: tile
        };
        var layer = layerCache.layer;

        if (tile.selected) {
          if (!layer) {
            layer = _this3._getSubLayer(tile);
          } else if (layerCache.needsUpdate) {
            layer = _this3._getSubLayer(tile, layer);
            layerCache.needsUpdate = false;
          }
        }

        layerCache.layer = layer;
        return layer;
      }).filter(Boolean);
    }
  }]);

  return Tile3DLayer;
}(CompositeLayer);

_defineProperty(Tile3DLayer, "defaultProps", defaultProps);

_defineProperty(Tile3DLayer, "layerName", 'Tile3DLayer');

export { Tile3DLayer as default };

function getMeshGeometry(contentAttributes) {
  var attributes = {};
  attributes.positions = _objectSpread(_objectSpread({}, contentAttributes.positions), {}, {
    value: new Float32Array(contentAttributes.positions.value)
  });

  if (contentAttributes.normals) {
    attributes.normals = contentAttributes.normals;
  }

  if (contentAttributes.texCoords) {
    attributes.texCoords = contentAttributes.texCoords;
  }

  if (contentAttributes.colors) {
    attributes.colors = contentAttributes.colors;
  }

  if (contentAttributes.uvRegions) {
    attributes.uvRegions = contentAttributes.uvRegions;
  }

  return attributes;
}