import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

var LRUCache = function () {
  function LRUCache() {
    var limit = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 5;

    _classCallCheck(this, LRUCache);

    _defineProperty(this, "limit", void 0);

    _defineProperty(this, "_cache", {});

    _defineProperty(this, "_order", []);

    this.limit = limit;
  }

  _createClass(LRUCache, [{
    key: "get",
    value: function get(key) {
      var value = this._cache[key];

      if (value) {
        this._deleteOrder(key);

        this._appendOrder(key);
      }

      return value;
    }
  }, {
    key: "set",
    value: function set(key, value) {
      if (!this._cache[key]) {
        if (Object.keys(this._cache).length === this.limit) {
          this.delete(this._order[0]);
        }

        this._cache[key] = value;

        this._appendOrder(key);
      } else {
        this.delete(key);
        this._cache[key] = value;

        this._appendOrder(key);
      }
    }
  }, {
    key: "delete",
    value: function _delete(key) {
      var value = this._cache[key];

      if (value) {
        delete this._cache[key];

        this._deleteOrder(key);
      }
    }
  }, {
    key: "_deleteOrder",
    value: function _deleteOrder(key) {
      var index = this._order.indexOf(key);

      if (index >= 0) {
        this._order.splice(index, 1);
      }
    }
  }, {
    key: "_appendOrder",
    value: function _appendOrder(key) {
      this._order.push(key);
    }
  }]);

  return LRUCache;
}();

export { LRUCache as default };