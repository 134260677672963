import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _assertThisInitialized from "@babel/runtime/helpers/assertThisInitialized";
import _inherits from "@babel/runtime/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

import { clamp } from '@math.gl/core';
import Controller from './controller';
import { OrbitState } from './orbit-controller';
import LinearInterpolator from '../transitions/linear-interpolator';

var OrthographicState = function (_OrbitState) {
  _inherits(OrthographicState, _OrbitState);

  var _super = _createSuper(OrthographicState);

  function OrthographicState(props) {
    var _this;

    _classCallCheck(this, OrthographicState);

    _this = _super.call(this, props);

    _defineProperty(_assertThisInitialized(_this), "zoomAxis", void 0);

    _this.zoomAxis = props.zoomAxis || 'all';
    return _this;
  }

  _createClass(OrthographicState, [{
    key: "_calculateNewZoom",
    value: function _calculateNewZoom(_ref) {
      var scale = _ref.scale,
          startZoom = _ref.startZoom;

      var _this$getViewportProp = this.getViewportProps(),
          maxZoom = _this$getViewportProp.maxZoom,
          minZoom = _this$getViewportProp.minZoom;

      if (startZoom === undefined) {
        startZoom = this.getViewportProps().zoom;
      }

      var deltaZoom = Math.log2(scale);

      if (Array.isArray(startZoom)) {
        var _startZoom = startZoom,
            _startZoom2 = _slicedToArray(_startZoom, 2),
            newZoomX = _startZoom2[0],
            newZoomY = _startZoom2[1];

        switch (this.zoomAxis) {
          case 'X':
            newZoomX = clamp(newZoomX + deltaZoom, minZoom, maxZoom);
            break;

          case 'Y':
            newZoomY = clamp(newZoomY + deltaZoom, minZoom, maxZoom);
            break;

          default:
            var z = Math.min(newZoomX + deltaZoom, newZoomY + deltaZoom);

            if (z < minZoom) {
              deltaZoom += minZoom - z;
            }

            z = Math.max(newZoomX + deltaZoom, newZoomY + deltaZoom);

            if (z > maxZoom) {
              deltaZoom += maxZoom - z;
            }

            newZoomX += deltaZoom;
            newZoomY += deltaZoom;
        }

        return [newZoomX, newZoomY];
      }

      return clamp(startZoom + deltaZoom, minZoom, maxZoom);
    }
  }]);

  return OrthographicState;
}(OrbitState);

var OrthographicController = function (_Controller) {
  _inherits(OrthographicController, _Controller);

  var _super2 = _createSuper(OrthographicController);

  function OrthographicController() {
    var _this2;

    _classCallCheck(this, OrthographicController);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this2 = _super2.call.apply(_super2, [this].concat(args));

    _defineProperty(_assertThisInitialized(_this2), "ControllerState", OrthographicState);

    _defineProperty(_assertThisInitialized(_this2), "transition", {
      transitionDuration: 300,
      transitionInterpolator: new LinearInterpolator(['target', 'zoom'])
    });

    _defineProperty(_assertThisInitialized(_this2), "dragMode", 'pan');

    return _this2;
  }

  _createClass(OrthographicController, [{
    key: "_onPanRotate",
    value: function _onPanRotate() {
      return false;
    }
  }]);

  return OrthographicController;
}(Controller);

export { OrthographicController as default };