import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { COMPONENT_SYMBOL, PROP_TYPES_SYMBOL, ASYNC_ORIGINAL_SYMBOL, ASYNC_RESOLVED_SYMBOL, ASYNC_DEFAULTS_SYMBOL } from './constants';
import { createProps } from './create-props';
var counter = 0;

var Component = function () {
  function Component() {
    _classCallCheck(this, Component);

    _defineProperty(this, "id", void 0);

    _defineProperty(this, "props", void 0);

    _defineProperty(this, "count", void 0);

    for (var _len = arguments.length, propObjects = new Array(_len), _key = 0; _key < _len; _key++) {
      propObjects[_key] = arguments[_key];
    }

    this.props = createProps(this, propObjects);
    this.id = this.props.id;
    this.count = counter++;
  }

  _createClass(Component, [{
    key: "clone",
    value: function clone(newProps) {
      var props = this.props;
      var asyncProps = {};

      for (var key in props[ASYNC_DEFAULTS_SYMBOL]) {
        if (key in props[ASYNC_RESOLVED_SYMBOL]) {
          asyncProps[key] = props[ASYNC_RESOLVED_SYMBOL][key];
        } else if (key in props[ASYNC_ORIGINAL_SYMBOL]) {
          asyncProps[key] = props[ASYNC_ORIGINAL_SYMBOL][key];
        }
      }

      return new this.constructor(_objectSpread(_objectSpread(_objectSpread({}, props), asyncProps), newProps));
    }
  }]);

  return Component;
}();

_defineProperty(Component, "componentName", 'Component');

_defineProperty(Component, "defaultProps", {});

export { Component as default };