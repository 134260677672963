import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _get from "@babel/runtime/helpers/get";
import _inherits from "@babel/runtime/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

import * as widgets from '@jupyter-widgets/base';
import JupyterTransport from './jupyter-transport';
var JupyterTransportView = null;
var DOMWidgetView = widgets && widgets.DOMWidgetView;

if (DOMWidgetView) {
  var View = function (_DOMWidgetView) {
    _inherits(View, _DOMWidgetView);

    var _super = _createSuper(View);

    function View() {
      _classCallCheck(this, View);

      return _super.apply(this, arguments);
    }

    _createClass(View, [{
      key: "initialize",
      value: function initialize() {
        this.listenTo(this.model, 'destroy', this.remove);
        this.transport = new JupyterTransport();
        this.transport.jupyterModel = this.model;
        this.transport.jupyterView = this;

        this.transport._initialize();

        _get(_getPrototypeOf(View.prototype), "initialize", this).apply(this, arguments);
      }
    }, {
      key: "remove",
      value: function remove() {
        if (this.transport) {
          this.transport._finalize();

          this.transport.jupyterModel = null;
          this.transport.jupyterView = null;
          this.transport = null;
        }
      }
    }, {
      key: "render",
      value: function render() {
        _get(_getPrototypeOf(View.prototype), "render", this).call(this);

        this.model.on('change:json_input', this.onJsonChanged.bind(this));
        this.model.on('change:data_buffer', this.onDataBufferChanged.bind(this));
        this.onDataBufferChanged();
      }
    }, {
      key: "onJsonChanged",
      value: function onJsonChanged() {
        var json = JSON.parse(this.model.get('json_input'));

        this.transport._messageReceived({
          type: 'json',
          json: json
        });
      }
    }, {
      key: "onDataBufferChanged",
      value: function onDataBufferChanged() {
        var json = this.model.get('json_input');
        var dataBuffer = this.model.get('data_buffer');

        if (json && dataBuffer) {
          this.transport._messageReceived({
            type: 'json-with-binary',
            json: json,
            binary: dataBuffer
          });
        } else {
          this.transport._messageReceived({
            type: 'json',
            json: json
          });
        }
      }
    }]);

    return View;
  }(DOMWidgetView);

  JupyterTransportView = View;
}

export default JupyterTransportView;