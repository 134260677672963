import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _assertThisInitialized from "@babel/runtime/helpers/assertThisInitialized";
import _inherits from "@babel/runtime/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

import { CompositeLayer, _flatten as flatten } from '@deck.gl/core';
import { GeoJsonLayer } from '@deck.gl/layers';
import { Tileset2D, STRATEGY_DEFAULT } from '../tileset-2d';
import { urlType, getURLFromTemplate } from '../tileset-2d';
var defaultProps = {
  TilesetClass: Tileset2D,
  data: {
    type: 'data',
    value: []
  },
  dataComparator: urlType.equal,
  renderSubLayers: {
    type: 'function',
    value: function value(props) {
      return new GeoJsonLayer(props);
    }
  },
  getTileData: {
    type: 'function',
    optional: true,
    value: null
  },
  onViewportLoad: {
    type: 'function',
    optional: true,
    value: null
  },
  onTileLoad: {
    type: 'function',
    value: function value(tile) {}
  },
  onTileUnload: {
    type: 'function',
    value: function value(tile) {}
  },
  onTileError: {
    type: 'function',
    value: function value(err) {
      return console.error(err);
    }
  },
  extent: {
    type: 'array',
    optional: true,
    value: null,
    compare: true
  },
  tileSize: 512,
  maxZoom: null,
  minZoom: 0,
  maxCacheSize: null,
  maxCacheByteSize: null,
  refinementStrategy: STRATEGY_DEFAULT,
  zRange: null,
  maxRequests: 6,
  zoomOffset: 0
};

var TileLayer = function (_CompositeLayer) {
  _inherits(TileLayer, _CompositeLayer);

  var _super = _createSuper(TileLayer);

  function TileLayer() {
    var _this;

    _classCallCheck(this, TileLayer);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));

    _defineProperty(_assertThisInitialized(_this), "state", void 0);

    return _this;
  }

  _createClass(TileLayer, [{
    key: "initializeState",
    value: function initializeState() {
      this.state = {
        tileset: null,
        isLoaded: false
      };
    }
  }, {
    key: "finalizeState",
    value: function finalizeState() {
      var _this$state, _this$state$tileset;

      (_this$state = this.state) === null || _this$state === void 0 ? void 0 : (_this$state$tileset = _this$state.tileset) === null || _this$state$tileset === void 0 ? void 0 : _this$state$tileset.finalize();
    }
  }, {
    key: "isLoaded",
    get: function get() {
      var _this$state2, _this$state2$tileset, _this$state2$tileset$;

      return Boolean((_this$state2 = this.state) === null || _this$state2 === void 0 ? void 0 : (_this$state2$tileset = _this$state2.tileset) === null || _this$state2$tileset === void 0 ? void 0 : (_this$state2$tileset$ = _this$state2$tileset.selectedTiles) === null || _this$state2$tileset$ === void 0 ? void 0 : _this$state2$tileset$.every(function (tile) {
        return tile.isLoaded && tile.layers && tile.layers.every(function (layer) {
          return layer.isLoaded;
        });
      }));
    }
  }, {
    key: "shouldUpdateState",
    value: function shouldUpdateState(_ref) {
      var changeFlags = _ref.changeFlags;
      return changeFlags.somethingChanged;
    }
  }, {
    key: "updateState",
    value: function updateState(_ref2) {
      var changeFlags = _ref2.changeFlags;
      var tileset = this.state.tileset;
      var propsChanged = changeFlags.propsOrDataChanged || changeFlags.updateTriggersChanged;
      var dataChanged = changeFlags.dataChanged || changeFlags.updateTriggersChanged && (changeFlags.updateTriggersChanged.all || changeFlags.updateTriggersChanged.getTileData);

      if (!tileset) {
        tileset = new this.props.TilesetClass(this._getTilesetOptions());
        this.setState({
          tileset: tileset
        });
      } else if (propsChanged) {
        tileset.setOptions(this._getTilesetOptions());

        if (dataChanged) {
          tileset.reloadAll();
        } else {
          tileset.tiles.forEach(function (tile) {
            tile.layers = null;
          });
        }
      }

      this._updateTileset();
    }
  }, {
    key: "_getTilesetOptions",
    value: function _getTilesetOptions() {
      var _this$props = this.props,
          tileSize = _this$props.tileSize,
          maxCacheSize = _this$props.maxCacheSize,
          maxCacheByteSize = _this$props.maxCacheByteSize,
          refinementStrategy = _this$props.refinementStrategy,
          extent = _this$props.extent,
          maxZoom = _this$props.maxZoom,
          minZoom = _this$props.minZoom,
          maxRequests = _this$props.maxRequests,
          zoomOffset = _this$props.zoomOffset;
      return {
        maxCacheSize: maxCacheSize,
        maxCacheByteSize: maxCacheByteSize,
        maxZoom: maxZoom,
        minZoom: minZoom,
        tileSize: tileSize,
        refinementStrategy: refinementStrategy,
        extent: extent,
        maxRequests: maxRequests,
        zoomOffset: zoomOffset,
        getTileData: this.getTileData.bind(this),
        onTileLoad: this._onTileLoad.bind(this),
        onTileError: this._onTileError.bind(this),
        onTileUnload: this._onTileUnload.bind(this)
      };
    }
  }, {
    key: "_updateTileset",
    value: function _updateTileset() {
      var tileset = this.state.tileset;
      var _this$props2 = this.props,
          zRange = _this$props2.zRange,
          modelMatrix = _this$props2.modelMatrix;
      var frameNumber = tileset.update(this.context.viewport, {
        zRange: zRange,
        modelMatrix: modelMatrix
      });
      var isLoaded = tileset.isLoaded;
      var loadingStateChanged = this.state.isLoaded !== isLoaded;
      var tilesetChanged = this.state.frameNumber !== frameNumber;

      if (isLoaded && (loadingStateChanged || tilesetChanged)) {
        this._onViewportLoad();
      }

      if (tilesetChanged) {
        this.setState({
          frameNumber: frameNumber
        });
      }

      this.state.isLoaded = isLoaded;
    }
  }, {
    key: "_onViewportLoad",
    value: function _onViewportLoad() {
      var tileset = this.state.tileset;
      var onViewportLoad = this.props.onViewportLoad;

      if (onViewportLoad) {
        onViewportLoad(tileset.selectedTiles);
      }
    }
  }, {
    key: "_onTileLoad",
    value: function _onTileLoad(tile) {
      this.props.onTileLoad(tile);
      tile.layers = null;
      this.setNeedsUpdate();
    }
  }, {
    key: "_onTileError",
    value: function _onTileError(error, tile) {
      this.props.onTileError(error);
      tile.layers = null;
      this.setNeedsUpdate();
    }
  }, {
    key: "_onTileUnload",
    value: function _onTileUnload(tile) {
      this.props.onTileUnload(tile);
    }
  }, {
    key: "getTileData",
    value: function getTileData(tile) {
      var _this$props3 = this.props,
          data = _this$props3.data,
          getTileData = _this$props3.getTileData,
          fetch = _this$props3.fetch;
      var signal = tile.signal;
      tile.url = typeof data === 'string' || Array.isArray(data) ? getURLFromTemplate(data, tile) : null;

      if (getTileData) {
        return getTileData(tile);
      }

      if (fetch && tile.url) {
        return fetch(tile.url, {
          propName: 'data',
          layer: this,
          signal: signal
        });
      }

      return null;
    }
  }, {
    key: "renderSubLayers",
    value: function renderSubLayers(props) {
      return this.props.renderSubLayers(props);
    }
  }, {
    key: "getSubLayerPropsByTile",
    value: function getSubLayerPropsByTile(tile) {
      return null;
    }
  }, {
    key: "getPickingInfo",
    value: function getPickingInfo(_ref3) {
      var info = _ref3.info,
          sourceLayer = _ref3.sourceLayer;
      var sourceTile = sourceLayer.props.tile;

      if (info.picked) {
        info.tile = sourceTile;
      }

      info.sourceTile = sourceTile;
      return info;
    }
  }, {
    key: "_updateAutoHighlight",
    value: function _updateAutoHighlight(info) {
      var sourceTile = info.sourceTile;

      if (sourceTile && sourceTile.layers) {
        var _iterator = _createForOfIteratorHelper(sourceTile.layers),
            _step;

        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var layer = _step.value;
            layer.updateAutoHighlight(info);
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      }
    }
  }, {
    key: "renderLayers",
    value: function renderLayers() {
      var _this2 = this;

      return this.state.tileset.tiles.map(function (tile) {
        var subLayerProps = _this2.getSubLayerPropsByTile(tile);

        if (!tile.isLoaded && !tile.content) {} else if (!tile.layers) {
          var layers = _this2.renderSubLayers(_objectSpread(_objectSpread(_objectSpread({}, _this2.props), _this2.getSubLayerProps({
            id: tile.id,
            updateTriggers: _this2.props.updateTriggers
          })), {}, {
            data: tile.content,
            _offset: 0,
            tile: tile
          }));

          tile.layers = flatten(layers, Boolean).map(function (layer) {
            return layer.clone(_objectSpread({
              tile: tile
            }, subLayerProps));
          });
        } else if (subLayerProps && tile.layers[0] && Object.keys(subLayerProps).some(function (propName) {
          return tile.layers[0].props[propName] !== subLayerProps[propName];
        })) {
          tile.layers = tile.layers.map(function (layer) {
            return layer.clone(subLayerProps);
          });
        }

        return tile.layers;
      });
    }
  }, {
    key: "filterSubLayer",
    value: function filterSubLayer(_ref4) {
      var layer = _ref4.layer,
          cullRect = _ref4.cullRect;
      var tile = layer.props.tile;
      return this.state.tileset.isTileVisible(tile, cullRect);
    }
  }]);

  return TileLayer;
}(CompositeLayer);

_defineProperty(TileLayer, "defaultProps", defaultProps);

_defineProperty(TileLayer, "layerName", 'TileLayer');

export { TileLayer as default };