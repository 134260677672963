import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _inherits from "@babel/runtime/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

import { h3SetToMultiPolygon } from 'h3-js';
import { createIterable } from '@deck.gl/core';
import { default as H3HexagonLayer } from './h3-hexagon-layer';
import GeoCellLayer from '../geo-cell-layer/GeoCellLayer';
var defaultProps = {
  getHexagons: {
    type: 'accessor',
    value: function value(d) {
      return d.hexagons;
    }
  }
};

var H3ClusterLayer = function (_GeoCellLayer) {
  _inherits(H3ClusterLayer, _GeoCellLayer);

  var _super = _createSuper(H3ClusterLayer);

  function H3ClusterLayer() {
    _classCallCheck(this, H3ClusterLayer);

    return _super.apply(this, arguments);
  }

  _createClass(H3ClusterLayer, [{
    key: "initializeState",
    value: function initializeState() {
      H3HexagonLayer._checkH3Lib();
    }
  }, {
    key: "updateState",
    value: function updateState(_ref) {
      var props = _ref.props,
          changeFlags = _ref.changeFlags;

      if (changeFlags.dataChanged || changeFlags.updateTriggersChanged && changeFlags.updateTriggersChanged.getHexagons) {
        var data = props.data,
            getHexagons = props.getHexagons;
        var polygons = [];

        var _createIterable = createIterable(data),
            iterable = _createIterable.iterable,
            objectInfo = _createIterable.objectInfo;

        var _iterator = _createForOfIteratorHelper(iterable),
            _step;

        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var object = _step.value;
            objectInfo.index++;
            var hexagons = getHexagons(object, objectInfo);
            var multiPolygon = h3SetToMultiPolygon(hexagons, true);

            var _iterator2 = _createForOfIteratorHelper(multiPolygon),
                _step2;

            try {
              for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
                var polygon = _step2.value;
                polygons.push(this.getSubLayerRow({
                  polygon: polygon
                }, object, objectInfo.index));
              }
            } catch (err) {
              _iterator2.e(err);
            } finally {
              _iterator2.f();
            }
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }

        this.setState({
          polygons: polygons
        });
      }
    }
  }, {
    key: "indexToBounds",
    value: function indexToBounds() {
      var _this$props = this.props,
          getElevation = _this$props.getElevation,
          getFillColor = _this$props.getFillColor,
          getLineColor = _this$props.getLineColor,
          getLineWidth = _this$props.getLineWidth;
      return {
        data: this.state.polygons,
        getPolygon: function getPolygon(d) {
          return d.polygon;
        },
        getElevation: this.getSubLayerAccessor(getElevation),
        getFillColor: this.getSubLayerAccessor(getFillColor),
        getLineColor: this.getSubLayerAccessor(getLineColor),
        getLineWidth: this.getSubLayerAccessor(getLineWidth)
      };
    }
  }]);

  return H3ClusterLayer;
}(GeoCellLayer);

_defineProperty(H3ClusterLayer, "layerName", 'H3ClusterLayer');

_defineProperty(H3ClusterLayer, "defaultProps", defaultProps);

export { H3ClusterLayer as default };