import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _inherits from "@babel/runtime/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

import Viewport from '../viewports/viewport';
import { Matrix4, clamp } from '@math.gl/core';
import { pixelsToWorld } from '@math.gl/web-mercator';
import * as vec2 from 'gl-matrix/vec2';
var viewMatrix = new Matrix4().lookAt({
  eye: [0, 0, 1]
});

function getProjectionMatrix(_ref) {
  var width = _ref.width,
      height = _ref.height,
      near = _ref.near,
      far = _ref.far,
      padding = _ref.padding;
  var left = -width / 2;
  var right = width / 2;
  var bottom = -height / 2;
  var top = height / 2;

  if (padding) {
    var _padding$left = padding.left,
        l = _padding$left === void 0 ? 0 : _padding$left,
        _padding$right = padding.right,
        r = _padding$right === void 0 ? 0 : _padding$right,
        _padding$top = padding.top,
        t = _padding$top === void 0 ? 0 : _padding$top,
        _padding$bottom = padding.bottom,
        b = _padding$bottom === void 0 ? 0 : _padding$bottom;
    var offsetX = clamp((l + width - r) / 2, 0, width) - width / 2;
    var offsetY = clamp((t + height - b) / 2, 0, height) - height / 2;
    left -= offsetX;
    right -= offsetX;
    bottom += offsetY;
    top += offsetY;
  }

  return new Matrix4().ortho({
    left: left,
    right: right,
    bottom: bottom,
    top: top,
    near: near,
    far: far
  });
}

var OrthographicViewport = function (_Viewport) {
  _inherits(OrthographicViewport, _Viewport);

  var _super = _createSuper(OrthographicViewport);

  function OrthographicViewport(props) {
    _classCallCheck(this, OrthographicViewport);

    var width = props.width,
        height = props.height,
        _props$near = props.near,
        near = _props$near === void 0 ? 0.1 : _props$near,
        _props$far = props.far,
        far = _props$far === void 0 ? 1000 : _props$far,
        _props$zoom = props.zoom,
        zoom = _props$zoom === void 0 ? 0 : _props$zoom,
        _props$target = props.target,
        target = _props$target === void 0 ? [0, 0, 0] : _props$target,
        _props$padding = props.padding,
        padding = _props$padding === void 0 ? null : _props$padding,
        _props$flipY = props.flipY,
        flipY = _props$flipY === void 0 ? true : _props$flipY;
    var zoomX = Array.isArray(zoom) ? zoom[0] : zoom;
    var zoomY = Array.isArray(zoom) ? zoom[1] : zoom;
    var zoom_ = Math.min(zoomX, zoomY);
    var scale = Math.pow(2, zoom_);
    var distanceScales;

    if (zoomX !== zoomY) {
      var scaleX = Math.pow(2, zoomX);
      var scaleY = Math.pow(2, zoomY);
      distanceScales = {
        unitsPerMeter: [scaleX / scale, scaleY / scale, 1],
        metersPerUnit: [scale / scaleX, scale / scaleY, 1]
      };
    }

    return _super.call(this, _objectSpread(_objectSpread({}, props), {}, {
      longitude: undefined,
      position: target,
      viewMatrix: viewMatrix.clone().scale([scale, scale * (flipY ? -1 : 1), scale]),
      projectionMatrix: getProjectionMatrix({
        width: width || 1,
        height: height || 1,
        padding: padding,
        near: near,
        far: far
      }),
      zoom: zoom_,
      distanceScales: distanceScales
    }));
  }

  _createClass(OrthographicViewport, [{
    key: "projectFlat",
    value: function projectFlat(_ref2) {
      var _ref3 = _slicedToArray(_ref2, 2),
          X = _ref3[0],
          Y = _ref3[1];

      var unitsPerMeter = this.distanceScales.unitsPerMeter;
      return [X * unitsPerMeter[0], Y * unitsPerMeter[1]];
    }
  }, {
    key: "unprojectFlat",
    value: function unprojectFlat(_ref4) {
      var _ref5 = _slicedToArray(_ref4, 2),
          x = _ref5[0],
          y = _ref5[1];

      var metersPerUnit = this.distanceScales.metersPerUnit;
      return [x * metersPerUnit[0], y * metersPerUnit[1]];
    }
  }, {
    key: "panByPosition",
    value: function panByPosition(coords, pixel) {
      var fromLocation = pixelsToWorld(pixel, this.pixelUnprojectionMatrix);
      var toLocation = this.projectFlat(coords);
      var translate = vec2.add([], toLocation, vec2.negate([], fromLocation));
      var newCenter = vec2.add([], this.center, translate);
      return {
        target: this.unprojectFlat(newCenter)
      };
    }
  }]);

  return OrthographicViewport;
}(Viewport);

export { OrthographicViewport as default };