import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

export function mergeShaders(target, source) {
  if (!source) {
    return target;
  }

  var result = _objectSpread(_objectSpread({}, target), source);

  if ('defines' in source) {
    result.defines = _objectSpread(_objectSpread({}, target.defines), source.defines);
  }

  if ('modules' in source) {
    result.modules = (target.modules || []).concat(source.modules);

    if (source.modules.some(function (module) {
      return module.name === 'project64';
    })) {
      var index = result.modules.findIndex(function (module) {
        return module.name === 'project32';
      });

      if (index >= 0) {
        result.modules.splice(index, 1);
      }
    }
  }

  if ('inject' in source) {
    if (!target.inject) {
      result.inject = source.inject;
    } else {
      var mergedInjection = _objectSpread({}, target.inject);

      for (var key in source.inject) {
        mergedInjection[key] = (mergedInjection[key] || '') + source.inject[key];
      }

      result.inject = mergedInjection;
    }
  }

  return result;
}