import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _get from "@babel/runtime/helpers/get";
import _inherits from "@babel/runtime/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

import { GridCellLayer } from '@deck.gl/layers';
import { defaultColorRange } from '../utils/color-utils';
import { pointToDensityGridDataCPU } from './grid-aggregator';
import CPUAggregator from '../utils/cpu-aggregator';
import AggregationLayer from '../aggregation-layer';

function nop() {}

var defaultProps = {
  colorDomain: null,
  colorRange: defaultColorRange,
  getColorValue: {
    type: 'accessor',
    value: null
  },
  getColorWeight: {
    type: 'accessor',
    value: 1
  },
  colorAggregation: 'SUM',
  lowerPercentile: {
    type: 'number',
    min: 0,
    max: 100,
    value: 0
  },
  upperPercentile: {
    type: 'number',
    min: 0,
    max: 100,
    value: 100
  },
  colorScaleType: 'quantize',
  onSetColorDomain: nop,
  elevationDomain: null,
  elevationRange: [0, 1000],
  getElevationValue: {
    type: 'accessor',
    value: null
  },
  getElevationWeight: {
    type: 'accessor',
    value: 1
  },
  elevationAggregation: 'SUM',
  elevationLowerPercentile: {
    type: 'number',
    min: 0,
    max: 100,
    value: 0
  },
  elevationUpperPercentile: {
    type: 'number',
    min: 0,
    max: 100,
    value: 100
  },
  elevationScale: {
    type: 'number',
    min: 0,
    value: 1
  },
  elevationScaleType: 'linear',
  onSetElevationDomain: nop,
  gridAggregator: pointToDensityGridDataCPU,
  cellSize: {
    type: 'number',
    min: 0,
    max: 1000,
    value: 1000
  },
  coverage: {
    type: 'number',
    min: 0,
    max: 1,
    value: 1
  },
  getPosition: {
    type: 'accessor',
    value: function value(x) {
      return x.position;
    }
  },
  extruded: false,
  material: true,
  _filterData: {
    type: 'function',
    value: null,
    optional: true
  }
};

var CPUGridLayer = function (_AggregationLayer) {
  _inherits(CPUGridLayer, _AggregationLayer);

  var _super = _createSuper(CPUGridLayer);

  function CPUGridLayer() {
    _classCallCheck(this, CPUGridLayer);

    return _super.apply(this, arguments);
  }

  _createClass(CPUGridLayer, [{
    key: "initializeState",
    value: function initializeState() {
      var cpuAggregator = new CPUAggregator({
        getAggregator: function getAggregator(props) {
          return props.gridAggregator;
        },
        getCellSize: function getCellSize(props) {
          return props.cellSize;
        }
      });
      this.state = {
        cpuAggregator: cpuAggregator,
        aggregatorState: cpuAggregator.state
      };
      var attributeManager = this.getAttributeManager();
      attributeManager.add({
        positions: {
          size: 3,
          type: 5130,
          accessor: 'getPosition'
        }
      });
    }
  }, {
    key: "updateState",
    value: function updateState(opts) {
      _get(_getPrototypeOf(CPUGridLayer.prototype), "updateState", this).call(this, opts);

      this.setState({
        aggregatorState: this.state.cpuAggregator.updateState(opts, {
          viewport: this.context.viewport,
          attributes: this.getAttributes(),
          numInstances: this.getNumInstances()
        })
      });
    }
  }, {
    key: "getPickingInfo",
    value: function getPickingInfo(_ref) {
      var info = _ref.info;
      return this.state.cpuAggregator.getPickingInfo({
        info: info
      });
    }
  }, {
    key: "_onGetSublayerColor",
    value: function _onGetSublayerColor(cell) {
      return this.state.cpuAggregator.getAccessor('fillColor')(cell);
    }
  }, {
    key: "_onGetSublayerElevation",
    value: function _onGetSublayerElevation(cell) {
      return this.state.cpuAggregator.getAccessor('elevation')(cell);
    }
  }, {
    key: "_getSublayerUpdateTriggers",
    value: function _getSublayerUpdateTriggers() {
      return this.state.cpuAggregator.getUpdateTriggers(this.props);
    }
  }, {
    key: "renderLayers",
    value: function renderLayers() {
      var _this$props = this.props,
          elevationScale = _this$props.elevationScale,
          extruded = _this$props.extruded,
          cellSize = _this$props.cellSize,
          coverage = _this$props.coverage,
          material = _this$props.material,
          transitions = _this$props.transitions;
      var cpuAggregator = this.state.cpuAggregator;
      var SubLayerClass = this.getSubLayerClass('grid-cell', GridCellLayer);

      var updateTriggers = this._getSublayerUpdateTriggers();

      return new SubLayerClass({
        cellSize: cellSize,
        coverage: coverage,
        material: material,
        elevationScale: elevationScale,
        extruded: extruded,
        getFillColor: this._onGetSublayerColor.bind(this),
        getElevation: this._onGetSublayerElevation.bind(this),
        transitions: transitions && {
          getFillColor: transitions.getColorValue || transitions.getColorWeight,
          getElevation: transitions.getElevationValue || transitions.getElevationWeight
        }
      }, this.getSubLayerProps({
        id: 'grid-cell',
        updateTriggers: updateTriggers
      }), {
        data: cpuAggregator.state.layerData.data
      });
    }
  }]);

  return CPUGridLayer;
}(AggregationLayer);

_defineProperty(CPUGridLayer, "layerName", 'CPUGridLayer');

_defineProperty(CPUGridLayer, "defaultProps", defaultProps);

export { CPUGridLayer as default };