function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import { COORDINATE_SYSTEM, createIterable } from '@deck.gl/core';
var RADIAN_PER_DEGREE = Math.PI / 180;
var modelMatrix = new Float32Array(16);
var valueArray = new Float32Array(12);

function calculateTransformMatrix(targetMatrix, orientation, scale) {
  var pitch = orientation[0] * RADIAN_PER_DEGREE;
  var yaw = orientation[1] * RADIAN_PER_DEGREE;
  var roll = orientation[2] * RADIAN_PER_DEGREE;
  var sr = Math.sin(roll);
  var sp = Math.sin(pitch);
  var sw = Math.sin(yaw);
  var cr = Math.cos(roll);
  var cp = Math.cos(pitch);
  var cw = Math.cos(yaw);
  var scx = scale[0];
  var scy = scale[1];
  var scz = scale[2];
  targetMatrix[0] = scx * cw * cp;
  targetMatrix[1] = scx * sw * cp;
  targetMatrix[2] = scx * -sp;
  targetMatrix[3] = scy * (-sw * cr + cw * sp * sr);
  targetMatrix[4] = scy * (cw * cr + sw * sp * sr);
  targetMatrix[5] = scy * cp * sr;
  targetMatrix[6] = scz * (sw * sr + cw * sp * cr);
  targetMatrix[7] = scz * (-cw * sr + sw * sp * cr);
  targetMatrix[8] = scz * cp * cr;
}

function getExtendedMat3FromMat4(mat4) {
  mat4[0] = mat4[0];
  mat4[1] = mat4[1];
  mat4[2] = mat4[2];
  mat4[3] = mat4[4];
  mat4[4] = mat4[5];
  mat4[5] = mat4[6];
  mat4[6] = mat4[8];
  mat4[7] = mat4[9];
  mat4[8] = mat4[10];
  mat4[9] = mat4[12];
  mat4[10] = mat4[13];
  mat4[11] = mat4[14];
  return mat4.subarray(0, 12);
}

export var MATRIX_ATTRIBUTES = {
  size: 12,
  accessor: ['getOrientation', 'getScale', 'getTranslation', 'getTransformMatrix'],
  shaderAttributes: {
    instanceModelMatrix__LOCATION_0: {
      size: 3,
      elementOffset: 0
    },
    instanceModelMatrix__LOCATION_1: {
      size: 3,
      elementOffset: 3
    },
    instanceModelMatrix__LOCATION_2: {
      size: 3,
      elementOffset: 6
    },
    instanceTranslation: {
      size: 3,
      elementOffset: 9
    }
  },
  update: function update(attribute, _ref) {
    var startRow = _ref.startRow,
        endRow = _ref.endRow;
    var _this$props = this.props,
        data = _this$props.data,
        getOrientation = _this$props.getOrientation,
        getScale = _this$props.getScale,
        getTranslation = _this$props.getTranslation,
        getTransformMatrix = _this$props.getTransformMatrix;
    var arrayMatrix = Array.isArray(getTransformMatrix);
    var constantMatrix = arrayMatrix && getTransformMatrix.length === 16;
    var constantScale = Array.isArray(getScale);
    var constantOrientation = Array.isArray(getOrientation);
    var constantTranslation = Array.isArray(getTranslation);
    var hasMatrix = constantMatrix || !arrayMatrix && Boolean(getTransformMatrix(data[0]));

    if (hasMatrix) {
      attribute.constant = constantMatrix;
    } else {
      attribute.constant = constantOrientation && constantScale && constantTranslation;
    }

    var instanceModelMatrixData = attribute.value;

    if (attribute.constant) {
      var matrix;

      if (hasMatrix) {
        modelMatrix.set(getTransformMatrix);
        matrix = getExtendedMat3FromMat4(modelMatrix);
      } else {
        matrix = valueArray;
        var orientation = getOrientation;
        var scale = getScale;
        calculateTransformMatrix(matrix, orientation, scale);
        matrix.set(getTranslation, 9);
      }

      attribute.value = new Float32Array(matrix);
    } else {
      var i = startRow * attribute.size;

      var _createIterable = createIterable(data, startRow, endRow),
          iterable = _createIterable.iterable,
          objectInfo = _createIterable.objectInfo;

      var _iterator = _createForOfIteratorHelper(iterable),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var object = _step.value;
          objectInfo.index++;

          var _matrix = void 0;

          if (hasMatrix) {
            modelMatrix.set(constantMatrix ? getTransformMatrix : getTransformMatrix(object, objectInfo));
            _matrix = getExtendedMat3FromMat4(modelMatrix);
          } else {
            _matrix = valueArray;

            var _orientation = constantOrientation ? getOrientation : getOrientation(object, objectInfo);

            var _scale = constantScale ? getScale : getScale(object, objectInfo);

            calculateTransformMatrix(_matrix, _orientation, _scale);

            _matrix.set(constantTranslation ? getTranslation : getTranslation(object, objectInfo), 9);
          }

          instanceModelMatrixData[i++] = _matrix[0];
          instanceModelMatrixData[i++] = _matrix[1];
          instanceModelMatrixData[i++] = _matrix[2];
          instanceModelMatrixData[i++] = _matrix[3];
          instanceModelMatrixData[i++] = _matrix[4];
          instanceModelMatrixData[i++] = _matrix[5];
          instanceModelMatrixData[i++] = _matrix[6];
          instanceModelMatrixData[i++] = _matrix[7];
          instanceModelMatrixData[i++] = _matrix[8];
          instanceModelMatrixData[i++] = _matrix[9];
          instanceModelMatrixData[i++] = _matrix[10];
          instanceModelMatrixData[i++] = _matrix[11];
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    }
  }
};
export function shouldComposeModelMatrix(viewport, coordinateSystem) {
  return coordinateSystem === COORDINATE_SYSTEM.CARTESIAN || coordinateSystem === COORDINATE_SYSTEM.METER_OFFSETS || coordinateSystem === COORDINATE_SYSTEM.DEFAULT && !viewport.isGeospatial;
}