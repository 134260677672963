import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _typeof from "@babel/runtime/helpers/typeof";
import parseExpressionString from './helpers/parse-expression-string';
import assert from './utils/assert';
import { TYPE_KEY, FUNCTION_KEY } from './syntactic-sugar';

var isObject = function isObject(value) {
  return value && _typeof(value) === 'object';
};

var JSONConfiguration = function () {
  function JSONConfiguration() {
    _classCallCheck(this, JSONConfiguration);

    this.typeKey = TYPE_KEY;
    this.functionKey = FUNCTION_KEY;
    this.log = console;
    this.classes = {};
    this.reactComponents = {};
    this.enumerations = {};
    this.constants = {};
    this.functions = {};
    this.React = null;
    this.convertFunction = parseExpressionString;

    this.preProcessClassProps = function (Class, props) {
      return props;
    };

    this.postProcessConvertedJson = function (json) {
      return json;
    };

    for (var _len = arguments.length, configurations = new Array(_len), _key = 0; _key < _len; _key++) {
      configurations[_key] = arguments[_key];
    }

    for (var _i = 0, _configurations = configurations; _i < _configurations.length; _i++) {
      var configuration = _configurations[_i];
      this.merge(configuration);
    }
  }

  _createClass(JSONConfiguration, [{
    key: "merge",
    value: function merge(configuration) {
      for (var key in configuration) {
        switch (key) {
          case 'layers':
          case 'views':
            Object.assign(this.classes, configuration[key]);
            break;

          default:
            if (key in this) {
              var value = configuration[key];
              this[key] = isObject(this[key]) ? Object.assign(this[key], value) : value;
            }

        }
      }
    }
  }, {
    key: "validate",
    value: function validate(configuration) {
      assert(!this.typeKey || typeof this.typeKey === 'string');
      assert(isObject(this.classes));
      return true;
    }
  }]);

  return JSONConfiguration;
}();

export { JSONConfiguration as default };