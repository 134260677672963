import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _assertThisInitialized from "@babel/runtime/helpers/assertThisInitialized";
import _get from "@babel/runtime/helpers/get";
import _inherits from "@babel/runtime/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

import { CompositeLayer, AttributeManager, _compareProps as compareProps } from '@deck.gl/core';
import { cssToDeviceRatio } from '@luma.gl/core';
import { filterProps } from './utils/prop-utils';

var AggregationLayer = function (_CompositeLayer) {
  _inherits(AggregationLayer, _CompositeLayer);

  var _super = _createSuper(AggregationLayer);

  function AggregationLayer() {
    var _this;

    _classCallCheck(this, AggregationLayer);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));

    _defineProperty(_assertThisInitialized(_this), "state", void 0);

    return _this;
  }

  _createClass(AggregationLayer, [{
    key: "initializeAggregationLayer",
    value: function initializeAggregationLayer(dimensions) {
      _get(_getPrototypeOf(AggregationLayer.prototype), "initializeState", this).call(this, this.context);

      this.setState({
        ignoreProps: filterProps(this.constructor._propTypes, dimensions.data.props),
        dimensions: dimensions
      });
    }
  }, {
    key: "updateState",
    value: function updateState(opts) {
      _get(_getPrototypeOf(AggregationLayer.prototype), "updateState", this).call(this, opts);

      var changeFlags = opts.changeFlags;

      if (changeFlags.extensionsChanged) {
        var shaders = this.getShaders({});

        if (shaders && shaders.defines) {
          shaders.defines.NON_INSTANCED_MODEL = 1;
        }

        this.updateShaders(shaders);
      }

      this._updateAttributes();
    }
  }, {
    key: "updateAttributes",
    value: function updateAttributes(changedAttributes) {
      this.setState({
        changedAttributes: changedAttributes
      });
    }
  }, {
    key: "getAttributes",
    value: function getAttributes() {
      return this.getAttributeManager().getShaderAttributes();
    }
  }, {
    key: "getModuleSettings",
    value: function getModuleSettings() {
      var _this$context = this.context,
          viewport = _this$context.viewport,
          mousePosition = _this$context.mousePosition,
          gl = _this$context.gl;
      var moduleSettings = Object.assign(Object.create(this.props), {
        viewport: viewport,
        mousePosition: mousePosition,
        pickingActive: 0,
        devicePixelRatio: cssToDeviceRatio(gl)
      });
      return moduleSettings;
    }
  }, {
    key: "updateShaders",
    value: function updateShaders(shaders) {}
  }, {
    key: "isAggregationDirty",
    value: function isAggregationDirty(updateOpts) {
      var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var props = updateOpts.props,
          oldProps = updateOpts.oldProps,
          changeFlags = updateOpts.changeFlags;
      var _params$compareAll = params.compareAll,
          compareAll = _params$compareAll === void 0 ? false : _params$compareAll,
          dimension = params.dimension;
      var ignoreProps = this.state.ignoreProps;
      var dataProps = dimension.props,
          _dimension$accessors = dimension.accessors,
          accessors = _dimension$accessors === void 0 ? [] : _dimension$accessors;
      var updateTriggersChanged = changeFlags.updateTriggersChanged;

      if (changeFlags.dataChanged) {
        return true;
      }

      if (updateTriggersChanged) {
        if (updateTriggersChanged.all) {
          return true;
        }

        var _iterator = _createForOfIteratorHelper(accessors),
            _step;

        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var accessor = _step.value;

            if (updateTriggersChanged[accessor]) {
              return true;
            }
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      }

      if (compareAll) {
        if (changeFlags.extensionsChanged) {
          return true;
        }

        return compareProps({
          oldProps: oldProps,
          newProps: props,
          ignoreProps: ignoreProps,
          propTypes: this.constructor._propTypes
        });
      }

      var _iterator2 = _createForOfIteratorHelper(dataProps),
          _step2;

      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var name = _step2.value;

          if (props[name] !== oldProps[name]) {
            return true;
          }
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }

      return false;
    }
  }, {
    key: "isAttributeChanged",
    value: function isAttributeChanged(name) {
      var changedAttributes = this.state.changedAttributes;

      if (!name) {
        return !isObjectEmpty(changedAttributes);
      }

      return changedAttributes && changedAttributes[name] !== undefined;
    }
  }, {
    key: "_getAttributeManager",
    value: function _getAttributeManager() {
      return new AttributeManager(this.context.gl, {
        id: this.props.id,
        stats: this.context.stats
      });
    }
  }]);

  return AggregationLayer;
}(CompositeLayer);

_defineProperty(AggregationLayer, "layerName", 'AggregationLayer');

export { AggregationLayer as default };

function isObjectEmpty(obj) {
  var isEmpty = true;

  for (var key in obj) {
    isEmpty = false;
    break;
  }

  return isEmpty;
}