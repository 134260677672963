import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _assertThisInitialized from "@babel/runtime/helpers/assertThisInitialized";
import _get from "@babel/runtime/helpers/get";
import _inherits from "@babel/runtime/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

import { log } from '@deck.gl/core';
import { ColumnLayer } from '@deck.gl/layers';
import { defaultColorRange } from '../utils/color-utils';
import { pointToHexbin } from './hexagon-aggregator';
import CPUAggregator from '../utils/cpu-aggregator';
import AggregationLayer from '../aggregation-layer';

function nop() {}

var defaultProps = {
  colorDomain: null,
  colorRange: defaultColorRange,
  getColorValue: {
    type: 'accessor',
    value: null
  },
  getColorWeight: {
    type: 'accessor',
    value: 1
  },
  colorAggregation: 'SUM',
  lowerPercentile: {
    type: 'number',
    value: 0,
    min: 0,
    max: 100
  },
  upperPercentile: {
    type: 'number',
    value: 100,
    min: 0,
    max: 100
  },
  colorScaleType: 'quantize',
  onSetColorDomain: nop,
  elevationDomain: null,
  elevationRange: [0, 1000],
  getElevationValue: {
    type: 'accessor',
    value: null
  },
  getElevationWeight: {
    type: 'accessor',
    value: 1
  },
  elevationAggregation: 'SUM',
  elevationLowerPercentile: {
    type: 'number',
    value: 0,
    min: 0,
    max: 100
  },
  elevationUpperPercentile: {
    type: 'number',
    value: 100,
    min: 0,
    max: 100
  },
  elevationScale: {
    type: 'number',
    min: 0,
    value: 1
  },
  elevationScaleType: 'linear',
  onSetElevationDomain: nop,
  radius: {
    type: 'number',
    value: 1000,
    min: 1
  },
  coverage: {
    type: 'number',
    min: 0,
    max: 1,
    value: 1
  },
  extruded: false,
  hexagonAggregator: pointToHexbin,
  getPosition: {
    type: 'accessor',
    value: function value(x) {
      return x.position;
    }
  },
  material: true,
  _filterData: {
    type: 'function',
    value: null,
    optional: true
  }
};

var HexagonLayer = function (_AggregationLayer) {
  _inherits(HexagonLayer, _AggregationLayer);

  var _super = _createSuper(HexagonLayer);

  function HexagonLayer() {
    var _this;

    _classCallCheck(this, HexagonLayer);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));

    _defineProperty(_assertThisInitialized(_this), "state", void 0);

    return _this;
  }

  _createClass(HexagonLayer, [{
    key: "initializeState",
    value: function initializeState() {
      var cpuAggregator = new CPUAggregator({
        getAggregator: function getAggregator(props) {
          return props.hexagonAggregator;
        },
        getCellSize: function getCellSize(props) {
          return props.radius;
        }
      });
      this.state = {
        cpuAggregator: cpuAggregator,
        aggregatorState: cpuAggregator.state,
        vertices: null
      };
      var attributeManager = this.getAttributeManager();
      attributeManager.add({
        positions: {
          size: 3,
          type: 5130,
          accessor: 'getPosition'
        }
      });
    }
  }, {
    key: "updateState",
    value: function updateState(opts) {
      _get(_getPrototypeOf(HexagonLayer.prototype), "updateState", this).call(this, opts);

      if (opts.changeFlags.propsOrDataChanged) {
        var aggregatorState = this.state.cpuAggregator.updateState(opts, {
          viewport: this.context.viewport,
          attributes: this.getAttributes()
        });

        if (this.state.aggregatorState.layerData !== aggregatorState.layerData) {
          var _ref = aggregatorState.layerData || {},
              hexagonVertices = _ref.hexagonVertices;

          this.setState({
            vertices: hexagonVertices && this.convertLatLngToMeterOffset(hexagonVertices)
          });
        }

        this.setState({
          aggregatorState: aggregatorState
        });
      }
    }
  }, {
    key: "convertLatLngToMeterOffset",
    value: function convertLatLngToMeterOffset(hexagonVertices) {
      var viewport = this.context.viewport;

      if (Array.isArray(hexagonVertices) && hexagonVertices.length === 6) {
        var vertex0 = hexagonVertices[0];
        var vertex3 = hexagonVertices[3];
        var centroid = [(vertex0[0] + vertex3[0]) / 2, (vertex0[1] + vertex3[1]) / 2];
        var centroidFlat = viewport.projectFlat(centroid);

        var _viewport$getDistance = viewport.getDistanceScales(centroid),
            metersPerUnit = _viewport$getDistance.metersPerUnit;

        var vertices = hexagonVertices.map(function (vt) {
          var vtFlat = viewport.projectFlat(vt);
          return [(vtFlat[0] - centroidFlat[0]) * metersPerUnit[0], (vtFlat[1] - centroidFlat[1]) * metersPerUnit[1]];
        });
        return vertices;
      }

      log.error('HexagonLayer: hexagonVertices needs to be an array of 6 points')();
      return null;
    }
  }, {
    key: "getPickingInfo",
    value: function getPickingInfo(_ref2) {
      var info = _ref2.info;
      return this.state.cpuAggregator.getPickingInfo({
        info: info
      });
    }
  }, {
    key: "_onGetSublayerColor",
    value: function _onGetSublayerColor(cell) {
      return this.state.cpuAggregator.getAccessor('fillColor')(cell);
    }
  }, {
    key: "_onGetSublayerElevation",
    value: function _onGetSublayerElevation(cell) {
      return this.state.cpuAggregator.getAccessor('elevation')(cell);
    }
  }, {
    key: "_getSublayerUpdateTriggers",
    value: function _getSublayerUpdateTriggers() {
      return this.state.cpuAggregator.getUpdateTriggers(this.props);
    }
  }, {
    key: "renderLayers",
    value: function renderLayers() {
      var _this$props = this.props,
          elevationScale = _this$props.elevationScale,
          extruded = _this$props.extruded,
          coverage = _this$props.coverage,
          material = _this$props.material,
          transitions = _this$props.transitions;
      var _this$state = this.state,
          aggregatorState = _this$state.aggregatorState,
          vertices = _this$state.vertices;
      var SubLayerClass = this.getSubLayerClass('hexagon-cell', ColumnLayer);

      var updateTriggers = this._getSublayerUpdateTriggers();

      var geometry = vertices ? {
        vertices: vertices,
        radius: 1
      } : {
        radius: aggregatorState.layerData.radiusCommon || 1,
        radiusUnits: 'common',
        angle: 90
      };
      return new SubLayerClass(_objectSpread(_objectSpread({}, geometry), {}, {
        diskResolution: 6,
        elevationScale: elevationScale,
        extruded: extruded,
        coverage: coverage,
        material: material,
        getFillColor: this._onGetSublayerColor.bind(this),
        getElevation: this._onGetSublayerElevation.bind(this),
        transitions: transitions && {
          getFillColor: transitions.getColorValue || transitions.getColorWeight,
          getElevation: transitions.getElevationValue || transitions.getElevationWeight
        }
      }), this.getSubLayerProps({
        id: 'hexagon-cell',
        updateTriggers: updateTriggers
      }), {
        data: aggregatorState.layerData.data
      });
    }
  }]);

  return HexagonLayer;
}(AggregationLayer);

_defineProperty(HexagonLayer, "layerName", 'HexagonLayer');

_defineProperty(HexagonLayer, "defaultProps", defaultProps);

export { HexagonLayer as default };