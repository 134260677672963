import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _assertThisInitialized from "@babel/runtime/helpers/assertThisInitialized";
import _get from "@babel/runtime/helpers/get";
import _inherits from "@babel/runtime/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

import Viewport from './viewport';
import { pixelsToWorld, getViewMatrix, addMetersToLngLat as _addMetersToLngLat, unitsPerMeter, getProjectionParameters, altitudeToFovy, fovyToAltitude, fitBounds as _fitBounds, getBounds as _getBounds } from '@math.gl/web-mercator';
import * as vec2 from 'gl-matrix/vec2';
import { Matrix4, clamp } from '@math.gl/core';

var WebMercatorViewport = function (_Viewport) {
  _inherits(WebMercatorViewport, _Viewport);

  var _super = _createSuper(WebMercatorViewport);

  function WebMercatorViewport() {
    var _this;

    var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    _classCallCheck(this, WebMercatorViewport);

    var _opts$latitude = opts.latitude,
        latitude = _opts$latitude === void 0 ? 0 : _opts$latitude,
        _opts$longitude = opts.longitude,
        longitude = _opts$longitude === void 0 ? 0 : _opts$longitude,
        _opts$zoom = opts.zoom,
        zoom = _opts$zoom === void 0 ? 0 : _opts$zoom,
        _opts$pitch = opts.pitch,
        pitch = _opts$pitch === void 0 ? 0 : _opts$pitch,
        _opts$bearing = opts.bearing,
        bearing = _opts$bearing === void 0 ? 0 : _opts$bearing,
        _opts$nearZMultiplier = opts.nearZMultiplier,
        nearZMultiplier = _opts$nearZMultiplier === void 0 ? 0.1 : _opts$nearZMultiplier,
        _opts$farZMultiplier = opts.farZMultiplier,
        farZMultiplier = _opts$farZMultiplier === void 0 ? 1.01 : _opts$farZMultiplier,
        nearZ = opts.nearZ,
        farZ = opts.farZ,
        _opts$orthographic = opts.orthographic,
        orthographic = _opts$orthographic === void 0 ? false : _opts$orthographic,
        projectionMatrix = opts.projectionMatrix,
        _opts$repeat = opts.repeat,
        repeat = _opts$repeat === void 0 ? false : _opts$repeat,
        _opts$worldOffset = opts.worldOffset,
        worldOffset = _opts$worldOffset === void 0 ? 0 : _opts$worldOffset,
        position = opts.position,
        padding = opts.padding,
        _opts$legacyMeterSize = opts.legacyMeterSizes,
        legacyMeterSizes = _opts$legacyMeterSize === void 0 ? false : _opts$legacyMeterSize;
    var width = opts.width,
        height = opts.height,
        _opts$altitude = opts.altitude,
        altitude = _opts$altitude === void 0 ? 1.5 : _opts$altitude;
    var scale = Math.pow(2, zoom);
    width = width || 1;
    height = height || 1;
    var fovy;
    var projectionParameters = null;

    if (projectionMatrix) {
      altitude = projectionMatrix[5] / 2;
      fovy = altitudeToFovy(altitude);
    } else {
      if (opts.fovy) {
        fovy = opts.fovy;
        altitude = fovyToAltitude(fovy);
      } else {
        fovy = altitudeToFovy(altitude);
      }

      var offset;

      if (padding) {
        var _padding$top = padding.top,
            top = _padding$top === void 0 ? 0 : _padding$top,
            _padding$bottom = padding.bottom,
            bottom = _padding$bottom === void 0 ? 0 : _padding$bottom;
        offset = [0, clamp((top + height - bottom) / 2, 0, height) - height / 2];
      }

      projectionParameters = getProjectionParameters({
        width: width,
        height: height,
        scale: scale,
        center: position && [0, 0, position[2] * unitsPerMeter(latitude)],
        offset: offset,
        pitch: pitch,
        fovy: fovy,
        nearZMultiplier: nearZMultiplier,
        farZMultiplier: farZMultiplier
      });

      if (Number.isFinite(nearZ)) {
        projectionParameters.near = nearZ;
      }

      if (Number.isFinite(farZ)) {
        projectionParameters.far = farZ;
      }
    }

    var viewMatrixUncentered = getViewMatrix({
      height: height,
      pitch: pitch,
      bearing: bearing,
      scale: scale,
      altitude: altitude
    });

    if (worldOffset) {
      var viewOffset = new Matrix4().translate([512 * worldOffset, 0, 0]);
      viewMatrixUncentered = viewOffset.multiplyLeft(viewMatrixUncentered);
    }

    _this = _super.call(this, _objectSpread(_objectSpread(_objectSpread({}, opts), {}, {
      width: width,
      height: height,
      viewMatrix: viewMatrixUncentered,
      longitude: longitude,
      latitude: latitude,
      zoom: zoom
    }, projectionParameters), {}, {
      fovy: fovy,
      focalDistance: altitude
    }));

    _defineProperty(_assertThisInitialized(_this), "longitude", void 0);

    _defineProperty(_assertThisInitialized(_this), "latitude", void 0);

    _defineProperty(_assertThisInitialized(_this), "pitch", void 0);

    _defineProperty(_assertThisInitialized(_this), "bearing", void 0);

    _defineProperty(_assertThisInitialized(_this), "altitude", void 0);

    _defineProperty(_assertThisInitialized(_this), "fovy", void 0);

    _defineProperty(_assertThisInitialized(_this), "orthographic", void 0);

    _defineProperty(_assertThisInitialized(_this), "_subViewports", void 0);

    _defineProperty(_assertThisInitialized(_this), "_pseudoMeters", void 0);

    _this.latitude = latitude;
    _this.longitude = longitude;
    _this.zoom = zoom;
    _this.pitch = pitch;
    _this.bearing = bearing;
    _this.altitude = altitude;
    _this.fovy = fovy;
    _this.orthographic = orthographic;
    _this._subViewports = repeat ? [] : null;
    _this._pseudoMeters = legacyMeterSizes;
    Object.freeze(_assertThisInitialized(_this));
    return _this;
  }

  _createClass(WebMercatorViewport, [{
    key: "subViewports",
    get: function get() {
      if (this._subViewports && !this._subViewports.length) {
        var bounds = this.getBounds();
        var minOffset = Math.floor((bounds[0] + 180) / 360);
        var maxOffset = Math.ceil((bounds[2] - 180) / 360);

        for (var x = minOffset; x <= maxOffset; x++) {
          var offsetViewport = x ? new WebMercatorViewport(_objectSpread(_objectSpread({}, this), {}, {
            worldOffset: x
          })) : this;

          this._subViewports.push(offsetViewport);
        }
      }

      return this._subViewports;
    }
  }, {
    key: "projectPosition",
    value: function projectPosition(xyz) {
      if (this._pseudoMeters) {
        return _get(_getPrototypeOf(WebMercatorViewport.prototype), "projectPosition", this).call(this, xyz);
      }

      var _this$projectFlat = this.projectFlat(xyz),
          _this$projectFlat2 = _slicedToArray(_this$projectFlat, 2),
          X = _this$projectFlat2[0],
          Y = _this$projectFlat2[1];

      var Z = (xyz[2] || 0) * unitsPerMeter(xyz[1]);
      return [X, Y, Z];
    }
  }, {
    key: "unprojectPosition",
    value: function unprojectPosition(xyz) {
      if (this._pseudoMeters) {
        return _get(_getPrototypeOf(WebMercatorViewport.prototype), "unprojectPosition", this).call(this, xyz);
      }

      var _this$unprojectFlat = this.unprojectFlat(xyz),
          _this$unprojectFlat2 = _slicedToArray(_this$unprojectFlat, 2),
          X = _this$unprojectFlat2[0],
          Y = _this$unprojectFlat2[1];

      var Z = (xyz[2] || 0) / unitsPerMeter(Y);
      return [X, Y, Z];
    }
  }, {
    key: "addMetersToLngLat",
    value: function addMetersToLngLat(lngLatZ, xyz) {
      return _addMetersToLngLat(lngLatZ, xyz);
    }
  }, {
    key: "panByPosition",
    value: function panByPosition(coords, pixel) {
      var fromLocation = pixelsToWorld(pixel, this.pixelUnprojectionMatrix);
      var toLocation = this.projectFlat(coords);
      var translate = vec2.add([], toLocation, vec2.negate([], fromLocation));
      var newCenter = vec2.add([], this.center, translate);

      var _this$unprojectFlat3 = this.unprojectFlat(newCenter),
          _this$unprojectFlat4 = _slicedToArray(_this$unprojectFlat3, 2),
          longitude = _this$unprojectFlat4[0],
          latitude = _this$unprojectFlat4[1];

      return {
        longitude: longitude,
        latitude: latitude
      };
    }
  }, {
    key: "getBounds",
    value: function getBounds() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var corners = _getBounds(this, options.z || 0);

      return [Math.min(corners[0][0], corners[1][0], corners[2][0], corners[3][0]), Math.min(corners[0][1], corners[1][1], corners[2][1], corners[3][1]), Math.max(corners[0][0], corners[1][0], corners[2][0], corners[3][0]), Math.max(corners[0][1], corners[1][1], corners[2][1], corners[3][1])];
    }
  }, {
    key: "fitBounds",
    value: function fitBounds(bounds) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var width = this.width,
          height = this.height;

      var _fitBounds2 = _fitBounds(_objectSpread({
        width: width,
        height: height,
        bounds: bounds
      }, options)),
          longitude = _fitBounds2.longitude,
          latitude = _fitBounds2.latitude,
          zoom = _fitBounds2.zoom;

      return new WebMercatorViewport({
        width: width,
        height: height,
        longitude: longitude,
        latitude: latitude,
        zoom: zoom
      });
    }
  }]);

  return WebMercatorViewport;
}(Viewport);

_defineProperty(WebMercatorViewport, "displayName", 'WebMercatorViewport');

export { WebMercatorViewport as default };