import _asyncToGenerator from "@babel/runtime/helpers/asyncToGenerator";
import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _createClass from "@babel/runtime/helpers/createClass";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _regeneratorRuntime from "@babel/runtime/regenerator";
export var Tile2DHeader = function () {
  function Tile2DHeader(index) {
    _classCallCheck(this, Tile2DHeader);

    _defineProperty(this, "index", void 0);

    _defineProperty(this, "isVisible", void 0);

    _defineProperty(this, "isSelected", void 0);

    _defineProperty(this, "parent", void 0);

    _defineProperty(this, "children", void 0);

    _defineProperty(this, "content", void 0);

    _defineProperty(this, "state", void 0);

    _defineProperty(this, "layers", void 0);

    _defineProperty(this, "id", void 0);

    _defineProperty(this, "zoom", void 0);

    _defineProperty(this, "userData", void 0);

    _defineProperty(this, "boundingBox", void 0);

    _defineProperty(this, "_abortController", void 0);

    _defineProperty(this, "_loader", void 0);

    _defineProperty(this, "_loaderId", void 0);

    _defineProperty(this, "_isLoaded", void 0);

    _defineProperty(this, "_isCancelled", void 0);

    _defineProperty(this, "_needsReload", void 0);

    _defineProperty(this, "_bbox", void 0);

    this.index = index;
    this.isVisible = false;
    this.isSelected = false;
    this.parent = null;
    this.children = [];
    this.content = null;
    this._loader = undefined;
    this._abortController = null;
    this._loaderId = 0;
    this._isLoaded = false;
    this._isCancelled = false;
    this._needsReload = false;
  }

  _createClass(Tile2DHeader, [{
    key: "bbox",
    get: function get() {
      return this._bbox;
    },
    set: function set(value) {
      if (this._bbox) return;
      this._bbox = value;

      if ('west' in value) {
        this.boundingBox = [[value.west, value.south], [value.east, value.north]];
      } else {
        this.boundingBox = [[value.left, value.top], [value.right, value.bottom]];
      }
    }
  }, {
    key: "data",
    get: function get() {
      var _this = this;

      return this.isLoading && this._loader ? this._loader.then(function () {
        return _this.data;
      }) : this.content;
    }
  }, {
    key: "isLoaded",
    get: function get() {
      return this._isLoaded && !this._needsReload;
    }
  }, {
    key: "isLoading",
    get: function get() {
      return Boolean(this._loader) && !this._isCancelled;
    }
  }, {
    key: "needsReload",
    get: function get() {
      return this._needsReload || this._isCancelled;
    }
  }, {
    key: "byteLength",
    get: function get() {
      var result = this.content ? this.content.byteLength : 0;

      if (!Number.isFinite(result)) {
        console.error('byteLength not defined in tile data');
      }

      return result;
    }
  }, {
    key: "_loadData",
    value: function () {
      var _loadData2 = _asyncToGenerator(_regeneratorRuntime.mark(function _callee(_ref) {
        var getData, requestScheduler, onLoad, onError, index, id, bbox, userData, zoom, loaderId, signal, requestToken, tileData, error;
        return _regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                getData = _ref.getData, requestScheduler = _ref.requestScheduler, onLoad = _ref.onLoad, onError = _ref.onError;
                index = this.index, id = this.id, bbox = this.bbox, userData = this.userData, zoom = this.zoom;
                loaderId = this._loaderId;
                this._abortController = new AbortController();
                signal = this._abortController.signal;
                _context.next = 7;
                return requestScheduler.scheduleRequest(this, function (tile) {
                  return tile.isSelected ? 1 : -1;
                });

              case 7:
                requestToken = _context.sent;

                if (requestToken) {
                  _context.next = 11;
                  break;
                }

                this._isCancelled = true;
                return _context.abrupt("return");

              case 11:
                if (!this._isCancelled) {
                  _context.next = 14;
                  break;
                }

                requestToken.done();
                return _context.abrupt("return");

              case 14:
                tileData = null;
                _context.prev = 15;
                _context.next = 18;
                return getData({
                  index: index,
                  id: id,
                  bbox: bbox,
                  userData: userData,
                  zoom: zoom,
                  signal: signal
                });

              case 18:
                tileData = _context.sent;
                _context.next = 24;
                break;

              case 21:
                _context.prev = 21;
                _context.t0 = _context["catch"](15);
                error = _context.t0 || true;

              case 24:
                _context.prev = 24;
                requestToken.done();
                return _context.finish(24);

              case 27:
                if (!(loaderId !== this._loaderId)) {
                  _context.next = 29;
                  break;
                }

                return _context.abrupt("return");

              case 29:
                this._loader = undefined;
                this.content = tileData;

                if (!(this._isCancelled && !tileData)) {
                  _context.next = 34;
                  break;
                }

                this._isLoaded = false;
                return _context.abrupt("return");

              case 34:
                this._isLoaded = true;
                this._isCancelled = false;

                if (error) {
                  onError(error, this);
                } else {
                  onLoad(this);
                }

              case 37:
              case "end":
                return _context.stop();
            }
          }
        }, _callee, this, [[15, 21, 24, 27]]);
      }));

      function _loadData(_x) {
        return _loadData2.apply(this, arguments);
      }

      return _loadData;
    }()
  }, {
    key: "loadData",
    value: function loadData(opts) {
      this._isLoaded = false;
      this._isCancelled = false;
      this._needsReload = false;
      this._loaderId++;
      this._loader = this._loadData(opts);
      return this._loader;
    }
  }, {
    key: "setNeedsReload",
    value: function setNeedsReload() {
      if (this.isLoading) {
        this.abort();
        this._loader = undefined;
      }

      this._needsReload = true;
    }
  }, {
    key: "abort",
    value: function abort() {
      var _this$_abortControlle;

      if (this.isLoaded) {
        return;
      }

      this._isCancelled = true;
      (_this$_abortControlle = this._abortController) === null || _this$_abortControlle === void 0 ? void 0 : _this$_abortControlle.abort();
    }
  }]);

  return Tile2DHeader;
}();